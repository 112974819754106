import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for dynamic meta tags
import Login from './Login';
import Home from './Home';
import Cart from './Cart';
import Guides from './Guides';
import More from './More';
import Shop from './Shop';
import ItemDetails from './ItemDetails';
import Inventory from './Inventory';
import Orders from './Orders';
import Pricing from './Pricing';
import Demo from './Demo';
import FoodCostingTemplateInstructions from './FoodCostingTemplateInstructions';
import AboutUs from './AboutUs';
import Signup from './Signup';
import MOQTracker from './MOQTracker';
import PurchaseHistory from './PurchaseHistory';
import AlternateProducts from './AlternateProducts';
import GetDemo from './GetDemo';
import Lists from './Lists';
import Blog from './Blog';
import BlogPage from './BlogPage'; // Import BlogPage
import Solutions from './Solutions';
import CaseStudies from './CaseStudies';
import Checkout from './Checkout';
import Returns from './Returns';
import StartReturn from './StartReturn';
import LandingPage from './LandingPage';
import Features from './Features';
import ContactUs from './ContactUs';
import Settings from './Settings';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Press from './Press'; // Import Press
import Calculator from './Calculator'; // Import Calculator
import { CartProvider } from './CartContext';
import ProtectedRoute from './ProtectedRoute';

import './styles/global.css';

// Import Roboto font weights
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';

// Import Material UI ThemeProvider and custom theme
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

// Import NavBars
import SalesNavBar from './SalesNavBar';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CartProvider>
        {/* Add Helmet to include global og:url meta tag */}
        <Helmet>
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/"
            element={
              <>
                <SalesNavBar />
                <LandingPage />
              </>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/signup"
            element={
              <>
                <SalesNavBar />
                <Signup />
              </>
            }
          />
          <Route
            path="/features"
            element={
              <>
                <SalesNavBar />
                <Features />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <SalesNavBar />
                <Pricing />
              </>
            }
          />
          <Route
            path="/calculator"
            element={
              <>
                <SalesNavBar />
                <Calculator />
              </>
            }
          />
          <Route
            path="/aboutus"
            element={
              <>
                <SalesNavBar />
                <AboutUs />
              </>
            }
          />
          <Route
            path="/solutions"
            element={
              <>
                <SalesNavBar />
                <Solutions />
              </>
            }
          />
          <Route
            path="/blogs/food-for-thought"
            element={
              <>
                <SalesNavBar />
                <Blog />
              </>
            }
          />
          {/* Updated Route for individual blog posts using handle */}
          <Route
            path="/blogs/food-for-thought/:handle/"
            element={
              <>
                <SalesNavBar />
                <BlogPage />
              </>
            }
          />
          <Route
            path="/press"
            element={
              <>
                <SalesNavBar />
                <Press />
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <>
                <SalesNavBar />
                <ContactUs />
              </>
            }
          />
          <Route
            path="/demo"
            element={
              <>
                <SalesNavBar />
                <Demo />
              </>
            }
          />
          <Route
            path="/get-demo"
            element={
              <>
                <SalesNavBar />
                <GetDemo />
              </>
            }
          />
          <Route
            path="/case-studies"
            element={
              <>
                <SalesNavBar />
                <CaseStudies />
              </>
            }
          />
          {/* Terms and Privacy Policy */}
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <SalesNavBar />
                <TermsAndConditions />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <SalesNavBar />
                <PrivacyPolicy />
              </>
            }
          />
          {/* Settings Route */}
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          {/* Protected Routes */}
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/more"
            element={
              <ProtectedRoute>
                <More />
              </ProtectedRoute>
            }
          />
          <Route
            path="/shop"
            element={
              <ProtectedRoute>
                <Shop />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product/:productId"
            element={
              <ProtectedRoute>
                <ItemDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/moq-tracker"
            element={
              <ProtectedRoute>
                <MOQTracker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/alternateproducts/:productId"
            element={
              <ProtectedRoute>
                <AlternateProducts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchase-history"
            element={
              <ProtectedRoute>
                <PurchaseHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lists"
            element={
              <ProtectedRoute>
                <Lists />
              </ProtectedRoute>
            }
          />
          <Route
            path="/guides"
            element={
              <ProtectedRoute>
                <Guides />
              </ProtectedRoute>
            }
          />
          <Route
            path="/returns"
            element={
              <ProtectedRoute>
                <Returns />
              </ProtectedRoute>
            }
          />
          <Route
            path="/start-return"
            element={
              <ProtectedRoute>
                <StartReturn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/food-costing-template-instructions"
            element={
              <>
                <SalesNavBar />
                <FoodCostingTemplateInstructions />
              </>
            }
          />

          {/* Redirect any unknown routes to LandingPage */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </CartProvider>
    </ThemeProvider>
  );
}

export default App;
