import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';

import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ListAlt as ListAltIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import AddToCartButton from './AddToCartButton';
import fallbackLogo from './assets/small-logo.avif';

/**
 * Helper: getAuthHeadersAndUser
 */
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }

  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };

  return { user, headers };
}

/**
 * InventoryProductCard
 */
function InventoryProductCard({
  productInfo = {},
  item = {},
  multiplier = 1,
  restaurantId,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  onCompareClick,
  compareOpen = false,
  onInventoryDeleted,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Image error handling
  const [imageError, setImageError] = useState(false);

  // Add to List states
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // **NEW**: We'll also show a Snackbar (toast) after the user adds an item to a list
  const [listAddToast, setListAddToast] = useState('');

  // Confirm delete states
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteInventorySuccess, setDeleteInventorySuccess] = useState('');
  const [deleteInventoryError, setDeleteInventoryError] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  // Removed from UI if user deletes item
  const [isDeleted, setIsDeleted] = useState(false);

  // Local states for stock & par
  const [stockLocal, setStockLocal] = useState(
    productInfo.stock ?? item.On_Hand ?? 0
  );
  const [parLocal, setParLocal] = useState(
    productInfo.par ?? item.PAR ?? 0
  );

  // Edit stock/PAR modal states
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editStock, setEditStock] = useState('');
  const [editPar, setEditPar] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [updateError, setUpdateError] = useState('');

  // Destructure from productInfo
  const {
    inventory_item_id,
    main_product_id,
    short_description,
    ff_catalog_number,
    brand,
    purchase_price = 0,
    case_priced_by,
    each_price = 0,
    each_priced_by,
    product_volume,
    product_volume_priced_by,
    vendor_name,
    vendor_logo,
    image_url,
    lead_time,
    special_notes,
    hasalternates,
    bestdeal, // for "Best Price" logic
  } = productInfo;

  const finalInventoryItemId = inventory_item_id;
  const finalProductId = main_product_id ?? item.main_product_id ?? null;

  // If user actually deleted, don't render anything
  if (isDeleted) {
    return null;
  }

  // user-defined if no product_id
  const isUserDefinedItem = !finalProductId;

  // Favorites logic
  const isFavorited = !isUserDefinedItem && !!favoritesMap[finalProductId];

  // On card click => navigate if not user-defined
  const handleCardClick = () => {
    if (isUserDefinedItem) return;
    navigate(`/product/${finalProductId}`);
  };

  // Favorite
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (!restaurantId) return;
    if (isFavorited) {
      onRemoveFavorite?.(finalProductId);
    } else {
      onAddFavorite?.(finalProductId);
    }
  };

  // ----------------
  // Add to List
  // ----------------
  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    if (isUserDefinedItem) return; // no product to add
    setOpenAddListDialog(true);
    fetchLists();
  };
  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id: finalProductId,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      // Also set our toast message
      setListAddToast('Item successfully added to the list!');

      // Wait a bit, then close the dialog
      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // ----------------
  // Delete from inventory
  // ----------------
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setConfirmDelete(true);
  };
  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setConfirmDelete(false);
  };
  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    handleDeleteFromInventoryClick();
  };

  async function handleDeleteFromInventoryClick() {
    if (!finalInventoryItemId) return;
    try {
      setDeleteInProgress(true);
      setDeleteInventorySuccess('');
      setDeleteInventoryError('');

      const { headers } = getAuthHeadersAndUser();
      await axios.delete(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory/${finalInventoryItemId}`,
        { headers }
      );

      setDeleteInventorySuccess('Item successfully removed from inventory!');
      onInventoryDeleted?.(finalInventoryItemId);

      setIsDeleted(true);
    } catch (err) {
      console.error('Error deleting inventory item:', err);
      setDeleteInventoryError('Failed to remove item from inventory.');
    } finally {
      setDeleteInProgress(false);
      setConfirmDelete(false);
    }
  }

  // ----------------
  // Edit stock/par
  // ----------------
  const handleOpenEditModal = (e) => {
    e.stopPropagation();
    if (!finalInventoryItemId) return;
    setUpdateSuccess('');
    setUpdateError('');

    setEditStock(stockLocal);
    setEditPar(parLocal);
    setEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setUpdateSuccess('');
    setUpdateError('');
  };

  async function handleStockParUpdate() {
    setUpdateLoading(true);
    setUpdateSuccess('');
    setUpdateError('');

    try {
      const { headers } = getAuthHeadersAndUser();
      const body = {
        inventory_item_id: finalInventoryItemId,
        onhand: Number(editStock),
        par: Number(editPar),
      };

      const response = await axios.patch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/update_inventory_item',
        body,
        { headers }
      );

      const updatedItem = response.data;
      setUpdateSuccess('Stock/PAR updated successfully!');

      // update local states
      setStockLocal(updatedItem.On_Hand ?? 0);
      setParLocal(updatedItem.PAR ?? 0);

      setTimeout(() => {
        handleCloseEditModal();
      }, 1200);
    } catch (error) {
      console.error('Error updating stock/par:', error);
      setUpdateError('Failed to update stock/par. Please try again.');
    } finally {
      setUpdateLoading(false);
    }
  }

  // Fallback image
  const finalImageUrl = imageError || !image_url ? fallbackLogo : image_url;
  const handleImageError = () => setImageError(true);

  // Show a red border if stock <= par
  const showOrderNowFlag = finalInventoryItemId && stockLocal <= parLocal;

  // Compare styling
  const compareArrow = compareOpen ? '▲' : '▼';
  const compareButtonColor = compareOpen ? '#006D6D' : '#949E98';

  // If bestdeal === false => highlight "Better Deals Available" with orange background + white text
  const compareButtonStyles =
    bestdeal === false
      ? {
          color: '#ffffff',
          backgroundColor: '#F87906',
        }
      : {
          color: compareButtonColor,
        };

  // Compare button text
  const compareButtonText = bestdeal === false ? 'Better Deals Available' : 'Compare';

  // ---------------
  // PRICING LOGIC
  // ---------------
  // 1) Main "Case" label
  const casePriceLabel =
    purchase_price <= 0
      ? 'MARKET'
      : case_priced_by?.toLowerCase() === 'lb'
      ? `$${(purchase_price * multiplier).toFixed(2)} LB`
      : `$${(purchase_price * multiplier).toFixed(2)} ${case_priced_by || 'CS'}`;

  // 2) second line with cost per unit, if needed
  let casePPULabel = null;
  if (purchase_price > 0 && case_priced_by?.toLowerCase() === 'lb') {
    const pricePerOz = (purchase_price * multiplier) / 16;
    casePPULabel = `$${pricePerOz.toFixed(2)}/oz`;
  } else if (
    purchase_price > 0 &&
    product_volume &&
    product_volume > 0 &&
    product_volume_priced_by
  ) {
    const ppu = (purchase_price * multiplier) / product_volume;
    casePPULabel = `$${ppu.toFixed(2)}/${product_volume_priced_by}`;
  }

  // "each" row
  const eachPriceLabel =
    each_price > 0
      ? `$${(each_price * multiplier).toFixed(2)} ${each_priced_by || 'EA'}`
      : null;

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'box-shadow 0.2s',
          backgroundColor: 'transparent',
          border: showOrderNowFlag
            ? '2px solid #E92409'
            : compareOpen
            ? `2px solid ${theme.palette.grey[400]}`
            : `1px solid ${theme.palette.divider}`,
          '&:hover': {
            boxShadow: theme.shadows[4],
            backgroundColor: alpha(theme.palette.background.paper, 0.1),
          },
        }}
        onClick={handleCardClick}
      >
        {/* Vendor Logo pinned top-right (only if we have a real product_id) */}
        {!isUserDefinedItem && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: '#fff',
              overflow: 'hidden',
              boxShadow: theme.shadows[2],
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={vendor_logo || fallbackLogo}
              alt={vendor_name || 'Vendor'}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackLogo;
              }}
            />
          </Box>
        )}

        {/**
         * TOP-LEFT ICON ROW:
         * - Favorites (heart)
         * - Add to List
         * - Delete confirm/cancel or delete icon
         */}
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(1),
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 0,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Favorites Heart (only if there's a product_id) */}
          {!isUserDefinedItem && (
            <IconButton
              sx={{
                color: '#E92409',
                p: 0.5,
                '&:hover': {
                  color: alpha('#E92409', 0.7),
                },
              }}
              onClick={handleFavoriteClick}
            >
              {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          )}

          {/* Add to List (only if not user-defined) */}
          {!isUserDefinedItem && (
            <IconButton
              sx={{
                color: '#006D6D',
                p: 0.5,
                '&:hover': {
                  color: alpha('#006D6D', 0.7),
                },
              }}
              onClick={handleOpenListDialog}
            >
              <ListAltIcon />
            </IconButton>
          )}

          {/* Delete confirm/deny for inventory_item_id */}
          {finalInventoryItemId && (
            <>
              {confirmDelete ? (
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  <IconButton
                    onClick={handleConfirmDelete}
                    sx={{ color: theme.palette.success.main, p: '2px' }}
                    aria-label="confirm delete"
                    size="small"
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    onClick={handleCancelDelete}
                    sx={{ color: theme.palette.grey[500], p: '2px' }}
                    aria-label="cancel delete"
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <IconButton
                  onClick={handleDeleteClick}
                  sx={{
                    color: alpha(theme.palette.text.primary, 0.5),
                    p: 0.5,
                  }}
                  aria-label="delete from inventory"
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </>
          )}
        </Box>

        {/* Main Content */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ p: theme.spacing(0.25) }}>
            {/* Product Image */}
            <Box sx={{ position: 'relative', mt: 4 }}>
              <CardMedia
                component="img"
                height="80"
                image={finalImageUrl}
                alt={short_description || 'Product image'}
                loading="lazy"
                onError={handleImageError}
                sx={{
                  objectFit: 'contain',
                  backgroundColor: 'transparent',
                  borderRadius: theme.shape.borderRadius,
                }}
              />

              {/* Catalog Number pinned top-left */}
              {ff_catalog_number && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: theme.spacing(0.5),
                    left: theme.spacing(0.5),
                    backgroundColor: 'white',
                    padding: theme.spacing(0.125, 0.25),
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#949E98',
                      fontFamily: 'Poppins',
                      fontSize: '7pt',
                    }}
                  >
                    {ff_catalog_number}
                  </Typography>
                </Box>
              )}

              {/* Lead Time & Special Notes pinned bottom-right */}
              {(lead_time > 0 ||
                (special_notes && !/no/i.test(special_notes))) && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    right: theme.spacing(1),
                    display: 'flex',
                    gap: 0.5,
                  }}
                >
                  {lead_time > 0 && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: theme.shadows[1],
                      }}
                    >
                      <AvTimerIcon
                        sx={{
                          fontSize: '1rem',
                          color: '#F87906',
                        }}
                      />
                    </Box>
                  )}
                  {special_notes && !/no/i.test(special_notes) && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: theme.shadows[1],
                      }}
                    >
                      <NoteAltIcon
                        sx={{
                          fontSize: '1rem',
                          color: '#F87906',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}

              {/* "Best Price" banner if bestdeal == true */}
              {bestdeal && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    left: theme.spacing(1),
                    backgroundColor: '#00BC48',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    zIndex: 3,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ffffff',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '0.75rem',
                    }}
                  >
                    Best Price
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Brand & Description */}
            <Box sx={{ mt: 1, px: 1 }}>
              {brand && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#3A3F3F',
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    mb: 0.1,
                  }}
                >
                  {brand}
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: '#131616',
                  fontSize: '9pt',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  lineHeight: 1.3,
                  minHeight: '2.6em',
                  mb: 0,
                }}
              >
                {short_description || ''}
              </Typography>

              {/* Stock/PAR row + edit icon */}
              {finalInventoryItemId ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 0.25,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '10pt',
                      fontWeight: 600,
                      color: '#131616',
                    }}
                  >
                    Stock: {stockLocal}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '10pt',
                      fontWeight: 600,
                      color: '#131616',
                    }}
                  >
                    PAR: {parLocal}
                  </Typography>

                  <IconButton
                    size="small"
                    sx={{
                      color: alpha('#006D6D', 0.8),
                      '&:hover': {
                        color: alpha('#006D6D', 0.5),
                      },
                    }}
                    onClick={handleOpenEditModal}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 0.25,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8pt',
                      color: '#949E98',
                    }}
                  >
                    Stock: —
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8pt',
                      color: '#949E98',
                    }}
                  >
                    PAR: —
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Price & AddToCart (Shop-like) */}
            <Box sx={{ mt: 1, px: 1 }} onClick={(e) => e.stopPropagation()}>
              {/* CASE row */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: eachPriceLabel ? 0.5 : 0,
                }}
              >
                {/* Left: case price & optional PPU */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: '9pt',
                      color: '#006D6D',
                    }}
                  >
                    {casePriceLabel}
                  </Typography>
                  {casePPULabel && (
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '7pt',
                        color: '#949E98',
                      }}
                    >
                      ({casePPULabel})
                    </Typography>
                  )}
                </Box>

                {/* Right: AddToCart (only if we have a real product) */}
                {!isUserDefinedItem && (
                  <AddToCartButton
                    productId={finalProductId}
                    isSplit={false}
                    buttonLabel="Case"
                  />
                )}
              </Box>

              {/* EACH row if each_price > 0 */}
              {eachPriceLabel && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Left: each price */}
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: '9pt',
                      color: '#006D6D',
                    }}
                  >
                    {eachPriceLabel}
                  </Typography>

                  {/* Right: AddToCart with isSplit */}
                  {!isUserDefinedItem && (
                    <AddToCartButton
                      productId={finalProductId}
                      isSplit
                      buttonLabel="Each"
                    />
                  )}
                </Box>
              )}
            </Box>
          </CardContent>
        </Box>

        {/* Compare Button (if hasalternates) */}
        <Box
          sx={{
            py: 0.5,
            textAlign: 'center',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {hasalternates ? (
            <Button
              variant="text"
              sx={{
                ...compareButtonStyles,
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '0.6rem',
                textTransform: 'none',
                paddingX: 1,
                paddingY: 0.25,
                minHeight: 'auto',
                borderRadius: 2,
              }}
              onClick={() => onCompareClick?.(finalProductId)}
            >
              {compareButtonText} {compareArrow}
            </Button>
          ) : (
            <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
              Compare ▼
            </Box>
          )}
        </Box>
      </Card>

      {/* -------------------- Dialogs & Snackbars -------------------- */}

      {/* Add to List Dialog */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Select a List
        </DialogTitle>
        <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText
                      primary={list.list_name}
                      primaryTypographyProps={{
                        fontFamily: theme.typography.fontFamily,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert
              severity="success"
              sx={{
                mt: theme.spacing(2),
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseListDialog}
            disabled={addingToList}
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* EDIT STOCK/PAR DIALOG */}
      <Dialog
        open={editModalOpen}
        onClose={handleCloseEditModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Edit Stock & PAR
        </DialogTitle>
        <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
          {updateError && <Alert severity="error">{updateError}</Alert>}
          {updateSuccess && <Alert severity="success">{updateSuccess}</Alert>}

          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Stock (On Hand)"
              type="number"
              value={editStock}
              onChange={(e) => setEditStock(e.target.value)}
              inputProps={{ min: 0 }}
              sx={{ fontFamily: theme.typography.fontFamily }}
            />
            <TextField
              label="PAR Level"
              type="number"
              value={editPar}
              onChange={(e) => setEditPar(e.target.value)}
              inputProps={{ min: 0 }}
              sx={{ fontFamily: theme.typography.fontFamily }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditModal}
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleStockParUpdate}
            disabled={updateLoading}
            variant="contained"
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            {updateLoading ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* SNACKBARS FOR DELETING FROM INVENTORY */}
      {deleteInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setDeleteInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDeleteInventorySuccess('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {deleteInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {deleteInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setDeleteInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDeleteInventoryError('')}
            severity="error"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {deleteInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Snackbar for "Add to List" success (outside the Dialog) */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default InventoryProductCard;
