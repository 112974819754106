// CartItem.js
import React, { useState, useContext } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ListAlt as ListAltIcon,
  Inventory as InventoryIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { CartContext } from './CartContext';  // <-- IMPORT CartContext
import AddToCartButton from './AddToCartButton';
import fallbackLogo from './assets/small-logo.avif';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

// --------------
// Auth helper
// --------------
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }
  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

// --------------
// ProductImageSection
// --------------
function ProductImageSection({ product }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { image_url, vendor_logo, vendor_name, short_description, BestDeal } = product;

  // Fallbacks
  const productImage = image_url || fallbackLogo;
  const vendorLogo = vendor_logo || fallbackLogo;
  const vendorShortName = vendor_name || 'Vendor';

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '15%' },
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: isMobile ? 1 : 0,
      }}
    >
      {/* Product image */}
      <Box
        component="img"
        src={productImage}
        alt={short_description || 'Product'}
        sx={{
          width: isMobile ? 90 : 60,
          height: isMobile ? 90 : 60,
          objectFit: 'cover',
          borderRadius: 1,
        }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackLogo;
        }}
      />

      {/* Vendor logo pinned on top-right */}
      <Box
        sx={{
          position: 'absolute',
          top: '15%',
          right: 0,
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: '#fff',
          overflow: 'hidden',
          boxShadow: theme.shadows[2],
          zIndex: 2,
          transform: 'translate(-25%, -50%)',
        }}
      >
        <Box
          component="img"
          src={vendorLogo}
          alt={vendorShortName}
          sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackLogo;
          }}
        />
      </Box>

      {/* "Best Price" banner if BestDeal === true */}
      {BestDeal && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: '#00BC48',
            display: 'inline-flex',
            alignItems: 'center',
            padding: '2px 8px',
            borderRadius: '4px',
            zIndex: 3,
            margin: '4px',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: '#ffffff',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              fontFamily: 'Poppins',
            }}
          >
            Best Price
          </Typography>
        </Box>
      )}
    </Box>
  );
}

// --------------
// PriceLine
// --------------
function PriceLine({ price, perUnit }) {
  const theme = useTheme();

  // If price === 'MARKET', show that in big teal letters
  if (price === 'MARKET') {
    return (
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '9pt',
          fontWeight: 'bold',
          color: '#006D6D',
        }}
      >
        MARKET
      </Typography>
    );
  }

  // Otherwise, numeric price
  return (
    <Box>
      <Typography
        component="span"
        sx={{
          fontFamily: 'Poppins',
          fontSize: '9pt',
          fontWeight: 'bold',
          color: '#006D6D',
        }}
      >
        {price}
      </Typography>
      {perUnit && (
        <Typography
          component="span"
          sx={{
            fontFamily: 'Poppins',
            fontSize: '7pt',
            color: '#949E98',
            marginLeft: '6px',
          }}
        >
          ({perUnit})
        </Typography>
      )}
    </Box>
  );
}

// --------------
// CartItem
// --------------
function CartItem({
  product,
  multiplier = 1,
  canExpand = false,
  onExpand,
  restaurantId,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  onCompareClick,
  compareOpen = false,

  // Callback to refresh cart in parent
  onQuantityChange,

  // These are no longer used for direct deletion
  orderitem_id,
  duplicate_order_item_ids,

  // Optional: Current total quantity
  quantity,
}) {
  const {
    main_product_id,
    short_description,
    ff_catalog_number,
    brand,
    purchase_price,
    case_priced_by,
    each_price,
    each_priced_by,
    price_per_unit,
    price_per_unit_priced_by,
    product_pack_size,
    hasalternates,
    lead_time,
    special_notes,
    BestDeal,
  } = product;

  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Access the cart to figure out how many "cases" or "each" are in the cart
  const { cartItems, handleAddToCart } = useContext(CartContext);

  // We find the line items for this product in the cart:
  const caseItem = cartItems.find(
    (item) => item.product_id === main_product_id && item.Split_Item === false
  );
  const eachItem = cartItems.find(
    (item) => item.product_id === main_product_id && item.Split_Item === true
  );

  // If found, we read .quantity, else zero
  const caseQuantity = caseItem ? caseItem.quantity : 0;
  const eachQuantity = eachItem ? eachItem.quantity : 0;

  // Compare highlight
  const compareBorder = compareOpen ? `2px solid ${theme.palette.grey[400]}` : 'none';
  const compareArrow = compareOpen ? '▲' : '▼';

  // If BestDeal === false => orange background & white text
  const compareButtonStyles =
    BestDeal === false
      ? { color: 'white', backgroundColor: '#F87906' }
      : { color: compareOpen ? '#006D6D' : '#949E98' };
  const compareButtonText = BestDeal === false ? 'Better Deals Available' : 'Compare';

  // Click anywhere on the row => go to product detail
  const handleRowClick = () => {
    navigate(`/product/${main_product_id}`);
  };

  // ------------------------------------
  // Favorites logic
  // ------------------------------------
  const isFavorited = !!favoritesMap[main_product_id];
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (!restaurantId) return;
    if (isFavorited) onRemoveFavorite(main_product_id);
    else onAddFavorite(main_product_id);
  };

  // ------------------------------------
  // Add to List
  // ------------------------------------
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };
  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id: main_product_id,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      setTimeout(() => handleCloseListDialog(), 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // ------------------------------------
  // Add to Inventory
  // ------------------------------------
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryInProgress, setAddInventoryInProgress] = useState(false);

  const handleAddToInventoryClick = async (e) => {
    e.stopPropagation();
    try {
      setAddInventoryInProgress(true);
      setAddInventorySuccess('');
      setAddInventoryError('');
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }
      const data = {
        restaurant_id: user.restaurant_id,
        item_description: short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id: main_product_id,
      };
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );
      setAddInventorySuccess('Item successfully added to inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    } finally {
      setAddInventoryInProgress(false);
    }
  };

  // ------------------------------------
  // Helper to remove the "case" or "each" from cart by setting quantity=0
  // ------------------------------------
  const handleRemoveCase = async (e) => {
    e.stopPropagation();
    await handleAddToCart(main_product_id, 0, false);
    onQuantityChange?.();
  };

  const handleRemoveEach = async (e) => {
    e.stopPropagation();
    await handleAddToCart(main_product_id, 0, true);
    onQuantityChange?.();
  };

  // ------------------------------------
  // Price logic
  // ------------------------------------
  let casePrice = 'MARKET';
  let casePerUnit = null;

  if (purchase_price > 0) {
    const numeric = (purchase_price * multiplier).toFixed(2);
    const suffix = case_priced_by || 'CS';
    casePrice = `$${numeric} ${suffix}`;

    if (price_per_unit > 0) {
      casePerUnit = `$${(price_per_unit * multiplier).toFixed(2)}/${
        price_per_unit_priced_by || ''
      }`;
    }
  }

  // For “each”
  let eachLine = null;
  if (each_price > 0) {
    const numericEach = (each_price * multiplier).toFixed(2);
    const suffixEach = each_priced_by || 'EA';
    eachLine = `$${numericEach} ${suffixEach}`;
  }

  // brand | pack_size | ff_catalog_number
  const rawBrand = brand === '0' ? '' : brand || '';
  const rawCatalog = ff_catalog_number === '0' ? '' : ff_catalog_number;
  const brandLineArr = [rawBrand, product_pack_size, rawCatalog].filter(Boolean);
  const brandLine = brandLineArr.join(' | ');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          padding: 1,
          position: 'relative',
          overflow: 'hidden',
          border: compareOpen ? compareBorder : 'none',
          borderBottom: '1px solid #e0e0e0',
          cursor: 'pointer',
        }}
        onClick={handleRowClick}
      >
        {/* Optional Expand icon */}
        {canExpand && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              onExpand?.(main_product_id);
            }}
          >
            <ExpandMoreIcon
              sx={{
                transform: false ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              }}
            />
          </IconButton>
        )}

        <ProductImageSection product={product} />

        {/* DESKTOP layout */}
        {!isMobile && (
          <>
            <Box
              sx={{
                flex: 1,
                paddingLeft: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {/* Icons row */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0,
                  marginBottom: theme.spacing(0.5),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {/* Favorite icon */}
                <IconButton
                  onClick={handleFavoriteClick}
                  sx={{
                    color: '#E92409',
                    p: 0.5,
                    '&:hover': { color: alpha('#E92409', 0.7) },
                  }}
                >
                  {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>

                <IconButton
                  onClick={handleOpenListDialog}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': { color: alpha('#006D6D', 0.7) },
                  }}
                >
                  <ListAltIcon />
                </IconButton>

                <IconButton
                  onClick={handleAddToInventoryClick}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': {
                      color: alpha(theme.palette.primary.main, 0.7),
                    },
                  }}
                >
                  <InventoryIcon />
                </IconButton>
              </Box>

              {/* Description */}
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '9pt',
                  color: '#131616',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  paddingRight: theme.spacing(4),
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {short_description || ''}
              </Typography>

              {/* Brand line, lead_time, etc. */}
              {brandLine && (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    color: '#3A3F3F',
                    mt: 0.25,
                    paddingRight: theme.spacing(4),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {brandLine}

                  {lead_time > 0 && (
                    <Tooltip title={`Lead Time: ${lead_time}`}>
                      <AvTimerIcon
                        sx={{
                          fontSize: '1rem',
                          color: '#F87906',
                          ml: 0.5,
                        }}
                      />
                    </Tooltip>
                  )}

                  {special_notes && special_notes !== 'No Special Flags' && (
                    <Tooltip title={special_notes}>
                      <NoteAltIcon
                        sx={{
                          fontSize: '1rem',
                          color: '#F87906',
                          ml: 0.5,
                        }}
                      />
                    </Tooltip>
                  )}
                </Typography>
              )}

              {/* Compare button (Desktop) */}
              <Box sx={{ py: 0.5, textAlign: 'left' }}>
                {hasalternates ? (
                  <Button
                    variant="text"
                    sx={{
                      ...compareButtonStyles,
                      fontFamily: 'Poppins',
                      fontWeight: 600,
                      fontSize: '0.6rem',
                      textTransform: 'none',
                      paddingX: 1,
                      paddingY: 0.25,
                      minHeight: 'auto',
                      borderRadius: 2,
                      mt: 0.25,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCompareClick?.(main_product_id);
                    }}
                  >
                    {compareButtonText} {compareArrow}
                  </Button>
                ) : (
                  <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
                    Compare ▼
                  </Box>
                )}
              </Box>
            </Box>

            {/* Right column: Price lines + AddToCart + Delete icons */}
            <Box
              sx={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {/* CASE line */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {/* Left (Price) */}
                <PriceLine price={casePrice} perUnit={casePerUnit} />
                {/* Right (Trash icon + AddToCart) */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {caseQuantity > 1 && (
                    <Tooltip title="Remove all cases from cart">
                      <IconButton
                        onClick={handleRemoveCase}
                        sx={{
                          color: theme.palette.grey[500],
                          p: 0.5,
                          '&:hover': { color: alpha(theme.palette.grey[600], 0.7) },
                        }}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <AddToCartButton
                    productId={main_product_id}
                    isSplit={false}
                    buttonLabel="Case"
                    onQuantityChange={onQuantityChange}
                    sx={{ width: 'auto', minWidth: 'unset' }}
                  />
                </Box>
              </Box>

              {/* EACH line (if present) */}
              {each_price > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  {/* Left (Price) */}
                  <PriceLine price={eachLine} perUnit={null} />
                  {/* Right (Trash icon + AddToCart) */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {eachQuantity > 1 && (
                      <Tooltip title="Remove all eaches from cart">
                        <IconButton
                          onClick={handleRemoveEach}
                          sx={{
                            color: theme.palette.grey[500],
                            p: 0.5,
                            '&:hover': { color: alpha(theme.palette.grey[600], 0.7) },
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <AddToCartButton
                      productId={main_product_id}
                      isSplit
                      buttonLabel="Each"
                      onQuantityChange={onQuantityChange}
                      sx={{ width: 'auto', minWidth: 'unset' }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}

        {/* MOBILE layout */}
        {isMobile && (
          <Box sx={{ width: '100%', mt: 1 }}>
            {/* Icons row */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                marginBottom: theme.spacing(0.5),
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Favorite icon */}
              <IconButton
                onClick={handleFavoriteClick}
                sx={{
                  color: '#E92409',
                  p: 0.5,
                  '&:hover': { color: alpha('#E92409', 0.7) },
                }}
              >
                {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>

              <IconButton
                onClick={handleOpenListDialog}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': { color: alpha('#006D6D', 0.7) },
                }}
              >
                <ListAltIcon />
              </IconButton>

              <IconButton
                onClick={handleAddToInventoryClick}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': { color: alpha(theme.palette.primary.main, 0.7) },
                }}
              >
                <InventoryIcon />
              </IconButton>
            </Box>

            {/* Short description */}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '9pt',
                color: '#131616',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {short_description || ''}
            </Typography>

            {/* brand line, lead_time, special_notes */}
            {brandLine && (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '8pt',
                  color: '#3A3F3F',
                  mt: 0.25,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {brandLine}

                {lead_time > 0 && (
                  <Tooltip title={`Lead Time: ${lead_time}`}>
                    <AvTimerIcon
                      sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                    />
                  </Tooltip>
                )}

                {special_notes && special_notes !== 'No Special Flags' && (
                  <Tooltip title={special_notes}>
                    <NoteAltIcon
                      sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                    />
                  </Tooltip>
                )}
              </Typography>
            )}

            {/* Price lines + AddToCart, vertical stack */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                mt: 1,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* CASE line */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {/* Left (Price) */}
                <PriceLine price={casePrice} perUnit={casePerUnit} />
                {/* Right (Trash icon + AddToCart) */}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {caseQuantity > 1 && (
                    <Tooltip title="Remove all cases from cart">
                      <IconButton
                        onClick={handleRemoveCase}
                        sx={{
                          color: theme.palette.grey[500],
                          p: 0.5,
                          '&:hover': { color: alpha(theme.palette.grey[600], 0.7) },
                        }}
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <AddToCartButton
                    productId={main_product_id}
                    isSplit={false}
                    buttonLabel="Case"
                    onQuantityChange={onQuantityChange}
                    sx={{ width: 'auto', minWidth: 'unset' }}
                  />
                </Box>
              </Box>

              {/* If each_price > 0 => each line */}
              {each_price > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  {/* Left (Price) */}
                  <PriceLine price={eachLine} perUnit={null} />
                  {/* Right (Trash icon + AddToCart) */}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {eachQuantity > 1 && (
                      <Tooltip title="Remove all eaches from cart">
                        <IconButton
                          onClick={handleRemoveEach}
                          sx={{
                            color: theme.palette.grey[500],
                            p: 0.5,
                            '&:hover': { color: alpha(theme.palette.grey[600], 0.7) },
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <AddToCartButton
                      productId={main_product_id}
                      isSplit
                      buttonLabel="Each"
                      onQuantityChange={onQuantityChange}
                      sx={{ width: 'auto', minWidth: 'unset' }}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            {/* Compare button (Mobile) 
                - Centered below pricing, above bottom border */}
            <Box
              sx={{
                mt: 2,
                mb: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {hasalternates ? (
                <Button
                  variant="text"
                  sx={{
                    ...compareButtonStyles,
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    fontSize: '0.6rem',
                    textTransform: 'none',
                    paddingX: 1,
                    paddingY: 0.25,
                    minHeight: 'auto',
                    borderRadius: 2,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onCompareClick?.(main_product_id);
                  }}
                >
                  {compareButtonText} {compareArrow}
                </Button>
              ) : (
                <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
                  Compare ▼
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {/* ------------ Add to List Dialog ------------ */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select a List</DialogTitle>
        <DialogContent>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText primary={list.list_name} />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography variant="body2" align="center">
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseListDialog} disabled={addingToList}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* ---------- Inventory & Add-to-list Success/Errors ----------- */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{ width: '100%' }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default CartItem;
