import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Card,
  Divider,
  Alert,
  Button,
  CircularProgress,
  Avatar,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  LockOpen as AddLoginIcon,
  Close as CloseIcon,
  Add as AddIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

function ConnectSuppliers() {
  const theme = useTheme();

  // Grab user + restaurant from localStorage
  const userData = JSON.parse(localStorage.getItem('user')) || {};
  const { restaurant_id: restaurantId, id: userId } = userData;

  // For listing existing connected vendors
  const [connectedVendors, setConnectedVendors] = useState([]);
  const [loadingConnectedVendors, setLoadingConnectedVendors] = useState(true);
  const [errorConnectedVendors, setErrorConnectedVendors] = useState(null);

  // For editing/deleting login, etc.
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentVendorLogin, setCurrentVendorLogin] = useState(null);
  const [editUsername, setEditUsername] = useState('');
  const [editUsernameConfirm, setEditUsernameConfirm] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editPasswordConfirm, setEditPasswordConfirm] = useState('');
  const [isUpdatingLoginInfo, setIsUpdatingLoginInfo] = useState(false);
  const [editLoginError, setEditLoginError] = useState(null);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [vendorLoginToDelete, setVendorLoginToDelete] = useState(null);

  const [deleteVendorConfirmOpen, setDeleteVendorConfirmOpen] = useState(false);
  const [vendorToDelete, setVendorToDelete] = useState(null);

  // For adding a new vendor (wizard)
  const [openAddVendorWizard, setOpenAddVendorWizard] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  // Steps data
  const steps = ['Supplier Information', 'Sales Representative', 'Minimum Requirements'];

  // List of all vendors
  const [vendors, setVendors] = useState([]);
  const [hasAccount, setHasAccount] = useState(false);

  // Wizard form fields
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorUsername, setVendorUsername] = useState('');
  const [vendorPassword, setVendorPassword] = useState('');
  const [vendorUsernameConfirm, setVendorUsernameConfirm] = useState('');
  const [vendorPasswordConfirm, setVendorPasswordConfirm] = useState('');

  const [salesRepName, setSalesRepName] = useState('');
  const [salesRepEmail, setSalesRepEmail] = useState('');

  const [MOQCases, setMOQCases] = useState('');
  const [MOQDollar, setMOQDollar] = useState('');

  const [customVendorName, setCustomVendorName] = useState('');

  // Submit states
  const [isVendorSubmitting, setIsVendorSubmitting] = useState(false);
  const [vendorSubmitError, setVendorSubmitError] = useState(null);
  const [vendorSubmitSuccess, setVendorSubmitSuccess] = useState(false);

  // Restaurant name (for Slack, etc.)
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');

  // Fetch all vendors
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const res = await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/vendor');
        if (!res.ok) throw new Error('Failed to fetch vendors.');
        const data = await res.json();
        setVendors(data);
      } catch (err) {
        console.error('Error fetching vendors:', err);
      }
    };
    fetchVendors();
  }, []);

  // Fetch restaurant name
  useEffect(() => {
    if (!restaurantId) return;
    const fetchRestaurant = async () => {
      try {
        const resp = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant/${restaurantId}`
        );
        if (resp.ok) {
          const data = await resp.json();
          setRestaurantName(data.name || 'Unknown Restaurant');
        }
      } catch (err) {
        console.error('Error fetching restaurant info:', err);
      }
    };
    fetchRestaurant();
  }, [restaurantId]);

  // Load connected vendors
  const fetchConnectedVendors = async () => {
    setLoadingConnectedVendors(true);
    try {
      if (!restaurantId) throw new Error('Restaurant ID not found');

      // Get restaurant_vendors with expanded vendor
      const rvRes = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}&_vendor=*`
      );
      if (!rvRes.ok) throw new Error('Failed to fetch connected vendors.');
      const restaurantVendors = await rvRes.json();

      // Get logins
      const loginRes = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins?restaurant_id=${restaurantId}`
      );
      if (!loginRes.ok) throw new Error('Failed to fetch vendor login info.');
      const vendorLogins = await loginRes.json();

      // map logins => vendor_id
      const loginMap = new Map();
      vendorLogins.forEach((login) => {
        loginMap.set(login.vendor_id, login);
      });

      // combine
      const combined = restaurantVendors.map((rv) => {
        const loginInfo = loginMap.get(rv.vendor_id);
        return {
          ...rv,
          vendorData: rv._vendor,
          loginInfo,
        };
      });

      setConnectedVendors(combined);
    } catch (err) {
      console.error(err);
      setErrorConnectedVendors('Failed to load connected vendors.');
    } finally {
      setLoadingConnectedVendors(false);
    }
  };

  useEffect(() => {
    if (restaurantId) {
      fetchConnectedVendors();
    }
  }, [restaurantId]);

  /* ---------------------------------------------------------
   *  Edit/Delete Vendor Logins
   * --------------------------------------------------------- */
  const handleOpenEditModal = (vendor) => {
    setCurrentVendorLogin(vendor);
    setEditUsername(vendor.loginInfo?.username || '');
    setEditUsernameConfirm('');
    setEditPassword('');
    setEditPasswordConfirm('');
    setEditLoginError(null);
    setEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setCurrentVendorLogin(null);
    setEditUsername('');
    setEditUsernameConfirm('');
    setEditPassword('');
    setEditPasswordConfirm('');
    setEditLoginError(null);
  };

  const handleUpdateLoginInfo = async () => {
    if (
      !editUsername.trim() ||
      !editUsernameConfirm.trim() ||
      !editPassword ||
      !editPasswordConfirm
    ) {
      setEditLoginError('Please fill in all required fields.');
      return;
    }
    if (editUsername !== editUsernameConfirm) {
      setEditLoginError('Usernames do not match.');
      return;
    }
    if (editPassword !== editPasswordConfirm) {
      setEditLoginError('Passwords do not match.');
      return;
    }

    setIsUpdatingLoginInfo(true);
    try {
      // 1) Update the login info
      const payload = {
        restaurant_vendor_log_ins_id: currentVendorLogin.loginInfo.id,
        username: editUsername.trim(),
        password: editPassword,
      };

      const resp = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/edit_login_info',
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.message || 'Failed to update login info.');
      }

      // 2) Mark the login as correct
      const statusPayload = {
        login_correct: true, // we set to true since user has updated/fixed it
        restaurant_id: restaurantId,
        vendor_id: currentVendorLogin.vendor_id,
      };

      const statusResp = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/login_status',
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(statusPayload),
        }
      );
      if (!statusResp.ok) {
        const data = await statusResp.json();
        throw new Error(
          data.message || 'Failed to update login status to true.'
        );
      }

      // Refresh data
      fetchConnectedVendors();
      handleCloseEditModal();
    } catch (err) {
      console.error('Error updating login info:', err);
      setEditLoginError(err.message || 'Failed to update login info.');
    } finally {
      setIsUpdatingLoginInfo(false);
    }
  };

  // Delete login
  const handleOpenDeleteConfirm = (vendor) => {
    setVendorLoginToDelete(vendor);
    setDeleteConfirmOpen(true);
  };
  const handleCloseDeleteConfirm = () => {
    setVendorLoginToDelete(null);
    setDeleteConfirmOpen(false);
  };
  const handleDeleteLoginInfo = async () => {
    setIsUpdatingLoginInfo(true);
    try {
      const { loginInfo } = vendorLoginToDelete;
      const resp = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins/${loginInfo.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.message || 'Failed to delete login info.');
      }
      fetchConnectedVendors();
      handleCloseDeleteConfirm();
    } catch (err) {
      console.error('Error deleting login info:', err);
    } finally {
      setIsUpdatingLoginInfo(false);
    }
  };

  // Delete entire vendor
  const handleOpenDeleteVendorConfirm = (vendor) => {
    setVendorToDelete(vendor);
    setDeleteVendorConfirmOpen(true);
  };
  const handleCloseDeleteVendorConfirm = () => {
    setVendorToDelete(null);
    setDeleteVendorConfirmOpen(false);
  };
  const handleDeleteVendor = async () => {
    setIsUpdatingLoginInfo(true);
    try {
      const resp = await fetch(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors/${vendorToDelete.id}`,
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.message || 'Failed to delete vendor.');
      }
      fetchConnectedVendors();
      handleCloseDeleteVendorConfirm();
    } catch (err) {
      console.error('Error deleting vendor:', err);
    } finally {
      setIsUpdatingLoginInfo(false);
    }
  };

  /* ---------------------------------------------------------
   *  Add new Login if vendor is connected but lacks one
   * --------------------------------------------------------- */
  const [addLoginModalOpen, setAddLoginModalOpen] = useState(false);
  const [currentVendorToAddLogin, setCurrentVendorToAddLogin] = useState(null);
  const [addLoginUsername, setAddLoginUsername] = useState('');
  const [addLoginUsernameConfirm, setAddLoginUsernameConfirm] = useState('');
  const [addLoginPassword, setAddLoginPassword] = useState('');
  const [addLoginPasswordConfirm, setAddLoginPasswordConfirm] = useState('');
  const [addLoginError, setAddLoginError] = useState(null);
  const [isAddingLoginInfo, setIsAddingLoginInfo] = useState(false);

  const handleOpenAddLoginModal = (vendor) => {
    setCurrentVendorToAddLogin(vendor);
    setAddLoginUsername('');
    setAddLoginUsernameConfirm('');
    setAddLoginPassword('');
    setAddLoginPasswordConfirm('');
    setAddLoginError(null);
    setAddLoginModalOpen(true);
  };
  const handleCloseAddLoginModal = () => {
    setAddLoginModalOpen(false);
    setCurrentVendorToAddLogin(null);
    setAddLoginUsername('');
    setAddLoginUsernameConfirm('');
    setAddLoginPassword('');
    setAddLoginPasswordConfirm('');
    setAddLoginError(null);
  };
  const handleAddLoginInfo = async () => {
    if (
      !addLoginUsername.trim() ||
      !addLoginUsernameConfirm.trim() ||
      !addLoginPassword ||
      !addLoginPasswordConfirm
    ) {
      setAddLoginError('Please fill in all required fields.');
      return;
    }
    if (addLoginUsername !== addLoginUsernameConfirm) {
      setAddLoginError('Usernames do not match.');
      return;
    }
    if (addLoginPassword !== addLoginPasswordConfirm) {
      setAddLoginError('Passwords do not match.');
      return;
    }

    setIsAddingLoginInfo(true);
    setAddLoginError(null);

    try {
      const payload = {
        restaurant_id: restaurantId,
        vendor_id: currentVendorToAddLogin.vendor_id,
        username: addLoginUsername.trim(),
        password: addLoginPassword,
      };
      const resp = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        }
      );
      if (!resp.ok) {
        const data = await resp.json();
        throw new Error(data.message || 'Failed to add login info.');
      }

      fetchConnectedVendors();
      handleCloseAddLoginModal();
    } catch (err) {
      console.error('Error adding login info:', err);
      setAddLoginError(err.message || 'Failed to add login info.');
    } finally {
      setIsAddingLoginInfo(false);
    }
  };

  /* ---------------------------------------------------------
   *  Add Vendor Wizard
   * --------------------------------------------------------- */
  const handleOpenAddVendorWizard = () => {
    setActiveStep(0);
    setHasAccount(false);
    setSelectedVendor(null);
    setCustomVendorName('');
    setVendorUsername('');
    setVendorPassword('');
    setVendorUsernameConfirm('');
    setVendorPasswordConfirm('');
    setSalesRepName('');
    setSalesRepEmail('');
    setMOQCases('');
    setMOQDollar('');
    setVendorSubmitError(null);
    setIsVendorSubmitting(false);
    setOpenAddVendorWizard(true);
  };

  const handleCloseAddVendorWizard = () => {
    setOpenAddVendorWizard(false);
  };

  const handleNextStep = () => {
    if (activeStep === 0) {
      if (!selectedVendor && !customVendorName.trim()) {
        setVendorSubmitError('Please select a supplier or enter a custom name.');
        return;
      }
      if (hasAccount) {
        if (
          !vendorUsername.trim() ||
          !vendorPassword ||
          !vendorUsernameConfirm ||
          !vendorPasswordConfirm
        ) {
          setVendorSubmitError('Please fill in all account fields.');
          return;
        }
        if (vendorUsername !== vendorUsernameConfirm) {
          setVendorSubmitError('Usernames do not match.');
          return;
        }
        if (vendorPassword !== vendorPasswordConfirm) {
          setVendorSubmitError('Passwords do not match.');
          return;
        }
      }
    }
    if (activeStep === 1) {
      if (!salesRepName.trim() || !salesRepEmail.trim()) {
        setVendorSubmitError('Please fill in Sales Rep Name and Email.');
        return;
      }
    }
    setVendorSubmitError(null);
    setActiveStep((prev) => prev + 1);
  };

  const handleFinishWizard = async () => {
    setIsVendorSubmitting(true);
    setVendorSubmitError(null);

    try {
      if (!restaurantId) throw new Error('Restaurant ID not found.');
      if (!userId) throw new Error('User ID not found.');

      let actualVendorId = null;
      let vendorNameForSlack = '';

      if (selectedVendor) {
        actualVendorId = selectedVendor.id;
        vendorNameForSlack = selectedVendor.Long_Name || 'Unknown Vendor';
      } else {
        vendorNameForSlack = customVendorName.trim();
      }

      if (actualVendorId) {
        if (hasAccount) {
          // 1) Add vendor
          const vendorPayload = {
            restaurant_id: restaurantId,
            vendor_id: actualVendorId,
            vendor_email: salesRepEmail.trim(),
            sales_rep: salesRepName.trim(),
            MOQ_Cases: MOQCases ? parseInt(MOQCases, 10) : null,
            MOQ_Dollar: MOQDollar ? parseFloat(MOQDollar) : null,
          };

          const vendorResp = await fetch(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_restaurant_vendor',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(vendorPayload),
            }
          );
          if (!vendorResp.ok) {
            const data = await vendorResp.json();
            throw new Error(data.message || 'Failed to add vendor.');
          }

          // 2) Add login
          const loginPayload = {
            restaurant_id: restaurantId,
            vendor_id: actualVendorId,
            username: vendorUsername.trim(),
            password: vendorPassword,
          };
          const loginResp = await fetch(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendor_log_ins',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(loginPayload),
            }
          );
          if (!loginResp.ok) {
            const data = await loginResp.json();
            throw new Error(data.message || 'Failed to add vendor login.');
          }
        } else {
          // Help ticket if no account
          const subject = 'Supplier Account Setup Request';
          const description = `Requested Vendor: ${vendorNameForSlack}`;

          const helpTicketData = {
            subject,
            description,
            user_id: userId,
            ticket_type_id: 2,
            ticket_status_id: 0,
            admin_notes: '',
            last_updated: null,
          };

          const ticketResp = await fetch(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(helpTicketData),
            }
          );
          if (!ticketResp.ok) {
            const data = await ticketResp.json();
            throw new Error(
              data.message || 'Failed to submit vendor setup request.'
            );
          }
        }
      } else {
        // custom => help ticket
        const subject = 'Supplier Account Setup Request';
        const description = `Other Vendor: ${vendorNameForSlack}`;

        const helpTicketData = {
          subject,
          description,
          user_id: userId,
          ticket_type_id: 2,
          ticket_status_id: 0,
          admin_notes: '',
          last_updated: null,
        };

        const helpTicketResp = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(helpTicketData),
          }
        );
        if (!helpTicketResp.ok) {
          const data = await helpTicketResp.json();
          throw new Error(
            data.message || 'Failed to submit vendor request (custom).'
          );
        }
      }

      // Slack notification
      await sendSlackNotification(
        restaurantName,
        vendorNameForSlack,
        hasAccount,
        salesRepName,
        salesRepEmail
      );

      setVendorSubmitSuccess(true);
      setOpenAddVendorWizard(false);
      fetchConnectedVendors();
    } catch (err) {
      console.error('Error adding vendor:', err);
      setVendorSubmitError(err.message || 'Failed to add vendor.');
    } finally {
      setIsVendorSubmitting(false);
    }
  };

  const sendSlackNotification = async (
    restaurantName,
    vendorName,
    hasAccount,
    repName,
    repEmail
  ) => {
    try {
      const text = `${restaurantName} has submitted a new supplier request.
Vendor: ${vendorName}
Has Account Already: ${hasAccount ? 'Yes' : 'No'}
Sales Rep: ${repName} (${repEmail})
`;
      await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text }),
      });
    } catch (e) {
      console.warn('Slack notification failed.', e);
    }
  };

  const handleCloseSnackbar = () => {
    setVendorSubmitSuccess(false);
  };

  /* ----------------------------------
   * Render
   * ---------------------------------- */

  return (
    <Card sx={{ p: 3, mt: 4, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Add or Connect Suppliers
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Select suppliers you'd like to work with. If you already have an account,
        you can provide your details to connect. If not, we can help set one up
        for you.
      </Typography>

      {loadingConnectedVendors ? (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : errorConnectedVendors ? (
        <Alert severity="error">{errorConnectedVendors}</Alert>
      ) : (
        <Grid
          container
          rowSpacing={5}
          columnSpacing={1}
          sx={{ mt: 0, mb: 4 }}
          alignItems="stretch"
        >
          {connectedVendors.length === 0 && (
            <Grid item xs={12}>
              <Typography>
                You have not connected any vendor accounts yet.
              </Typography>
            </Grid>
          )}

          {/* Existing Vendors */}
          {connectedVendors.map((vendor) => {
            const isLoginIncorrect =
              vendor.loginInfo && vendor.loginInfo.login_correct === false;

            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={vendor.id}
                sx={{ display: 'flex' }}
              >
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: isLoginIncorrect
                      ? '#F87906'
                      : theme.palette.grey[300],
                    borderRadius: 2,
                    p: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    position: 'relative', // so the "X" can be absolutely positioned
                  }}
                >
                  {/* The "X" icon to remove this vendor from the list */}
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      color: theme.palette.grey[500],
                    }}
                    onClick={() => handleOpenDeleteVendorConfirm(vendor)}
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* Logo & Vendor Name */}
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    {vendor.vendorData?.Logo_URL ? (
                      <Avatar
                        src={vendor.vendorData.Logo_URL}
                        alt={vendor.vendorData.Short_Name}
                        variant="square"
                        sx={{ width: 56, height: 56, mr: 2, borderRadius: 0 }}
                        imgProps={{ style: { objectFit: 'contain' } }}
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        sx={{
                          width: 56,
                          height: 56,
                          mr: 2,
                          borderRadius: 0,
                          backgroundColor: theme.palette.grey[300],
                        }}
                      >
                        {vendor.vendorData?.Short_Name
                          ? vendor.vendorData.Short_Name.charAt(0)
                          : 'V'}
                      </Avatar>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="subtitle1">
                        {vendor.vendorData?.Long_Name ||
                          vendor.vendorData?.Short_Name ||
                          'Unknown Vendor'}
                      </Typography>
                      {isLoginIncorrect && (
                        <Tooltip title="Login is incorrect">
                          <WarningAmberIcon
                            sx={{ color: '#F87906', ml: 1, fontSize: 20 }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </Box>

                  {/* If there's a login, show username */}
                  {vendor.loginInfo && (
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                      Username: {vendor.loginInfo.username}
                    </Typography>
                  )}

                  {/* Info */}
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      <strong>Sales Rep:</strong> {vendor.sales_rep || 'N/A'}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      <strong>Sales Rep Email:</strong>{' '}
                      {vendor.vendor_email || 'N/A'}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      <strong>MOQ Cases:</strong> {vendor.MOQ_Cases || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>MOQ Dollar:</strong> {vendor.MOQ_Dollar || 'N/A'}
                    </Typography>
                  </Box>

                  {/* Buttons at the bottom */}
                  <Box sx={{ display: 'flex', gap: 1, mt: 'auto' }}>
                    {vendor.loginInfo ? (
                      <>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditModal(vendor)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenDeleteConfirm(vendor)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenAddLoginModal(vendor)}
                      >
                        <AddLoginIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
            );
          })}

          {/* "Add Supplier" tile */}
          <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
            <Box
              sx={{
                border: '2px dashed',
                borderColor: theme.palette.grey[300],
                borderRadius: 2,
                p: 2,
                textAlign: 'center',
                cursor: 'pointer',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleOpenAddVendorWizard}
            >
              <AddIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                Add Supplier
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Snackbar for success */}
      <Snackbar
        open={vendorSubmitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Supplier added successfully."
      />

      {/* Wizard Modal */}
      <Dialog
        open={openAddVendorWizard}
        onClose={handleCloseAddVendorWizard}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{steps[activeStep] || 'Add Supplier'}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {vendorSubmitError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {vendorSubmitError}
            </Alert>
          )}

          {activeStep === 0 && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                Please select an existing supplier or enter a custom name.
              </DialogContentText>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  select
                  label="Supplier"
                  value={selectedVendor ? selectedVendor.id : ''}
                  onChange={(e) => {
                    const chosenId = e.target.value;
                    const found = vendors.find((v) => v.id === parseInt(chosenId, 10));
                    setSelectedVendor(found || null);
                    setCustomVendorName('');
                  }}
                  SelectProps={{ native: true }}
                >
                  <option value=""></option>
                  {vendors.map((v) => (
                    <option key={v.id} value={v.id}>
                      {v.Long_Name}
                    </option>
                  ))}
                </TextField>

                <Typography align="center" sx={{ mt: 1 }}>
                  OR
                </Typography>

                <TextField
                  label="Other / Custom Supplier Name"
                  value={customVendorName}
                  onChange={(e) => {
                    setCustomVendorName(e.target.value);
                    if (e.target.value.trim()) {
                      setSelectedVendor(null);
                    }
                  }}
                />
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasAccount}
                    onChange={() => setHasAccount((prev) => !prev)}
                  />
                }
                label="I already have a supplier login"
                sx={{ mt: 2 }}
              />

              {hasAccount && (
                <>
                  <TextField
                    label="Username"
                    value={vendorUsername}
                    onChange={(e) => setVendorUsername(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    label="Confirm Username"
                    value={vendorUsernameConfirm}
                    onChange={(e) => setVendorUsernameConfirm(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    label="Password"
                    type="password"
                    value={vendorPassword}
                    onChange={(e) => setVendorPassword(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    value={vendorPasswordConfirm}
                    onChange={(e) => setVendorPasswordConfirm(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                </>
              )}
            </>
          )}

          {activeStep === 1 && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                Please provide the sales representative's information.
              </DialogContentText>
              <TextField
                label="Sales Rep Name"
                value={salesRepName}
                onChange={(e) => setSalesRepName(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Sales Rep Email"
                value={salesRepEmail}
                onChange={(e) => setSalesRepEmail(e.target.value)}
                fullWidth
              />
            </>
          )}

          {activeStep === 2 && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                If applicable, enter any minimum order requirements.
              </DialogContentText>
              <TextField
                label="Case Minimum"
                value={MOQCases}
                onChange={(e) => setMOQCases(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Spend Minimum"
                value={MOQDollar}
                onChange={(e) => setMOQDollar(e.target.value)}
                fullWidth
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          {activeStep > 0 && (
            <Button
              onClick={() => setActiveStep((prev) => prev - 1)}
              disabled={isVendorSubmitting}
            >
              Back
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button onClick={handleNextStep} disabled={isVendorSubmitting}>
              Continue
            </Button>
          ) : (
            <Button onClick={handleFinishWizard} disabled={isVendorSubmitting}>
              {isVendorSubmitting ? 'Submitting...' : 'Finish'}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Edit Login Info Dialog */}
      <Dialog
        open={editModalOpen}
        onClose={handleCloseEditModal}
        aria-labelledby="edit-login-dialog-title"
      >
        <DialogTitle id="edit-login-dialog-title">Edit Login Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the new username and password.
          </DialogContentText>
          {editLoginError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {editLoginError}
            </Alert>
          )}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={editUsername}
            onChange={(e) => setEditUsername(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Username"
            variant="outlined"
            fullWidth
            value={editUsernameConfirm}
            onChange={(e) => setEditUsernameConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={editPassword}
            onChange={(e) => setEditPassword(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            fullWidth
            value={editPasswordConfirm}
            onChange={(e) => setEditPasswordConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button onClick={handleUpdateLoginInfo} disabled={isUpdatingLoginInfo}>
            {isUpdatingLoginInfo ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Login Confirm */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteConfirm}
        aria-labelledby="delete-login-dialog-title"
      >
        <DialogTitle id="delete-login-dialog-title">Delete Login Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the login information for{' '}
            {vendorLoginToDelete?.vendorData?.Long_Name || 'this vendor'}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirm}>Cancel</Button>
          <Button
            onClick={handleDeleteLoginInfo}
            color="error"
            disabled={isUpdatingLoginInfo}
          >
            {isUpdatingLoginInfo ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Vendor Confirm */}
      <Dialog
        open={deleteVendorConfirmOpen}
        onClose={handleCloseDeleteVendorConfirm}
        aria-labelledby="delete-vendor-dialog-title"
      >
        <DialogTitle id="delete-vendor-dialog-title">Delete Vendor</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove{' '}
            {vendorToDelete?.vendorData?.Long_Name || 'this vendor'} from your
            connected vendors?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteVendorConfirm}>Cancel</Button>
          <Button
            onClick={handleDeleteVendor}
            color="error"
            disabled={isUpdatingLoginInfo}
          >
            {isUpdatingLoginInfo ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Login Info Dialog */}
      <Dialog
        open={addLoginModalOpen}
        onClose={handleCloseAddLoginModal}
        aria-labelledby="add-login-dialog-title"
      >
        <DialogTitle id="add-login-dialog-title">Add Login Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your username and password for{' '}
            {currentVendorToAddLogin?.vendorData?.Long_Name || 'this vendor'}.
          </DialogContentText>
          {addLoginError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {addLoginError}
            </Alert>
          )}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={addLoginUsername}
            onChange={(e) => setAddLoginUsername(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Username"
            variant="outlined"
            fullWidth
            value={addLoginUsernameConfirm}
            onChange={(e) => setAddLoginUsernameConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={addLoginPassword}
            onChange={(e) => setAddLoginPassword(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            fullWidth
            value={addLoginPasswordConfirm}
            onChange={(e) => setAddLoginPasswordConfirm(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddLoginModal}>Cancel</Button>
          <Button onClick={handleAddLoginInfo} disabled={isAddingLoginInfo}>
            {isAddingLoginInfo ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default ConnectSuppliers;
