import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

//
// Search styling similar to your FilterPanel
//
const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const SearchInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0.5, 0.5, 0.5, 0),
  paddingLeft: `calc(1em + ${theme.spacing(3)})`,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  color: theme.palette.text.primary,
}));

function PHFilterPanel({
  categories = [],
  selectedCategory,
  setSelectedCategory,
  subcategories = [],
  selectedSubcategory,
  setSelectedSubcategory,
  vendors = [],
  selectedVendor,
  setSelectedVendor,
  clearAllFilters,

  // For both mobile & desktop
  itemsPerPage,
  setItemsPerPage,
  viewMode,
  setViewMode,

  // Boolean to determine if we're on mobile
  isMobile,
}) {
  const theme = useTheme();
  const [expandedPanel, setExpandedPanel] = useState(null);

  // Vendor search
  const [vendorSearch, setVendorSearch] = useState('');

  // Filter vendors by search
  const filteredVendors = vendors.filter(
    (v) =>
      v.Short_Name &&
      v.Short_Name.toLowerCase().includes(vendorSearch.toLowerCase())
  );

  // Reset subcategory if category is reset
  useEffect(() => {
    if (selectedCategory === 0) {
      setSelectedSubcategory(0);
    }
  }, [selectedCategory, setSelectedSubcategory]);

  // Category checkbox logic
  const handleCategoryCheck = (catId) => {
    if (selectedCategory === catId) {
      // unselect same category
      setSelectedCategory(0);
      setSelectedSubcategory(0);
      setExpandedPanel(null);
    } else {
      // select a new category
      setSelectedCategory(catId);
      // auto expand subcategory
      setExpandedPanel('subcategory');
    }
  };

  // Subcategory checkbox logic
  const handleSubcategoryCheck = (subId) => {
    if (selectedSubcategory === subId) {
      // unselect
      setSelectedSubcategory(0);
      setExpandedPanel(null);
    } else {
      setSelectedSubcategory(subId);
      // auto expand vendor
      setExpandedPanel('vendor');
    }
  };

  // Single vendor logic
  const handleVendorCheck = (venId) => {
    if (selectedVendor === venId) {
      setSelectedVendor(0);
    } else {
      setSelectedVendor(venId);
    }
  };

  // Accordion expand/collapse
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  // Utility for label text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
  };

  // Display labels
  const categoryLabel = selectedCategory ? `Category (1)` : 'Category';
  const subcategoryLabel = selectedSubcategory ? `Subcategory (1)` : 'Subcategory';
  const vendorLabel = selectedVendor ? `Vendor (1)` : 'Vendor';

  return (
    <Box sx={{ px: 2 /* left/right padding to match shops filter panel */ }}>
      {/* Header row: "Filter By" + "Clear All" */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'light',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
          }}
        >
          Filter By
        </Typography>
        <Button
          variant="text"
          onClick={() => {
            clearAllFilters();
            setExpandedPanel(null);
          }}
          sx={{
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '0.875rem',
          }}
        >
          Clear All
        </Button>
      </Box>

      {/* MOBILE-ONLY: Items Per Page and View toggles (Sort By removed) */}
      {isMobile && (
        <Box sx={{ mb: 2 }}>
          {/* Items Per Page */}
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel>Items Per Page</InputLabel>
            <Select
              value={itemsPerPage}
              label="Items Per Page"
              onChange={(e) => setItemsPerPage(e.target.value)}
              sx={{ fontFamily: theme.typography.fontFamily }}
            >
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={48}>48</MenuItem>
              <MenuItem value={96}>96</MenuItem>
            </Select>
          </FormControl>

          {/* View Mode Switch */}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.875rem',
                mr: 1,
              }}
            >
              View:
            </Typography>
            <IconButton
              onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
              sx={{ p: 0.5 }}
            >
              {viewMode === 'grid' ? (
                <ViewListIcon fontSize="small" />
              ) : (
                <ViewModuleIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Category */}
      <Accordion
        expanded={expandedPanel === 'category'}
        onChange={handleAccordionChange('category')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="category-content"
          id="category-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            {categoryLabel}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {categories.map((cat) => (
              <FormControlLabel
                key={cat.id}
                control={
                  <Checkbox
                    checked={selectedCategory === cat.id}
                    onChange={() => handleCategoryCheck(cat.id)}
                  />
                }
                label={truncateText(cat.name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Subcategory */}
      {selectedCategory !== 0 && (
        <Accordion
          expanded={expandedPanel === 'subcategory'}
          onChange={handleAccordionChange('subcategory')}
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls="subcategory-content"
            id="subcategory-header"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              minHeight: '48px',
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'regular',
                color: 'black',
                fontSize: '1rem',
              }}
            >
              {subcategoryLabel}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {subcategories.map((sub) => (
                <FormControlLabel
                  key={sub.id}
                  control={
                    <Checkbox
                      checked={selectedSubcategory === sub.id}
                      onChange={() => handleSubcategoryCheck(sub.id)}
                    />
                  }
                  label={truncateText(sub.name, 20)}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Single Vendor */}
      <Accordion
        expanded={expandedPanel === 'vendor'}
        onChange={handleAccordionChange('vendor')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="vendor-content"
          id="vendor-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            {vendorLabel}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Search Bar */}
          <SearchWrapper>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <SearchInput
              placeholder="Search Vendor"
              value={vendorSearch}
              onChange={(e) => setVendorSearch(e.target.value)}
            />
          </SearchWrapper>
          <FormGroup>
            {filteredVendors.map((ven) => (
              <FormControlLabel
                key={ven.id}
                control={
                  <Checkbox
                    checked={selectedVendor === ven.id}
                    onChange={() => handleVendorCheck(ven.id)}
                  />
                }
                label={truncateText(ven.Short_Name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default PHFilterPanel;
