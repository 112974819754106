// src/ContactUs.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet'; // Import Helmet
import SalesNavBar from './SalesNavBar';
import Footer from './Footer';
import '@fontsource/poppins/400.css'; // Regular weight
import '@fontsource/poppins/500.css'; // Medium weight
import '@fontsource/poppins/700.css'; // Bold weight

// Styled Components
const Root = styled(Box)({
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
});

const ExtendedGreenSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#D6ECE9',
  height: '384px', // Approximately 4 inches (4 * 96px = 384px)
}));

const WaveBackground = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#D6ECE9',
  height: '400px',
  margin: 0,
  padding: 0,
}));

const WaveSvg = styled('svg')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '100%',
}));

const MainContent = styled(Box)(({ theme, isMobile }) => ({
  backgroundColor: 'transparent',
  fontFamily: 'Poppins, sans-serif',
  position: 'relative',
  zIndex: 2,
  marginTop: isMobile ? theme.spacing(12) : '-674px', // Adjusted marginTop
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(8),
}));

const StyledFormBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: theme.spacing(4),
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  border: '1px solid #E0E0E0',
}));

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    restaurantName: '',
    location: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Validation errors
  const [validationErrors, setValidationErrors] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
      // Load Calendly script
    const calendlyScript = document.createElement('script');
    calendlyScript.src = 'https://assets.calendly.com/assets/external/widget.js';
    calendlyScript.async = true;
      document.body.appendChild(calendlyScript);

    // Load reCAPTCHA script
    const recaptchaScript = document.createElement('script');
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js?render=6LcTvr0qAAAAAHgGBoVmU2U4hMX8IvM1mNRMevni';
    recaptchaScript.async = true;
      document.body.appendChild(recaptchaScript);

  // Cleanup both scripts when component unmounts
    return () => {
      document.body.removeChild(calendlyScript);
      document.body.removeChild(recaptchaScript);
    };
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const val = e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));

    // Clear validation error for this field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validateForm = () => {
    const errors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    // Phone number validation (10 or 11 digits)
    const phoneRegex = /^\d{10,11}$/;
    if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Please enter a valid phone number (10 or 11 digits)';
    }

    return errors;
  };

  // Helper function to send Slack notification
  const sendSlackNotification = async () => {
    const slackPayload = {
      text: `You received a new message from your online store's contact form.
      
**Name:** ${formData.firstName} ${formData.lastName}
**Email:** ${formData.email}
**Phone Number:** ${formData.phoneNumber}
**Organization:** ${formData.restaurantName}
**Location:** ${formData.location}
**Comment:** ${formData.message}`,
    };

    const response = await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_sales_form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(slackPayload),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to send Slack notification');
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      setLoading(false);
      return;
    }

    // Use reCAPTCHA to validate the user
    window.grecaptcha.ready(() => {
    window.grecaptcha
      .execute('6LcTvr0qAAAAAHgGBoVmU2U4hMX8IvM1mNRMevni', { action: 'submit' })
      .then(async (token) => {

    // Prepare data for Xano Help Tickets API
    const helpTicketPayload = {
      subject: `Contact Us Submission from ${formData.firstName} ${formData.lastName}`,
      description: `
**First Name:** ${formData.firstName}
**Last Name:** ${formData.lastName}
**Email:** ${formData.email}
**Phone Number:** ${formData.phoneNumber}
**Restaurant/Company:** ${formData.restaurantName}
**Location:** ${formData.location}
**Message:** ${formData.message}
    `,
      user_id: 0, // Assuming no authenticated user
      ticket_type_id: 4, // Hard-coded as per requirement
      ticket_status_id: 0, // Default status
      admin_notes: '',
      last_updated: null,
    };

    try {
      // Submit to Xano Help Tickets API
      const helpTicketResponse = await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(helpTicketPayload),
      });

      if (!helpTicketResponse.ok) {
        const errorData = await helpTicketResponse.json();
        throw new Error(errorData.message || 'Failed to submit the form. Please try again.');
      }

      // After successful help ticket submission, send Slack notification
      try {
        await sendSlackNotification();
      } catch (slackError) {
        // If Slack notification fails, inform the user but don't block the form submission
        setErrorMessage(`Form submitted successfully, but failed to send Slack notification: ${slackError.message}`);
        setLoading(false);
        // Reset form fields
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          restaurantName: '',
          location: '',
          message: '',
        });
        return;
      }

      // If both submissions are successful
      setLoading(false);
      setSuccessMessage('Your message has been sent successfully!');
      // Reset form fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        restaurantName: '',
        location: '',
        message: '',
      });
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.message || 'An unexpected error occurred. Please try again.');
      }
    });
  });
};

  return (
    <Root>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Contact Us</title>
        <meta
          name="description"
          content="Get in touch with FareFood to streamline your restaurant's food orders. Fill out our contact form or schedule a call to learn how we can help you save time and reduce costs."
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Contact Us" />
        <meta
          property="og:description"
          content="Reach out to FareFood today to optimize your restaurant's food ordering process. Contact us via the form or schedule a call to discover how we can assist you."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/contactus" />
        <meta property="og:image" content="https://www.farefood.com/assets/contactus-og-image.jpg" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Contact Us" />
        <meta
          name="twitter:description"
          content="Contact FareFood to enhance your restaurant's food ordering system. Fill out our contact form or schedule a call to see how we can help you save time and reduce costs."
        />
        <meta name="twitter:image" content="https://www.farefood.com/assets/contactus-twitter-image.jpg" />
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Conditionally Render Background on Desktop Only */}
      {!isMobile && (
        <>
          {/* Extended Green Section */}
          <ExtendedGreenSection />

          {/* Wave Background */}
          <WaveBackground>
            {/* Wave SVG */}
            <WaveSvg viewBox="0 0 1440 320" preserveAspectRatio="none">
              <path
                fill="#FFFFFF" // White wave
                d="M0,256L80,234.7C160,213,320,171,480,154.7C640,139,800,149,960,160C1120,171,1280,181,1360,186.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </WaveSvg>
          </WaveBackground>
        </>
      )}

      {/* Main Content */}
      <MainContent isMobile={isMobile}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="flex-start">
            {/* Left Side - Form */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  color: '#000',
                  fontWeight: 'bold',
                  marginBottom: (theme) => theme.spacing(2),
                  fontFamily: 'Poppins, sans-serif',
                }}
              >
                Let's talk
              </Typography>

              {/* Form with Outline and Shadow */}
              <StyledFormBox>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  {successMessage && (
                    <Alert
                      severity="success"
                      sx={{ marginBottom: (theme) => theme.spacing(2) }}
                    >
                      {successMessage}
                    </Alert>
                  )}
                  {errorMessage && (
                    <Alert
                      severity="error"
                      sx={{ marginBottom: (theme) => theme.spacing(2) }}
                    >
                      {errorMessage}
                    </Alert>
                  )}

                  <Grid container spacing={2}>
                    {/* First Name and Last Name */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>

                    {/* Email and Phone Number */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        error={!!validationErrors.email}
                        helperText={validationErrors.email}
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone Number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        error={!!validationErrors.phoneNumber}
                        helperText={validationErrors.phoneNumber}
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>

                    {/* Restaurant/Company Name and Location */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Restaurant/Company"
                        name="restaurantName"
                        value={formData.restaurantName}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Location"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>

                    {/* Message */}
                    <Grid item xs={12}>
                      <TextField
                        label="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          textTransform: 'none',
                          width: '25%',
                          fontFamily: 'Poppins, sans-serif',
                          backgroundColor: '#006D6D',
                          '&:hover': {
                            backgroundColor: '#004C4C',
                          },
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </StyledFormBox>
            </Grid>

            {/* Right Side - Calendly Embed */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 'bold',
                  marginBottom: (theme) => theme.spacing(2),
                  color: '#000000',
                }}
              >
                Schedule a call
              </Typography>
              <Box
                className="calendly-inline-widget"
                data-url="https://calendly.com/farefood-michael?hide_gdpr_banner=1"
                style={{
                  minWidth: '320px',
                  height: '700px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                  border: '1px solid #E0E0E0',
                  marginTop: '0px',
                }}
              ></Box>
            </Grid>
          </Grid>
        </Container>
      </MainContent>

      {/* Footer */}
      <Footer />
    </Root>
  );
};

export default ContactUs;
