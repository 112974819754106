// src/DesktopItemDetails.js

import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Breadcrumbs,
  Link,
  IconButton,
  Divider,
  CircularProgress,
  Collapse,
  Alert,
  Button,
} from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  RotateRight as RotateRightIcon,
  Info as InfoIcon,
  RoomService as RoomServiceIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Inventory as InventoryIcon,
  List as ListIcon,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CompareListItem from './CompareListItem';
import AddToCartButton from './AddToCartButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

function DesktopItemDetails({
  product,
  associatedImages,
  selectedImage,
  setSelectedImage,
  imageIndex,
  handleImageNavigation,
  truncateText,
  showFullDescription,
  setShowFullDescription,
  multiplier,
  adjustedCasePrice,
  adjustedCasePricePerUnit,
  adjustedEachPrice,
  compareProducts,
  compareLoading,
  compareError,
  compareOpen,
  setCompareOpen,
  isProductFavorited,
  handleAddFavorite,
  handleRemoveFavorite,
  addInventoryButtonText,
  handleAddToInventory,
  addInventorySuccess,
  addInventoryError,
  addListButtonText,
  handleOpenAddInventoryModal,
  addListSuccess,
  addListError,
  sampleRequestLoading,
  sampleRequestSuccess,
  sampleRequestError,
  sampleButtonText,
  handleRequestSample,
  navigate,
}) {
  const StyledTooltip = styled(({ className, ...props }) => (
    <Box component="span">
      <InfoIcon {...props} />
    </Box>
  ))(({ theme }) => ({}));

  const inStockTooltip = (
    <Typography variant="body2" sx={{ fontFamily: 'Poppins' }}>
      Inventory is confirmed at the time of purchase. Some items may occasionally be out of stock,
      but we'll notify you and work to find a suitable replacement.
    </Typography>
  );

  // This is basically the same as your original “desktop” JSX, with no changes.
  return (
    <Grid container spacing={4} sx={{ pt: 10, pb: 8 }}>
      {/* LEFT SIDE: IMAGES + BREADCRUMBS + (desktop) DESCRIPTION */}
      <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
        {/* BREADCRUMBS */}
        <Box sx={{ mb: 1 }}>
          <Breadcrumbs
            separator={
              <Typography
                sx={{
                  color: '#A5A5A5',
                  fontFamily: 'Poppins',
                  fontSize: '9pt',
                }}
              >
                /
              </Typography>
            }
            aria-label="breadcrumb"
            sx={{ mb: 1 }}
          >
            <Link
              underline="hover"
              onClick={() => navigate('/home')}
              sx={{
                cursor: 'pointer',
                color: '#0E9C9B',
                fontFamily: 'Poppins',
                fontSize: '9pt',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Home
            </Link>

            {product.product_category_id > 0 ? (
              <Link
                underline="hover"
                onClick={() => navigate(`/shop?category=${product.product_category_id}`)}
                sx={{
                  cursor: 'pointer',
                  color: '#0E9C9B',
                  fontFamily: 'Poppins',
                  fontSize: '9pt',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {product.category_name || 'Category'}
              </Link>
            ) : (
              <Typography
                sx={{
                  color: '#0E9C9B',
                  fontFamily: 'Poppins',
                  fontSize: '9pt',
                }}
              >
                {product.category_name || 'Category'}
              </Typography>
            )}

            {product.product_subcategory_id > 0 && product.subcategory_name && (
              <Link
                underline="hover"
                onClick={() =>
                  navigate(
                    `/shop?category=${product.product_category_id}&subcategory=${product.product_subcategory_id}`
                  )
                }
                sx={{
                  cursor: 'pointer',
                  color: '#0E9C9B',
                  fontFamily: 'Poppins',
                  fontSize: '9pt',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {product.subcategory_name}
              </Link>
            )}
          </Breadcrumbs>
        </Box>

        {/* Main Image */}
        <Box
          sx={{
            width: '100%',
            height: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 1,
            overflow: 'hidden',
            backgroundColor: '#fff',
            mb: 2,
            border: '1px solid #E0E0E0',
            position: 'relative',
          }}
        >
          <Box
            component="img"
            src={selectedImage || product.Image_URL || ''}
            alt={product.short_description || 'Product'}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
          {product.vendor_logo_url && (
            <Box
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                width: 80,
                height: 80,
                borderRadius: '50%',
                backgroundColor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 1,
                zIndex: 2,
              }}
            >
              <Box
                component="img"
                src={product.vendor_logo_url}
                alt={product.vendor_short_name || 'Vendor Logo'}
                sx={{
                  width: '80%',
                  height: '80%',
                  objectFit: 'contain',
                  borderRadius: '50%',
                }}
              />
            </Box>
          )}
        </Box>

        {/* Thumbnails row */}
        {associatedImages.length > 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', minHeight: 80, mb: 2 }}>
            <IconButton
              onClick={() => handleImageNavigation('left')}
              disabled={imageIndex === 0}
            >
              <ArrowBackIos />
            </IconButton>

            <Box sx={{ display: 'flex', flex: 1, gap: 1, alignItems: 'center' }}>
              {associatedImages.slice(imageIndex, imageIndex + 5).map((img, idx) => (
                <Box
                  key={`thumb-${idx}`}
                  onClick={() => setSelectedImage(img.URL)}
                  sx={{
                    width: 60,
                    height: 60,
                    borderRadius: 1,
                    border: '1px solid #E0E0E0',
                    flexShrink: 0,
                    cursor: 'pointer',
                    overflow: 'hidden',
                    position: 'relative',
                    boxShadow:
                      selectedImage === img.URL ? '0 0 0 2px #06D6D6' : 'none',
                    zIndex: selectedImage === img.URL ? 2 : 1,
                  }}
                >
                  <Box
                    component="img"
                    src={img.URL}
                    alt={`Thumb ${idx}`}
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                </Box>
              ))}
            </Box>

            <IconButton
              onClick={() => handleImageNavigation('right')}
              disabled={imageIndex >= associatedImages.length - 5}
            >
              <ArrowForwardIos />
            </IconButton>
          </Box>
        )}

        {/* DESCRIPTION (desktop-only) */}
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '9pt',
              fontWeight: 600,
              color: '#000',
              mb: 1,
            }}
          >
            Description
          </Typography>

          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              h1: ({ node, ...props }) => (
                <Typography variant="h6" sx={{ fontSize: '14px', color: 'black' }} {...props} />
              ),
              h2: ({ node, ...props }) => (
                <Typography variant="subtitle1" sx={{ fontSize: '12px', color: 'black' }} {...props} />
              ),
              h3: ({ node, ...props }) => (
                <Typography variant="body1" sx={{ fontSize: '12px', color: 'black' }} {...props} />
              ),
              h4: ({ node, ...props }) => (
                <Typography variant="body2" sx={{ fontSize: '10px', color: 'black' }} {...props} />
              ),
              p: ({ node, ...props }) => (
                <Typography variant="body2" sx={{ fontSize: '10px', color: 'black' }} {...props} />
              ),
              strong: ({ node, ...props }) => (
                <Typography component="span" sx={{ fontWeight: 'normal', color: 'black' }} {...props} />
              ),
            }}
          >
            {showFullDescription
              ? product.markdown_description || ''
              : truncateText(product.markdown_description || '', 200)}
          </ReactMarkdown>

          {product.markdown_description &&
            product.markdown_description.length > 200 && (
              <Button
                onClick={() => setShowFullDescription(!showFullDescription)}
                sx={{
                  mt: 1,
                  fontFamily: 'Poppins',
                  color: 'primary.main',
                  textTransform: 'none',
                }}
              >
                {showFullDescription ? 'Read Less' : 'Read More'}
              </Button>
            )}

          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '12px' }}>
              Disclaimer: FareFood shares product detail provided by the supplier. If you
              believe any information is incorrect, please contact us at restaurants@fare.food.
            </Typography>
          </Box>
        </Box>
      </Grid>

      {/* RIGHT SIDE: PRODUCT DETAILS */}
      <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
        {/* Buttons Row */}
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mb: 2 }}>
          <Button
            variant="outlined"
            onClick={handleAddToInventory}
            disabled={addInventoryButtonText === 'Added to Inventory!'}
            startIcon={<InventoryIcon sx={{ color: '#06D6D' }} />}
            sx={{
              color: '#06D6D',
              textTransform: 'none',
              borderColor: '#e0e0e0',
              backgroundColor: 'white',
              fontWeight: 500,
              fontFamily: 'Poppins',
              fontSize: '7.5pt',
              '&:hover': {
                borderColor: '#e0e0e0',
                backgroundColor: 'rgba(25,118,210,0.04)',
              },
            }}
          >
            {addInventoryButtonText}
          </Button>

          <Button
            variant="outlined"
            onClick={handleOpenAddInventoryModal}
            disabled={addListButtonText === 'Added to List!'}
            startIcon={<ListIcon sx={{ color: '#06D6D' }} />}
            sx={{
              color: '#06D6D',
              textTransform: 'none',
              borderColor: '#e0e0e0',
              backgroundColor: 'white',
              fontWeight: 500,
              fontFamily: 'Poppins',
              fontSize: '7.5pt',
              '&:hover': {
                borderColor: '#e0e0e0',
                backgroundColor: 'rgba(25,118,210,0.04)',
              },
            }}
          >
            {addListButtonText}
          </Button>

          <Button
            variant="outlined"
            onClick={() =>
              isProductFavorited(product.id)
                ? handleRemoveFavorite(product.id)
                : handleAddFavorite(product.id)
            }
            startIcon={
              isProductFavorited(product.id) ? (
                <FavoriteIcon sx={{ color: '#E92409' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: '#E92409' }} />
              )
            }
            sx={{
              color: '#06D6D',
              textTransform: 'none',
              borderColor: '#e0e0e0',
              backgroundColor: 'white',
              fontWeight: 500,
              fontFamily: 'Poppins',
              fontSize: '7.5pt',
              '&:hover': {
                borderColor: '#e0e0e0',
                backgroundColor: 'rgba(25,118,210,0.04)',
              },
            }}
          >
            {isProductFavorited(product.id)
              ? 'Remove from Favorites'
              : 'Add to Favorites'}
          </Button>
        </Box>

        {/* BRAND, TITLE, PACK SIZE */}
        <Typography sx={{ fontSize: '8pt', color: '#3A3F3F' }}>
          {product.brand}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '12pt',
            color: '#131616',
            mt: 1,
          }}
        >
          {product.short_description}
        </Typography>
        <Typography sx={{ fontSize: '7.5pt', color: '#949E98', mt: 0.5 }}>
          {truncateText(product.pack_size || 'N/A', 50)} |{' '}
          {truncateText(product.FF_Catalog_Number || 'N/A', 50)}
        </Typography>

        {/* BEST PRICE / BETTER PRICE */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: 2 }}>
          {product.bestdeal && (
            <Box
              sx={{
                backgroundColor: '#00BC48',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                mb: 1,
              }}
            >
              <Typography variant="caption" sx={{ color: '#fff', fontWeight: 'bold' }}>
                Best Price
              </Typography>
            </Box>
          )}
          {!product.bestdeal && product.hasalternates && (
            <Box
              sx={{
                backgroundColor: '#E76F01',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                mb: 1,
              }}
            >
              <Typography variant="caption" sx={{ color: '#fff', fontWeight: 'bold' }}>
                Better Price Available
              </Typography>
            </Box>
          )}

          {/* CASE PRICE */}
          {adjustedCasePrice > 0 ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                  <Typography
                    sx={{ fontFamily: 'Poppins', fontSize: '13pt', fontWeight: 'bold', color: '#006D6D' }}
                  >
                    ${adjustedCasePrice.toFixed(2)}
                  </Typography>
                  {product.case_priced_by && (
                    <Typography sx={{ fontSize: '9pt', color: '#949E98' }}>
                      {product.case_priced_by}
                    </Typography>
                  )}
                </Box>
                {adjustedCasePricePerUnit > 0 && product.price_per_unit_priced_by && (
                  <Typography sx={{ fontSize: '9pt', color: '#949E98', mt: 0.5 }}>
                    ${adjustedCasePricePerUnit.toFixed(2)} / {product.price_per_unit_priced_by}
                  </Typography>
                )}
              </Box>
              <Box>
                <AddToCartButton productId={product.id} isSplit={false} buttonLabel="Case" />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '13pt',
                  fontWeight: 'bold',
                  color: '#006D6D',
                }}
              >
                MARKET
              </Typography>
              <Box>
                <AddToCartButton productId={product.id} isSplit={false} buttonLabel="Case" />
              </Box>
            </Box>
          )}

          {/* EACH PRICE */}
          {adjustedEachPrice > 0 && product.each_priced_by && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                width: '100%',
                mt: 2,
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                  <Typography
                    sx={{ fontFamily: 'Poppins', fontSize: '13pt', fontWeight: 'bold', color: '#006D6D' }}
                  >
                    ${adjustedEachPrice.toFixed(2)}
                  </Typography>
                  <Typography sx={{ fontSize: '9pt', color: '#949E98' }}>
                    {product.each_priced_by}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <AddToCartButton productId={product.id} isSplit buttonLabel="Each" />
              </Box>
            </Box>
          )}
        </Box>

        <Divider sx={{ width: '100%', my: 2 }} />

        {/* Times purchased */}
        {product.times_previously_purchased > 0 && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <RotateRightIcon sx={{ color: '#495555', mr: 1, fontSize: '1.2rem' }} />
              <Typography sx={{ fontSize: '10pt', color: '#495555' }}>
                Purchased {product.times_previously_purchased} times
              </Typography>
            </Box>
            <Divider sx={{ width: '100%', my: 2 }} />
          </>
        )}

        {/* In Stock */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <InfoIcon sx={{ color: '#949E98', mr: 1, fontSize: '1.2rem' }} />
          <Typography sx={{ fontSize: '10pt', color: '#495555' }}>In Stock</Typography>
        </Box>

        <Divider sx={{ width: '100%', my: 2 }} />

        {/* Request Sample */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Button
            variant="text"
            onClick={handleRequestSample}
            startIcon={
              <RoomServiceIcon
                sx={{
                  color: '#495555',
                  fontSize: '1.2rem',
                }}
              />
            }
            sx={{
              color: '#495555',
              fontSize: '10pt',
              fontWeight: 'medium',
              textTransform: 'none',
              p: 0,
              minWidth: 'auto',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {sampleRequestLoading
              ? 'Submitting...'
              : sampleRequestSuccess
              ? 'Sample Requested'
              : sampleButtonText}
          </Button>
        </Box>

        <Divider sx={{ width: '100%', mb: 2 }} />

        {/* Feedback Alerts */}
        {addInventorySuccess && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {addInventorySuccess}
          </Alert>
        )}
        {addInventoryError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {addInventoryError}
          </Alert>
        )}
        {addListSuccess && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {addListSuccess}
          </Alert>
        )}
        {addListError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {addListError}
          </Alert>
        )}
        {sampleRequestSuccess && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {sampleRequestSuccess}
          </Alert>
        )}
        {sampleRequestError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {sampleRequestError}
          </Alert>
        )}

        {/* Compare Products */}
        {product.hasalternates && (
          <Box sx={{ mt: 2 }}>
            <Button
              variant="text"
              onClick={() => setCompareOpen(!compareOpen)}
              sx={{
                fontWeight: 'medium',
                mb: 2,
                textTransform: 'none',
                fontSize: '1rem',
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Explore Similar Options
              <ExpandMoreIcon
                sx={{
                  marginLeft: 1,
                  transform: compareOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
            </Button>
            <Collapse in={compareOpen}>
              {compareLoading ? (
                <Box sx={{ textAlign: 'center', my: 2 }}>
                  <CircularProgress size={24} />
                </Box>
              ) : compareError ? (
                <Alert severity="error" sx={{ m: 2 }}>
                  {compareError}
                </Alert>
              ) : compareProducts.length > 0 ? (
                compareProducts.map((cmp) => (
                  <Box key={cmp.product_id} sx={{ mb: 1 }}>
                    <CompareListItem
                      product={cmp}
                      multiplier={multiplier}
                      favoritesMap={{}} // pass in if you need
                      onAddFavorite={handleAddFavorite}
                      onRemoveFavorite={handleRemoveFavorite}
                    />
                  </Box>
                ))
              ) : (
                <Typography variant="body1" align="center" sx={{ my: 4 }}>
                  No compare products available.
                </Typography>
              )}
            </Collapse>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default DesktopItemDetails;
