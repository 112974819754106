// src/pages/PurchaseHistory.js

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Drawer,
  Container,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Fab,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import TopNavBar from './TopNavBar';
import PHFilterPanel from './PHFilterPanel';
import PHProductCard from './PHProductCard';
import PHListItem from './PHListItem';
import CompareListItem from './CompareListItem';

// ---------------------
// Styled Search components (identical to Inventory)
// ---------------------
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 300, // adjust as needed
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled('input')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
}));

function PurchaseHistory() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // ---------------------
  // Debounce for Search
  // ---------------------
  const [searchInput, setSearchInput] = useState(''); // immediate typing
  const [searchTerm, setSearchTerm] = useState('');   // debounced value

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchTerm(searchInput);
      setCurrentPage(1);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchInput]);

  // ---------------------
  // States
  // ---------------------
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(0);

  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [viewMode, setViewMode] = useState('grid');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [purchaseHistoryItems, setPurchaseHistoryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  // Compare / favorites
  const [compareMap, setCompareMap] = useState({});
  const [compareExpanded, setCompareExpanded] = useState({});
  const [favoritesMap, setFavoritesMap] = useState({});

  // User data
  const [userId, setUserId] = useState(null);
  const [multiplier, setMultiplier] = useState(1);
  const [restaurantId, setRestaurantId] = useState(null);

  // ---------------------
  // On Mount: parse user
  // ---------------------
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUserId(parsedUser.id ?? 1);

        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);

        if (parsedUser.restaurant_id) {
          setRestaurantId(parsedUser.restaurant_id);
        }
      } catch (err) {
        console.error('Error parsing user data:', err);
      }
    } else {
      setUserId(1);
    }
  }, []);

  // ---------------------
  // Fetch categories / subcategories / vendors
  // ---------------------
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const resp = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
          { headers: { 'Content-Type': 'application/json' } }
        );
        if (Array.isArray(resp.data)) {
          setCategories(resp.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchVendors = async (rId) => {
      if (!rId) return;
      try {
        const resp = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
          {
            headers: { 'Content-Type': 'application/json' },
            params: { restaurant_id: rId },
          }
        );
        if (Array.isArray(resp.data)) {
          const vendorList = resp.data.map((d) => d._vendor);
          setVendors(vendorList);
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    const fetchFavorites = async (rId) => {
      if (!rId) return;
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
          {
            params: { restaurant_id: rId },
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (Array.isArray(response.data)) {
          const map = {};
          response.data.forEach((favItem) => {
            const { product_id, id: list_item_id } = favItem;
            if (!map[product_id]) {
              map[product_id] = [];
            }
            map[product_id].push(list_item_id);
          });
          setFavoritesMap(map);
        }
      } catch (err) {
        console.error('Error fetching favorites:', err);
      }
    };

    fetchCategories();

    if (restaurantId) {
      fetchVendors(restaurantId);
      fetchFavorites(restaurantId);
    }
  }, [restaurantId]);

  // Subcategories
  useEffect(() => {
    const fetchSubcategories = async (catId) => {
      if (catId === 0) {
        setSubcategories([]);
        setSelectedSubcategory(0);
        return;
      }
      try {
        const resp = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
          {
            headers: { 'Content-Type': 'application/json' },
            params: { filter: catId },
          }
        );
        if (Array.isArray(resp.data)) {
          setSubcategories(resp.data);
        }
      } catch (error) {
        console.error('Error fetching subcategories:', error);
        setSubcategories([]);
      }
    };

    fetchSubcategories(selectedCategory);
  }, [selectedCategory]);

  // ---------------------
  // Favorites add/remove
  // ---------------------
  const handleAddFavorite = async (productId) => {
    if (!restaurantId) return;
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        { product_id: productId, restaurant_id: restaurantId },
        { headers: { 'Content-Type': 'application/json' } }
      );
      // refetch
      const updatedResp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: restaurantId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(updatedResp.data)) {
        const map = {};
        updatedResp.data.forEach((favItem) => {
          const { product_id, id: list_item_id } = favItem;
          if (!map[product_id]) {
            map[product_id] = [];
          }
          map[product_id].push(list_item_id);
        });
        setFavoritesMap(map);
      }
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    if (!favoritesMap[productId]) return;
    try {
      const listItemIds = favoritesMap[productId];
      const deleteRequests = listItemIds.map((id) =>
        axios.delete('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item', {
          data: { list_item_id: id },
          headers: { 'Content-Type': 'application/json' },
        })
      );
      await Promise.all(deleteRequests);
      // refetch
      const updatedResp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: restaurantId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(updatedResp.data)) {
        const map = {};
        updatedResp.data.forEach((favItem) => {
          const { product_id, id: list_item_id } = favItem;
          if (!map[product_id]) {
            map[product_id] = [];
          }
          map[product_id].push(list_item_id);
        });
        setFavoritesMap(map);
      }
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  // ---------------------
  // Compare
  // ---------------------
  const handleCompareClick = async (productId) => {
    if (compareExpanded[productId]) {
      setCompareExpanded((prev) => ({ ...prev, [productId]: false }));
      return;
    }

    try {
      const userJson = localStorage.getItem('user');
      const user = userJson ? JSON.parse(userJson) : null;
      const localUserId = user ? user.id : 1;

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_2',
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            product_id: productId,
            user_id: localUserId,
          },
        }
      );

      if (Array.isArray(response.data)) {
        setCompareMap((prev) => ({
          ...prev,
          [productId]: response.data,
        }));
        setCompareExpanded((prev) => ({ ...prev, [productId]: true }));
      }
    } catch (error) {
      console.error('Error fetching compare products:', error);
    }
  };

  // ---------------------
  // Fetch Purchase History
  // ---------------------
  const fetchPurchaseHistory = async () => {
    if (!userId) return;
    setLoading(true);

    try {
      const offset = (currentPage - 1) * itemsPerPage;

      const body = {
        useridfilter: userId,
        limit: itemsPerPage,
        offset,
        productnamefilter: searchTerm, // debounced
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory,
        vendor_id: selectedVendor,
      };

      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/purchase_history_items_react_sql',
        body,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (Array.isArray(response.data)) {
        const items = response.data;
        setPurchaseHistoryItems(items);

        const fetchedTotal = items.length > 0 ? items[0].total_count : 0;
        setTotalCount(fetchedTotal);

        const newTotalPages = Math.ceil(fetchedTotal / itemsPerPage);
        setTotalPages(newTotalPages);
      } else {
        setPurchaseHistoryItems([]);
        setTotalCount(0);
      }
    } catch (error) {
      console.error('Error fetching purchase history:', error);
      setPurchaseHistoryItems([]);
      setTotalCount(0);
    } finally {
      setLoading(false);
    }
  };

  // Re-fetch items on relevant changes
  useEffect(() => {
    fetchPurchaseHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userId,
    currentPage,
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    searchTerm,
    itemsPerPage,
  ]);

  // ---------------------
  // Handlers
  // ---------------------
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleViewToggle = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor(0);
    setSearchInput('');
    setSearchTerm('');
    setItemsPerPage(24);
    setCurrentPage(1);
  };

  // ---------------------
  // Rendering
  // ---------------------
  const renderPurchaseHistory = () => {
    if (loading) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <CircularProgress />
        </Box>
      );
    }
    if (!loading && purchaseHistoryItems.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <Typography variant="h6">No purchase history found.</Typography>
        </Box>
      );
    }

    if (viewMode === 'grid') {
      return (
        <Grid container spacing={2}>
          {purchaseHistoryItems.map((item) => {
            const isCompareOpen = !!compareExpanded[item.product_id];
            return (
              <React.Fragment key={item.product_id}>
                <Grid item xs={6} sm={4} md={3}>
                  <PHProductCard
                    product={item}
                    multiplier={multiplier}
                    favoritesMap={favoritesMap}
                    onAddFavorite={handleAddFavorite}
                    onRemoveFavorite={handleRemoveFavorite}
                    compareOpen={isCompareOpen}
                    onCompareClick={handleCompareClick}
                  />
                </Grid>

                {isCompareOpen && item.hasalternates && compareMap[item.product_id] && (
                  <Grid item xs={12}>
                    <Box sx={{ backgroundColor: '#f9f9f9', p: theme.spacing(1) }}>
                      {compareMap[item.product_id].map((compareItem) => (
                        <CompareListItem
                          key={compareItem.product_id}
                          product={compareItem}
                          multiplier={multiplier}
                          restaurantId={restaurantId}
                          favoritesMap={favoritesMap}
                          onAddFavorite={handleAddFavorite}
                          onRemoveFavorite={handleRemoveFavorite}
                        />
                      ))}
                    </Box>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      );
    }

    // LIST
    return (
      <Box>
        {purchaseHistoryItems.map((item) => {
          const isCompareOpen = !!compareExpanded[item.product_id];
          return (
            <React.Fragment key={item.product_id}>
              <PHListItem
                product={item}
                multiplier={multiplier}
                compareOpen={isCompareOpen}
                onCompareClick={handleCompareClick}
                favoritesMap={favoritesMap}
                onAddFavorite={handleAddFavorite}
                onRemoveFavorite={handleRemoveFavorite}
              />

              {isCompareOpen && item.hasalternates && compareMap[item.product_id] && (
                <Box sx={{ backgroundColor: '#f9f9f9', p: theme.spacing(1) }}>
                  {compareMap[item.product_id].map((compareItem) => (
                    <CompareListItem
                      key={compareItem.product_id}
                      product={compareItem}
                      multiplier={multiplier}
                      restaurantId={restaurantId}
                      favoritesMap={favoritesMap}
                      onAddFavorite={handleAddFavorite}
                      onRemoveFavorite={handleRemoveFavorite}
                    />
                  ))}
                </Box>
              )}
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>FareFood | Purchase History</title>
      </Helmet>

      {/* Sticky Nav */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar title="Purchase History" />
      </Box>

      <Container maxWidth="lg" sx={{ paddingTop: theme.spacing(8) }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginTop: isMobile ? theme.spacing(1) : theme.spacing(4),
            marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
          }}
        >
          Purchase History
        </Typography>

        {/* 
          Controls row (like Inventory):
          On mobile => stacked. On desktop => single row with search on the left,
          items-per-page + view toggle on the right.
        */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isMobile ? 'stretch' : 'center',
            gap: isMobile ? 2 : 0,
            marginBottom: theme.spacing(2),
          }}
        >
          {/* Left: Search */}
          <Box sx={{ flexGrow: isMobile ? 0 : 1 }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Purchase History..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Search>
          </Box>

          {/* Right side => Items Per Page + View Toggle (desktop only) */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControl sx={{ minWidth: 80 }}>
                <InputLabel>Items</InputLabel>
                <Select
                  value={itemsPerPage}
                  label="Items"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={48}>48</MenuItem>
                  <MenuItem value={96}>96</MenuItem>
                </Select>
              </FormControl>

              <IconButton onClick={handleViewToggle}>
                {viewMode === 'grid' ? <ViewListIcon /> : <ViewModuleIcon />}
              </IconButton>
            </Box>
          )}
        </Box>

        <Grid container spacing={2} alignItems="flex-start">
          {/* Filter Sidebar (desktop) */}
          {!isMobile && (
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                height: 'calc(100vh - 64px)',
                position: 'sticky',
                top: '64px',
                overflowY: 'auto',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <PHFilterPanel
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                subcategories={subcategories}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
                vendors={vendors}
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                viewMode={viewMode}
                setViewMode={setViewMode}
                clearAllFilters={clearAllFilters}
                isMobile={false}
              />
            </Grid>
          )}

          {/* Main content */}
          <Grid
            item
            xs={12}
            sm={isMobile ? 12 : 9}
            md={isMobile ? 12 : 10}
            sx={{
              paddingTop: isMobile ? theme.spacing(1) : 0,
              paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            {renderPurchaseHistory()}

            {/* Pagination with extra bottom margin on mobile */}
            {totalPages > 1 && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'center', 
                  mt: 4,
                  mb: isMobile ? 8 : 0  // <--- Added margin bottom for mobile
                }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  size={isMobile ? 'small' : 'medium'}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Mobile: Floating Filter FAB */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: theme.spacing(8),
            right: theme.spacing(2),
            zIndex: 9999,
          }}
        >
          <Fab color="primary" onClick={toggleDrawer(true)}>
            <FilterListIcon />
          </Fab>
        </Box>
      )}

      {/* Mobile Filter Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ sx: { width: 250 } }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <PHFilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            vendors={vendors}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            clearAllFilters={clearAllFilters}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            viewMode={viewMode}
            setViewMode={setViewMode}
            isMobile
          />
        </Box>
      </Drawer>
    </>
  );
}

export default PurchaseHistory;
