// Shop.js
import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Drawer,
  Container,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';
import FilterPanel from './FilterPanel';
import ProductCard from './ProductCard';
import { Helmet } from 'react-helmet';
import ProductListItem from './ProductListItem';
import CompareListItem from './CompareListItem';

function Shop() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const initialSearchTerm = searchParams.get('query') || '';
  const initialCategory = parseInt(searchParams.get('category'), 10) || 0;
  const initialSubcategory = parseInt(searchParams.get('subcategory'), 10) || 0;
  const initialVendor = searchParams.get('vendor')
    ? searchParams.get('vendor').split(',').map(Number)
    : [];
  const initialSort = searchParams.get('sort') || 'default';
  const initialItemsPerPage = parseInt(searchParams.get('ipp'), 10) || 24;

  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    initialSubcategory
  );
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(initialVendor);

  // Sorting & Layout
  const [sortOption, setSortOption] = useState(initialSort);
  const [viewMode, setViewMode] = useState('grid'); // "grid" or "list"

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [multiplier, setMultiplier] = useState(1);
  const [restaurantId, setRestaurantId] = useState(null);

  // Items per page
  const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPage);

  // Compare data
  const [compareMap, setCompareMap] = useState({});
  const [compareExpanded, setCompareExpanded] = useState({});

  // Products
  const [allProducts, setAllProducts] = useState([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Favorites
  const [favoritesMap, setFavoritesMap] = useState({});

  // Tracks whether vendor accounts are still loading products
  const [accountsLoading, setAccountsLoading] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);
        if (parsedUser.restaurant_id) {
          setRestaurantId(parsedUser.restaurant_id);
        }
      } catch (error) {
        console.error('Error parsing user data:', error);
        setMultiplier(1);
      }
    } else {
      setMultiplier(1);
    }
  }, []);

  // ---------------------
  // Favorites Handling
  // ---------------------
  const fetchFavorites = async (restId) => {
    if (!restId) return;
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: restId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        const map = {};
        response.data.forEach((favItem) => {
          const { product_id, id: list_item_id } = favItem;
          if (!map[product_id]) {
            map[product_id] = [];
          }
          map[product_id].push(list_item_id);
        });
        setFavoritesMap(map);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setFavoritesMap({});
    }
  };

  const handleAddFavorite = async (productId) => {
    if (!restaurantId) {
      console.warn('No restaurantId for adding to favorites.');
      return;
    }
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        {
          product_id: productId,
          restaurant_id: restaurantId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    if (!favoritesMap[productId]) return;
    try {
      const listItemIds = favoritesMap[productId];
      const deleteRequests = listItemIds.map((id) =>
        axios.delete(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item',
          {
            data: { list_item_id: id },
            headers: { 'Content-Type': 'application/json' },
          }
        )
      );
      await Promise.all(deleteRequests);
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  // ---------------------
  // Compare
  // ---------------------
  const handleCompareClick = async (productId) => {
    // If compare is already expanded => collapse it
    if (compareExpanded[productId]) {
      setCompareExpanded((prev) => ({ ...prev, [productId]: false }));
      return;
    }

    try {
      const mainItem = displayProducts.find(
        (p) => p.main_product_id === productId
      );
      const mainItemPPU = mainItem?.price_per_unit ?? 999999;

      const userJson = localStorage.getItem('user');
      const user = userJson ? JSON.parse(userJson) : null;
      const userId = user ? user.id : 1;

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_2',
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            product_id: productId,
            user_id: userId,
          },
        }
      );

      if (Array.isArray(response.data)) {
        const updatedCompareItems = response.data.map((compareItem) => {
          if (!compareItem.bestdeal) {
            const comparePPU = compareItem.price_per_unit ?? 999999;
            compareItem.better_price =
              comparePPU > 0 && comparePPU < mainItemPPU;
          }
          return compareItem;
        });

        // Filter out duplicates by product_id
        const uniqueCompareItems = updatedCompareItems.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.product_id === item.product_id)
        );

        setCompareMap((prev) => ({
          ...prev,
          [productId]: uniqueCompareItems,
        }));
        setCompareExpanded((prev) => ({ ...prev, [productId]: true }));
      }
    } catch (error) {
      console.error('Error comparing products:', error);
    }
  };

  // ---------------------
  // Category & Vendors
  // ---------------------
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
        { headers: { 'Content-Type': 'application/json' } }
      );
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    if (categoryId === 0) {
      setSubcategories([]);
      setSelectedSubcategory(0);
      return;
    }
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
        {
          headers: { 'Content-Type': 'application/json' },
          params: { filter: categoryId },
        }
      );
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setSubcategories([]);
    }
  };

  const fetchVendors = async () => {
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        throw new Error('User data not found in local storage.');
      }
      const user = JSON.parse(userJson);
      const rId = user.restaurant_id;
      if (!rId) {
        throw new Error('Restaurant ID not found in user data.');
      }

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
        {
          params: { restaurant_id: rId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.status !== 200) {
        throw new Error('Failed to fetch restaurant vendors');
      }
      const data = response.data;
      const fetchedVendors = data.map((item) => item._vendor);
      setVendors(fetchedVendors);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setVendors([]);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // ---------------------
  // Check if accounts are still loading (initially)
  // ---------------------
  useEffect(() => {
    const checkAccountsLoading = async () => {
      if (!restaurantId) return;
      try {
        const response = await axios.post(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/loading_products',
          { restaurant_id: restaurantId },
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (Array.isArray(response.data) && response.data.length > 0) {
          setAccountsLoading(response.data[0].loadingproducts === true);
        }
      } catch (err) {
        console.error('Error checking if accounts are loading:', err);
      }
    };

    checkAccountsLoading();
  }, [restaurantId]);

  // ---------------------
  // Poll if accounts are loading
  // ---------------------
  useEffect(() => {
    let pollInterval;

    if (accountsLoading && restaurantId) {
      // Poll every 30 seconds (adjust as desired)
      pollInterval = setInterval(async () => {
        try {
          const response = await axios.post(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/loading_products',
            { restaurant_id: restaurantId },
            { headers: { 'Content-Type': 'application/json' } }
          );
          if (
            Array.isArray(response.data) &&
            response.data.length > 0 &&
            response.data[0].loadingproducts === false
          ) {
            // If loading has finished, clear interval and refetch products
            clearInterval(pollInterval);
            setAccountsLoading(false);
            fetchProducts();
          }
        } catch (err) {
          console.error('Error polling for loaded products:', err);
        }
      }, 30000);
    }

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [accountsLoading, restaurantId]);

  // ---------------------
  // Fetch Products
  // ---------------------
  const fetchProducts = async () => {
    // If there’s no searchTerm, no category, and no vendor selected,
    // we skip fetching (blank state).
    if (!searchTerm && selectedCategory === 0 && selectedVendor.length === 0) {
      setAllProducts([]);
      setTotalResults(0);
      setTotalPages(0);
      return;
    }

    setLoadingProducts(true);
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        throw new Error('User data not found in local storage.');
      }
      const user = JSON.parse(userJson);
      const rId = user.restaurant_id;
      if (!rId) {
        throw new Error('Restaurant ID not found in user data.');
      }

      const body = {
        restaurant_id: rId,
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory,
        vendor_ids: selectedVendor,
        productnamefilter: searchTerm,
        limit: itemsPerPage,
        offset: (currentPage - 1) * itemsPerPage,
      };

      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_specific_search_8_sql_3',
        body,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (response.status !== 200) {
        throw new Error('Failed to fetch products');
      }
      const data = response.data;

      if (!Array.isArray(data) || data.length === 0) {
        setAllProducts([]);
        setTotalResults(0);
        setTotalPages(0);
      } else {
        data.forEach((item) => {
          // Convert LB to oz, etc.:
          if (item.case_priced_by?.toLowerCase() === 'lb') {
            const ppu = item.purchase_price / 16;
            item.price_per_unit = parseFloat(ppu.toFixed(3));
            item.price_per_unit_priced_by = 'oz';
          } else if (item.purchase_price > 0 && item.product_volume > 0) {
            const ppu = item.purchase_price / item.product_volume;
            item.price_per_unit = parseFloat(ppu.toFixed(3));
            item.price_per_unit_priced_by = item.product_volume_priced_by;
          } else {
            item.price_per_unit = 0;
            item.price_per_unit_priced_by = '';
          }
        });

        const totalItems = data[0].total_items || 0;
        setAllProducts(data);
        setTotalResults(totalItems);
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setAllProducts([]);
      setTotalResults(0);
      setTotalPages(0);
    } finally {
      setLoadingProducts(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchCategories();
    fetchVendors();
    fetchSubcategories(selectedCategory);
    if (restaurantId) {
      fetchFavorites(restaurantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplier, restaurantId]);

  useEffect(() => {
    fetchSubcategories(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Update search params
  useEffect(() => {
    const params = {};
    if (searchTerm) params.query = searchTerm;
    if (selectedCategory !== 0) params.category = selectedCategory;
    if (selectedSubcategory !== 0) params.subcategory = selectedSubcategory;
    if (selectedVendor.length > 0) params.vendor = selectedVendor.join(',');
    if (sortOption !== 'default') params.sort = sortOption;
    if (itemsPerPage !== 24) params.ipp = itemsPerPage;

    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    sortOption,
    searchTerm,
    itemsPerPage,
  ]);

  // Function to clear all filters
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor([]);
    setSortOption('default');
    setItemsPerPage(24); // optional reset

    const params = {};
    if (searchTerm) params.query = searchTerm;
    setSearchParams(params);
  };

  // Whenever a search is performed via TopNavBar, clear filters first & then set the search term
  const handleSearchFromNavBar = (newSearchTerm) => {
    // Clear all filters
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor([]);
    setSortOption('default');
    setItemsPerPage(24);

    // Then set new search term
    setSearchTerm(newSearchTerm);
    setCurrentPage(1);
  };

  // Re-fetch on filter changes
  useEffect(() => {
    setCurrentPage(1);
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    selectedSubcategory,
    searchTerm,
    selectedVendor,
    itemsPerPage,
  ]);

  // Re-fetch on page change
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  // ---------------------
  // Pagination & Toggle
  // ---------------------
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleViewToggle = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  // ---------------------
  // Sorting
  // ---------------------
  const sortProducts = (products, option, mul) => {
    const sorted = [...products];
    switch (option) {
      case 'price_low_high':
        sorted.sort((a, b) => a.purchase_price * mul - b.purchase_price * mul);
        break;
      case 'price_high_low':
        sorted.sort((a, b) => b.purchase_price * mul - a.purchase_price * mul);
        break;
      case 'ppu_low_high':
        sorted.sort((a, b) => {
          const pA = a.price_per_unit ?? Infinity;
          const pB = b.price_per_unit ?? Infinity;
          return pA * mul - pB * mul;
        });
        break;
      case 'ppu_high_low':
        sorted.sort((a, b) => {
          const pA = a.price_per_unit ?? 0;
          const pB = b.price_per_unit ?? 0;
          return pB * mul - pA * mul;
        });
        break;
      default:
        // "default" => sort by product ID
        sorted.sort((a, b) => a.main_product_id - b.main_product_id);
        break;
    }
    return sorted;
  };

  useEffect(() => {
    const validProducts = allProducts.filter((p) => p.main_product_id);
    const sortedProducts = sortProducts(validProducts, sortOption, multiplier);
    setDisplayProducts(sortedProducts);
  }, [allProducts, sortOption, multiplier]);

  // ---------------------
  // Render Helpers
  // ---------------------
  const renderProducts = () => {
    // Show loading spinner while fetching from our main products API
    if (loadingProducts) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <CircularProgress />
        </Box>
      );
    }

    // If loaded, but no results:
    if (!loadingProducts && displayProducts.length === 0) {
      // If user has connected accounts but they're still loading on the server side:
      if (accountsLoading) {
        return (
          <Box sx={{ textAlign: 'center', margin: theme.spacing(4) }}>
            <CircularProgress size={48} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Your vendor accounts are connecting...
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Hang tight! We’ll show your products as soon as they’re ready.
            </Typography>
          </Box>
        );
      }

      // Otherwise, show a standard "no products" state
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <Typography variant="h6">
            No products found. Connect your vendor accounts in settings.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/settings')}
            sx={{ marginTop: theme.spacing(2) }}
          >
            Connect Your Accounts
          </Button>
        </Box>
      );
    }

    // Otherwise, render the products in grid or list mode
    if (viewMode === 'grid') {
      return (
        <Grid container spacing={2}>
          {displayProducts.map((product) => (
            <React.Fragment key={product.main_product_id}>
              <Grid item xs={6} sm={4} md={3}>
                <ProductCard
                  product={product}
                  multiplier={multiplier}
                  restaurantId={restaurantId}
                  favoritesMap={favoritesMap}
                  onAddFavorite={handleAddFavorite}
                  onRemoveFavorite={handleRemoveFavorite}
                  onCompareClick={handleCompareClick}
                  compareOpen={!!compareExpanded[product.main_product_id]}
                />
              </Grid>

              {/* Compare Row */}
              {compareExpanded[product.main_product_id] &&
                compareMap[product.main_product_id] &&
                product.hasalternates && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        backgroundColor: '#ffffff',
                        padding: theme.spacing(1),
                      }}
                    >
                      {compareMap[product.main_product_id].map(
                        (compareItem) => (
                          <CompareListItem
                            key={compareItem.product_id}
                            product={compareItem}
                            multiplier={multiplier}
                            restaurantId={restaurantId}
                            favoritesMap={favoritesMap}
                            onAddFavorite={handleAddFavorite}
                            onRemoveFavorite={handleRemoveFavorite}
                          />
                        )
                      )}
                    </Box>
                  </Grid>
                )}
            </React.Fragment>
          ))}
        </Grid>
      );
    }

    // LIST VIEW
    return (
      <Box>
        {displayProducts.map((product) => (
          <React.Fragment key={product.main_product_id}>
            <ProductListItem
              product={product}
              multiplier={multiplier}
              restaurantId={restaurantId}
              favoritesMap={favoritesMap}
              onAddFavorite={handleAddFavorite}
              onRemoveFavorite={handleRemoveFavorite}
              onCompareClick={handleCompareClick}
              compareOpen={!!compareExpanded[product.main_product_id]}
            />
            {/* Compare Row */}
            {compareExpanded[product.main_product_id] &&
              compareMap[product.main_product_id] &&
              product.hasalternates && (
                <Box sx={{ mb: 2 }}>
                  {compareMap[product.main_product_id].map((compareItem) => (
                    <CompareListItem
                      key={compareItem.product_id}
                      product={compareItem}
                      multiplier={multiplier}
                      restaurantId={restaurantId}
                      favoritesMap={favoritesMap}
                      onAddFavorite={handleAddFavorite}
                      onRemoveFavorite={handleRemoveFavorite}
                    />
                  ))}
                </Box>
              )}
          </React.Fragment>
        ))}
      </Box>
    );
  };

  // Single-row category display on desktop, normal on mobile
  const renderNoFiltersContent = () => (
    <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
      <Typography variant="h5" gutterBottom>
        Ready to discover the perfect products?
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
        Just type in a search term, or select a category to see thousands of
        incredible items handpicked for you!
      </Typography>

      <Grid
        container
        spacing={2}
        wrap={isMobile ? 'wrap' : 'nowrap'}
        justifyContent={isMobile ? 'center' : 'flex-start'}
        sx={{
          overflowX: isMobile ? 'visible' : 'auto',
        }}
      >
        {categories.map((cat) => (
          <Grid
            item
            key={cat.id}
            xs={6}
            sm={6}
            md="auto"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Box
              onClick={() => {
                setSelectedCategory(cat.id);
                setSearchTerm('');
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                border: '1px solid #ccc',
                borderRadius: 2,
                padding: theme.spacing(2),
                textAlign: 'center',
                '&:hover': {
                  boxShadow: 4,
                },
                minWidth: 80,
                maxWidth: 80,
                minHeight: 100,
                maxHeight: 100,
              }}
            >
              <img
                src={cat.icon?.url}
                alt={cat.name}
                style={{
                  maxWidth: '60%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{ marginTop: theme.spacing(1), fontWeight: 600 }}
              >
                {cat.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  // Determines whether to show noFilters or actual products
  const renderContent = () => {
    const noFilters =
      !searchTerm && selectedCategory === 0 && selectedVendor.length === 0;

    if (noFilters) {
      return renderNoFiltersContent();
    }

    return (
      <>
        {renderProducts()}
        {totalPages > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: theme.spacing(4),
              marginBottom: isMobile ? theme.spacing(8) : 0,
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? 'small' : 'medium'}
              showFirstButton={!isMobile}
              showLastButton={!isMobile}
              siblingCount={isMobile ? 0 : 1}
              boundaryCount={isMobile ? 1 : 2}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <div>
      <Helmet>
        <title>FareFood | Shop</title>
      </Helmet>

      {/* TopNavBar now calls our custom handler that clears filters and sets search term */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar
          onSearch={handleSearchFromNavBar}
          initialSearchTerm={searchTerm}
        />
      </Box>

      <Container maxWidth="lg" sx={{ paddingTop: theme.spacing(8) }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            marginTop: isMobile ? theme.spacing(1) : theme.spacing(4),
            marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
          }}
        >
          {searchTerm ? `Results for "${searchTerm}"` : 'All Products'}
        </Typography>

        {/* Mobile: show results count up top (desktop gets its own below) */}
        {isMobile && totalResults > 0 && (
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ marginBottom: theme.spacing(1) }}
          >
            {`Showing ${(currentPage - 1) * itemsPerPage + 1}-
              ${(currentPage - 1) * itemsPerPage + displayProducts.length}
              out of ${totalResults} results`}
          </Typography>
        )}

        <Grid container spacing={2} alignItems="flex-start">
          {/* Filter Sidebar (Desktop) */}
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            sx={{
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              display: { xs: 'none', sm: 'block' },
              height: 'calc(100vh - 64px)',
              position: 'sticky',
              top: '64px',
              overflowY: 'auto',
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <FilterPanel
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              subcategories={subcategories}
              selectedSubcategory={selectedSubcategory}
              setSelectedSubcategory={setSelectedSubcategory}
              selectedVendor={selectedVendor}
              setSelectedVendor={setSelectedVendor}
              clearAllFilters={clearAllFilters}
              sortOption={sortOption}
              setSortOption={setSortOption}
              viewMode={viewMode}
              setViewMode={setViewMode}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
            />
          </Grid>

          {/* Main Products Section */}
          <Grid item xs={12} sm={9} md={10}>
            {/* Controls Row (Desktop only) */}
            {!isMobile && (
              <Grid
                container
                alignItems="center"
                sx={{ marginBottom: theme.spacing(2), marginTop: 0 }}
              >
                {/* Left column: results text (or 0) */}
                <Grid item xs>
                  <Typography variant="subtitle2" color="text.secondary">
                    {totalResults > 0
                      ? `Showing ${
                          (currentPage - 1) * itemsPerPage + 1
                        }-${
                          (currentPage - 1) * itemsPerPage +
                          displayProducts.length
                        } out of ${totalResults} results`
                      : 'Showing 0 results'}
                  </Typography>
                </Grid>

                {/* Right column: Sort, Items per page, View Toggle */}
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel id="sort-select-label">Sort By</InputLabel>
                      <Select
                        labelId="sort-select-label"
                        id="sort-select"
                        value={sortOption}
                        label="Sort By"
                        onChange={(e) => setSortOption(e.target.value)}
                      >
                        <MenuItem value="default">Default</MenuItem>
                        <MenuItem value="price_low_high">
                          Price: Low to High
                        </MenuItem>
                        <MenuItem value="price_high_low">
                          Price: High to Low
                        </MenuItem>
                        <MenuItem value="ppu_low_high">
                          Price/Unit: Low to High
                        </MenuItem>
                        <MenuItem value="ppu_high_low">
                          Price/Unit: High to Low
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 80 }}>
                      <InputLabel>Items</InputLabel>
                      <Select
                        value={itemsPerPage}
                        label="Items"
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={48}>48</MenuItem>
                        <MenuItem value={96}>96</MenuItem>
                      </Select>
                    </FormControl>

                    <IconButton onClick={handleViewToggle}>
                      {viewMode === 'grid' ? (
                        <ViewListIcon />
                      ) : (
                        <ViewModuleIcon />
                      )}
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            )}

            {/* Product content or "no filters" content */}
            {renderContent()}
          </Grid>
        </Grid>
      </Container>

      {/* Drawer for Mobile Filters */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleDrawer(false)} aria-label="close filters">
              <CloseIcon />
            </IconButton>
          </Box>

          <FilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            clearAllFilters={clearAllFilters}
            sortOption={sortOption}
            setSortOption={setSortOption}
            viewMode={viewMode}
            setViewMode={setViewMode}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </Box>
      </Drawer>

      {/* Mobile-only filter button */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: theme.spacing(12),
            right: theme.spacing(2),
            zIndex: 9999,
          }}
        >
          <IconButton
            onClick={toggleDrawer(true)}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
              boxShadow: theme.shadows[4],
            }}
            size="large"
          >
            <FilterListIcon />
          </IconButton>
        </Box>
      )}
    </div>
  );
}

export default Shop;
