import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
  TextField,
  useMediaQuery,
  Tooltip,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ListAlt as ListAltIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import axios from 'axios';

import fallbackLogo from './assets/small-logo.avif';
import AddToCartButton from './AddToCartButton';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

/** 
 * Helper: getAuthHeadersAndUser
 */
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }

  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

/**
 * A small helper to render the product (inventory) image, 
 * the vendor logo pinned top-right, and the "Best Price" banner if needed,
 * mirroring how ProductListItem does it.
 */
function InventoryImageSection({ item }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    image_url,
    vendor_logo,
    vendor_name,
    short_description,
    bestdeal,
  } = item;

  const productImage = image_url || fallbackLogo;
  const logo = vendor_logo || fallbackLogo;
  const vendorShortName = vendor_name || 'Vendor';

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '15%' },
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: isMobile ? 1 : 0,
      }}
    >
      {/* Main product image */}
      <Box
        component="img"
        src={productImage}
        alt={short_description || 'Inventory item'}
        sx={{
          width: isMobile ? 90 : 60,
          height: isMobile ? 90 : 60,
          objectFit: 'cover',
          borderRadius: 1,
        }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackLogo;
        }}
      />

      {/* Vendor logo pinned top-right */}
      <Box
        sx={{
          position: 'absolute',
          top: '15%',
          right: 0,
          width: 40,
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          backgroundColor: '#fff',
          overflow: 'hidden',
          boxShadow: theme.shadows[2],
          zIndex: 2,
          transform: 'translate(-25%, -50%)',
        }}
      >
        <Box
          component="img"
          src={logo}
          alt={vendorShortName}
          sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackLogo;
          }}
        />
      </Box>

      {/* "Best Price" banner if bestdeal === true */}
      {bestdeal && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: '#00BC48',
            display: 'inline-flex',
            alignItems: 'center',
            padding: '2px 8px',
            borderRadius: '4px',
            zIndex: 3,
            margin: '4px',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: '#ffffff',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '0.75rem',
              fontFamily: 'Poppins',
            }}
          >
            Best Price
          </Typography>
        </Box>
      )}
    </Box>
  );
}

/**
 * Helper to replicate the PriceLine approach from ProductListItem,
 * displaying either "MARKET" or a numeric price + optional per-unit.
 */
function PriceLine({ price, perUnit }) {
  if (price === 'MARKET') {
    return (
      <Typography
        sx={{
          fontFamily: 'Poppins',
          fontSize: '9pt',
          fontWeight: 'bold',
          color: '#006D6D',
        }}
      >
        MARKET
      </Typography>
    );
  }
  // Otherwise, numeric
  return (
    <Box>
      <Typography
        component="span"
        sx={{
          fontFamily: 'Poppins',
          fontSize: '9pt',
          fontWeight: 'bold',
          color: '#006D6D',
        }}
      >
        {price}
      </Typography>
      {perUnit && (
        <Typography
          component="span"
          sx={{
            fontFamily: 'Poppins',
            fontSize: '7pt',
            color: '#949E98',
            marginLeft: '6px',
          }}
        >
          ({perUnit})
        </Typography>
      )}
    </Box>
  );
}

/**
 * InventoryListItem
 *
 * Follows the same layout logic as ProductListItem:
 * - On desktop: left image, middle info, right price lines
 * - On mobile: single column
 * - Red border if stock <= par
 */
function InventoryListItem({
  item = {},
  multiplier = 1,
  restaurantId,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  onCompareClick,
  compareOpen = false,
  onInventoryDeleted,
  isMobile = false,
  onClick,
}) {
  const theme = useTheme();
  const deviceIsMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const finalIsMobile = deviceIsMobile || isMobile;

  const {
    inventory_item_id,
    main_product_id,
    short_description,
    ff_catalog_number,
    brand,
    product_pack_size,
    purchase_price = 0,
    case_priced_by,
    each_price = 0,
    each_priced_by,
    product_volume,
    product_volume_priced_by,
    vendor_logo,
    vendor_name,
    image_url,
    hasalternates,
    bestdeal, // => "Best Price" or "Better Deals Available"
    lead_time, // if you need it
    special_notes, // if you need it
    stock,
    par,
  } = item;

  const finalProductId = main_product_id || 0;
  const isUserDefinedItem = finalProductId === 0;

  // Local state for stock/PAR
  const [stockLocal, setStockLocal] = useState(stock ?? 0);
  const [parLocal, setParLocal] = useState(par ?? 0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // Edit Stock/PAR
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editStock, setEditStock] = useState(stockLocal);
  const [editPar, setEditPar] = useState(parLocal);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [updateError, setUpdateError] = useState('');

  // Add to List
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [listsLoading, setListsLoading] = useState(false);
  const [lists, setLists] = useState([]);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // **NEW**: We'll also show a Snackbar after the user adds an item to a list
  const [listAddToast, setListAddToast] = useState('');

  // Delete from inventory
  const [deleteInventorySuccess, setDeleteInventorySuccess] = useState('');
  const [deleteInventoryError, setDeleteInventoryError] = useState('');
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  if (isDeleted) return null;

  // Compare button arrow/color
  const compareArrow = compareOpen ? '▲' : '▼';
  const compareButtonColor = compareOpen ? '#006D6D' : '#949E98';
  const compareButtonStyles =
    bestdeal === false
      ? { color: '#ffffff', backgroundColor: '#F87906' }
      : { color: compareButtonColor };
  const compareButtonText =
    bestdeal === false ? 'Better Deals Available' : 'Compare';

  // Favorites
  const isFavorited = !!favoritesMap[finalProductId];

  // Pricing
  let casePrice = 'MARKET';
  let casePerUnit = null;
  if (purchase_price > 0) {
    const numeric = (purchase_price * multiplier).toFixed(2);
    const suffix = case_priced_by || 'CS';
    casePrice = `$${numeric} ${suffix}`;

    // If LB => per oz
    if (case_priced_by?.toLowerCase() === 'lb') {
      const pricePerOz = (purchase_price * multiplier) / 16;
      casePerUnit = `$${pricePerOz.toFixed(2)}/oz`;
    }
    // If volume data => per unit
    else if (product_volume && product_volume > 0 && product_volume_priced_by) {
      const ppu = (purchase_price * multiplier) / product_volume;
      casePerUnit = `$${ppu.toFixed(2)}/${product_volume_priced_by}`;
    }
  }

  let eachLine = null;
  if (each_price > 0) {
    const numericEach = (each_price * multiplier).toFixed(2);
    const suffixEach = each_priced_by || 'EA';
    eachLine = `$${numericEach} ${suffixEach}`;
  }

  // brand line => brand | pack_size | ff_catalog_number
  const rawBrand = brand && brand !== '0' ? brand : '';
  const rawCatalog =
    ff_catalog_number && ff_catalog_number !== '0' ? ff_catalog_number : '';
  const brandLineArr = [rawBrand, product_pack_size, rawCatalog].filter(Boolean);
  const brandLine = brandLineArr.join(' | ');

  // Handler
  const handleRowClick = () => onClick?.(item);

  // 1) Favorites
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (!restaurantId || isUserDefinedItem) return;
    if (isFavorited) onRemoveFavorite?.(finalProductId);
    else onAddFavorite?.(finalProductId);
  };

  // 2) Add to List
  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    if (isUserDefinedItem) return;
    setOpenAddListDialog(true);
    fetchLists();
  };
  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };
  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) throw new Error('No restaurant_id in user data.');
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }
  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          product_id: finalProductId,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item added to the list!');
      // Also show our Toast
      setListAddToast('Item added to the list!');

      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // 3) Delete
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    if (!inventory_item_id) return;
    setConfirmDelete(true);
  };
  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setConfirmDelete(false);
  };
  const handleConfirmDelete = async (e) => {
    e.stopPropagation();
    if (!inventory_item_id) return;
    try {
      setDeleteInProgress(true);
      setDeleteInventorySuccess('');
      setDeleteInventoryError('');

      const { headers } = getAuthHeadersAndUser();
      await axios.delete(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory/${inventory_item_id}`,
        { headers }
      );

      setDeleteInventorySuccess('Item removed from inventory!');
      onInventoryDeleted?.(inventory_item_id);
      setIsDeleted(true);
    } catch (err) {
      console.error('Error deleting item:', err);
      setDeleteInventoryError('Failed to remove item from inventory.');
    } finally {
      setDeleteInProgress(false);
      setConfirmDelete(false);
    }
  };

  // 4) Edit Stock/PAR
  const handleOpenEditModal = (e) => {
    e.stopPropagation();
    if (!inventory_item_id) return;
    setEditStock(stockLocal);
    setEditPar(parLocal);
    setUpdateSuccess('');
    setUpdateError('');
    setEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setUpdateSuccess('');
    setUpdateError('');
  };
  const handleStockParUpdate = async () => {
    if (!inventory_item_id) return;
    setUpdateLoading(true);
    setUpdateSuccess('');
    setUpdateError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      const body = {
        inventory_item_id,
        onhand: Number(editStock),
        par: Number(editPar),
      };
      const response = await axios.patch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/update_inventory_item',
        body,
        { headers }
      );
      const updatedItem = response.data;
      setUpdateSuccess('Stock/PAR updated!');
      setStockLocal(updatedItem.On_Hand ?? 0);
      setParLocal(updatedItem.PAR ?? 0);
      setTimeout(() => {
        handleCloseEditModal();
      }, 1200);
    } catch (error) {
      console.error('Error updating stock/par:', error);
      setUpdateError('Failed to update stock/par.');
    } finally {
      setUpdateLoading(false);
    }
  };

  // Check if we should show a red border if stock <= par
  const showOrderNowFlag = inventory_item_id && stockLocal <= parLocal;

  // For optional compare border if no red border
  const borderStyles = showOrderNowFlag
    ? '2px solid #E92409'
    : compareOpen
    ? `2px solid ${theme.palette.grey[400]}`
    : `1px solid ${theme.palette.divider}`;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: finalIsMobile ? 'column' : 'row',
          alignItems: finalIsMobile ? 'flex-start' : 'center',
          padding: 1,
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
          border: borderStyles,
          borderRadius: 1,
          backgroundColor: 'transparent',
        }}
        onClick={handleRowClick}
      >
        {/* LEFT: Image section */}
        <InventoryImageSection item={item} />

        {/* DESKTOP layout */}
        {!finalIsMobile && (
          <>
            {/* Middle column: icons, short desc, brand, stock/PAR, compare */}
            <Box
              sx={{
                flex: 1,
                paddingLeft: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {/* Icons row */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0,
                  marginBottom: theme.spacing(0.5),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {/* Favorite */}
                {!isUserDefinedItem && (
                  <IconButton
                    onClick={handleFavoriteClick}
                    sx={{
                      color: '#E92409',
                      p: 0.5,
                      '&:hover': { color: alpha('#E92409', 0.7) },
                    }}
                  >
                    {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton>
                )}

                {/* Add to List */}
                {!isUserDefinedItem && (
                  <IconButton
                    onClick={handleOpenListDialog}
                    sx={{
                      color: '#006D6D',
                      p: 0.5,
                      '&:hover': { color: alpha('#006D6D', 0.7) },
                    }}
                  >
                    <ListAltIcon />
                  </IconButton>
                )}

                {/* Delete */}
                {inventory_item_id && (
                  <>
                    {confirmDelete ? (
                      <>
                        <IconButton
                          onClick={handleConfirmDelete}
                          sx={{ color: theme.palette.success.main, p: '2px' }}
                          aria-label="confirm delete"
                          size="small"
                        >
                          <CheckIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={handleCancelDelete}
                          sx={{ color: theme.palette.grey[500], p: '2px' }}
                          aria-label="cancel delete"
                          size="small"
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        onClick={handleDeleteClick}
                        sx={{
                          color: alpha(theme.palette.text.primary, 0.5),
                          p: 0.5,
                        }}
                        aria-label="delete from inventory"
                        size="small"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>

              {/* Short description */}
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '9pt',
                  color: '#131616',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  paddingRight: theme.spacing(4),
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {short_description || ''}
              </Typography>

              {/* brand line, lead_time, special_notes */}
              {brandLine && (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    color: '#3A3F3F',
                    mt: 0.25,
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: theme.spacing(4),
                  }}
                >
                  {brandLine}

                  {lead_time > 0 && (
                    <Tooltip title={`Lead Time: ${lead_time}`}>
                      <AvTimerIcon
                        sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                      />
                    </Tooltip>
                  )}
                  {special_notes &&
                    special_notes !== 'No Special Flags' && (
                      <Tooltip title={special_notes}>
                        <NoteAltIcon
                          sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                        />
                      </Tooltip>
                    )}
                </Typography>
              )}

              {/* Stock/PAR + edit */}
              {inventory_item_id ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mt: 1,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '10pt',
                      fontWeight: 600,
                      color: '#131616',
                    }}
                  >
                    Stock: {stockLocal} | PAR: {parLocal}
                  </Typography>
                  <IconButton
                    size="small"
                    sx={{
                      color: alpha('#006D6D', 0.8),
                      '&:hover': { color: alpha('#006D6D', 0.5) },
                    }}
                    onClick={handleOpenEditModal}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <Box sx={{ mt: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: 'Poppins', fontSize: '8pt', color: '#949E98' }}
                  >
                    Stock: — | PAR: —
                  </Typography>
                </Box>
              )}

              {/* Compare button */}
              <Box sx={{ py: 0.5, textAlign: 'left', mt: 1 }}>
                {hasalternates ? (
                  <Button
                    variant="text"
                    sx={{
                      ...compareButtonStyles,
                      fontFamily: 'Poppins',
                      fontWeight: 600,
                      fontSize: '0.6rem',
                      textTransform: 'none',
                      paddingX: 1,
                      paddingY: 0.25,
                      minHeight: 'auto',
                      borderRadius: 2,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCompareClick?.(finalProductId);
                    }}
                  >
                    {compareButtonText} {compareArrow}
                  </Button>
                ) : (
                  <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
                    Compare ▼
                  </Box>
                )}
              </Box>
            </Box>

            {/* Right column: price lines + add to cart */}
            <Box
              sx={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Case line */}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr auto',
                  alignItems: 'center',
                }}
              >
                <PriceLine
                  price={casePrice}
                  perUnit={casePrice === 'MARKET' ? null : casePerUnit}
                />
                {!isUserDefinedItem && (
                  <AddToCartButton
                    productId={finalProductId}
                    isSplit={false}
                    buttonLabel="Case"
                  />
                )}
              </Box>

              {/* Each line */}
              {eachLine && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <PriceLine price={eachLine} />
                  {!isUserDefinedItem && (
                    <AddToCartButton
                      productId={finalProductId}
                      isSplit
                      buttonLabel="Each"
                    />
                  )}
                </Box>
              )}
            </Box>
          </>
        )}

        {/* MOBILE layout */}
        {finalIsMobile && (
          <Box sx={{ width: '100%', mt: 1 }}>
            {/* Icons row */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                marginBottom: theme.spacing(0.5),
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Favorite */}
              {!isUserDefinedItem && (
                <IconButton
                  onClick={handleFavoriteClick}
                  sx={{
                    color: '#E92409',
                    p: 0.5,
                    '&:hover': { color: alpha('#E92409', 0.7) },
                  }}
                >
                  {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
              )}

              {/* Add to List */}
              {!isUserDefinedItem && (
                <IconButton
                  onClick={handleOpenListDialog}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': { color: alpha('#006D6D', 0.7) },
                  }}
                >
                  <ListAltIcon />
                </IconButton>
              )}

              {/* Delete */}
              {inventory_item_id && (
                <>
                  {confirmDelete ? (
                    <>
                      <IconButton
                        onClick={handleConfirmDelete}
                        sx={{ color: theme.palette.success.main, p: '2px' }}
                        aria-label="confirm delete"
                        size="small"
                      >
                        <CheckIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={handleCancelDelete}
                        sx={{ color: theme.palette.grey[500], p: '2px' }}
                        aria-label="cancel delete"
                        size="small"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      onClick={handleDeleteClick}
                      sx={{
                        color: alpha(theme.palette.text.primary, 0.5),
                        p: 0.5,
                      }}
                      aria-label="delete from inventory"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  )}
                </>
              )}
            </Box>

            {/* Short description */}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '9pt',
                color: '#131616',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {short_description || ''}
            </Typography>

            {/* brand line, lead_time, special_notes */}
            {brandLine && (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '8pt',
                  color: '#3A3F3F',
                  mt: 0.25,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {brandLine}

                {lead_time > 0 && (
                  <Tooltip title={`Lead Time: ${lead_time}`}>
                    <AvTimerIcon
                      sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                    />
                  </Tooltip>
                )}
                {special_notes &&
                  special_notes !== 'No Special Flags' && (
                    <Tooltip title={special_notes}>
                      <NoteAltIcon
                        sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                      />
                    </Tooltip>
                  )}
              </Typography>
            )}

            {/* Stock/PAR + Edit (mobile) */}
            {inventory_item_id ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mt: 1,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '10pt',
                    fontWeight: 600,
                    color: '#131616',
                  }}
                >
                  Stock: {stockLocal} | PAR: {parLocal}
                </Typography>
                <IconButton
                  size="small"
                  sx={{
                    color: alpha('#006D6D', 0.8),
                    '&:hover': { color: alpha('#006D6D', 0.5) },
                  }}
                  onClick={handleOpenEditModal}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ mt: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: 'Poppins', fontSize: '8pt', color: '#949E98' }}
                >
                  Stock: — | PAR: —
                </Typography>
              </Box>
            )}

            {/* Price lines (vertical stack) */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Case row */}
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr auto',
                  alignItems: 'center',
                }}
              >
                <PriceLine
                  price={casePrice}
                  perUnit={casePrice === 'MARKET' ? null : casePerUnit}
                />
                {!isUserDefinedItem && (
                  <AddToCartButton
                    productId={finalProductId}
                    isSplit={false}
                    buttonLabel="Case"
                  />
                )}
              </Box>

              {/* Each row */}
              {eachLine && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <PriceLine price={eachLine} />
                  {!isUserDefinedItem && (
                    <AddToCartButton
                      productId={finalProductId}
                      isSplit
                      buttonLabel="Each"
                    />
                  )}
                </Box>
              )}
            </Box>

            {/* Compare button, centered (mobile) */}
            <Box
              sx={{ textAlign: 'center', mt: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              {hasalternates ? (
                <Button
                  variant="text"
                  sx={{
                    ...compareButtonStyles,
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    fontSize: '0.6rem',
                    textTransform: 'none',
                    paddingX: 1,
                    paddingY: 0.25,
                    minHeight: 'auto',
                    borderRadius: 2,
                    mt: 0.25,
                  }}
                  onClick={() => onCompareClick?.(finalProductId)}
                >
                  {compareButtonText} {compareArrow}
                </Button>
              ) : (
                <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
                  Compare ▼
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {/* -------------------- Dialogs & Snackbars -------------------- */}

      {/* EDIT STOCK/PAR DIALOG */}
      <Dialog
        open={editModalOpen}
        onClose={handleCloseEditModal}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Edit Stock & PAR</DialogTitle>
        <DialogContent>
          {updateError && <Alert severity="error">{updateError}</Alert>}
          {updateSuccess && <Alert severity="success">{updateSuccess}</Alert>}

          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Stock (On Hand)"
              type="number"
              value={editStock}
              onChange={(e) => setEditStock(e.target.value)}
              inputProps={{ min: 0 }}
            />
            <TextField
              label="PAR Level"
              type="number"
              value={editPar}
              onChange={(e) => setEditPar(e.target.value)}
              inputProps={{ min: 0 }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal}>Cancel</Button>
          <Button
            onClick={handleStockParUpdate}
            disabled={updateLoading}
            variant="contained"
          >
            {updateLoading ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* ADD TO LIST DIALOG */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select a List</DialogTitle>
        <DialogContent>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText primary={list.list_name} />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography variant="body2" align="center">
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseListDialog} disabled={addingToList}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* DELETE SNACKBARS */}
      {deleteInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setDeleteInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDeleteInventorySuccess('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {deleteInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {deleteInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setDeleteInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDeleteInventoryError('')}
            severity="error"
            sx={{ width: '100%' }}
          >
            {deleteInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Toast for "Add to List" success */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default InventoryListItem;
