// src/Demo.js

import React, { useEffect } from 'react';
import { Box, Typography, Button, Container, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet'; // Import Helmet
import SalesNavBar from './SalesNavBar';
import Footer from './Footer';

// Styled Components
const Root = styled(Box)({
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
});

const MainContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontFamily: 'Poppins, sans-serif',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 Aspect Ratio
  height: 0,
  overflow: 'hidden',
  marginBottom: theme.spacing(4),
}));

const VideoIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 0,
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  flexDirection: { xs: 'column', sm: 'row' },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  paddingX: 3,
  paddingY: 1.5,
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  letterSpacing: '0.5px',
}));

const Demo = () => {
  const theme = useTheme();

  useEffect(() => {
    // Any additional effects if needed
  }, []);

  return (
    <Root>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Demo</title>
        <meta
          name="description"
          content="Experience FareFood in action! Watch our demo to see how FareFood streamlines your restaurant's food ordering process, saving you time and money."
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Demo" />
        <meta
          property="og:description"
          content="See how FareFood can revolutionize your restaurant's food orders. Watch our demo to learn more!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/demo" />
        <meta property="og:image" content="https://www.farefood.com/assets/demo-og-image.jpg" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Demo" />
        <meta
          name="twitter:description"
          content="Watch our FareFood demo to discover how we help restaurants streamline food orders, reduce costs, and save valuable time."
        />
        <meta name="twitter:image" content="https://www.farefood.com/assets/demo-twitter-image.jpg" />
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <MainContent>
        <Container maxWidth="lg">
          {/* Header Text */}
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: 500,
              color: '#000000',
              marginBottom: theme.spacing(4),
              fontFamily: 'Poppins, sans-serif',
              fontSize: '2.019rem', // Reduced by 15% from 2.375rem
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.615rem', // Reduced by 15% from 1.9rem
              },
            }}
          >
            See how FareFood saves you time and money on food orders.
          </Typography>

          {/* Video Embed */}
          <VideoContainer>
            <VideoIframe
              src="https://www.loom.com/embed/24fd1b8c6ce84bde803c73f72cfeef98?sid=f353af89-96d2-4927-aaab-413e9e1762f2"
              frameBorder="0"
              webkitAllowFullScreen
              mozAllowFullScreen
              allowFullScreen
              title="FareFood Demo Video"
            ></VideoIframe>
          </VideoContainer>

          {/* Buttons */}
          <ButtonContainer>
            <StyledButton
              variant="contained"
              color="primary"
              href="/signup"
              sx={{
                backgroundColor: '#027F7C',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#006D6D',
                },
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              Try for free
            </StyledButton>

            <Button
              variant="outlined"
              href="/contactus"
              sx={{
                paddingX: 3,
                paddingY: 1.5,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '1rem',
                letterSpacing: '0.5px',
                color: '#027F7C',
                borderColor: '#027F7C',
                '&:hover': {
                  backgroundColor: '#E0F2F1',
                  borderColor: '#027F7C',
                },
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              Contact us
            </Button>
          </ButtonContainer>
        </Container>
      </MainContent>

      {/* Footer */}
      <Footer />
    </Root>
  );
};

export default Demo;
