import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import axios from 'axios';

import fallbackLogo from './assets/small-logo.avif';
import AddToCartButton from './AddToCartButton';

// Helper: auth
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }
  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

function ProductCard({
  product,
  multiplier = 1,
  restaurantId,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  onCompareClick,
  compareOpen = false, // indicates if compare is expanded
}) {
  const [imageError, setImageError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    main_product_id,
    short_description,
    ff_catalog_number,
    brand,
    purchase_price,      // <= 0 => MARKET
    case_priced_by,      
    each_price,          // > 0 => numeric row, otherwise skip
    each_priced_by,
    price_per_unit,      
    price_per_unit_priced_by,
    vendor_name,
    vendor_logo,
    image_url,
    lead_time,
    special_notes,
    hasalternates,  // for compare
    BestDeal,       // if false => "Better Deals Available"
  } = product;

  // Compare styling
  const compareBorderStyle = compareOpen
    ? `2px solid ${theme.palette.grey[400]}`
    : 'none';
  const compareArrow = compareOpen ? '▲' : '▼';

  // If BestDeal === false => orange background + white text
  const compareButtonColor = compareOpen ? '#006D6D' : '#949E98';
  const compareButtonStyles =
    BestDeal === false
      ? { color: 'white', backgroundColor: '#F87906' }
      : { color: compareButtonColor };
  const compareButtonText =
    BestDeal === false ? 'Better Deals Available' : 'Compare';

  // Nav
  const handleCardClick = () => {
    navigate(`/product/${main_product_id}`);
  };

  // Favorites
  const isFavorited = !!favoritesMap[main_product_id];
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (!restaurantId) return;
    if (isFavorited) {
      onRemoveFavorite(main_product_id);
    } else {
      onAddFavorite(main_product_id);
    }
  };

  // Add to List
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // New: We'll also show a Snackbar after the user adds an item to a list
  const [listAddToast, setListAddToast] = useState('');

  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };
  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id: main_product_id,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      // Also set our toast message (Snackbar)
      setListAddToast('Item successfully added to the list!');
      // Wait a bit, then close the dialog
      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // Add to Inventory
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryInProgress, setAddInventoryInProgress] = useState(false);

  const handleAddToInventoryClick = async (e) => {
    e.stopPropagation();
    try {
      setAddInventoryInProgress(true);
      setAddInventorySuccess('');
      setAddInventoryError('');
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }
      const data = {
        restaurant_id: user.restaurant_id,
        item_description: short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id: main_product_id,
      };
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );
      setAddInventorySuccess('Item successfully added to inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    } finally {
      setAddInventoryInProgress(false);
    }
  };

  // Image
  const productImage = imageError || !image_url ? fallbackLogo : image_url;
  const handleImageError = () => setImageError(true);

  // Price logic:
  function formatCaseRow() {
    if (purchase_price <= 0) {
      return {
        mainText: 'MARKET',
        subText: null,
      };
    }
    // Otherwise numeric
    const numeric = (purchase_price * multiplier).toFixed(2);
    const suffix = case_priced_by || 'CS';
    const mainText = `$${numeric} ${suffix}`;

    let subText = null;
    if (price_per_unit && price_per_unit > 0) {
      const ppu = (price_per_unit * multiplier).toFixed(2);
      const perUnitSuffix = price_per_unit_priced_by || '';
      subText = `$${ppu}/${perUnitSuffix}`;
    }

    return { mainText, subText };
  }

  function formatEachRow() {
    if (each_price > 0) {
      const numeric = (each_price * multiplier).toFixed(2);
      const suffix = each_priced_by || 'EA';
      return `${numeric} ${suffix}`;
    }
    return null; // skip if <= 0
  }

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'box-shadow 0.2s',
          backgroundColor: 'transparent',
          border: compareBorderStyle,
          '&:hover': {
            boxShadow: theme.shadows[4],
            backgroundColor: alpha(theme.palette.background.paper, 0.1),
          },
        }}
        onClick={handleCardClick}
      >
        {/* pinned vendor logo */}
        {vendor_logo && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              width: 40,
              height: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: '#fff',
              overflow: 'hidden',
              boxShadow: theme.shadows[2],
              zIndex: 2,
            }}
          >
            <img
              src={vendor_logo}
              alt={vendor_name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackLogo;
              }}
            />
          </Box>
        )}

        {/* pinned Fav / List / Inventory icons on top-left */}
        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(1),
            zIndex: 2,
            color: '#E92409',
            p: 0.5,
            '&:hover': {
              color: alpha('#E92409', 0.7),
            },
          }}
          onClick={handleFavoriteClick}
        >
          {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(4),
            zIndex: 2,
            color: '#006D6D',
            p: 0.5,
            '&:hover': { color: alpha('#006D6D', 0.7) },
          }}
          onClick={handleOpenListDialog}
        >
          <ListAltIcon />
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(7),
            zIndex: 2,
            color: '#006D6D',
            p: 0.5,
            '&:hover': { color: alpha(theme.palette.primary.main, 0.7) },
          }}
          onClick={handleAddToInventoryClick}
        >
          <InventoryIcon />
        </IconButton>

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ p: theme.spacing(0.25) }}>
            {/* Image container */}
            <Box sx={{ position: 'relative', mt: 4 }}>
              <CardMedia
                component="img"
                height="80"
                image={productImage}
                alt={short_description || 'Product image'}
                loading="lazy"
                onLoad={() => setImageLoaded(true)}
                onError={handleImageError}
                sx={{
                  objectFit: 'contain',
                  backgroundColor:
                    imageError || !image_url ? 'white' : 'transparent',
                  borderRadius: theme.shape.borderRadius,
                }}
              />

              {/* If ff_catalog_number => pinned top-left corner */}
              {ff_catalog_number && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: theme.spacing(0.5),
                    left: theme.spacing(0.5),
                    backgroundColor: 'white',
                    padding: theme.spacing(0.125, 0.25),
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#949E98',
                      fontFamily: 'Poppins',
                      fontSize: '7pt',
                    }}
                  >
                    {ff_catalog_number}
                  </Typography>
                </Box>
              )}

              {/* lead_time / special_notes pinned bottom-right */}
              {(lead_time > 0 ||
                (special_notes && !/no/i.test(special_notes))) && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    right: theme.spacing(1),
                    display: 'flex',
                    gap: 0.5,
                  }}
                >
                  {lead_time > 0 && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: theme.shadows[1],
                      }}
                    >
                      <AvTimerIcon sx={{ fontSize: '1rem', color: '#F87906' }} />
                    </Box>
                  )}
                  {special_notes && !/no/i.test(special_notes) && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: theme.shadows[1],
                      }}
                    >
                      <NoteAltIcon sx={{ fontSize: '1rem', color: '#F87906' }} />
                    </Box>
                  )}
                </Box>
              )}

              {/* If BestDeal => pinned bottom-left corner "Best Price" */}
              {BestDeal && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    left: theme.spacing(1),
                    backgroundColor: '#00BC48',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    zIndex: 3,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ffffff',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '0.75rem',
                    }}
                  >
                    Best Price
                  </Typography>
                </Box>
              )}
            </Box>

            {/* brand line + short desc */}
            <Box sx={{ mt: 1, px: 1 }}>
              {brand && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#3A3F3F',
                    fontWeight: theme.typography.subtitle1.fontWeight,
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    mb: 0.1,
                  }}
                >
                  {brand}
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: '#131616',
                  fontSize: '9pt',
                  lineHeight: '1.2em',
                  height: '2.4em',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  mb: 0,
                }}
              >
                {short_description || ''}
              </Typography>
            </Box>

            {/* Price & AddToCart */}
            <Box sx={{ mt: 1, px: 1 }} onClick={(e) => e.stopPropagation()}>
              {/* CASE row: always displayed */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 0.5,
                }}
              >
                {/* If purchase_price <= 0 => MARKET, else numeric */}
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {purchase_price <= 0 ? (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        fontSize: '9pt',
                        color: '#006D6D',
                      }}
                    >
                      MARKET
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: 'bold',
                          fontSize: '9pt',
                          color: '#006D6D',
                        }}
                      >
                        {`$${(purchase_price * multiplier).toFixed(2)} ${
                          case_priced_by || 'CS'
                        }`}
                      </Typography>
                      {price_per_unit > 0 && (
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '7pt',
                            color: '#949E98',
                          }}
                        >
                          {`($${(price_per_unit * multiplier).toFixed(
                            2
                          )}/${price_per_unit_priced_by || ''})`}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
                <AddToCartButton
                  productId={main_product_id}
                  isSplit={false}
                  buttonLabel="Case"
                />
              </Box>

              {/* Each row if each_price > 0 */}
              {each_price > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        fontSize: '9pt',
                        color: '#006D6D',
                      }}
                    >
                      {`$${(each_price * multiplier).toFixed(2)} ${
                        each_priced_by || 'EA'
                      }`}
                    </Typography>
                  </Box>
                  <AddToCartButton
                    productId={main_product_id}
                    isSplit
                    buttonLabel="Each"
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        </Box>

        {/* Compare button at bottom */}
        <Box
          sx={{
            py: 0.5,
            textAlign: 'center',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {hasalternates ? (
            <Button
              variant="text"
              sx={{
                ...compareButtonStyles,
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '0.6rem',
                textTransform: 'none',
                paddingX: 1,
                paddingY: 0.25,
                minHeight: 'auto',
                borderRadius: 2,
              }}
              onClick={() => onCompareClick?.(main_product_id)}
            >
              {compareButtonText} {compareArrow}
            </Button>
          ) : (
            <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
              Compare ▼
            </Box>
          )}
        </Box>
      </Card>

      {/* Add to List Dialog */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Select a List
        </DialogTitle>
        <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText
                      primary={list.list_name}
                      primaryTypographyProps={{
                        fontFamily: theme.typography.fontFamily,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {/* Inline success Alert inside the dialog */}
          {addListSuccess && (
            <Alert
              severity="success"
              sx={{
                mt: theme.spacing(2),
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseListDialog}
            disabled={addingToList}
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Inventory success/error */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{
              width: '100%',
              fontFamily: theme.typography.fontFamily,
            }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{
              width: '100%',
              fontFamily: theme.typography.fontFamily,
            }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Snackbar for "Add to List" success (outside the Dialog) */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{
              width: '100%',
              fontFamily: theme.typography.fontFamily,
            }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ProductCard;
