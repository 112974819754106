// src/components/Settings.js
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  Card,
  Divider,
  Alert,
  Button,
  TextField,
  CircularProgress,
  Grid,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import TopNavBar from './TopNavBar';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import ConnectSuppliers from './ConnectSuppliers';

function Settings() {
  const theme = useTheme();
  const navigate = useNavigate();

  // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem('user')) || {};
  const { email, restaurant_id: restaurantId } = userData;

  // State for restaurant data
  const [restaurantData, setRestaurantData] = useState(null);
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Resale Tax Certificate
  const [resaleTaxCertNumber, setResaleTaxCertNumber] = useState('');
  const [isSubmittingTaxCert, setIsSubmittingTaxCert] = useState(false);
  const [showResaleTaxCertForm, setShowResaleTaxCertForm] = useState(false);
  const [taxCertError, setTaxCertError] = useState(null);

  // Snackbar (if you want to show success messages, etc.)
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Logout dialog
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  // Fetch restaurant data
  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        if (!restaurantId) throw new Error('Restaurant ID not found');

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant/${restaurantId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch restaurant data.');
        }

        const data = await response.json();
        setRestaurantData(data);
        setRestaurantName(data.name || 'Unknown Restaurant');
        setResaleTaxCertNumber(data.resale_tax_certificate || '');
      } catch (err) {
        console.error('Error fetching restaurant data:', err);
        setError('Failed to load restaurant data.');
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurantData();
  }, [restaurantId]);

  // Handle Resale Tax Cert
  const handleAddResaleTaxCert = () => {
    setShowResaleTaxCertForm(true);
  };

  const handleSubmitResaleTaxCert = async () => {
    if (!resaleTaxCertNumber.trim()) {
      setTaxCertError('Resale Tax Certificate Number cannot be empty.');
      return;
    }

    setIsSubmittingTaxCert(true);
    setTaxCertError(null);

    try {
      if (!restaurantId) throw new Error('Restaurant ID not found');

      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_resale_tax_cert',
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            restaurant_id: restaurantId,
            resale_tax_certificate: resaleTaxCertNumber.trim(),
          }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(
          data.message || 'Failed to update resale tax certificate number.'
        );
      }

      // Success
      setShowResaleTaxCertForm(false);
      setRestaurantData({
        ...restaurantData,
        resale_tax_certificate: resaleTaxCertNumber.trim(),
        tax_cert_on_file: false,
      });

      // Optionally send Slack notification:
      await sendSlackNotificationForTaxCert(restaurantName, resaleTaxCertNumber.trim());
    } catch (err) {
      console.error('Error updating resale tax certificate:', err);
      setTaxCertError(err.message || 'Failed to update resale tax certificate.');
    } finally {
      setIsSubmittingTaxCert(false);
    }
  };

  const sendSlackNotificationForTaxCert = async (
    restaurantName,
    resaleTaxCertNumber
  ) => {
    try {
      const message = `${restaurantName} updated their resale tax certificate to ${resaleTaxCertNumber}.`;
      const slackPayload = { text: message };

      await fetch('https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(slackPayload),
      });
    } catch (slackError) {
      console.error('Slack notification failed:', slackError);
    }
  };

  // Logout
  const handleLogout = () => {
    localStorage.removeItem('user');
    // Clear any other localStorage keys as needed
    navigate('/login');
  };

  const openLogoutDialog = () => {
    setLogoutDialogOpen(true);
  };

  const closeLogoutDialog = () => {
    setLogoutDialogOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return (
      <>
        <TopNavBar sticky />
        <Container sx={{ textAlign: 'center', mt: 4, pb: 10 }}>
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <TopNavBar sticky />
        <Container sx={{ textAlign: 'center', mt: 4, pb: 10 }}>
          <Typography color="error">Error: {error}</Typography>
        </Container>
      </>
    );
  }

  const { tax_cert_on_file, location } = restaurantData;

  return (
    <>
      <Helmet>
        <title>FareFood | Settings</title>
      </Helmet>
      <TopNavBar sticky />

      <Container
        maxWidth="lg"
        sx={{
          pt: 8,
          backgroundColor: theme.palette.common.white,
          minHeight: '100vh',
          pb: 4,
        }}
      >
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
          Settings
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            {/* User Information */}
            <Card sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                User Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ mb: 3 }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Email:</strong> {email || 'Not Provided'}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Restaurant Name:</strong> {restaurantName}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Location:</strong> {location || 'Not Provided'}
                </Typography>
              </Box>
            </Card>

            {/* Resale Tax Certificate */}
            <Card sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Resale Tax Certificate
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ mb: 2 }}>
                {!resaleTaxCertNumber ? (
                  <>
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      <strong>You're Almost There!</strong> To unlock exclusive
                      benefits, please add your restaurant's resale tax
                      certificate number.
                    </Alert>
                    <Button variant="contained" onClick={handleAddResaleTaxCert}>
                      Add Resale Tax Certificate Number
                    </Button>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 2,
                      }}
                    >
                      <Typography variant="body1">
                        <strong>Resale Tax Certificate Number:</strong>{' '}
                        {resaleTaxCertNumber}
                      </Typography>
                      <Button variant="outlined" onClick={handleAddResaleTaxCert}>
                        Update
                      </Button>
                    </Box>

                    {!tax_cert_on_file ? (
                      <Alert severity="info">
                        We have received your certificate number and are
                        verifying it.
                      </Alert>
                    ) : (
                      <Alert
                        severity="success"
                        icon={<CheckCircleIcon fontSize="inherit" />}
                      >
                        <strong>Resale tax certificate verified!</strong>
                      </Alert>
                    )}
                  </>
                )}
              </Box>

              {showResaleTaxCertForm && (
                <>
                  <Divider sx={{ mb: 3 }} />
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Resale Tax Certificate Number"
                      variant="outlined"
                      fullWidth
                      value={resaleTaxCertNumber}
                      onChange={(e) => setResaleTaxCertNumber(e.target.value)}
                      sx={{ mb: 2 }}
                      error={Boolean(taxCertError)}
                      helperText={taxCertError}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitResaleTaxCert}
                        disabled={isSubmittingTaxCert}
                      >
                        {isSubmittingTaxCert ? 'Submitting...' : 'Submit'}
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
            </Card>

            {/* Add or Connect Suppliers (New Wizard design) */}
            <ConnectSuppliers />

            {/* Logout */}
            <Card sx={{ p: 3, mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Logout
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" sx={{ mb: 2 }}>
                Click the button below to logout of your account.
              </Typography>
              <Button variant="contained" onClick={openLogoutDialog}>
                Logout
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your request has been submitted successfully."
      />

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={closeLogoutDialog}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to log out?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeLogoutDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Settings;
