import React, { useState, useEffect, useContext } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Typography,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

// Import CartContext
import { CartContext } from './CartContext';

// Import your components
import LowStockComponent from './LowStockComponent';
import MOQComponent from './MOQComponent';
import SpendSummary from './SpendSummary';
import OrderSummary from './OrderSummary';

function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Access CartContext for subtotal
  const { subtotal } = useContext(CartContext);

  // Onboarding banner logic
  const [showOnboardBanner, setShowOnboardBanner] = useState(false);
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      setShowOnboardBanner(user.showonboardbanner || false);
    }
  }, []);
  const handleBannerDismiss = () => setShowOnboardBanner(false);

  // Low stock state
  const [lowStockItems, setLowStockItems] = useState([]);
  const [lowStockLoading, setLowStockLoading] = useState(true);
  const [lowStockError, setLowStockError] = useState(null);

  // MOQ state
  const [restaurantData, setRestaurantData] = useState(null);
  const [moqLoading, setMoqLoading] = useState(false);
  const [moqError, setMoqError] = useState(null);

  // Product modal references (if needed)
  const [selectedItem, setSelectedItem] = useState(null);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [multiplier, setMultiplier] = useState(1);

  // Helper to get restaurant ID
  const getRestaurantId = () => {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      throw new Error('User data not found in local storage.');
    }
    let user;
    try {
      user = JSON.parse(userJson);
    } catch (parseError) {
      throw new Error('Failed to parse user data from local storage.');
    }
    if (!user.restaurant_id) {
      throw new Error('Restaurant ID not found in user data.');
    }
    return user.restaurant_id;
  };

  // Fetch multiplier from localStorage
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
        } catch (e) {
          setMultiplier(1);
        }
      } else {
        setMultiplier(1);
      }
    };
    fetchUserData();
  }, []);

  // Fetch Low Stock Items
  const fetchInventory = async () => {
    setLowStockLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        navigate('/');
        return;
      }

      const params = {
        restaurantidfilter: user.restaurant_id,
        limit: 100,
        offset: 0,
      };
      const headers = { Authorization: `Bearer ${authToken}` };
      const apiEndpoint =
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventoryreact';

      const response = await axios.get(apiEndpoint, { params, headers });
      const fetchedInventory = response.data.items || [];

      // Filter to low stock
      const lowStockData = fetchedInventory.filter(
        (item) => item.On_Hand <= item.PAR
      );
      // Sort ascending by On_Hand
      lowStockData.sort((a, b) => a.On_Hand - b.On_Hand);

      setLowStockItems(lowStockData);
    } catch (error) {
      setLowStockError(error.message || 'Error fetching low stock inventory.');
    } finally {
      setLowStockLoading(false);
    }
  };

  useEffect(() => {
    fetchInventory();
  }, [navigate]);

  // Fetch MOQ Data
  useEffect(() => {
    const fetchMOQData = async () => {
      setMoqLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const restaurantId = getRestaurantId();

        if (!authToken || !restaurantId) {
          navigate('/');
          return;
        }

        const headers = { Authorization: `Bearer ${authToken}` };
        const params = { restaurant_id: restaurantId };

        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/moqtracker',
          { headers, params }
        );
        if (response.data && response.data.length > 0) {
          setRestaurantData(response.data[0]);
        } else {
          setRestaurantData(null);
        }
      } catch (error) {
        setMoqError(error.message || 'Error fetching MOQ data.');
      } finally {
        setMoqLoading(false);
      }
    };
    fetchMOQData();
  }, [navigate]);

  // Go to inventory page
  const handleGoToInventory = () => {
    navigate('/inventory');
  };

  // Click on an inventory item
  const handleInventoryItemClick = (item) => {
    setSelectedItem(item);
    setOpenProductModal(true);
  };

  return (
    <div>
      <Helmet>
        <title>FareFood | Home</title>
      </Helmet>

      {/* Sticky TopNavBar */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[4],
        }}
      >
        <TopNavBar onSearch={null} title="Home" />
      </Box>

      <Box
        sx={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* You can add a page title or remove this Box entirely */}
          <Box sx={{ marginBottom: theme.spacing(6), textAlign: 'left' }}>
            {/* Optional Page Title */}
          </Box>

          {/* Top row: SpendSummary -> OrderSummary -> MOQ */}
          <Grid container spacing={2} sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12} md={4}>
              <SpendSummary />
            </Grid>

            <Grid item xs={12} md={4}>
              {/* This now fetches orders correctly based on the user's ID */}
              <OrderSummary />
            </Grid>

            <Grid item xs={12} md={4}>
              <MOQComponent
                // If needed, pass props like:
                // restaurantData={restaurantData}
                // moqLoading={moqLoading}
                // moqError={moqError}
                // subtotal={subtotal}
              />
            </Grid>
          </Grid>

          {/* Bottom row: LowStockComponent */}
          <Grid container>
            <Grid item xs={12}>
              <LowStockComponent
                lowStockItems={lowStockItems}
                lowStockLoading={lowStockLoading}
                lowStockError={lowStockError}
                handleGoToInventory={handleGoToInventory}
                onItemClick={handleInventoryItemClick}
                multiplier={multiplier}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default Home;
