// src/components/Blog.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CircularProgress,
  Pagination,
  PaginationItem,
  useMediaQuery,
  useTheme,
  Container,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import Footer from './Footer';
import SalesNavBar from './SalesNavBar';
import { Helmet } from 'react-helmet';

// Import the Poppins font family
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

// Import icons for pagination
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const limit = 10; // Number of items per page

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Mobile devices

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const offset = (page - 1) * limit;
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/blog',
          {
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              limit: limit,
              offset: offset,
            },
          }
        );

        setBlogs(response.data.items);

        // Calculate totalPages based on total count from API response
        const totalCount =
          response.data.total_count ||
          response.data.totalCount ||
          response.data.total_items ||
          response.data.count;

        if (totalCount) {
          const calculatedTotalPages = Math.ceil(totalCount / limit);
          setTotalPages(calculatedTotalPages);
        } else {
          // If total_count is not available, set totalPages to 7 for testing
          setTotalPages(7);
          console.warn('Total count not available in API response. Using totalPages=7 for testing.');
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError('Failed to load blogs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [page]);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Handle blog post click
  const handleBlogClick = (handle) => {
    navigate(`/blogs/food-for-thought/${handle}/`);
  };

  // Determine pagination settings based on screen size
  const paginationSiblingCount = isMobile ? 0 : 2;
  const paginationBoundaryCount = isMobile ? 1 : 0;

  return (
    <Box>
      {/* Helmet for SEO */}
      <Helmet>
        <title>FareFood | Food for Thought</title>
        <meta
          name="description"
          content="Explore our 'Food for Thought' blog by FareFood. Discover the latest trends, insights, and delicious recipes that inspire your culinary journey."
        />
        {/* ... other meta tags ... */}
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <Box sx={{ backgroundColor: '#FFFFFF' }}>
        <Container maxWidth="lg" sx={{ paddingTop: 12, paddingBottom: 8 }}>
          {/* Header */}
          <Box sx={{ marginTop: 8 }}>
            <Typography
              variant="h3"
              component="h1"
              align="center"
              gutterBottom
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '2.019rem',
                fontWeight: 500,
                color: '#131616',
                marginBottom: 2,
              }}
            >
              Food for Thought
            </Typography>
            <Typography
              align="center"
              gutterBottom
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '1.2rem',
                color: '#555555',
                marginBottom: 4,
                fontWeight: 400,
              }}
            >
              Discover the latest tips and trends in the restaurant industry.
            </Typography>
          </Box>

          {/* Loading Indicator */}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {/* Error Message */}
          {error && (
            <Typography
              variant="body1"
              color="error"
              align="center"
              sx={{ marginTop: 4 }}
            >
              {error}
            </Typography>
          )}

          {/* Blog Grid */}
          {!loading && !error && (
            <>
              <Grid
                container
                spacing={4}
                sx={{
                  marginTop: 4,
                }}
              >
                {blogs.map((blog) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    key={blog.id}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Card sx={{ width: '100%', maxWidth: 600 }}>
                      <CardActionArea onClick={() => handleBlogClick(blog.Handle)}>
                        {/* Blog Image */}
                        {blog.Image_Src && (
                          <CardMedia
                            component="img"
                            sx={{
                              width: '100%',
                              height: { xs: 225, sm: 225 },
                              objectFit: 'cover',
                              borderTopLeftRadius: '4px',
                              borderTopRightRadius: '4px',
                            }}
                            image={blog.Image_Src}
                            alt={blog.Image_Alt_Text || blog.Title}
                            loading="lazy"
                          />
                        )}
                        <CardContent>
                          {/* Blog Title */}
                          <Typography
                            variant="h5"
                            component="div"
                            gutterBottom
                            sx={{
                              color: '#0E9C9B',
                              fontFamily: 'Poppins, sans-serif',
                              fontWeight: 500,
                            }}
                          >
                            {blog.Title}
                          </Typography>

                          {/* Blog Summary */}
                          {blog.Summary_HTML && (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontFamily: 'Poppins, sans-serif' }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(blog.Summary_HTML),
                                }}
                              />
                            </Typography>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>

              {/* Pagination Controls */}
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                  shape="rounded"
                  siblingCount={paginationSiblingCount}
                  boundaryCount={paginationBoundaryCount}
                  showFirstButton
                  showLastButton
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      slots={{
                        first: FirstPageIcon,
                        last: LastPageIcon,
                        previous: NavigateBeforeIcon,
                        next: NavigateNextIcon,
                      }}
                    />
                  )}
                  sx={{
                    '& .MuiPagination-ul': {
                      flexWrap: 'nowrap',
                    },
                  }}
                />
              </Box>
            </>
          )}
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Blog;
