// InventoryFilterPanel.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function InventoryFilterPanel({
  categories,
  selectedCategory,
  setSelectedCategory,
  subcategories,
  selectedSubcategory,
  setSelectedSubcategory,
  selectedVendor = [],
  setSelectedVendor,
  clearAllFilters,
  sortOption,
  setSortOption,
  viewMode,
  setViewMode,
  itemsPerPage,
  setItemsPerPage,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Vendors
  const [vendors, setVendors] = useState([]);
  const [vendorSearch, setVendorSearch] = useState('');

  // Which accordion is expanded?
  const [expandedPanel, setExpandedPanel] = useState(null);

  // Fetch vendors from your restaurant vendors API (with auth).
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const userJson = localStorage.getItem('user');
        if (!userJson) {
          throw new Error('User data not found in local storage.');
        }
        const user = JSON.parse(userJson);
        const restaurantId = user.restaurant_id;
        if (!restaurantId) {
          throw new Error('Restaurant ID not found in user data.');
        }

        const authToken = localStorage.getItem('authToken');
        const headers = { Authorization: `Bearer ${authToken}` };

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}`,
          { headers }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch vendors');
        }
        const data = await response.json();
        // data[i] => { _vendor: { id, Short_Name, ... } }
        const fetchedVendors = data.map((item) => item._vendor);
        setVendors(fetchedVendors);
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setVendors([]);
      }
    };
    fetchVendors();
  }, []);

  // If category is 0 => ensure subcategory is reset
  useEffect(() => {
    if (selectedCategory === 0) {
      setSelectedSubcategory(0);
    }
  }, [selectedCategory, setSelectedSubcategory]);

  // If a category is selected and subcategories exist => auto expand subcategory
  useEffect(() => {
    if (selectedCategory !== 0 && subcategories.length > 0) {
      setExpandedPanel('subcategory');
    }
  }, [selectedCategory, subcategories]);

  // Filter vendors based on user input
  const filteredVendors = vendors.filter((vendor) =>
    vendor.Short_Name?.toLowerCase().includes(vendorSearch.toLowerCase())
  );

  // Utility to truncate label text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
  };

  // Labels
  const categoryLabel = selectedCategory ? `Category (1)` : 'Category';
  const subcategoryLabel = selectedSubcategory ? `Subcategory (1)` : 'Subcategory';
  const vendorCount = selectedVendor.length;
  const vendorLabel = vendorCount > 0 ? `Vendor (${vendorCount})` : 'Vendor';

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  // Category check => uncheck or select
  const handleCategoryCheck = (catId) => {
    if (selectedCategory === catId) {
      setSelectedCategory(0);
    } else {
      setSelectedCategory(catId);
    }
    // After selecting/unselecting, close the accordion
    setExpandedPanel(null);
  };

  // Subcategory check => uncheck or select
  // If user selects a subcategory, auto close "subcategory" & open "vendor"
  const handleSubcategoryCheck = (subcatId) => {
    if (selectedSubcategory === subcatId) {
      setSelectedSubcategory(0);
    } else {
      setSelectedSubcategory(subcatId);
      setExpandedPanel('vendor');
    }
  };

  return (
    <Box>
      {/* Header row: Filter By + Clear All */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'light',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
          }}
        >
          Filter By
        </Typography>
        <Button
          variant="text"
          onClick={() => {
            clearAllFilters();
            setExpandedPanel(null);
          }}
          sx={{
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '0.875rem',
          }}
        >
          Clear All
        </Button>
      </Box>

      {/** 
       * Only show Sort/ItemsPerPage/ViewMode inline on mobile
       * (matching how the shops filter panel handles it).
       */}
      {isMobile && (
        <Box sx={{ mb: 2 }}>
          {/* Sort By */}
          <FormControl fullWidth sx={{ mb: 1 }} size="small">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOption}
              label="Sort By"
              onChange={(e) => setSortOption(e.target.value)}
              sx={{ fontFamily: theme.typography.fontFamily }}
            >
              <MenuItem value="default">Default</MenuItem>
              <MenuItem value="stock_low_high">Stock: Low to High</MenuItem>
              <MenuItem value="stock_high_low">Stock: High to Low</MenuItem>
            </Select>
          </FormControl>

          {/* Items Per Page */}
          <FormControl fullWidth sx={{ mb: 1 }} size="small">
            <InputLabel>Items Per Page</InputLabel>
            <Select
              value={itemsPerPage}
              label="Items Per Page"
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
              sx={{ fontFamily: theme.typography.fontFamily }}
            >
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={48}>48</MenuItem>
              <MenuItem value={96}>96</MenuItem>
            </Select>
          </FormControl>

          {/* View Mode */}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.875rem',
                mr: 1,
              }}
            >
              View:
            </Typography>
            <IconButton
              onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
              sx={{ p: 0.5 }}
            >
              {viewMode === 'grid' ? (
                <ViewListIcon fontSize="small" />
              ) : (
                <ViewModuleIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Box>
      )}

      {/* CATEGORY */}
      <Accordion
        expanded={expandedPanel === 'category'}
        onChange={handleAccordionChange('category')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="category-content"
          id="category-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            {categoryLabel}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {categories.map((cat) => (
              <FormControlLabel
                key={cat.id}
                control={
                  <Checkbox
                    checked={selectedCategory === cat.id}
                    onChange={() => handleCategoryCheck(cat.id)}
                  />
                }
                label={truncateText(cat.name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* SUBCATEGORY (only if selectedCategory != 0) */}
      {selectedCategory !== 0 && (
        <Accordion
          expanded={expandedPanel === 'subcategory'}
          onChange={handleAccordionChange('subcategory')}
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls="subcategory-content"
            id="subcategory-header"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              minHeight: '48px',
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'regular',
                color: 'black',
                fontSize: '1rem',
              }}
            >
              {subcategoryLabel}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {subcategories.map((subcat) => (
                <FormControlLabel
                  key={subcat.id}
                  control={
                    <Checkbox
                      checked={selectedSubcategory === subcat.id}
                      onChange={() => handleSubcategoryCheck(subcat.id)}
                    />
                  }
                  label={truncateText(subcat.name, 20)}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* VENDOR */}
      <Accordion
        expanded={expandedPanel === 'vendor'}
        onChange={handleAccordionChange('vendor')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="vendor-content"
          id="vendor-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            {vendorLabel}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Vendor"
              inputProps={{ 'aria-label': 'search vendors' }}
              value={vendorSearch}
              onChange={(e) => setVendorSearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredVendors.map((ven) => (
              <FormControlLabel
                key={ven.id}
                control={
                  <Checkbox
                    checked={selectedVendor.includes(ven.id)}
                    onChange={() => {
                      if (selectedVendor.includes(ven.id)) {
                        setSelectedVendor(
                          selectedVendor.filter((x) => x !== ven.id)
                        );
                      } else {
                        setSelectedVendor([...selectedVendor, ven.id]);
                      }
                    }}
                  />
                }
                label={truncateText(ven.Short_Name, 20)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default InventoryFilterPanel;
