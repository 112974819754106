// Cart.js
import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  useMediaQuery,
  useTheme,
  styled,
  alpha,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { CartContext } from './CartContext';

import TopNavBar from './TopNavBar';
import MOQTracker from './MOQTracker';
import CartItem from './CartItem';
import CompareListItem from './CompareListItem';

// --- NEW IMPORT for email/Slack notifications ---
import { sendOrderNotifications } from './services/orderNotificationService'; 

// -------------- Styled Search Input --------------
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 400,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Cart() {
  const { clearCart } = useContext(CartContext);

  // ---------------- Local State ----------------
  const [cartItems, setCartItems] = useState([]);
  
  // For search + filtering
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  // Group expansions for each category
  const [expandedCategories, setExpandedCategories] = useState({});

  // Basic user data
  const [restaurantId, setRestaurantId] = useState(null);
  const [multiplier, setMultiplier] = useState(1);

  // Tier from the restaurant record (Tier 1 => can't order, Tier 2 => can order, etc.)
  const [tier, setTier] = useState(1);

  // Additional restaurant info (for emailing)
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');
  const [restaurantLocation, setRestaurantLocation] = useState('Unknown Location');

  // Vendors for emailing logic
  const [vendors, setVendors] = useState([]);

  // Purchase flow
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Loading overlay for "Processing order..."
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  // UI notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Compare logic
  const [compareMap, setCompareMap] = useState({});
  const [compareExpanded, setCompareExpanded] = useState({});

  // Favorites
  const [favoritesMap, setFavoritesMap] = useState({});

  // Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // --------------------------------------------------
  // 1) On mount, read user from localStorage
  // --------------------------------------------------
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      const parsedUser = JSON.parse(userData);
      if (parsedUser.restaurant_id) {
        setRestaurantId(parsedUser.restaurant_id);
      }
      if (parsedUser.multiplier) {
        setMultiplier(parseFloat(parsedUser.multiplier));
      }
    }
  }, []);

  // --------------------------------------------------
  // 2) Fields used in the summary
  // --------------------------------------------------
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderSalesTax, setOrderSalesTax] = useState(0);
  const [orderFinalTotal, setOrderFinalTotal] = useState(0);
  const [totalCases, setTotalCases] = useState(0);
  const [totalEaches, setTotalEaches] = useState(0);

  // --------------------------------------------------
  // 3) Fetch cart items from Xano
  // --------------------------------------------------
  const fetchCartItems = async () => {
    try {
      const userData = localStorage.getItem('user');
      let userId = 1;
      if (userData) {
        const parsedUser = JSON.parse(userData);
        userId = parsedUser.id || 1;
      }

      const body = { user_id: userId, limit: 1000, offset: 0 };
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurantcartitems_sql',
        body,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (Array.isArray(response.data) && response.data.length > 0) {
        setCartItems(response.data);

        // We'll still read order_subtotal, etc. from the first item if available:
        setOrderSubtotal(parseFloat(response.data[0].order_subtotal || '0'));
        setOrderSalesTax(parseFloat(response.data[0].order_salestax || '0'));
        setOrderFinalTotal(parseFloat(response.data[0].order_finaltotal || '0'));

        // Instead of relying on response.data[0].total_cases, let's sum them:
        const sumCases = response.data.reduce((acc, item) => {
          return acc + (Number(item.quantity) || 0);
        }, 0);
        setTotalCases(sumCases);

        // If your API or items have a notion of total eaches, 
        // you can sum them similarly (or rely on response.data[0].total_eaches):
        setTotalEaches(parseFloat(response.data[0].total_eaches || '0'));
      } else {
        setCartItems([]);
        setOrderSubtotal(0);
        setOrderSalesTax(0);
        setOrderFinalTotal(0);
        setTotalCases(0);
        setTotalEaches(0);
      }
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setCartItems([]);
    }
  };

  // --------------------------------------------------
  // 4) Fetch the Restaurant Data (including Tier)
  // --------------------------------------------------
  const fetchRestaurantData = async (restId) => {
    try {
      const response = await axios.get(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant/${restId}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (response.data && response.data.id) {
        setRestaurantName(response.data.name || 'Unknown Restaurant');
        setRestaurantLocation(response.data.location || 'Unknown Location');

        if (typeof response.data.Tier !== 'undefined') {
          setTier(response.data.Tier);
        }
      }
    } catch (error) {
      console.error('Error fetching restaurant details:', error);
    }
  };

  // --------------------------------------------------
  // 5) Fetch Favorites
  // --------------------------------------------------
  const fetchFavorites = async (restId) => {
    if (!restId) return;
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: restId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        const map = {};
        response.data.forEach((fav) => {
          const { product_id, id } = fav;
          if (!map[product_id]) {
            map[product_id] = [];
          }
          map[product_id].push(id);
        });
        setFavoritesMap(map);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setFavoritesMap({});
    }
  };

  // --------------------------------------------------
  // 6) Fetch Vendors for emailing
  // --------------------------------------------------
  const fetchVendorEmails = async (restId) => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
        {
          params: { restaurant_id: restId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        setVendors(response.data);
      } else {
        console.error('Invalid data received for vendors.');
      }
    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  };

  // --------------------------------------------------
  // 7) Once we have a restaurantId, fetch data
  // --------------------------------------------------
  useEffect(() => {
    if (restaurantId) {
      fetchCartItems();
      fetchRestaurantData(restaurantId);
      fetchFavorites(restaurantId);
      fetchVendorEmails(restaurantId);
    }
  }, [restaurantId]);

  // --------------------------------------------------
  // 7b) If cartItems changes, re-filter
  // --------------------------------------------------
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredItems(cartItems);
    } else {
      const lower = searchTerm.toLowerCase();
      const filtered = cartItems.filter((item) =>
        item.short_description?.toLowerCase().includes(lower)
      );
      setFilteredItems(filtered);
    }
  }, [cartItems, searchTerm]);

  // --------------------------------------------------
  // 8) Group by category_name
  // --------------------------------------------------
  const groupedItems = useMemo(() => {
    return filteredItems.reduce((acc, item) => {
      const catName = item.category_name || 'Uncategorized';
      if (!acc[catName]) acc[catName] = [];
      acc[catName].push(item);
      return acc;
    }, {});
  }, [filteredItems]);

  const handleAccordionChange = (catName) => (event, isExpanded) => {
    setExpandedCategories((prev) => ({ ...prev, [catName]: isExpanded }));
  };

  // --------------------------------------------------
  // 9) Compare logic
  // --------------------------------------------------
  const handleCompareClick = async (productId) => {
    // If compare is currently expanded => collapse it
    if (compareExpanded[productId]) {
      setCompareExpanded((prev) => ({ ...prev, [productId]: false }));
      return;
    }

    // Otherwise, fetch compare data + expand
    try {
      let userId = 1;
      const userJson = localStorage.getItem('user');
      const user = userJson ? JSON.parse(userJson) : null;
      if (user?.id) {
        userId = user.id;
      }

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_2',
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            product_id: productId,
            user_id: userId,
          },
        }
      );

      if (Array.isArray(response.data)) {
        let mainItemPPU = 999999;
        if (response.data.length > 0) {
          // The main item is typically index 0
          mainItemPPU = parseFloat(response.data[0].price_per_unit || '999999');
        }

        const updated = response.data.map((compareItem) => {
          const comparePPU = parseFloat(compareItem.price_per_unit || '999999');
          compareItem.better_price = comparePPU > 0 && comparePPU < mainItemPPU;
          return compareItem;
        });
        setCompareMap((prev) => ({ ...prev, [productId]: updated }));
        setCompareExpanded((prev) => ({ ...prev, [productId]: true }));
      }
    } catch (error) {
      console.error('Error comparing products:', error);
    }
  };

  // --------------------------------------------------
  // 10) Favorites logic
  // --------------------------------------------------
  const handleAddFavorite = async (productId) => {
    if (!restaurantId) {
      console.warn('No restaurantId for adding to favorites.');
      return;
    }
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        {
          product_id: productId,
          restaurant_id: restaurantId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    if (!favoritesMap[productId]) return;
    try {
      const listItemIds = favoritesMap[productId];
      const deleteRequests = listItemIds.map((id) =>
        axios.delete('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item', {
          data: { list_item_id: id },
          headers: { 'Content-Type': 'application/json' },
        })
      );
      await Promise.all(deleteRequests);
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  // --------------------------------------------------
  // 11) Purchase Flow
  // --------------------------------------------------
  const handleCompletePurchase = () => {
    // If tier != 2 or there are no cases => button is disabled
    if (tier !== 2) {
      setSnackbar({
        open: true,
        message: 'Upgrade required to place orders.',
        severity: 'error',
      });
      return;
    }
    if (totalCases === 0) {
      setSnackbar({
        open: true,
        message: 'No items in your cart. Add items to place an order.',
        severity: 'error',
      });
      return;
    }
    setIsConfirmModalOpen(true);
  };

  const handleConfirmOrder = async () => {
    setIsConfirmModalOpen(false);
    setIsPlacingOrder(true);

    try {
      // Get user data
      const userData = localStorage.getItem('user');
      if (!userData) {
        throw new Error('User not found. Please log in.');
      }
      const parsedUser = JSON.parse(userData);

      // 1) Send all notifications (emails + Slack)
      await sendOrderNotifications({
        restaurantId,
        restaurantName,
        restaurantLocation,
        cartItems,
        totalCases,
        orderFinalTotal,
        userEmail: parsedUser.email,
        vendors,
      });

      // 2) Complete the order in Xano
      const payload = {
        restaurantidfilter: restaurantId,
        total_amount: orderFinalTotal,
        item_count: totalCases,
      };
      const completeResponse = await axios.put(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/Complete_Order',
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (!completeResponse.data || completeResponse.status !== 200) {
        throw new Error('Failed to complete the order. Please try again.');
      }

      // Success => order completed
      setSnackbar({
        open: true,
        message: 'Order completed successfully.',
        severity: 'success',
      });

      // Clear cart by re-fetching.
      await fetchCartItems();

      // Show the "Purchase Complete" dialog
      setPurchaseComplete(true);
    } catch (error) {
      console.error('Error completing order:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to complete order. Please try again later.',
        severity: 'error',
      });
    } finally {
      setIsPlacingOrder(false);
    }
  };

  // --------------------------------------------------
  // 12) Callback to re-fetch cart on quantity updates
  // --------------------------------------------------
  const handleCartQuantityChange = async () => {
    // Keep current data on screen, then refresh from Xano in the background
    await fetchCartItems();
  };

  // --------------------------------------------------
  // Render
  // --------------------------------------------------
  return (
    <div>
      <Helmet>
        <title>FareFood | Cart</title>
      </Helmet>

      <TopNavBar title="Cart" />

      <Box
        sx={{
          paddingTop: isMobile ? theme.spacing(7) : theme.spacing(8),
          paddingBottom: isMobile ? theme.spacing(8) : theme.spacing(2),
          px: isMobile ? theme.spacing(1) : theme.spacing(10),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          boxSizing: 'border-box',
          backgroundColor: 'white',
          overflowX: 'hidden',
        }}
      >
        <Grid
          container
          spacing={isMobile ? 0 : 4}
          sx={{
            maxWidth: '1400px',
            width: '100%',
            justifyContent: isMobile ? 'center' : 'flex-start',
            margin: 0,
          }}
        >
          {/* LEFT SIDE: Cart Items */}
          <Grid item xs={12} md={8}>
            {/* Title Row */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: theme.spacing(1),
                mt: theme.spacing(4),
              }}
            >
              <Typography variant="h5">Review Cart</Typography>
            </Box>

            {/* Search Input */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                pb: theme.spacing(1),
                gap: theme.spacing(1),
                flexWrap: 'wrap',
                mb: theme.spacing(2),
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search cart..."
                  inputProps={{ 'aria-label': 'search cart' }}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Search>
            </Box>

            {/* Cart Items or Empty */}
            {Object.keys(groupedItems).length === 0 ? (
              <Typography align="center" variant="body1">
                {searchTerm.trim()
                  ? 'No items match your search.'
                  : 'Your cart is empty.'}
              </Typography>
            ) : (
              Object.entries(groupedItems).map(([catName, items]) => (
                <Accordion
                  key={catName}
                  expanded={expandedCategories[catName] !== false}
                  onChange={handleAccordionChange(catName)}
                  sx={{ mb: theme.spacing(2), boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      borderBottom: '1px solid #e0e0e0',
                      '& .MuiAccordionSummary-content': { margin: 0 },
                    }}
                  >
                    <Typography variant="body1">{catName}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    {items.map((itm) => {
                      // Build product object for your CartItem
                      const productForListItem = {
                        main_product_id: itm.product_id,
                        short_description: itm.short_description,
                        ff_catalog_number: itm.ff_catalog_number,
                        brand: itm.brand,
                        // CASE-level price:
                        purchase_price: parseFloat(itm.rpp_purchase_price) || 0,
                        case_priced_by: itm.rpp_case_priced_by,
                        // EACH-level price:
                        each_price: parseFloat(itm.rpp_each_price) || 0,
                        each_priced_by: itm.rpp_each_priced_by,
                        product_pack_size: itm.pack_size,
                        price_per_unit: parseFloat(itm.price_per_unit || 0),
                        price_per_unit_priced_by: itm.priced_by || '',
                        hasalternates: itm.hasalternates,
                        lead_time: itm.lead_time,
                        special_notes: itm.special_notes,
                        BestDeal: itm.bestdeal,
                        image_url: itm.image_url,
                        vendor_logo: itm.vendor_logo_url,
                        vendor_name: itm.vendor_name || 'Vendor',
                      };

                      return (
                        <React.Fragment key={itm.order_item_id}>
                          <CartItem
                            product={productForListItem}
                            multiplier={multiplier}
                            restaurantId={restaurantId}
                            quantity={itm.quantity}
                            orderitem_id={itm.order_item_id}
                            duplicate_order_item_ids={itm.duplicate_order_item_ids}
                            // Favorites
                            favoritesMap={favoritesMap}
                            onAddFavorite={handleAddFavorite}
                            onRemoveFavorite={handleRemoveFavorite}
                            // Compare
                            onCompareClick={handleCompareClick}
                            compareOpen={!!compareExpanded[itm.product_id]}
                            // Refresh cart totals on quantity change
                            onQuantityChange={handleCartQuantityChange}
                          />

                          {/* Compare Row */}
                          {compareExpanded[itm.product_id] &&
                            productForListItem.hasalternates &&
                            compareMap[itm.product_id] && (
                              <Box sx={{ mb: 2, pl: 2, pr: 2 }}>
                                {compareMap[itm.product_id].map((compareItem) => (
                                  <CompareListItem
                                    key={compareItem.product_id}
                                    product={compareItem}
                                    multiplier={multiplier}
                                    restaurantId={restaurantId}
                                    // Favorites for compare items
                                    favoritesMap={favoritesMap}
                                    onAddFavorite={handleAddFavorite}
                                    onRemoveFavorite={handleRemoveFavorite}
                                    onQuantityChange={handleCartQuantityChange}
                                  />
                                ))}
                              </Box>
                            )}
                        </React.Fragment>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              ))
            )}

            {/* MOQ tracker on Mobile */}
            {isMobile && (
              <Box sx={{ mt: theme.spacing(2) }}>
                <MOQTracker />
              </Box>
            )}
          </Grid>

          {/* RIGHT SIDE: Summary */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                p: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                mt: theme.spacing(4),
                position: 'relative',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: theme.spacing(1) }}>
                Order Summary
              </Typography>
              <Divider sx={{ mb: theme.spacing(1) }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2">Total Cases</Typography>
                <Typography variant="body2">{totalCases}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2">Total Eaches</Typography>
                <Typography variant="body2">{totalEaches}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Typography variant="body2">Total Line Items</Typography>
                <Typography variant="body2">{cartItems.length}</Typography>
              </Box>
              <Divider sx={{ mb: 1 }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2">Subtotal</Typography>
                <Typography variant="body2">
                  ${orderSubtotal.toFixed(2)}
                </Typography>
              </Box>
              {orderSalesTax > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="body2">Sales Tax</Typography>
                  <Typography variant="body2">
                    ${orderSalesTax.toFixed(2)}
                  </Typography>
                </Box>
              )}
              <Divider sx={{ mb: 1 }} />

              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="subtitle1">Final Total</Typography>
                <Typography variant="subtitle1">
                  ${orderFinalTotal.toFixed(2)}
                </Typography>
              </Box>

              {/* Only enabled if tier === 2 AND totalCases > 0 */}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCompletePurchase}
                disabled={tier !== 2 || totalCases === 0}
                sx={{ textTransform: 'none', mb: isMobile ? 8 : 0 }}
              >
                {tier !== 2 ? 'Upgrade to Place Orders' : 'Complete Purchase'}
              </Button>
            </Box>

            {/* MOQ tracker on Desktop */}
            {!isMobile && (
              <Box sx={{ mt: theme.spacing(2) }}>
                <MOQTracker />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      {/* PROCESSING OVERLAY (BACKDROP) */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 9999 }}
        open={isPlacingOrder}
      >
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2, color: 'white' }}>
            Please don’t refresh the page. <br />
            Your order is processing...
          </Typography>
        </Box>
      </Backdrop>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Confirm Purchase Dialog */}
      <Dialog
        open={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Your Order</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ mb: 2 }}>
            Subtotal: ${orderSubtotal.toFixed(2)}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Sales Tax: ${orderSalesTax.toFixed(2)}
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Total: ${orderFinalTotal.toFixed(2)}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            By confirming, you agree to the terms and conditions of your purchase.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmModalOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmOrder} variant="contained" color="primary">
            Confirm Order
          </Button>
        </DialogActions>
      </Dialog>

      {/* Purchase Complete Dialog */}
      <Dialog open={purchaseComplete} onClose={() => setPurchaseComplete(false)}>
        <DialogTitle>Purchase Complete!</DialogTitle>
        <DialogContent>
          <Typography>Your purchase has been completed successfully.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPurchaseComplete(false);
              navigate('/orders');
              clearCart();
            }}
            variant="outlined"
          >
            View Orders
          </Button>
          <Button
            onClick={() => {
              setPurchaseComplete(false);
              navigate('/home');
              clearCart();
            }}
            variant="contained"
            color="primary"
          >
            Go Home
          </Button>
        </DialogActions>
      </Dialog>

      {/* Checkout Error Snackbar */}
      {checkoutError && (
        <Snackbar
          open={Boolean(checkoutError)}
          autoHideDuration={6000}
          onClose={() => setCheckoutError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setCheckoutError(null)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {checkoutError}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default Cart;
