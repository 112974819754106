import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Container,
  Grid,
  Divider,
  IconButton,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Drawer,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  useMediaQuery,
  Pagination,
} from '@mui/material';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import ListsFilterPanel from './ListsFilterPanel';
import usePersistedState from './hooks/usePersistedState';
import ListProductCard from './ListProductCard';
import ListListItem from './ListListItem';
import CompareListItem from './CompareListItem';

// ---------------------------------------------------------------------------------
// Styled components for the search bar
// ---------------------------------------------------------------------------------
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 300,
  marginTop: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled('input')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
}));

function Lists() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // ------------------------------
  // Persisted State
  // ------------------------------
  const [selectedListId, setSelectedListId] = usePersistedState(
    'selectedListId',
    null,
    JSON.parse
  );
  const [selectedCategory, setSelectedCategory] = usePersistedState(
    'selectedCategory',
    0,
    JSON.parse
  );
  const [selectedSubcategory, setSelectedSubcategory] = usePersistedState(
    'selectedSubcategory',
    0,
    JSON.parse
  );
  const [selectedVendor, setSelectedVendor] = usePersistedState(
    'selectedVendor',
    null,
    JSON.parse
  );
  const [selectedBrands, setSelectedBrands] = usePersistedState(
    'selectedBrands',
    [],
    JSON.parse
  );
  const [searchTerm, setSearchTerm] = usePersistedState('list_searchTerm', '', JSON.parse);

  // We’ll store a separate debounced value to avoid multiple calls while typing
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  // ------------------------------
  // Lists & Items
  // ------------------------------
  const [userLists, setUserLists] = useState([]);
  const [activeList, setActiveList] = useState(null);
  const [listItems, setListItems] = useState([]);
  const [errorListItems, setErrorListItems] = useState(null);
  const [loadingListItems, setLoadingListItems] = useState(false);
  const [loadingLists, setLoadingLists] = useState(true);
  const [errorLists, setErrorLists] = useState(null);

  // ------------------------------
  // Filter Data (Categories, Subcats, Vendors)
  // ------------------------------
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [vendors, setVendors] = useState([]);

  // ------------------------------
  // Pagination & view mode
  // ------------------------------
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [viewMode, setViewMode] = useState('grid');

  // ------------------------------
  // Create List Modal
  // ------------------------------
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [creatingList, setCreatingList] = useState(false);
  const [createListError, setCreateListError] = useState(null);

  // ------------------------------
  // Snackbar
  // ------------------------------
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // ------------------------------
  // Delete entire list
  // ------------------------------
  const [deletingListId, setDeletingListId] = useState(null);
  const [deleteListLoading, setDeleteListLoading] = useState(false);

  // ------------------------------
  // Delete single item
  // ------------------------------
  const [deleteItemLoading, setDeleteItemLoading] = useState(false);

  // ------------------------------
  // Multiplier
  // ------------------------------
  const [multiplier, setMultiplier] = useState(1);

  // ------------------------------
  // User data
  // ------------------------------
  const [userRestaurantId, setUserRestaurantId] = useState(null);
  const [userId, setUserId] = useState(null);

  // ------------------------------
  // Mobile filter drawer
  // ------------------------------
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  // ------------------------------
  // Favorites & Compare
  // ------------------------------
  const [favoritesMap, setFavoritesMap] = useState({});
  const [compareOpenId, setCompareOpenId] = useState(null);
  const [compareMap, setCompareMap] = useState({});

  // ------------------------------
  // On mount: load user
  // ------------------------------
  useEffect(() => {
    const userJson = localStorage.getItem('user');
    if (userJson) {
      try {
        const parsed = JSON.parse(userJson);
        setUserRestaurantId(parsed.restaurant_id || null);
        setUserId(parsed.id || null);
        setMultiplier(parseFloat(parsed.multiplier) || 1);
      } catch (err) {
        console.error('Error parsing user data:', err);
        setErrorLists('Invalid user data. Please log in again.');
        setLoadingLists(false);
      }
    } else {
      setErrorLists('User not found. Please log in.');
      setLoadingLists(false);
    }
  }, []);

  // ------------------------------
  // Fetch favorites
  // ------------------------------
  const fetchFavorites = async (restId) => {
    if (!restId) return;
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: restId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        const favMap = {};
        response.data.forEach((favItem) => {
          const { product_id, id: list_item_id } = favItem;
          if (!favMap[product_id]) {
            favMap[product_id] = [];
          }
          favMap[product_id].push(list_item_id);
        });
        setFavoritesMap(favMap);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setFavoritesMap({});
    }
  };

  const handleAddFavorite = async (productId) => {
    if (!userRestaurantId) {
      console.warn('No userRestaurantId for adding favorites.');
      return;
    }
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        { product_id: productId, restaurant_id: userRestaurantId },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await fetchFavorites(userRestaurantId);
    } catch (err) {
      console.error('Error adding favorite:', err);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    if (!favoritesMap[productId]) return;
    try {
      const listItemIds = favoritesMap[productId];
      const requests = listItemIds.map((id) =>
        axios.delete('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item', {
          data: { list_item_id: id },
          headers: { 'Content-Type': 'application/json' },
        })
      );
      await Promise.all(requests);
      await fetchFavorites(userRestaurantId);
    } catch (err) {
      console.error('Error removing favorite:', err);
    }
  };

  useEffect(() => {
    if (userRestaurantId) {
      fetchFavorites(userRestaurantId);
    }
  }, [userRestaurantId]);

  // ------------------------------
  // Fetch categories & vendors
  // ------------------------------
  const fetchCategories = async () => {
    try {
      const resp = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category', {
        headers: { 'Content-Type': 'application/json' },
      });
      setCategories(resp.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setCategories([]);
    }
  };

  const fetchSubcats = async (catId) => {
    if (catId === 0) {
      setSubcategories([]);
      setSelectedSubcategory(0);
      return;
    }
    try {
      const resp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
        {
          headers: { 'Content-Type': 'application/json' },
          params: { filter: catId },
        }
      );
      setSubcategories(resp.data || []);
    } catch (err) {
      console.error('Error fetching subcategories:', err);
      setSubcategories([]);
    }
  };

  const fetchVendors = async () => {
    if (!userRestaurantId) return;
    try {
      const resp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors',
        {
          params: { restaurant_id: userRestaurantId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const data = Array.isArray(resp.data) ? resp.data : [];
      // Each item has `_vendor` object, so we map that out:
      const vendList = data.map((item) => item._vendor);
      setVendors(vendList);
    } catch (err) {
      console.error('Error fetching vendors:', err);
      setVendors([]);
    }
  };

  // fetch categories & vendors on mount
  useEffect(() => {
    fetchCategories();
    fetchVendors();
  }, [userRestaurantId]);

  // subcategories
  useEffect(() => {
    fetchSubcats(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // ------------------------------
  // Fetch user Lists
  // ------------------------------
  useEffect(() => {
    if (!userRestaurantId) {
      setLoadingLists(false);
      return;
    }
    const fetchLists = async () => {
      try {
        const params = { restaurantidfilter: userRestaurantId };
        if (searchTerm) {
          params.productnamefilter = searchTerm;
        }
        const resp = await axios.get('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists', {
          params,
          headers: { 'Content-Type': 'application/json' },
        });

        let allLists = [];
        if (Array.isArray(resp.data)) {
          allLists = resp.data;
        } else if (resp.data && Array.isArray(resp.data.lists)) {
          allLists = resp.data.lists;
        }
        setUserLists(allLists);
        setLoadingLists(false);

        // fallback if selectedListId is invalid
        if (!selectedListId || !allLists.find((l) => l.id === selectedListId)) {
          if (allLists.length > 0) {
            setSelectedListId(allLists[0].id);
          } else {
            setActiveList(null);
          }
        }
      } catch (err) {
        console.error('Error fetching lists:', err);
        setErrorLists('Failed to fetch lists. Please try again later.');
        setLoadingLists(false);
      }
    };
    fetchLists();
  }, [userRestaurantId, searchTerm, selectedListId]);

  // ------------------------------
  // fetch list items (always replace the array)
  // ------------------------------
  const fetchListItems = useCallback(
    async (listId, pageNum, localSearchTerm) => {
      if (!listId) return;
      setLoadingListItems(true);
      setErrorListItems(null);

      try {
        const offset = (pageNum - 1) * itemsPerPage;
        const body = {
          lists_id: listId,
          productnamefilter: localSearchTerm,
          limit: itemsPerPage,
          offset,
          category_id: selectedCategory || 0,
          subcategory_id: selectedSubcategory || 0,
          vendor_id: selectedVendor || 0,
          user_id: userId || 1,
        };
        const resp = await axios.post(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_of_items_react_sql_2',
          body,
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (Array.isArray(resp.data)) {
          // Important: Always replace instead of appending
          setListItems(resp.data);

          let total = 0;
          if (resp.data.length > 0) {
            total = resp.data[0].total_items || 0;
          }
          setTotalResults(total);
          setTotalPages(Math.ceil(total / itemsPerPage));
        } else {
          setErrorListItems('Unexpected server response.');
          setTotalPages(0);
          setTotalResults(0);
        }
      } catch (error) {
        console.error('Error fetching list items:', error);
        setErrorListItems('Failed to fetch items. Please try again.');
      } finally {
        setLoadingListItems(false);
      }
    },
    [
      userId,
      selectedCategory,
      selectedSubcategory,
      selectedVendor,
      itemsPerPage,
    ]
  );

  // ------------------------------
  // Debounce the searchTerm to avoid multiple calls while typing
  // ------------------------------
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  // ------------------------------
  // Unified effect to fetch items
  // when activeList changes, page changes, debouncedSearchTerm changes,
  // or selectedCategory/subcategory/vendor changes
  // ------------------------------
  useEffect(() => {
    if (!selectedListId) {
      setActiveList(null);
      setListItems([]);
      setTotalPages(0);
      setTotalResults(0);
      return;
    }

    const newActiveList = userLists.find((l) => l.id === selectedListId);
    if (!newActiveList) {
      setActiveList(null);
      setListItems([]);
      setTotalPages(0);
      setTotalResults(0);
      return;
    }

    setActiveList(newActiveList);
    fetchListItems(newActiveList.id, currentPage, debouncedSearchTerm);
  }, [
    selectedListId,
    userLists,
    currentPage,
    debouncedSearchTerm,
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    fetchListItems,
  ]);

  // ------------------------------
  // Clear Filters (ONLY category, subcategory, vendor)
  // ------------------------------
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor(null);
    // We do NOT touch selectedListId or searchTerm or anything else
  };

  // ------------------------------
  // Pagination
  // ------------------------------
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (e) => {
    const newVal = e.target.value;
    setItemsPerPage(newVal);
    setCurrentPage(1);
    setTotalPages(0);
    setTotalResults(0);
    setListItems([]);
  };

  // ------------------------------
  // Search Handler
  // ------------------------------
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    // Reset pagination so you start at page 1 for a new search
    setCurrentPage(1);
  };

  // ------------------------------
  // Create a new list
  // ------------------------------
  const openCreateModal = () => {
    setNewListName('');
    setCreateListError(null);
    setCreateModalOpen(true);
  };
  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };
  const handleCreateList = async () => {
    if (!newListName.trim()) {
      setCreateListError('List name cannot be empty.');
      return;
    }
    setCreatingList(true);
    setCreateListError(null);
    try {
      const resp = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        { list_name: newListName.trim(), restaurant_id: userRestaurantId },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const created = resp.data;
      if (created) {
        setUserLists((prev) => [...prev, created]);
        setSelectedListId(created.id);
      }
      setSnackbarMessage('List created successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCreateModalOpen(false);
    } catch (err) {
      let msg = 'Failed to create list.';
      if (err.response?.data?.error) {
        msg = err.response.data.error;
      }
      setCreateListError(msg);
    } finally {
      setCreatingList(false);
    }
  };

  // ------------------------------
  // Snackbar close
  // ------------------------------
  const handleSnackbarClose = (evt, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // ------------------------------
  // Delete entire list
  // ------------------------------
  const handleDeleteListClick = (listId) => {
    setDeletingListId(listId);
  };
  const handleConfirmDeleteList = async (listId) => {
    setDeleteListLoading(true);
    try {
      await axios.delete(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists/${listId}`,
        {
          data: { lists_id: listId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setUserLists((prev) => prev.filter((l) => l.id !== listId));
      if (selectedListId === listId) {
        const remain = userLists.filter((l) => l.id !== listId);
        if (remain.length > 0) {
          setSelectedListId(remain[0].id);
        } else {
          setActiveList(null);
          setSelectedListId(null);
          setListItems([]);
          setCurrentPage(1);
          setTotalResults(0);
          setTotalPages(0);
        }
      }
      setSnackbarMessage('List deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      let msg = 'Failed to delete list.';
      if (err.response?.data?.error) {
        msg = err.response.data.error;
      }
      setSnackbarMessage(msg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingListId(null);
      setDeleteListLoading(false);
    }
  };
  const handleCancelDeleteList = () => {
    setDeletingListId(null);
  };

  // ------------------------------
  // Delete single item
  // ------------------------------
  const handleDeleteProduct = async (listItemId) => {
    setDeleteItemLoading(true);
    try {
      await axios.delete(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item',
        {
          data: { list_item_id: listItemId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      // Filter out the deleted item by matching on .list_item_id
      setListItems((prev) => prev.filter((it) => it.list_item_id !== listItemId));
      setSnackbarMessage('Item deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      let msg = 'Failed to delete item.';
      if (err.response?.data?.error) {
        msg = err.response.data.error;
      }
      setSnackbarMessage(msg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeleteItemLoading(false);
    }
  };

  // ------------------------------
  // Compare logic
  // ------------------------------
  const handleCompareClick = async (productId) => {
    if (compareOpenId === productId) {
      setCompareOpenId(null);
      return;
    }
    try {
      const localUserId = userId || 1;
      const resp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_3',
        {
          headers: { 'Content-Type': 'application/json' },
          params: { product_id: productId, user_id: localUserId },
        }
      );
      if (Array.isArray(resp.data)) {
        setCompareMap((prev) => ({ ...prev, [productId]: resp.data }));
        setCompareOpenId(productId);
      }
    } catch (err) {
      console.error('Error comparing products:', err);
    }
  };

  // ------------------------------
  // Render list items
  // ------------------------------
  const renderListItems = () => {
    if (loadingListItems) {
      return (
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      );
    }
    if (errorListItems) {
      return (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorListItems}
        </Alert>
      );
    }
    if (listItems.length === 0) {
      return (
        <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
          No items available in this list.
        </Typography>
      );
    }

    // Grid view
    if (viewMode === 'grid') {
      return (
        <>
          <Grid
            container
            spacing={2}
            sx={{ paddingBottom: isMobile ? theme.spacing(8) : 0 }}
          >
            {listItems.map((item) => {
              const mappedProduct = {
                product_id: item.product_id,
                short_description: item.short_description,
                image_url: item.image_url,
                brand: item.brand || '',
                pack_size: item.pack_size || '',
                vendor_logo_url: item.vendor_logo_url,
                ff_catalog_number: item.ff_catalog_number || '',
                rpp_purchase_price: Number(item.rpp_purchase_price) || 0,
                rpp_case_priced_by: item.rpp_case_priced_by || '',
                rpp_each_price: Number(item.rpp_each_price) || 0,
                rpp_each_priced_by: item.rpp_each_priced_by || '',
                product_per_unit_price: Number(item.product_per_unit_price) || 0,
                lead_time: item.lead_time || 0,
                special_notes: item.special_notes,
                bestdeal: item.bestdeal,
                hasalternates: item.hasalternates,
              };

              return (
                <React.Fragment key={item.list_item_id}>
                  <Grid item xs={6} sm={4} md={3}>
                    <ListProductCard
                      product={mappedProduct}
                      multiplier={multiplier}
                      favoritesMap={favoritesMap}
                      onAddFavorite={handleAddFavorite}
                      onRemoveFavorite={handleRemoveFavorite}
                      compareOpen={compareOpenId === mappedProduct.product_id}
                      onCompareClick={handleCompareClick}
                      showDelete
                      onDelete={() => handleDeleteProduct(item.list_item_id)}
                      listItemId={item.list_item_id}
                    />
                  </Grid>

                  {/* Compare row if expanded */}
                  {compareOpenId === mappedProduct.product_id &&
                    mappedProduct.hasalternates && (
                      <Grid item xs={12}>
                        <Box sx={{ backgroundColor: '#f9f9f9', p: 1 }}>
                          {compareMap[mappedProduct.product_id]?.map((cmp) => (
                            <CompareListItem
                              key={`compareItem-${cmp.product_id}`}
                              product={cmp}
                              multiplier={multiplier}
                              restaurantId={userRestaurantId}
                              favoritesMap={favoritesMap}
                              onAddFavorite={handleAddFavorite}
                              onRemoveFavorite={handleRemoveFavorite}
                            />
                          ))}
                        </Box>
                      </Grid>
                    )}
                </React.Fragment>
              );
            })}
          </Grid>

          {/* Pagination */}
          {totalPages > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size={isMobile ? 'small' : 'medium'}
                showFirstButton={!isMobile}
                showLastButton={!isMobile}
              />
            </Box>
          )}
        </>
      );
    }

    // List view
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '2px' }}>
          {listItems.map((item) => {
            const mappedProduct = {
              product_id: item.product_id,
              short_description: item.short_description,
              image_url: item.image_url,
              brand: item.brand || '',
              pack_size: item.pack_size || '',
              vendor_logo_url: item.vendor_logo_url,
              ff_catalog_number: item.ff_catalog_number || '',
              rpp_purchase_price: Number(item.rpp_purchase_price) || 0,
              rpp_case_priced_by: item.rpp_case_priced_by || '',
              rpp_each_price: Number(item.rpp_each_price) || 0,
              rpp_each_priced_by: item.rpp_each_priced_by || '',
              product_per_unit_price: Number(item.product_per_unit_price) || 0,
              lead_time: item.lead_time || 0,
              special_notes: item.special_notes,
              bestdeal: item.bestdeal,
              hasalternates: item.hasalternates,
            };

            return (
              <React.Fragment key={item.list_item_id}>
                <ListListItem
                  product={mappedProduct}
                  listItemId={item.list_item_id}
                  multiplier={multiplier}
                  favoritesMap={favoritesMap}
                  onAddFavorite={handleAddFavorite}
                  onRemoveFavorite={handleRemoveFavorite}
                  compareOpen={compareOpenId === mappedProduct.product_id}
                  onCompareClick={handleCompareClick}
                  showDelete
                  onDelete={() => handleDeleteProduct(item.list_item_id)}
                  restaurantId={userRestaurantId}
                />

                {/* Compare row if expanded */}
                {compareOpenId === mappedProduct.product_id &&
                  mappedProduct.hasalternates && (
                    <Box sx={{ backgroundColor: '#f9f9f9', p: 1 }}>
                      {compareMap[mappedProduct.product_id]?.map((cmp) => (
                        <CompareListItem
                          key={`compareItem-${cmp.product_id}`}
                          product={cmp}
                          multiplier={multiplier}
                          restaurantId={userRestaurantId}
                          favoritesMap={favoritesMap}
                          onAddFavorite={handleAddFavorite}
                          onRemoveFavorite={handleRemoveFavorite}
                        />
                      ))}
                    </Box>
                  )}
              </React.Fragment>
            );
          })}
        </Box>

        {/* Pagination */}
        {totalPages > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? 'small' : 'medium'}
              showFirstButton={!isMobile}
              showLastButton={!isMobile}
            />
          </Box>
        )}
      </>
    );
  };

  // ------------------------------
  // Render
  // ------------------------------
  return (
    <div>
      <Helmet>
        <title>FareFood | Lists</title>
        <meta
          name="description"
          content="Organize and manage your FareFood lists with ease..."
        />
        <meta property="og:title" content="FareFood | Lists" />
        <meta
          property="og:description"
          content="Effortlessly organize your favorite items..."
        />
      </Helmet>

      {/* Sticky Nav */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar />
      </Box>

      {loadingLists ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: theme.spacing(8) }}>
          <CircularProgress />
        </Box>
      ) : errorLists ? (
        <Container maxWidth="sm" sx={{ textAlign: 'center', pt: theme.spacing(8) }}>
          <Alert severity="error">{errorLists}</Alert>
        </Container>
      ) : (
        <Container maxWidth="lg" sx={{ pt: theme.spacing(8) }}>
          <Typography variant="h5" sx={{ mb: 1, pt: 4, color: 'primary.main' }}>
            {activeList ? activeList.list_name : 'Select a List to View Items'}
          </Typography>

          {/* 
            On MOBILE, search bar remains beneath the title.
            On DESKTOP, it’s moved to the row with Items Per Page + Toggle.
          */}
          {isMobile && (
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Items…"
                aria-label="search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </Search>
          )}

          <Grid container spacing={2} alignItems="flex-start" sx={{ mt: 2 }}>
            {/* DESKTOP SIDE PANEL */}
            {!isMobile && (
              <Grid
                item
                xs={12}
                sm={3}
                md={2}
                sx={{
                  paddingTop: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                  display: { xs: 'none', sm: 'block' },
                  height: 'calc(100vh - 64px)',
                  position: 'sticky',
                  top: '64px',
                  overflowY: 'auto',
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                {/* Create a List button (desktop) */}
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mb: 2,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={openCreateModal}
                >
                  Create a List
                </Button>
                <Divider sx={{ mb: 2 }} />

                <ListsFilterPanel
                  lists={userLists}
                  selectedListId={selectedListId}
                  setSelectedListId={(id) => setSelectedListId(Number(id))}
                  categories={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  subcategories={subcategories}
                  selectedSubcategory={selectedSubcategory}
                  setSelectedSubcategory={setSelectedSubcategory}
                  vendors={vendors}
                  selectedVendor={selectedVendor}
                  setSelectedVendor={(id) => setSelectedVendor(id !== null ? Number(id) : null)}
                  selectedBrands={selectedBrands}
                  setSelectedBrands={setSelectedBrands}

                  /* Clears only category/subcat/vendor */
                  clearAllFilters={clearAllFilters}

                  /* Props for showing/hiding mobile vs desktop UI */
                  isMobile={false}
                  openCreateModal={openCreateModal}
                  viewMode={viewMode}
                  setViewMode={setViewMode}
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={handleItemsPerPageChange}
                />
              </Grid>
            )}

            {/* MAIN CONTENT */}
            <Grid item xs={12} sm={isMobile ? 12 : 9} md={isMobile ? 12 : 10} sx={{ py: 2 }}>
              {/* Desktop-only row for Search + Items per page & toggle */}
              {!isMobile && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  sx={{ mb: 2 }}
                >
                  {/* Left side: Search */}
                  <Grid item>
                    <Search>
                      <SearchIconWrapper>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        placeholder="Search Items…"
                        aria-label="search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </Search>
                  </Grid>

                  {/* Right side: Items per page + Grid/List toggle */}
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <FormControl sx={{ minWidth: 80 }}>
                          <InputLabel id="items-per-page-label">Items</InputLabel>
                          <Select
                            labelId="items-per-page-label"
                            id="items-per-page"
                            value={itemsPerPage}
                            label="Items"
                            onChange={handleItemsPerPageChange}
                          >
                            <MenuItem value={24}>24</MenuItem>
                            <MenuItem value={48}>48</MenuItem>
                            <MenuItem value={96}>96</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item>
                        <IconButton
                          onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                        >
                          {viewMode === 'grid' ? <ViewListIcon /> : <ViewModuleIcon />}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* Render your list items */}
              {activeList ? (
                renderListItems()
              ) : (
                <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
                  Select a list to view items.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {/* MOBILE FILTER BUTTON */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: theme.spacing(12),
            right: theme.spacing(2),
            zIndex: 9999,
          }}
        >
          <IconButton
            onClick={() => setIsFilterDrawerOpen(true)}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
              boxShadow: theme.shadows[4],
            }}
            size="large"
          >
            <FilterListIcon />
          </IconButton>
        </Box>
      )}

      {/* MOBILE FILTER DRAWER */}
      <Drawer
        anchor="left"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        ModalProps={{ keepMounted: true }}
      >
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => setIsFilterDrawerOpen(false)} aria-label="close filter">
              <CloseIcon />
            </IconButton>
          </Box>

          <ListsFilterPanel
            lists={userLists}
            selectedListId={selectedListId}
            setSelectedListId={(id) => setSelectedListId(Number(id))}
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            vendors={vendors}
            selectedVendor={selectedVendor}
            setSelectedVendor={(id) => setSelectedVendor(id !== null ? Number(id) : null)}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}

            // Clears only cat/subcat/vendor
            clearAllFilters={clearAllFilters}

            // Mobile
            isMobile
            openCreateModal={openCreateModal}
            viewMode={viewMode}
            setViewMode={setViewMode}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={handleItemsPerPageChange}
          />
        </Box>
      </Drawer>

      {/* CREATE LIST MODAL */}
      <Dialog open={createModalOpen} onClose={closeCreateModal} fullWidth maxWidth="sm">
        <DialogTitle>Create a New List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            disabled={creatingList}
          />
          {createListError && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {createListError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateModal} disabled={creatingList}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateList}
            variant="contained"
            disabled={creatingList}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': { backgroundColor: theme.palette.primary.dark },
            }}
          >
            {creatingList ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : snackbarSeverity === 'error'
                ? theme.palette.error.main
                : theme.palette.info.main,
            color: theme.palette.common.white,
          },
        }}
      />

      {/* CONFIRM DELETE ENTIRE LIST */}
      <Dialog
        open={Boolean(deletingListId)}
        onClose={handleCancelDeleteList}
        aria-labelledby="delete-list-dialog-title"
      >
        <DialogTitle id="delete-list-dialog-title">Delete List</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this list? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteList} disabled={deleteListLoading}>
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmDeleteList(deletingListId)}
            variant="contained"
            color="error"
            disabled={deleteListLoading}
            startIcon={deleteListLoading ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Lists;
