// src/MobileItemDetails.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Collapse,
  CircularProgress,
  Alert,
  Breadcrumbs,
  Link,
} from '@mui/material';
import {
  ArrowBackIos,
  ArrowForwardIos,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Inventory as InventoryIcon,
  List as ListIcon,
  RoomService as RoomServiceIcon,
} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CompareListItem from './CompareListItem';
import AddToCartButton from './AddToCartButton';

function MobileItemDetails({
  product,
  associatedImages,
  selectedImage,
  setSelectedImage,
  imageIndex,
  handleImageNavigation,
  truncateText,
  multiplier,
  adjustedCasePrice,
  adjustedCasePricePerUnit,
  adjustedEachPrice,
  compareProducts,
  compareLoading,
  compareError,
  compareOpen,
  setCompareOpen,
  isProductFavorited,
  handleAddFavorite,
  handleRemoveFavorite,
  addInventoryButtonText,
  handleAddToInventory,
  addInventorySuccess,
  addInventoryError,
  addListButtonText,
  handleOpenAddInventoryModal,
  addListSuccess,
  addListError,
  sampleRequestLoading,
  sampleRequestSuccess,
  sampleRequestError,
  sampleButtonText,
  handleRequestSample,
  navigate,
}) {
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  // Show "Purchased x times" only if > 0
  const showPurchasedCell = product.times_previously_purchased > 0;

  // If numeric price <= 0 => show “MARKET” text
  const isCaseMarket = adjustedCasePrice <= 0;
  const isEachMarket = adjustedEachPrice <= 0;

  // For that row of inventory/list/favorite
  const ROW_HEIGHT = 40;

  return (
    <Box sx={{ pt: 6, pb: 8 }}>
      {/* BREADCRUMBS */}
      <Box sx={{ mb: 1, px: 2 }}>
        <Breadcrumbs
          separator={
            <Typography sx={{ color: '#A5A5A5', fontFamily: 'Poppins', fontSize: '9pt' }}>
              /
            </Typography>
          }
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            onClick={() => navigate('/home')}
            sx={{
              cursor: 'pointer',
              color: '#0E9C9B',
              fontFamily: 'Poppins',
              fontSize: '9pt',
              textDecoration: 'none',
              '&:hover': { textDecoration: 'underline' },
            }}
          >
            Home
          </Link>

          {product.product_category_id > 0 ? (
            <Link
              underline="hover"
              onClick={() =>
                navigate(`/shop?category=${product.product_category_id}`)
              }
              sx={{
                cursor: 'pointer',
                color: '#0E9C9B',
                fontFamily: 'Poppins',
                fontSize: '9pt',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {product.category_name || 'Category'}
            </Link>
          ) : (
            <Typography
              sx={{
                color: '#0E9C9B',
                fontFamily: 'Poppins',
                fontSize: '9pt',
              }}
            >
              {product.category_name || 'Category'}
            </Typography>
          )}

          {product.product_subcategory_id > 0 && product.subcategory_name && (
            <Link
              underline="hover"
              onClick={() =>
                navigate(
                  `/shop?category=${product.product_category_id}&subcategory=${product.product_subcategory_id}`
                )
              }
              sx={{
                cursor: 'pointer',
                color: '#0E9C9B',
                fontFamily: 'Poppins',
                fontSize: '9pt',
                textDecoration: 'none',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {product.subcategory_name}
            </Link>
          )}
        </Breadcrumbs>
      </Box>

      {/* BRAND + SHORT DESCRIPTION + PACK SIZE */}
      <Box sx={{ px: 2 }}>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '10px',
            fontWeight: 500,
            color: '#006D6D',
          }}
        >
          {product.brand}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 600,
            color: '#131616',
          }}
        >
          {product.short_description}
        </Typography>
        <Typography sx={{ mt: 0.5, fontSize: '9px', color: '#949E98' }}>
          {truncateText(product.pack_size || 'N/A', 50)} |{' '}
          {truncateText(product.FF_Catalog_Number || 'N/A', 50)}
        </Typography>
      </Box>

      {/* MAIN IMAGE */}
      <Box
        sx={{
          width: '100%',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: '1px solid #E0E0E0',
          position: 'relative',
          mt: 1,
        }}
      >
        <Box
          component="img"
          src={selectedImage || product.Image_URL || ''}
          alt="product"
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
          }}
        />

        {/* Vendor Logo (top-right) */}
        {product.vendor_logo_url && (
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              width: 50,
              height: 50,
              borderRadius: '50%',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 1,
              zIndex: 2,
            }}
          >
            <Box
              component="img"
              src={product.vendor_logo_url}
              alt={product.vendor_short_name || 'Vendor Logo'}
              sx={{
                width: '80%',
                height: '80%',
                objectFit: 'contain',
                borderRadius: '50%',
              }}
            />
          </Box>
        )}

        {/* "Best Price" or "Better Price" badge (bottom-left) */}
        {product.bestdeal ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: 8,
              left: 8,
              backgroundColor: '#00BC48',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '8px',
                color: '#fff',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Best Price
            </Typography>
          </Box>
        ) : product.hasalternates ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: 8,
              left: 8,
              backgroundColor: '#E76F01',
              px: 1,
              py: 0.5,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '8px',
                color: '#fff',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              Better Price Available
            </Typography>
          </Box>
        ) : null}

        {/* Arrows if multiple images */}
        {associatedImages.length > 1 && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 8,
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              gap: 1,
            }}
          >
            <IconButton
              onClick={() => handleImageNavigation('left')}
              disabled={imageIndex === 0}
              sx={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.9)',
                },
              }}
            >
              <ArrowBackIos fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => handleImageNavigation('right')}
              disabled={imageIndex >= associatedImages.length - 5}
              sx={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.9)',
                },
              }}
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* INVENTORY / LIST / FAVORITE ROW */}
      <Box
        sx={{
          width: '100%',
          height: ROW_HEIGHT,
          border: '1px solid #E0E0E0',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          mt: 0,
        }}
      >
        {/* Add to Inventory */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #E0E0E0',
          }}
        >
          <Button
            variant="text"
            onClick={handleAddToInventory}
            disabled={addInventoryButtonText === 'Added to Inventory!'}
            startIcon={<InventoryIcon sx={{ color: '#006D6D' }} />}
            sx={{
              textTransform: 'none',
              fontSize: '9px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {addInventoryButtonText}
          </Button>
        </Box>

        {/* Add to List */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #E0E0E0',
          }}
        >
          <Button
            variant="text"
            onClick={handleOpenAddInventoryModal}
            disabled={addListButtonText === 'Added to List!'}
            startIcon={<ListIcon sx={{ color: '#006D6D' }} />}
            sx={{
              textTransform: 'none',
              fontSize: '9px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {addListButtonText}
          </Button>
        </Box>

        {/* Favorite / Unfavorite */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="text"
            onClick={() =>
              isProductFavorited(product.id)
                ? handleRemoveFavorite(product.id)
                : handleAddFavorite(product.id)
            }
            startIcon={
              isProductFavorited(product.id) ? (
                <FavoriteIcon sx={{ color: '#E92409' }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: '#E92409' }} />
              )
            }
            sx={{
              textTransform: 'none',
              fontSize: '9px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {isProductFavorited(product.id) ? 'Unfavorite' : 'Favorite'}
          </Button>
        </Box>
      </Box>

      {/* IN STOCK / PURCHASED / SAMPLE ROW */}
      <Box
        sx={{
          width: '100%',
          height: ROW_HEIGHT,
          border: '1px solid #E0E0E0',
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          mt: 0,
        }}
      >
        {/* In Stock */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #E0E0E0',
          }}
        >
          <Typography sx={{ fontSize: '9px', color: '#495555' }}>In Stock</Typography>
        </Box>

        {/* Purchased x times (only if > 0) */}
        {showPurchasedCell && (
          <Box
            sx={{
              flex: 1,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: '1px solid #E0E0E0',
            }}
          >
            <Typography sx={{ fontSize: '9px', color: '#495555' }}>
              Purchased {product.times_previously_purchased}x
            </Typography>
          </Box>
        )}

        {/* Request Sample */}
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="text"
            onClick={handleRequestSample}
            startIcon={<RoomServiceIcon sx={{ fontSize: '14px', color: '#495555' }} />}
            sx={{
              textTransform: 'none',
              fontSize: '9px',
              justifyContent: 'center',
              color: '#495555',
              width: '100%',
            }}
          >
            {sampleRequestLoading
              ? 'Submitting...'
              : sampleRequestSuccess
              ? 'Sample Requested'
              : sampleButtonText}
          </Button>
        </Box>
      </Box>

      {/* ALERT MESSAGES */}
      <Box sx={{ px: 2, pt: 0 }}>
        {addInventorySuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {addInventorySuccess}
          </Alert>
        )}
        {addInventoryError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {addInventoryError}
          </Alert>
        )}
        {addListSuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {addListSuccess}
          </Alert>
        )}
        {addListError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {addListError}
          </Alert>
        )}
        {sampleRequestSuccess && (
          <Alert severity="success" sx={{ mb: 1 }}>
            {sampleRequestSuccess}
          </Alert>
        )}
        {sampleRequestError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {sampleRequestError}
          </Alert>
        )}
      </Box>

      {/* DESCRIPTION COLLAPSIBLE */}
      <Box sx={{ width: '100%', border: '1px solid #E0E0E0', overflow: 'hidden', mt: 0 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 2,
            py: 1.5,
            cursor: 'pointer',
            userSelect: 'none',
          }}
          onClick={() => setDescriptionOpen(!descriptionOpen)}
        >
          <ArrowDropDownIcon
            sx={{
              fontSize: '16px',
              transform: descriptionOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease-in-out',
              color: '#495555',
              mr: 1,
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 500,
              fontSize: '8.5pt',
              color: '#131616',
            }}
          >
            Description
          </Typography>
        </Box>
        <Collapse in={descriptionOpen}>
          <Box sx={{ px: 2, pb: 2 }}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8.5pt',
                      color: '#131616',
                      fontWeight: 400,
                    }}
                    {...props}
                  />
                ),
                h2: ({ node, ...props }) => (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8.5pt',
                      color: '#131616',
                      fontWeight: 400,
                    }}
                    {...props}
                  />
                ),
                h3: ({ node, ...props }) => (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8.5pt',
                      color: '#131616',
                      fontWeight: 400,
                    }}
                    {...props}
                  />
                ),
                h4: ({ node, ...props }) => (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8.5pt',
                      color: '#131616',
                      fontWeight: 400,
                    }}
                    {...props}
                  />
                ),
                p: ({ node, ...props }) => (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8.5pt',
                      color: '#131616',
                      fontWeight: 400,
                    }}
                    {...props}
                  />
                ),
                strong: ({ node, ...props }) => (
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8.5pt',
                      color: '#131616',
                      fontWeight: 'bold',
                    }}
                    {...props}
                  />
                ),
              }}
            >
              {product.markdown_description || ''}
            </ReactMarkdown>
          </Box>
        </Collapse>
      </Box>

      {/* COMPARE PRODUCTS */}
      {product.hasalternates && (
        <Box sx={{ px: 2, pt: 1 }}>
          <Typography
            sx={{
              color: '#131616',
              fontFamily: 'Poppins',
              fontWeight: 500,
              fontSize: '8.5pt',
              mb: 1,
            }}
          >
            Compare Products
          </Typography>
          {compareLoading ? (
            <Box sx={{ textAlign: 'center', my: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : compareError ? (
            <Alert severity="error" sx={{ m: 2 }}>
              {compareError}
            </Alert>
          ) : compareProducts.length > 0 ? (
            compareProducts.map((cmp) => (
              <Box key={cmp.product_id} sx={{ mb: 1 }}>
                <CompareListItem
                  product={cmp}
                  multiplier={multiplier}
                  onAddFavorite={handleAddFavorite}
                  onRemoveFavorite={handleRemoveFavorite}
                />
              </Box>
            ))
          ) : (
            <Typography variant="body2" align="center" sx={{ my: 4 }}>
              No compare products available.
            </Typography>
          )}
        </Box>
      )}

      {/* 
         BOTTOM “ADD” BAR 
         Updated to show "case_priced_by" next to the case price 
         and "each_priced_by" next to the each price.
      */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 56, // If you have a bottom nav, adjust as needed
          left: 0,
          width: '100%',
          backgroundColor: '#fff',
          boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
          zIndex: 999,
          px: 0,
          py: 1,
        }}
      >
        {adjustedCasePrice > 0 && adjustedEachPrice > 0 ? (
          // CASE + EACH
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', width: '100%' }}>
            {/* CASE block */}
            <Box sx={{ flex: 1, borderRight: '1px solid #E0E0E0' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 0,
                }}
              >
                {/* Price on the left */}
                <Box sx={{ display: 'flex', flexDirection: 'column', m: 0 }}>
                  {isCaseMarket ? (
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                      MARKET
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                        ${adjustedCasePrice.toFixed(2)}
                      </Typography>
                      {product.case_priced_by && (
                        <Typography sx={{ fontSize: '10px', color: '#949E98' }}>
                          {product.case_priced_by}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {adjustedCasePricePerUnit > 0 && (
                    <Typography sx={{ fontSize: '10px', color: '#949E98', m: 0 }}>
                      ${adjustedCasePricePerUnit.toFixed(2)} /{' '}
                      {product.price_per_unit_priced_by || 'unit'}
                    </Typography>
                  )}
                </Box>
                {/* Button on the right */}
                <AddToCartButton productId={product.id} isSplit={false} buttonLabel="Case" />
              </Box>
            </Box>

            {/* EACH block */}
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 0,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', m: 0 }}>
                  {isEachMarket ? (
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                      MARKET
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                        ${adjustedEachPrice.toFixed(2)}
                      </Typography>
                      {product.each_priced_by && (
                        <Typography sx={{ fontSize: '10px', color: '#949E98' }}>
                          {product.each_priced_by}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
                <AddToCartButton productId={product.id} isSplit buttonLabel="Each" />
              </Box>
            </Box>
          </Box>
        ) : adjustedCasePrice > 0 ? (
          // CASE ONLY
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 0,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 0 }}>
              {isCaseMarket ? (
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                  MARKET
                </Typography>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                    ${adjustedCasePrice.toFixed(2)}
                  </Typography>
                  {product.case_priced_by && (
                    <Typography sx={{ fontSize: '10px', color: '#949E98' }}>
                      {product.case_priced_by}
                    </Typography>
                  )}
                </Box>
              )}
              {adjustedCasePricePerUnit > 0 && (
                <Typography sx={{ fontSize: '10px', color: '#949E98' }}>
                  ${adjustedCasePricePerUnit.toFixed(2)} /{' '}
                  {product.price_per_unit_priced_by || 'unit'}
                </Typography>
              )}
            </Box>
            <AddToCartButton productId={product.id} isSplit={false} buttonLabel="Add" />
          </Box>
        ) : adjustedEachPrice > 0 ? (
          // EACH ONLY
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 0,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', m: 0 }}>
              {isEachMarket ? (
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                  MARKET
                </Typography>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#006D6D' }}>
                    ${adjustedEachPrice.toFixed(2)}
                  </Typography>
                  {product.each_priced_by && (
                    <Typography sx={{ fontSize: '10px', color: '#949E98' }}>
                      {product.each_priced_by}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
            <AddToCartButton productId={product.id} isSplit buttonLabel="Add" />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default MobileItemDetails;
