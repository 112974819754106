// src/CartContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const { user, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  // ----------------------------------------
  // Fetch Cart Items
  // ----------------------------------------
  const fetchCartItems = async () => {
    if (!isAuthenticated || !user) {
      console.error('User is not authenticated.');
      setCartItems([]);
      return;
    }

    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const headers = { Authorization: `Bearer ${authToken}` };
      const params = { restaurant_id: user.restaurant_id };

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurantcartitems',
        { headers, params }
      );

      console.log('Fetched Cart Items:', response.data);
      setCartItems(response.data);
    } catch (error) {
      console.error('Error fetching cart items:', error.response || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchCartItems();
    } else {
      setCartItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  // Update subtotal whenever cartItems changes
  useEffect(() => {
    let subtotalAmount = 0;
    cartItems.forEach((item) => {
      subtotalAmount += item.total_price;
    });
    setSubtotal(subtotalAmount);
  }, [cartItems]);

  // ----------------------------------------
  // Add or Update Cart with isSplit
  // If quantity is 0, we delete the item from cart.
  // ----------------------------------------
  const handleAddToCart = async (product_id, quantity, isSplit = false) => {
    try {
      if (!isAuthenticated || !user) {
        console.error('User is not authenticated.');
        navigate('/');
        return;
      }

      // If the user sets quantity to 0, call handleDeleteItem instead of just setting it to 0
      if (quantity === 0) {
        const itemToDelete = cartItems.find(
          (item) => item.product_id === product_id && item.Split_Item === isSplit
        );
        if (itemToDelete) {
          await handleDeleteItem(itemToDelete.id);
        }
        return; // Stop here, so we don't POST with quantity=0
      }

      const authToken = localStorage.getItem('authToken');
      if (!authToken || !user.id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        user_id: user.id,
        quantity,
        product_id,
        isSplit,
      };

      console.log('Adding/updating cart with data:', data);

      // POST to the new endpoint
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_cart_case_each_react_v1',
        data,
        { headers }
      );

      console.log('Add to Cart API Response:', response.data);

      // After adding/updating, re-fetch the cart
      await fetchCartItems();
    } catch (error) {
      console.error('Error adding item to cart:', error.response || error.message);
      alert('An error occurred while adding the item to the cart.');
    }
  };

  // ----------------------------------------
  // Handle Delete Item
  // ----------------------------------------
  const handleDeleteItem = async (orderitem_id) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      console.log(`Attempting to delete item with orderitem_id: ${orderitem_id}`);

      const response = await axios.delete(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_order_item_from_cart',
        {
          headers,
          data: { orderitem_id },
        }
      );

      console.log('Delete API Response:', response.data);

      // Update MOQ tracker if necessary
      await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/update_MOQ_tracker',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );

      await fetchCartItems();
    } catch (error) {
      console.error('Error deleting cart item:', error.response || error.message);

      if (error.response) {
        alert(`Failed to delete item: ${error.response.data.message || 'Server Error'}`);
      } else if (error.request) {
        alert('No response from server. Please try again later.');
      } else {
        alert('An unexpected error occurred while deleting the item.');
      }
    }
  };

  // ----------------------------------------
  // Clear Cart (new helper function)
  // ----------------------------------------
  const clearCart = () => {
    setCartItems([]);
  };

  // ----------------------------------------
  // Provider
  // ----------------------------------------
  return (
    <CartContext.Provider
      value={{
        cartItems,
        subtotal,
        loading,
        fetchCartItems,
        handleAddToCart,
        handleDeleteItem,
        clearCart, // make sure to expose clearCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
