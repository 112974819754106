import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Button
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { CartContext } from './CartContext';

// MUI Icons
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

const MOQComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [vendorsData, setVendorsData] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loadingVendors, setLoadingVendors] = useState(false);
  const [errorVendors, setErrorVendors] = useState(null);

  // For showing/hiding the arrow
  const [showArrow, setShowArrow] = useState(false);
  const scrollContainerRef = useRef(null);

  const { cartItems } = useContext(CartContext);

  // Fetch vendors
  useEffect(() => {
    const fetchVendors = async () => {
      setLoadingVendors(true);
      try {
        const userJson = localStorage.getItem('user');
        const user = JSON.parse(userJson);
        const restaurantId = user?.restaurant_id;

        if (!restaurantId) {
          throw new Error('Restaurant ID not found in user data.');
        }

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch vendors data.');
        }
        const data = await response.json();
        setVendorsData(data);
      } catch (error) {
        setErrorVendors(error.message);
      } finally {
        setLoadingVendors(false);
      }
    };

    fetchVendors();
  }, []);

  // Build the vendor objects and sort them
  useEffect(() => {
    if (vendorsData.length === 0) return;

    const newVendors = vendorsData.map((vendorData) => {
      const shortName = vendorData._vendor?.Short_Name ?? 'No Name';
      const logoUrl = vendorData._vendor?.Logo_URL;

      let casesBought = 0;
      let dollarsBought = 0;

      cartItems.forEach((item) => {
        const itemVendorId = item._product?._vendor?.id;
        if (itemVendorId === vendorData.vendor_id) {
          // Exclude 'split items' from case summation
          if (!item.Split_Item) {
            casesBought += item.quantity || 0;
          }
          // Always include in dollars
          dollarsBought += item.total_price || 0;
        }
      });

      // Compute how close they are (ratio from 0..1)
      const ratioCasesRaw =
        vendorData.MOQ_Cases > 0 ? casesBought / vendorData.MOQ_Cases : 1;
      const ratioDollarsRaw =
        vendorData.MOQ_Dollar > 0 ? dollarsBought / vendorData.MOQ_Dollar : 1;

      // Clamp each ratio to 1 if we exceed MOQ
      const ratioCases = Math.min(ratioCasesRaw, 1);
      const ratioDollars = Math.min(ratioDollarsRaw, 1);
      const overallRatio = Math.min(ratioCases, ratioDollars);

      return {
        id: vendorData.vendor_id,
        shortName,
        logoUrl,
        casesBought,
        dollarsBought,
        casesLimit: vendorData.MOQ_Cases || 0,
        dollarsLimit: vendorData.MOQ_Dollar || 0,
        overallRatio
      };
    });

    // Sort in descending order by overallRatio
    newVendors.sort((a, b) => b.overallRatio - a.overallRatio);
    setVendors(newVendors);
  }, [vendorsData, cartItems]);

  // Check if the list is scrollable
  useEffect(() => {
    if (!scrollContainerRef.current) return;
    const { scrollHeight, clientHeight } = scrollContainerRef.current;
    setShowArrow(scrollHeight > clientHeight);
  }, [vendors]);

  // Handle scroll
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    setShowArrow(scrollTop + clientHeight < scrollHeight - 1);
  };

  function ThickBar({ current, limit, isCases }) {
    if (!limit || limit <= 0) return null; // No limit => no bar

    const remainder = limit - current;
    const rawRatio = current / limit;
    const ratioClamped = Math.min(rawRatio, 1);
    const progressPercent = Math.round(ratioClamped * 100);

    const leftLabel = isCases
      ? `${current} case${current !== 1 ? 's' : ''}`
      : `$${current.toFixed(2)}`;

    let rightLabel;
    if (remainder > 0) {
      rightLabel = isCases
        ? `${remainder} to go`
        : `$${remainder.toFixed(2)} to go`;
    } else {
      rightLabel = isCases ? `${current} cases met` : `$${current.toFixed(2)} met`;
    }

    const LeftIcon = isCases ? AllInboxIcon : AttachMoneyIcon;

    return (
      <Box
        sx={{
          position: 'relative',
          backgroundColor: '#949E98',
          borderRadius: '9999px', // pill shape
          height: 30,
          width: '100%',
          overflow: 'hidden'
        }}
      >
        {/* Filled portion */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: `${progressPercent}%`,
            backgroundColor: '#006D6D',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            pl: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              color: '#fff',
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: '"Poppins", sans-serif',
              whiteSpace: 'nowrap'
            }}
          >
            <LeftIcon sx={{ fontSize: '16px' }} />
            {leftLabel}
          </Box>
        </Box>

        {/* Remainder portion */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            height: '100%',
            width: `${100 - progressPercent}%`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            pr: 1,
            color: '#fff',
            fontSize: '12px',
            fontWeight: 600,
            fontFamily: '"Poppins", sans-serif',
            whiteSpace: 'nowrap'
          }}
        >
          {rightLabel}
        </Box>
      </Box>
    );
  }

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        borderRadius: theme.shape.borderRadius,
        border: '1px solid #ccc',
        fontFamily: '"Poppins", sans-serif'
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
        {loadingVendors ? (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        ) : errorVendors ? (
          <Alert
            severity="error"
            sx={{ fontFamily: '"Poppins", sans-serif', fontSize: '10px' }}
          >
            {`There was an error fetching your MOQ Tracker data: ${errorVendors}. Please try again later.`}
          </Alert>
        ) : vendors.length > 0 ? (
          <>
            <Typography
              sx={{
                color: '#131616',
                fontWeight: 500, // Poppins Medium
                fontSize: '10pt',
                mb: 2,
                fontFamily: 'Poppins, sans-serif'
              }}
            >
              Order Minimums Tracker
            </Typography>

            {/* Scrollable region limited to a certain height */}
            <Box sx={{ flex: 1, minHeight: 0, position: 'relative' }}>
              <Grid
                container
                spacing={0.5}
                ref={scrollContainerRef}
                onScroll={handleScroll}
                sx={{
                  // Control how tall the vendor list can get
                  maxHeight: 280, // ~4 rows x 60px each
                  overflowY: 'auto',
                }}
              >
                {vendors.map((vendor) => {
                  const {
                    id,
                    shortName,
                    logoUrl,
                    casesBought,
                    casesLimit,
                    dollarsBought,
                    dollarsLimit
                  } = vendor;

                  const rows = [];

                  // CASES row
                  if (casesLimit > 0) {
                    rows.push(
                      <Grid item xs={12} key={`${id}-cases`}>
                        <Box
                          sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            height: 60
                          }}
                        >
                          {/* Logo or fallback text => 25% */}
                          <Box
                            sx={{
                              flex: '0 0 25%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              pl: 0.5
                            }}
                          >
                            {logoUrl ? (
                              <img
                                src={logoUrl}
                                alt="Vendor Logo"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: '10px',
                                  fontWeight: 600,
                                  fontFamily: 'Poppins, sans-serif'
                                }}
                              >
                                {shortName}
                              </Typography>
                            )}
                          </Box>

                          {/* ThickBar => 75% */}
                          <Box
                            sx={{
                              flex: 1,
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              pr: 0.5
                            }}
                          >
                            <ThickBar current={casesBought} limit={casesLimit} isCases />
                          </Box>
                        </Box>
                      </Grid>
                    );
                  }

                  // DOLLARS row
                  if (dollarsLimit > 0) {
                    rows.push(
                      <Grid item xs={12} key={`${id}-dollars`}>
                        <Box
                          sx={{
                            border: '1px solid #e0e0e0',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                            height: 60
                          }}
                        >
                          {/* Logo or fallback text => 25% */}
                          <Box
                            sx={{
                              flex: '0 0 25%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'center',
                              pl: 0.5
                            }}
                          >
                            {logoUrl ? (
                              <img
                                src={logoUrl}
                                alt="Vendor Logo"
                                style={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'contain'
                                }}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: '10px',
                                  fontWeight: 600,
                                  fontFamily: 'Poppins, sans-serif'
                                }}
                              >
                                {shortName}
                              </Typography>
                            )}
                          </Box>

                          {/* ThickBar => 75% */}
                          <Box
                            sx={{
                              flex: 1,
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              pr: 0.5
                            }}
                          >
                            <ThickBar
                              current={dollarsBought}
                              limit={dollarsLimit}
                              isCases={false}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    );
                  }

                  return rows;
                })}
              </Grid>

              {/* Slightly transparent arrow overlay if scrollable */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  display: showArrow ? 'flex' : 'none',
                  justifyContent: 'center',
                  pointerEvents: 'none',
                  background:
                    'linear-gradient(to bottom, rgba(255,255,255,0), #fff 80%)'
                }}
              >
                <ExpandCircleDownIcon
                  sx={{
                    color: 'rgba(128,128,128,0.5)',
                    mb: 0.5
                  }}
                />
              </Box>
            </Box>
          </>
        ) : (
          // Empty State
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              backgroundColor: alpha(theme.palette.info.light, 0.1),
              borderRadius: theme.shape.borderRadius,
              p: 2
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Poppins, sans-serif',
                color: theme.palette.text.primary
              }}
            >
              No MOQ to Track
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '12px',
                mb: 2,
                fontFamily: 'Poppins, sans-serif'
              }}
            >
              Start placing an order to track your order minimums. Your spend and
              order minimums will appear here once you begin ordering.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/shop')}
              sx={{
                textTransform: 'none',
                fontSize: '12px',
                fontFamily: 'Poppins, sans-serif'
              }}
            >
              Start an Order
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default MOQComponent;
