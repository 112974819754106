// src/FoodCostingTemplateInstructions.js

import React, { useEffect } from 'react';
import { Box, Typography, Button, Container, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet'; // Import Helmet
import SalesNavBar from './SalesNavBar';
import Footer from './Footer';

// Styled Components
const Root = styled(Box)({
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
});

const MainContent = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontFamily: 'Poppins, sans-serif',
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(8),
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 Aspect Ratio
  height: 0,
  overflow: 'hidden',
  marginBottom: theme.spacing(4),
}));

const VideoIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 0,
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  flexDirection: { xs: 'column', sm: 'row' },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  paddingX: 3,
  paddingY: 1.5,
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  letterSpacing: '0.5px',
}));

const FoodCostingTemplateInstructions = () => {
  const theme = useTheme();

  useEffect(() => {
    // Any additional effects if needed
  }, []);

  return (
    <Root>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Food Costing Template Instructions</title>
        <meta
          name="description"
          content="Learn how to use the FareFood food costing template with this step-by-step video guide. Simplify your food costing calculations today!"
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Food Costing Template Instructions" />
        <meta
          property="og:description"
          content="Watch this instructional video to get started with the FareFood food costing template."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/food-costing-template-instructions" />
        <meta property="og:image" content="https://www.farefood.com/assets/food-costing-instructions-og-image.jpg" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Food Costing Template Instructions" />
        <meta
          name="twitter:description"
          content="Simplify food costing with FareFood's instructional video. Get started now!"
        />
        <meta name="twitter:image" content="https://www.farefood.com/assets/food-costing-instructions-twitter-image.jpg" />
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <MainContent>
        <Container maxWidth="lg">
          {/* Header Text */}
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: 500,
              color: '#000000',
              marginBottom: theme.spacing(4),
              fontFamily: 'Poppins, sans-serif',
              fontSize: '2.019rem', // Reduced by 15% from 2.375rem
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.615rem', // Reduced by 15% from 1.9rem
              },
            }}
          >
            FareFood Food Costing Template Instructions
          </Typography>

          {/* Video Embed */}
          <VideoContainer>
            <VideoIframe
              src="https://www.loom.com/embed/36f2d25bc56b407890623879061d991b?sid=a30cd2df-8458-42dc-93b1-0475955b81b4"
              frameBorder="0"
              webkitAllowFullScreen
              mozAllowFullScreen
              allowFullScreen
              title="FareFood Food Costing Template Instructions"
            ></VideoIframe>
          </VideoContainer>

          {/* Buttons */}
          <ButtonContainer>
            <StyledButton
              variant="contained"
              color="primary"
              href="/signup"
              sx={{
                backgroundColor: '#027F7C',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#006D6D',
                },
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              Try FareFood
            </StyledButton>

            <Button
              variant="outlined"
              href="/contactus"
              sx={{
                paddingX: 3,
                paddingY: 1.5,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '1rem',
                letterSpacing: '0.5px',
                color: '#027F7C',
                borderColor: '#027F7C',
                '&:hover': {
                  backgroundColor: '#E0F2F1',
                  borderColor: '#027F7C',
                },
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              Contact us
            </Button>
          </ButtonContainer>
        </Container>
      </MainContent>

      {/* Footer */}
      <Footer />
    </Root>
  );
};

export default FoodCostingTemplateInstructions;
