// src/ItemDetails.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TopNavBar from './TopNavBar';
import DesktopItemDetails from './DesktopItemDetails';
import MobileItemDetails from './MobileItemDetails';

function ItemDetails() {
  // -------------------------------------------
  // State Variables
  // -------------------------------------------
  const [product, setProduct] = useState(null);
  const [associatedImages, setAssociatedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);

  // Restaurant-specific "case" price
  const [restaurantPrice, setRestaurantPrice] = useState(null);

  // Add to List
  const [openAddInventoryModal, setOpenAddInventoryModal] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addingToList, setAddingToList] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addListButtonText, setAddListButtonText] = useState('Add to List');

  // Add to Inventory
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryButtonText, setAddInventoryButtonText] = useState('Add to Inventory');

  // Sample Request
  const [sampleRequestLoading, setSampleRequestLoading] = useState(false);
  const [sampleRequestSuccess, setSampleRequestSuccess] = useState('');
  const [sampleRequestError, setSampleRequestError] = useState('');
  const [sampleButtonText, setSampleButtonText] = useState('Request a Sample');

  // Compare data (if used)
  const [compareProducts, setCompareProducts] = useState([]);
  const [compareLoading, setCompareLoading] = useState(false);
  const [compareError, setCompareError] = useState('');
  const [compareOpen, setCompareOpen] = useState(false);

  // Favorites
  const [favoritesMap, setFavoritesMap] = useState({});

  // Router & user details
  const { productId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationState = location.state || {};
  const { source, filters } = navigationState;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm')); // e.g. "large" breakpoint
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [multiplier, setMultiplier] = useState(1);
  const [restaurantId, setRestaurantId] = useState(null);
  const [userId, setUserId] = useState(null);

  // -------------------------------------------
  // Fetch multiplier, user from localStorage
  // -------------------------------------------
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);

        if (parsedUser.restaurant_id) {
          setRestaurantId(parsedUser.restaurant_id);
        }
        if (parsedUser.id) {
          setUserId(parsedUser.id);
        }
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        setMultiplier(1);
      }
    } else {
      setMultiplier(1);
    }
  }, []);

  // Helper: truncate text
  const truncateText = (text, maxLength = 50) => {
    if (!text) return '';
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // -------------------------------------------
  // Fetch product detail
  // -------------------------------------------
  const fetchProductDetail = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        console.warn('No authToken found in localStorage; skipping product fetch');
        setLoading(false);
        return;
      }
      if (!userId) {
        console.warn('No userId found; skipping product fetch');
        setLoading(false);
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const body = {
        user_id: userId,
        product_id: productId,
      };

      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/product_details_sql',
        body,
        { headers }
      );

      if (!Array.isArray(response.data) || response.data.length === 0) {
        console.warn('No product detail returned from product_details_sql');
        setLoading(false);
        return;
      }
      const item = response.data[0];

      // Map fields
      const mappedProduct = {
        id: item.product_id,
        short_description: item.short_description,
        markdown_description: item.markdown_description,
        pack_size: item.pack_size,
        FF_Catalog_Number: item.ff_catalog_number,
        brand: item.brand,
        product_volume: item.product_volume,
        priced_by: item.priced_by,
        Image_URL: item.main_image_url,

        purchase_price: parseFloat(item.rpp_purchase_price || 0),
        case_priced_by: item.rpp_case_priced_by || '',

        each_price: parseFloat(item.rpp_each_price || 0),
        each_priced_by: item.rpp_each_priced_by || '',

        case_price_per_unit: parseFloat(item.case_price_per_unit || 0),
        price_per_unit_priced_by: item.price_per_unit_priced_by || '',

        product_category_id: item.product_category_id || 0,
        product_subcategory_id: item.product_subcategory_id || 0,

        product_images: item.product_images ? JSON.parse(item.product_images) : [],
        vendor_short_name: item.vendor_short_name,
        vendor_logo_url: item.vendor_logo_url,
        hasalternates: item.hasalternates,
        category_name: item.category_name || '',
        subcategory_name: item.subcategory_name || '',
        times_previously_purchased: item.times_previously_purchased
          ? parseInt(item.times_previously_purchased, 10)
          : 0,

        bestdeal: item.bestdeal || false,
      };

      setProduct(mappedProduct);
      setRestaurantPrice(mappedProduct.purchase_price);

      if (mappedProduct.product_images.length > 0) {
        setAssociatedImages(mappedProduct.product_images);
        setSelectedImage(mappedProduct.product_images[0].URL);
      } else {
        setAssociatedImages([]);
        setSelectedImage(mappedProduct.Image_URL || '');
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoading(false);
    }
  };

  // -------------------------------------------
  // Fetch Compare Products (if hasalternates)
  // -------------------------------------------
  const fetchCompareProducts = async () => {
    setCompareLoading(true);
    setCompareError('');
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken || !userId) {
        console.warn('No authToken or userId to fetch compare products. Skipping.');
        setCompareLoading(false);
        return;
      }

      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_2',
        {
          headers,
          params: {
            product_id: productId,
            user_id: userId,
          },
        }
      );
      setCompareProducts(response.data || []);
    } catch (error) {
      console.error('Error fetching compare products:', error);
      setCompareError('Failed to load compare products.');
    } finally {
      setCompareLoading(false);
    }
  };

  // -------------------------------------------
  // Favorites
  // -------------------------------------------
  const fetchFavoriteStatus = async () => {
    if (!restaurantId) {
      return;
    }
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: restaurantId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        const newMap = {};
        response.data.forEach((fav) => {
          if (!newMap[fav.product_id]) {
            newMap[fav.product_id] = [];
          }
          newMap[fav.product_id].push(fav.id);
        });
        setFavoritesMap(newMap);
      }
    } catch (error) {
      console.error('Error checking favorite status:', error);
      setFavoritesMap({});
    }
  };

  const isProductFavorited = (prodId) => {
    return !!(favoritesMap[prodId] && favoritesMap[prodId].length > 0);
  };

  const handleAddFavorite = async (prodId) => {
    if (!restaurantId || !prodId) {
      console.error('Missing restaurantId or productId - cannot add favorite.');
      return;
    }
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        { product_id: prodId, restaurant_id: restaurantId },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await fetchFavoriteStatus();
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  const handleRemoveFavorite = async (prodId) => {
    if (!favoritesMap[prodId] || favoritesMap[prodId].length === 0) {
      return;
    }
    try {
      const deleteRequests = favoritesMap[prodId].map((listItemId) =>
        axios.delete('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item', {
          data: { list_item_id: listItemId },
          headers: { 'Content-Type': 'application/json' },
        })
      );
      await Promise.all(deleteRequests);
      await fetchFavoriteStatus();
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  // -------------------------------------------
  // On mount
  // -------------------------------------------
  useEffect(() => {
    if (userId) {
      fetchProductDetail();
    }
    // eslint-disable-next-line
  }, [productId, userId]);

  useEffect(() => {
    if (product && product.hasalternates) {
      fetchCompareProducts();
    } else {
      setCompareProducts([]);
    }
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (restaurantId) {
      fetchFavoriteStatus();
    }
    // eslint-disable-next-line
  }, [restaurantId]);

  // -------------------------------------------
  // Image Handlers (arrow nav)
  // -------------------------------------------
  const handleImageNavigation = (direction) => {
    if (direction === 'left' && imageIndex > 0) {
      setImageIndex((prev) => prev - 1);
    } else if (direction === 'right' && imageIndex < associatedImages.length - 5) {
      setImageIndex((prev) => prev + 1);
    }
  };

  // -------------------------------------------
  // Price Computations
  // -------------------------------------------
  const adjustedCasePrice = product ? product.purchase_price * multiplier : 0;
  const adjustedCasePricePerUnit = product
    ? product.case_price_per_unit * multiplier
    : 0;
  const adjustedEachPrice = product ? product.each_price * multiplier : 0;

  // -------------------------------------------
  // Add to List
  // -------------------------------------------
  const fetchLists = async () => {
    setListsLoading(true);
    setAddListError('');
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Missing authToken for fetching lists.');
      }
      if (!restaurantId) {
        throw new Error('No restaurant_id found. Cannot fetch lists.');
      }

      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: restaurantId },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  };

  const handleOpenAddInventoryModal = () => {
    setOpenAddInventoryModal(true);
    fetchLists();
  };

  const handleCloseAddInventoryModal = () => {
    setOpenAddInventoryModal(false);
    setAddListSuccess('');
    setAddListError('');
  };

  const handleAddToList = async (listId) => {
    if (!product) return;
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Missing auth token. Cannot add to list.');
      }
      if (!userId) {
        throw new Error('No user ID. Cannot add to list.');
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        list_item_name: '',
        product_id: product.id,
        lists_id: listId,
      };
      await axios.post('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item', data, {
        headers,
      });

      setAddListSuccess('Item successfully added to the list.');
      setAddListButtonText('Added to List!');
      setTimeout(() => {
        handleCloseAddInventoryModal();
      }, 1500);
    } catch (error) {
      console.error('Error adding item to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  };

  // -------------------------------------------
  // Add to Inventory
  // -------------------------------------------
  const handleAddToInventory = async () => {
    if (!product) return;
    setAddInventoryButtonText('Adding...');
    setAddInventorySuccess('');
    setAddInventoryError('');
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Missing auth token. Cannot add to inventory.');
      }
      if (!restaurantId) {
        throw new Error('No restaurantId found. Cannot add to inventory.');
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        restaurant_id: restaurantId,
        item_description: product.short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id: product.id,
      };
      await axios.post('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory', data, {
        headers,
      });

      setAddInventorySuccess('Item successfully added to inventory.');
      setAddInventoryButtonText('Added to Inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
      setAddInventoryButtonText('Add to Inventory');
    }
  };

  // -------------------------------------------
  // Request a Sample
  // -------------------------------------------
  const handleRequestSample = async () => {
    if (!product) return;
    setSampleRequestLoading(true);
    setSampleRequestSuccess('');
    setSampleRequestError('');
    setSampleButtonText('Submitting...');

    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Missing auth token. Cannot request sample.');
      }
      if (!userId) {
        throw new Error('No user ID to create help ticket.');
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        subject: `Sample Request for ${product.vendor_short_name || 'Vendor'} for ${
          product.short_description || 'Product'
        }`,
        description: `Product catalog #: ${product.FF_Catalog_Number || 'N/A'}`,
        user_id: userId,
        ticket_type_id: 7,
        ticket_status_id: 1,
        admin_notes: 'We have received your sample request and will coordinate with the supplier.',
        last_updated: new Date().toISOString(),
      };

      await axios.post('https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets', data, {
        headers,
      });

      setSampleRequestSuccess('Sample request successfully submitted.');
      setSampleButtonText('Sample Requested');
    } catch (error) {
      console.error('Error submitting sample request:', error);
      setSampleRequestError('Failed to submit sample request. Please try again.');
      setSampleButtonText('Request a Sample');
    } finally {
      setSampleRequestLoading(false);
    }
  };

  // -------------------------------------------
  // Render
  // -------------------------------------------
  const containerSx = {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0,
      maxWidth: '100%',
      overflowX: 'hidden',
    },
  };

  return (
    <>
      <TopNavBar
        title="Product Details"
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.background.paper,
        }}
      />
      <Container maxWidth="lg" sx={containerSx}>
        {/* Helmet Title */}
        {product && (
          <Helmet>
            <title>FareFood | {truncateText(product.short_description || '', 25)}</title>
          </Helmet>
        )}

        {/* Loading / No Product */}
        {loading ? (
          <Box sx={{ textAlign: 'center', marginTop: theme.spacing(4) }}>
            <CircularProgress size={24} />
          </Box>
        ) : !product ? (
          <Box sx={{ textAlign: 'center', marginTop: theme.spacing(4) }}>
            <Typography>No product information available.</Typography>
          </Box>
        ) : isDesktop ? (
          // -------------------------------------------
          // DESKTOP Layout
          // -------------------------------------------
          <DesktopItemDetails
            product={product}
            associatedImages={associatedImages}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            imageIndex={imageIndex}
            handleImageNavigation={handleImageNavigation}
            truncateText={truncateText}
            showFullDescription={showFullDescription}
            setShowFullDescription={setShowFullDescription}
            multiplier={multiplier}
            adjustedCasePrice={adjustedCasePrice}
            adjustedCasePricePerUnit={adjustedCasePricePerUnit}
            adjustedEachPrice={adjustedEachPrice}
            compareProducts={compareProducts}
            compareLoading={compareLoading}
            compareError={compareError}
            compareOpen={compareOpen}
            setCompareOpen={setCompareOpen}
            isProductFavorited={isProductFavorited}
            handleAddFavorite={handleAddFavorite}
            handleRemoveFavorite={handleRemoveFavorite}
            addInventoryButtonText={addInventoryButtonText}
            handleAddToInventory={handleAddToInventory}
            addInventorySuccess={addInventorySuccess}
            addInventoryError={addInventoryError}
            addListButtonText={addListButtonText}
            handleOpenAddInventoryModal={handleOpenAddInventoryModal}
            addListSuccess={addListSuccess}
            addListError={addListError}
            sampleRequestLoading={sampleRequestLoading}
            sampleRequestSuccess={sampleRequestSuccess}
            sampleRequestError={sampleRequestError}
            sampleButtonText={sampleButtonText}
            handleRequestSample={handleRequestSample}
            navigate={navigate}
          />
        ) : (
          // -------------------------------------------
          // MOBILE Layout
          // -------------------------------------------
          <MobileItemDetails
            product={product}
            associatedImages={associatedImages}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            imageIndex={imageIndex}
            handleImageNavigation={handleImageNavigation}
            truncateText={truncateText}
            showFullDescription={showFullDescription}
            setShowFullDescription={setShowFullDescription}
            multiplier={multiplier}
            adjustedCasePrice={adjustedCasePrice}
            adjustedCasePricePerUnit={adjustedCasePricePerUnit}
            adjustedEachPrice={adjustedEachPrice}
            compareProducts={compareProducts}
            compareLoading={compareLoading}
            compareError={compareError}
            compareOpen={compareOpen}
            setCompareOpen={setCompareOpen}
            isProductFavorited={isProductFavorited}
            handleAddFavorite={handleAddFavorite}
            handleRemoveFavorite={handleRemoveFavorite}
            addInventoryButtonText={addInventoryButtonText}
            handleAddToInventory={handleAddToInventory}
            addInventorySuccess={addInventorySuccess}
            addInventoryError={addInventoryError}
            addListButtonText={addListButtonText}
            handleOpenAddInventoryModal={handleOpenAddInventoryModal}
            addListSuccess={addListSuccess}
            addListError={addListError}
            sampleRequestLoading={sampleRequestLoading}
            sampleRequestSuccess={sampleRequestSuccess}
            sampleRequestError={sampleRequestError}
            sampleButtonText={sampleButtonText}
            handleRequestSample={handleRequestSample}
            navigate={navigate}
          />
        )}
      </Container>

      {/* The “Add to List” Modal is shared */}
      <Dialog
        open={openAddInventoryModal}
        onClose={handleCloseAddInventoryModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Select a List</DialogTitle>
        <DialogContent>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText primary={list.list_name} />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography variant="body2" align="center">
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddInventoryModal} disabled={addingToList}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ItemDetails;
