import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import fallbackLogo from './assets/small-logo.avif';
import AddToCartButton from './AddToCartButton';

/** Helper: get auth & user from localStorage */
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }
  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

/**
 * PHProductCard
 */
function PHProductCard({
  product,
  multiplier = 1,
  listView = false,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  compareOpen = false,
  onCompareClick,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // ----------------------------
  // Destructure fields
  // ----------------------------
  const {
    product_id,
    short_description = '',
    brand = '',
    ff_catalog_number = '', 
    image_url,
    vendor_logo_url,
    rpp_purchase_price,
    rpp_case_priced_by,
    rpp_each_price,
    rpp_each_priced_by,
    product_per_unit_price,
    lead_time,
    special_notes,
    timespurchased,
    lastdatepurchased,
    hasalternates = false,
    bestdeal = null,
  } = product;

  // ----------------------------
  // Favorites
  // ----------------------------
  const isFavorited = !!favoritesMap[product_id];
  const handleFavoriteClick = (event) => {
    event.stopPropagation();
    if (isFavorited) {
      onRemoveFavorite?.(product_id);
    } else {
      onAddFavorite?.(product_id);
    }
  };

  // ----------------------------
  // Add to List
  // ----------------------------
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // **NEW**: We'll show a Snackbar after adding to a list
  const [listAddToast, setListAddToast] = useState('');

  const handleOpenListDialog = (event) => {
    event.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };

  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      // Also set our toast message
      setListAddToast('Item successfully added to the list!');

      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // ----------------------------
  // Add to Inventory
  // ----------------------------
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryInProgress, setAddInventoryInProgress] = useState(false);

  const handleAddToInventoryClick = (event) => {
    event.stopPropagation();
    addToInventory();
  };

  async function addToInventory() {
    try {
      setAddInventoryInProgress(true);
      setAddInventorySuccess('');
      setAddInventoryError('');

      const { user, headers } = getAuthHeadersAndUser();
      const data = {
        restaurant_id: user.restaurant_id,
        item_description: short_description,
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id,
      };
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );
      setAddInventorySuccess('Item successfully added to inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    } finally {
      setAddInventoryInProgress(false);
    }
  }

  // ----------------------------
  // Compare
  // ----------------------------
  const compareBorderStyle = compareOpen
    ? `2px solid ${theme.palette.grey[400]}`
    : 'none';
  const compareArrow = compareOpen ? '▲' : '▼';
  const compareButtonColor = compareOpen ? '#006D6D' : '#949E98';
  const compareButtonStyles =
    bestdeal === false
      ? { color: '#fff', backgroundColor: '#F87906' }
      : { color: compareButtonColor };
  const compareLabel = bestdeal === false ? 'Better Deals Available' : 'Compare';

  // ----------------------------
  // Image fallback
  // ----------------------------
  const [imageError, setImageError] = useState(false);
  const finalImageURL = imageError || !image_url ? fallbackLogo : image_url;
  const handleImageError2 = () => setImageError(true);

  // ----------------------------
  // Navigation
  // ----------------------------
  const handleCardClick = () => {
    navigate(`/product/${product_id}`);
  };

  // ----------------------------
  // Price logic
  // ----------------------------
  function formatCasePrice() {
    if (rpp_purchase_price && Number(rpp_purchase_price) > 0) {
      const total = (Number(rpp_purchase_price) * multiplier).toFixed(2);
      const label = rpp_case_priced_by || 'CS';
      return `$${total} ${label}`;
    }
    if (Number(rpp_purchase_price) === 0 && rpp_case_priced_by) {
      return 'MARKET';
    }
    return null;
  }

  function formatCasePricePerUnit() {
    if (product_per_unit_price && Number(product_per_unit_price) > 0) {
      const perUnit = (Number(product_per_unit_price) * multiplier).toFixed(2);
      return `$${perUnit}/unit`;
    }
    return null;
  }

  function formatEachPrice() {
    if (rpp_each_price && Number(rpp_each_price) > 0) {
      const total = (Number(rpp_each_price) * multiplier).toFixed(2);
      const label = rpp_each_priced_by || 'EA';
      return `$${total} ${label}`;
    }
    if (Number(rpp_each_price) === 0 && rpp_each_priced_by) {
      return 'MARKET';
    }
    return null;
  }

  const casePrice = formatCasePrice();             
  const casePricePerUnit = formatCasePricePerUnit();
  const eachPrice = formatEachPrice();              

  // ----------------------------
  // Render
  // ----------------------------
  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'box-shadow 0.2s',
          backgroundColor: 'transparent',
          border: compareBorderStyle,
          '&:hover': {
            boxShadow: theme.shadows[4],
            backgroundColor: alpha(theme.palette.background.paper, 0.1),
          },
        }}
        onClick={handleCardClick}
      >
        {/* pinned Fav / List / Inventory icons top-left */}
        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(1),
            zIndex: 2,
            color: '#E92409',
            p: 0.5,
          }}
          onClick={handleFavoriteClick}
        >
          {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(4),
            zIndex: 2,
            color: '#006D6D',
            p: 0.5,
          }}
          onClick={handleOpenListDialog}
        >
          <ListAltIcon />
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(7),
            zIndex: 2,
            color: '#006D6D',
            p: 0.5,
          }}
          onClick={handleAddToInventoryClick}
        >
          <InventoryIcon />
        </IconButton>

        {/* Vendor Logo pinned top-right (only if not listView, vendor_logo_url exists) */}
        {!listView && vendor_logo_url && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: theme.shadows[2],
              overflow: 'hidden',
              zIndex: 2,
            }}
          >
            <img
              src={vendor_logo_url}
              alt="vendor logo"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackLogo;
              }}
            />
          </Box>
        )}

        {/* Main container => push Compare button to bottom */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <CardContent
            sx={{
              p: theme.spacing(0.25),
              flex: '1 0 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {/* IMAGE container */}
            <Box sx={{ position: 'relative', mt: 4 }}>
              <CardMedia
                component="img"
                height="80"
                image={finalImageURL}
                alt={short_description}
                loading="lazy"
                onError={handleImageError2}
                sx={{
                  objectFit: 'contain',
                  backgroundColor: imageError ? 'white' : 'transparent',
                  borderRadius: theme.shape.borderRadius,
                }}
              />

              {/* ff_catalog_number pinned top-left (same styling as ProductCard) */}
              {ff_catalog_number && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: theme.spacing(0.5),
                    left: theme.spacing(0.5),
                    backgroundColor: 'white',
                    padding: theme.spacing(0.125, 0.25),
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#949E98',
                      fontFamily: 'Poppins',
                      fontSize: '7pt',
                    }}
                  >
                    {ff_catalog_number}
                  </Typography>
                </Box>
              )}

              {/* lead_time / special_notes pinned bottom-right => match ProductCard’s styling */}
              {(lead_time > 0 || (special_notes && !/no/i.test(special_notes))) && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    right: theme.spacing(1),
                    display: 'flex',
                    gap: 0.5,
                  }}
                >
                  {lead_time > 0 && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: theme.shadows[1],
                      }}
                    >
                      <AvTimerIcon sx={{ fontSize: '1rem', color: '#F87906' }} />
                    </Box>
                  )}
                  {special_notes && !/no/i.test(special_notes) && (
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: theme.shadows[1],
                      }}
                    >
                      <NoteAltIcon sx={{ fontSize: '1rem', color: '#F87906' }} />
                    </Box>
                  )}
                </Box>
              )}

              {/* bestdeal => pinned bottom-left corner "Best Price" */}
              {bestdeal === true && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: theme.spacing(1),
                    left: theme.spacing(1),
                    backgroundColor: '#00BC48',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    zIndex: 3,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#fff',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '0.75rem',
                    }}
                  >
                    Best Price
                  </Typography>
                </Box>
              )}
            </Box>

            {/* BRAND + SHORT DESC */}
            <Box sx={{ mt: 1, px: 1 }}>
              {brand && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    color: '#3A3F3F',
                    fontWeight: theme.typography.subtitle2.fontWeight,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    mb: 0.2,
                  }}
                >
                  {brand}
                </Typography>
              )}

              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  color: '#131616',
                  fontSize: '9pt',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '1.2em',
                  height: '2.4em',
                  mb: 0,
                }}
              >
                {short_description}
              </Typography>
            </Box>

            {/* TIMES PURCHASED + LAST DATE */}
            <Box sx={{ mt: 0.5, px: 1 }}>
              {timespurchased && (
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '7.5pt',
                    fontWeight: 600,
                    color: '#626C6A',
                    display: 'block',
                    mb: 0.2,
                  }}
                >
                  Purchased {timespurchased} times
                </Typography>
              )}
              {lastdatepurchased && (
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '7.5pt',
                    fontWeight: 600,
                    color: '#626C6A',
                    display: 'block',
                  }}
                >
                  Last purchased on{' '}
                  {new Date(lastdatepurchased).toLocaleDateString()}
                </Typography>
              )}
            </Box>

            {/* PRICE + ADD-TO-CART (mirrors ProductCard) */}
            <Box sx={{ mt: 1, px: 1 }} onClick={(e) => e.stopPropagation()}>
              {/* CASE row (always display something, either numeric or MARKET) */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: eachPrice ? 0.5 : 0,
                }}
              >
                {casePrice ? (
                  casePrice === 'MARKET' ? (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        fontSize: '9pt',
                        color: '#006D6D',
                      }}
                    >
                      MARKET
                    </Typography>
                  ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontWeight: 'bold',
                          fontSize: '9pt',
                          color: '#006D6D',
                        }}
                      >
                        {casePrice}
                      </Typography>
                      {casePricePerUnit && (
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '7pt',
                            color: '#949E98',
                          }}
                        >
                          ({casePricePerUnit})
                        </Typography>
                      )}
                    </Box>
                  )
                ) : (
                  // If truly no case price => fallback as MARKET
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: '9pt',
                      color: '#006D6D',
                    }}
                  >
                    MARKET
                  </Typography>
                )}

                <AddToCartButton productId={product_id} buttonLabel="Case" />
              </Box>

              {/* EACH row if we have eachPrice */}
              {eachPrice && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: '9pt',
                      color: '#006D6D',
                    }}
                  >
                    {eachPrice}
                  </Typography>
                  <AddToCartButton
                    productId={product_id}
                    isSplit
                    buttonLabel="Each"
                  />
                </Box>
              )}
            </Box>
          </CardContent>
        </Box>

        {/* Compare button at bottom, centered */}
        {hasalternates && (
          <Box
            sx={{
              py: 0.5,
              textAlign: 'center',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              variant="text"
              sx={{
                ...compareButtonStyles,
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '0.6rem',
                textTransform: 'none',
                paddingX: 1,
                paddingY: 0.25,
                minHeight: 'auto',
                borderRadius: 2,
              }}
              onClick={() => onCompareClick?.(product_id)}
            >
              {compareLabel} {compareArrow}
            </Button>
          </Box>
        )}
      </Card>

      {/* ADD TO LIST DIALOG */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Select a List
        </DialogTitle>
        <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText
                      primary={list.list_name}
                      primaryTypographyProps={{
                        fontFamily: theme.typography.fontFamily,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert
              severity="success"
              sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
            >
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseListDialog}
            disabled={addingToList}
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* INVENTORY SUCCESS/ERROR SNACKBARS */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Toast/Snackbar for "Add to List" success */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default PHProductCard;
