// --- AddToCartButton.js ---
import React, { useContext, useState, useEffect } from 'react';
import { Button, IconButton, Typography, Box } from '@mui/material';
import { AddShoppingCart, Add, Remove, DeleteOutline } from '@mui/icons-material';
import { CartContext } from './CartContext';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * Props:
 *   productId (number)
 *   isSplit (bool)
 *   initialQuantity? (optional)
 *   buttonLabel (string)
 *   sx (object) -> optional style overrides
 *   onQuantityChange? (function) -> parent callback to signal quantity changed
 */
function AddToCartButton({
  productId,
  isSplit = false,
  initialQuantity,
  buttonLabel = 'Add',
  sx = {},
  onQuantityChange, // NEW: callback from parent
}) {
  const { cartItems, handleAddToCart } = useContext(CartContext);

  const [quantity, setQuantity] = useState(0);
  const [orderitem_id, setOrderitem_id] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ----------------------------------
  // On mount or cartItems change
  // ----------------------------------
  useEffect(() => {
    if (!cartItems || cartItems.length === 0) {
      setQuantity(0);
      setOrderitem_id(null);
      return;
    }
    // Look for the cart item that matches product_id + isSplit
    const foundItem = cartItems.find(
      (item) => item.product_id === productId && item.Split_Item === isSplit
    );
    if (foundItem) {
      setQuantity(foundItem.quantity);
      setOrderitem_id(foundItem.id);
    } else {
      setQuantity(0);
      setOrderitem_id(null);
    }
  }, [cartItems, productId, isSplit]);

  // ----------------------------------
  // Handle plus/minus/trash
  // ----------------------------------
  const handleAddClick = async (e) => {
    e.stopPropagation();
    const newQuantity = quantity + 1;
    
    // If handleAddToCart is async, we await it
    await handleAddToCart(productId, newQuantity, isSplit);

    // After successful update, signal parent
    if (onQuantityChange) {
      onQuantityChange();
    }
  };

  const handleDecreaseClick = async (e) => {
    e.stopPropagation();
    const newQuantity = quantity - 1;
    if (newQuantity < 0) return;

    await handleAddToCart(productId, newQuantity, isSplit);

    // Signal parent
    if (onQuantityChange) {
      onQuantityChange();
    }
  };

  // ----------------------------------
  // Renders the button content
  // ----------------------------------
  const renderContent = () => {
    if (quantity > 0) {
      // If quantity > 0, show plus/minus (or trash) row
      const MinusOrTrashIcon = quantity === 1 ? DeleteOutline : Remove;
      const minusOrTrashColor =
        quantity === 1
          ? theme.palette.error.main
          : theme.palette.primary.main;

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.primary.main}`,
            paddingX: theme.spacing(0.5),
            backgroundColor: 'white',
          }}
        >
          <IconButton
            onClick={handleDecreaseClick}
            size="small"
            sx={{
              color: minusOrTrashColor,
              padding: theme.spacing(0.5),
            }}
            aria-label="Decrease quantity"
          >
            <MinusOrTrashIcon fontSize="small" />
          </IconButton>

          <Typography
            variant="body2"
            sx={{
              marginX: theme.spacing(0.5),
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.75rem',
              minWidth: '20px',
              textAlign: 'center',
            }}
          >
            {quantity}
          </Typography>

          <IconButton
            onClick={handleAddClick}
            size="small"
            sx={{
              color: theme.palette.primary.main,
              padding: theme.spacing(0.5),
            }}
            aria-label="Increase quantity"
          >
            <Add fontSize="small" />
          </IconButton>
        </Box>
      );
    }

    // If quantity == 0, show a Button with icon + buttonLabel
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddShoppingCart />}
        onClick={handleAddClick}
        sx={{
          borderRadius: theme.shape.borderRadius,
          textTransform: 'none',
          whiteSpace: 'nowrap', 
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          fontFamily: theme.typography.fontFamily,
          fontSize: '0.75rem',
          padding: theme.spacing(0.75, 2),
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: theme.shadows[4],
          },
          minWidth: 'auto',
          width: 'auto',
          ...sx,
        }}
        aria-label="Add to Cart"
      >
        {buttonLabel}
      </Button>
    );
  };

  // ----------------------------------
  // Main Render
  // ----------------------------------
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {renderContent()}
    </Box>
  );
}

export default AddToCartButton;
