// src/components/OrderSummary.js
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function OrderSummary() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      setFetchError(null);
      try {
        // 1. Get user from localStorage
        const userData = localStorage.getItem('user');
        if (!userData) {
          throw new Error('No user data found in localStorage');
        }
        const user = JSON.parse(userData);

        // 2. Fetch orders for the logged-in user
        const response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_weeks_orders',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ user_id: user.id }),
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch orders');
        }

        const data = await response.json();

        // Sort descending by date_time_placed, or fallback to created_at
        data.sort((a, b) => {
          const dateA = b.date_time_placed ?? b.created_at; // prefer date_time_placed
          const dateB = a.date_time_placed ?? a.created_at;
          return dateA - dateB; // descending
        });

        setOrders(data);
      } catch (error) {
        setFetchError(error.message || 'Error fetching orders');
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Helper function to pick the best date field
  const getOrderDate = (order) => {
    // 1) If we have date_time_placed, use that
    if (order.date_time_placed) return new Date(order.date_time_placed);

    // 2) If we have date_placed (string), parse that
    if (order.date_placed) return new Date(order.date_placed);

    // 3) Fallback to created_at if the above are not valid
    if (order.created_at) return new Date(order.created_at);

    // Otherwise, return current date/time
    return new Date();
  };

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #ccc',
        borderRadius: 4,
        boxShadow: 0,
        backgroundColor: '#fff',
      }}
    >
      {/* 1) Loading state */}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '250px',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : /* 2) Error state */ fetchError ? (
        <Alert severity="error" sx={{ m: 2 }}>
          {`There was an error: ${fetchError}`}
        </Alert>
      ) : /* 3) No orders? */ orders.length === 0 ? (
        <Box
          sx={{
            textAlign: 'center',
            padding: theme.spacing(4),
            backgroundColor: alpha(theme.palette.info.light, 0.1),
            borderRadius: theme.shape.borderRadius,
            m: 2,
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Poppins, sans-serif',
              color: theme.palette.text.primary,
            }}
          >
            No Orders Yet
          </Typography>
          <Typography variant="body2" color="text.secondary">
            It looks like you haven’t placed your first order just yet.
            Explore our carefully curated products and discover great deals
            to keep your kitchen fully stocked.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/shop')}
            sx={{ mt: 2 }}
          >
            Browse Products
          </Button>
        </Box>
      ) : (
        /* 4) Normal "orders exist" view */
        <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
          <Typography
            variant="h6"
            sx={{
              color: '#131616',
              fontFamily: 'Poppins, Arial, sans-serif',
              fontWeight: 500,
              fontSize: '10pt',
              mb: 2,
            }}
          >
            Recent Orders
          </Typography>

          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 300,
              boxShadow: 'none',
              p: 0,
              flex: 1,
            }}
          >
            <Table
              stickyHeader
              sx={{
                '& .MuiTableCell-stickyHeader': {
                  backgroundColor: '#fff',
                },
              }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    '& th': {
                      backgroundColor: 'transparent',
                      padding: '4px 8px',
                    },
                  }}
                >
                  <TableCell
                    align="left"
                    sx={{
                      color: '#006D6D',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: '9pt',
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: '#006D6D',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: '9pt',
                    }}
                  >
                    Items
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color: '#006D6D',
                      fontFamily: 'Poppins, sans-serif',
                      fontWeight: 600,
                      fontSize: '9pt',
                    }}
                  >
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.slice(0, 8).map((order) => {
                  const orderDate = getOrderDate(order);

                  // Format the date to "Month Day, Year" (e.g., "January 14, 2025")
                  const formattedDate = orderDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  });

                  return (
                    <TableRow key={order.id}>
                      <TableCell
                        align="left"
                        sx={{
                          color: '#131616',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '10pt',
                          padding: '6px 8px',
                        }}
                      >
                        {formattedDate}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: '#131616',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '10pt',
                          padding: '6px 8px',
                        }}
                      >
                        {order.items}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color: '#131616',
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: '10pt',
                          padding: '6px 8px',
                        }}
                      >
                        ${order.total_amount}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      )}
    </Card>
  );
}

export default OrderSummary;
