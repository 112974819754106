import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
  useMediaQuery,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import axios from 'axios';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
// Removed: AccessTimeIcon, DescriptionIcon
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

// Added:
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import fallbackLogo from './assets/small-logo.avif';
import AddToCartButton from './AddToCartButton';

/** Helper: get auth & user from localStorage */
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }
  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

function ListProductCard({
  product,
  multiplier = 1,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  compareOpen = false,
  onCompareClick,
  showDelete = false,
  onDelete = () => {},
  listItemId = null,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // Destructure product fields
  const {
    product_id,
    short_description = '',
    brand = '',
    image_url,
    vendor_logo_url,
    ff_catalog_number = '',
    rpp_purchase_price = 0,
    rpp_case_priced_by = '',
    rpp_each_price = 0,
    rpp_each_priced_by = '',
    product_per_unit_price = 0,
    lead_time = 0,
    special_notes = '',
    bestdeal = null, // indicates "Best Price"
    hasalternates = false,
  } = product;

  // Favorites
  const isFavorited = !!favoritesMap[product_id];
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (isFavorited) {
      onRemoveFavorite?.(product_id);
    } else {
      onAddFavorite?.(product_id);
    }
  };

  // Add to List Dialog
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // **NEW**: we'll also show a Snackbar when the user adds to a list
  const [listAddToast, setListAddToast] = useState('');

  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };
  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      const resp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(resp.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        { list_item_name: '', product_id, lists_id: listId },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      // Also set the toast message
      setListAddToast('Item successfully added to the list!');

      setTimeout(() => handleCloseListDialog(), 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // Add to Inventory
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');

  const handleAddToInventoryClick = (e) => {
    e.stopPropagation();
    addToInventory();
  };

  async function addToInventory() {
    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id in user data.');
      }
      const data = {
        restaurant_id: user.restaurant_id,
        item_description: short_description,
        On_Hand: 1,
        PAR: 1,
        product_id,
      };
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );
      setAddInventorySuccess('Item successfully added to inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    }
  }

  // Compare styling
  const compareBorderStyle = compareOpen ? `2px solid ${theme.palette.grey[400]}` : 'none';
  const compareArrow = compareOpen ? '▲' : '▼';
  const defaultCompareColor = compareOpen ? '#006D6D' : '#949E98';
  const compareButtonStyles =
    bestdeal === false
      ? { color: '#fff', backgroundColor: '#F87906' }
      : { color: defaultCompareColor };
  const compareLabel = bestdeal === false ? 'Better Deals Available' : 'Compare';

  // Image fallback
  const [imageError, setImageError] = useState(false);
  const finalImageURL = imageError || !image_url ? fallbackLogo : image_url;
  const handleImageError2 = () => setImageError(true);

  // Card click => navigate
  const handleCardClick = () => {
    navigate(`/product/${product_id}`);
  };

  // Optional: delete item from list
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleDeleteIconClick = (e) => {
    e.stopPropagation();
    setConfirmDelete(true);
  };
  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    onDelete(listItemId);
    setConfirmDelete(false);
  };
  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setConfirmDelete(false);
  };

  // Pricing
  const purchasePrice = Number(rpp_purchase_price);
  const eachPrice = Number(rpp_each_price);

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'box-shadow 0.2s',
          backgroundColor: 'transparent',
          border: compareBorderStyle,
          '&:hover': {
            boxShadow: theme.shadows[4],
            backgroundColor: alpha(theme.palette.background.paper, 0.1),
          },
        }}
        onClick={handleCardClick}
      >
        {/* Vendor logo pinned top-right */}
        {vendor_logo_url && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              width: 40,
              height: 40,
              borderRadius: '50%',
              backgroundColor: '#fff',
              overflow: 'hidden',
              boxShadow: theme.shadows[2],
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={vendor_logo_url}
              alt="Vendor Logo"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = fallbackLogo;
              }}
            />
          </Box>
        )}

        {/* Fav / List / Inventory / optional Delete */}
        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(1),
            zIndex: 2,
            color: '#E92409',
            p: 0.5,
          }}
          onClick={handleFavoriteClick}
        >
          {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(4),
            zIndex: 2,
            color: '#006D6D',
            p: 0.5,
          }}
          onClick={handleOpenListDialog}
        >
          <ListAltIcon />
        </IconButton>

        <IconButton
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            left: theme.spacing(7),
            zIndex: 2,
            color: '#006D6D',
            p: 0.5,
          }}
          onClick={handleAddToInventoryClick}
        >
          <InventoryIcon />
        </IconButton>

        {showDelete && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              left: theme.spacing(10),
              zIndex: 2,
              display: 'flex',
              gap: 0.5,
            }}
          >
            {confirmDelete ? (
              <>
                <IconButton
                  sx={{
                    color: theme.palette.success.main,
                    p: 0.5,
                    backgroundColor: '#fff',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.success.main, 0.1),
                    },
                  }}
                  onClick={handleConfirmDelete}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton
                  sx={{
                    color: theme.palette.error.main,
                    p: 0.5,
                    backgroundColor: '#fff',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.error.main, 0.1),
                    },
                  }}
                  onClick={handleCancelDelete}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <IconButton
                sx={{
                  color: theme.palette.grey[700],
                  p: 0.5,
                }}
                onClick={handleDeleteIconClick}
              >
                <DeleteIcon fontSize="medium" />
              </IconButton>
            )}
          </Box>
        )}

        {/* Image */}
        <Box sx={{ position: 'relative', mt: 4 }}>
          <CardMedia
            component="img"
            height="80"
            image={finalImageURL}
            alt={short_description}
            loading="lazy"
            onError={handleImageError2}
            sx={{
              objectFit: 'contain',
              backgroundColor: imageError ? 'white' : 'transparent',
              borderRadius: theme.shape.borderRadius,
            }}
          />

          {/* Catalog number pinned top-left */}
          {ff_catalog_number && (
            <Box
              sx={{
                position: 'absolute',
                top: theme.spacing(0.5),
                left: theme.spacing(0.5),
                backgroundColor: 'white',
                padding: theme.spacing(0.125, 0.25),
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#949E98',
                  fontFamily: 'Poppins',
                  fontSize: '7pt',
                }}
              >
                {ff_catalog_number}
              </Typography>
            </Box>
          )}

          {/* lead_time / special_notes pinned bottom-right */}
          {(lead_time > 0 || (special_notes && !/no/i.test(special_notes))) && (
            <Box
              sx={{
                position: 'absolute',
                bottom: theme.spacing(1),
                right: theme.spacing(1),
                display: 'flex',
                gap: 0.5,
              }}
            >
              {lead_time > 0 && (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: theme.shadows[1],
                  }}
                >
                  <AvTimerIcon sx={{ fontSize: '1rem', color: '#F87906' }} />
                </Box>
              )}
              {special_notes && !/no/i.test(special_notes) && (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: theme.shadows[1],
                  }}
                >
                  <NoteAltIcon sx={{ fontSize: '1rem', color: '#F87906' }} />
                </Box>
              )}
            </Box>
          )}

          {/* "Best Price" label */}
          {bestdeal === true && (
            <Box
              sx={{
                position: 'absolute',
                bottom: theme.spacing(1),
                left: theme.spacing(1),
                backgroundColor: '#00BC48',
                display: 'inline-flex',
                alignItems: 'center',
                padding: '2px 8px',
                borderRadius: '4px',
                zIndex: 3,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#ffffff',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                }}
              >
                Best Price
              </Typography>
            </Box>
          )}
        </Box>

        <CardContent sx={{ p: 1, flex: '1 0 auto' }}>
          {/* BRAND LINE */}
          {brand && (
            <Typography
              variant="subtitle2"
              sx={{
                color: '#3A3F3F',
                fontWeight: theme.typography.subtitle1.fontWeight,
                fontFamily: 'Poppins',
                fontSize: '8pt',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                mb: 0.5,
              }}
            >
              {brand}
            </Typography>
          )}

          {/* short desc */}
          <Box sx={{ minHeight: 40, mb: 1 }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '9pt',
                color: '#131616',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {short_description}
            </Typography>
          </Box>

          {/* Pricing => CASE, then Each */}
          <Box onClick={(e) => e.stopPropagation()}>
            {/* CASE row */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: eachPrice > 0 ? 0.5 : 0,
              }}
            >
              <Box>
                {purchasePrice <= 0 ? (
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: '9pt',
                      color: '#006D6D',
                    }}
                  >
                    MARKET
                  </Typography>
                ) : (
                  <>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 'bold',
                        fontSize: '9pt',
                        color: '#006D6D',
                      }}
                    >
                      {`$${(purchasePrice * multiplier).toFixed(2)} ${
                        rpp_case_priced_by || 'CS'
                      }`}
                    </Typography>
                    {product_per_unit_price > 0 && (
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '7pt',
                          color: '#949E98',
                        }}
                      >
                        {`($${(product_per_unit_price * multiplier).toFixed(2)}/unit)`}
                      </Typography>
                    )}
                  </>
                )}
              </Box>
              <AddToCartButton productId={product_id} buttonLabel="Case" />
            </Box>

            {/* Each row => only if rpp_each_price > 0 */}
            {eachPrice > 0 && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontWeight: 'bold',
                      fontSize: '9pt',
                      color: '#006D6D',
                    }}
                  >
                    {`$${(eachPrice * multiplier).toFixed(2)} ${
                      rpp_each_priced_by || 'EA'
                    }`}
                  </Typography>
                </Box>
                <AddToCartButton productId={product_id} isSplit buttonLabel="Each" />
              </Box>
            )}
          </Box>
        </CardContent>

        {/* Compare button if hasalternates */}
        {hasalternates && (
          <Box
            sx={{ textAlign: 'center', py: 0.5 }}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              variant="text"
              sx={{
                ...compareButtonStyles,
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '0.6rem',
                textTransform: 'none',
                minHeight: 'auto',
                px: 1,
                py: 0.25,
                borderRadius: 2,
              }}
              onClick={() => onCompareClick?.(product_id)}
            >
              {compareLabel} {compareArrow}
            </Button>
          </Box>
        )}
      </Card>

      {/* Add to List Dialog */}
      <Dialog open={openAddListDialog} onClose={handleCloseListDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Select a List</DialogTitle>
        <DialogContent>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText primary={list.list_name} />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                  No lists available.
                </Typography>
              )}
            </List>
          )}

          {addListSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseListDialog} disabled={addingToList}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Inventory success/error */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{ width: '100%' }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Toast/Snackbar for "Add to List" success */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ListProductCard;
