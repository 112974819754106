import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputBase,
  FormGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme, styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';

// ---------------------
// STYLED COMPONENTS
// ---------------------
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1),
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function ListsFilterPanel({
  lists,
  selectedListId,
  setSelectedListId,
  categories,
  selectedCategory,
  setSelectedCategory,
  subcategories,
  selectedSubcategory,
  setSelectedSubcategory,
  vendors,
  selectedVendor,
  setSelectedVendor,
  selectedBrands,
  setSelectedBrands,
  clearAllFilters,

  // Mobile props
  isMobile,
  openCreateModal,
  viewMode,
  setViewMode,
  itemsPerPage,
  setItemsPerPage,
}) {
  const theme = useTheme();

  // ---------------------
  // ACCORDION STATE
  // ---------------------
  const [expandedPanel, setExpandedPanel] = useState(null);

  // ---------------------
  // SEARCH STATES
  // ---------------------
  const [listSearch, setListSearch] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  const [subcategorySearch, setSubcategorySearch] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');

  // ---------------------
  // FILTERED DATA
  // ---------------------
  const [filteredLists, setFilteredLists] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);

  // ---------------------
  // ACCORDION HANDLERS
  // ---------------------
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const handleListSelection = (listId) => {
    setSelectedListId(listId);
    setExpandedPanel(null);
  };

  const handleCategorySelection = (catId) => {
    if (catId === selectedCategory) {
      // unselect
      setSelectedCategory(0);
      setSelectedSubcategory(0);
    } else {
      setSelectedCategory(catId);
    }
    setExpandedPanel(null);
  };

  const handleSubcategorySelection = (subcatId) => {
    if (subcatId === selectedSubcategory) {
      setSelectedSubcategory(0);
    } else {
      setSelectedSubcategory(subcatId);
    }
    setExpandedPanel('vendor'); // auto-move to vendor panel
  };

  const handleVendorSelection = (vendorId) => {
    const numericId = Number(vendorId);
    if (numericId === selectedVendor) {
      setSelectedVendor(null);
    } else {
      setSelectedVendor(numericId);
    }
    setExpandedPanel(null);
  };

  // ---------------------
  // AUTO-ADVANCE ACCORDIONS
  // ---------------------
  useEffect(() => {
    if (selectedListId) {
      setExpandedPanel('category');
    }
  }, [selectedListId]);

  useEffect(() => {
    if (selectedCategory !== 0) {
      if (subcategories.length > 0) {
        setExpandedPanel('subcategory');
      } else {
        setExpandedPanel('vendor');
      }
    }
  }, [selectedCategory, subcategories]);

  useEffect(() => {
    if (selectedSubcategory !== 0) {
      setExpandedPanel('vendor');
    }
  }, [selectedSubcategory]);

  useEffect(() => {
    if (selectedVendor !== null) {
      setExpandedPanel(null);
    }
  }, [selectedVendor]);

  // ---------------------
  // CLEAR FILTERS
  // ---------------------
  const handleClearAll = () => {
    clearAllFilters();
    setExpandedPanel(null);
  };

  // ---------------------
  // SEARCH FILTER EFFECTS
  // ---------------------
  useEffect(() => {
    const lower = listSearch.toLowerCase();
    setFilteredLists(lists.filter((l) => l.list_name.toLowerCase().includes(lower)));
  }, [listSearch, lists]);

  useEffect(() => {
    const lower = categorySearch.toLowerCase();
    setFilteredCategories(
      categories.filter((c) => c.name.toLowerCase().includes(lower))
    );
  }, [categorySearch, categories]);

  useEffect(() => {
    const lower = subcategorySearch.toLowerCase();
    setFilteredSubcategories(
      subcategories.filter((sc) => sc.name.toLowerCase().includes(lower))
    );
  }, [subcategorySearch, subcategories]);

  // **KEY FIX**: check `_vendor.Short_Name` or `_vendor.vendor_short_name` 
  // (in your case, it's `_vendor.Short_Name` from the data).
  useEffect(() => {
    const lower = vendorSearch.toLowerCase();
    if (!vendors || vendors.length === 0) {
      setFilteredVendors([]);
      return;
    }
    setFilteredVendors(
      vendors.filter((v) => {
        // Each vendor is the `_vendor` object, e.g. { id:4, Short_Name:'US Foods', ... }
        const shortName = v.Short_Name || v.vendor_short_name || '';
        return shortName.toLowerCase().includes(lower);
      })
    );
  }, [vendorSearch, vendors]);

  // ---------------------
  // TEXT TRUNCATION
  // ---------------------
  const truncateText = (text = '', maxLength = 20) => {
    if (!text) return '';
    return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
  };

  // ---------------------
  // RENDER
  // ---------------------
  return (
    <Box>
      {/* MOBILE-ONLY UI CONTROLS */}
      {isMobile && (
        <Box sx={{ mb: 2 }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mb: 2,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
            onClick={openCreateModal}
          >
            Create a List
          </Button>

          <FormControl fullWidth size="small" sx={{ mb: 1 }}>
            <InputLabel>Items Per Page</InputLabel>
            <Select
              value={itemsPerPage}
              label="Items Per Page"
              onChange={(e) => setItemsPerPage(e.target.value)}
            >
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={48}>48</MenuItem>
              <MenuItem value={96}>96</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: '0.875rem',
                mr: 1,
              }}
            >
              View:
            </Typography>
            <IconButton onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}>
              {viewMode === 'grid' ? (
                <ViewListIcon fontSize="small" />
              ) : (
                <ViewModuleIcon fontSize="small" />
              )}
            </IconButton>
          </Box>
        </Box>
      )}

      {/* FILTER HEADER */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'light',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
          }}
        >
          Filter By
        </Typography>
        <Button
          variant="text"
          onClick={handleClearAll}
          sx={{
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: '0.875rem',
          }}
        >
          Clear All
        </Button>
      </Box>

      {/* LISTS FILTER */}
      <Accordion
        expanded={expandedPanel === 'lists'}
        onChange={handleAccordionChange('lists')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="lists-content"
          id="lists-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': { margin: 0 },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Lists
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Lists"
              aria-label="search lists"
              value={listSearch}
              onChange={(e) => setListSearch(e.target.value)}
            />
          </Search>

          <FormGroup>
            {filteredLists.map((list) => (
              <FormControlLabel
                key={list.id}
                control={
                  <Radio
                    checked={selectedListId === list.id}
                    onChange={() => handleListSelection(list.id)}
                    value={list.id.toString()}
                    name="list-selection"
                  />
                }
                label={truncateText(list.list_name)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
            {filteredLists.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  mt: 1,
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                }}
              >
                No lists found.
              </Typography>
            )}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* CATEGORY FILTER */}
      <Accordion
        expanded={expandedPanel === 'category'}
        onChange={handleAccordionChange('category')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="category-content"
          id="category-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': { margin: 0 },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Categories"
              aria-label="search categories"
              value={categorySearch}
              onChange={(e) => setCategorySearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredCategories.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Radio
                    checked={selectedCategory === category.id}
                    onChange={() => handleCategorySelection(category.id)}
                    value={category.id.toString()}
                    name="category-selection"
                  />
                }
                label={truncateText(category.name)}
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
            {filteredCategories.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  mt: 1,
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                }}
              >
                No categories found.
              </Typography>
            )}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* SUBCATEGORY FILTER */}
      {selectedCategory !== 0 && (
        <Accordion
          expanded={expandedPanel === 'subcategory'}
          onChange={handleAccordionChange('subcategory')}
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls="subcategory-content"
            id="subcategory-header"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              minHeight: '48px',
              '& .MuiAccordionSummary-content': { margin: 0 },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'regular',
                color: 'black',
                fontSize: '1rem',
              }}
            >
              Subcategory
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search Subcategories"
                aria-label="search subcategories"
                value={subcategorySearch}
                onChange={(e) => setSubcategorySearch(e.target.value)}
              />
            </Search>
            <FormGroup>
              {filteredSubcategories.map((subcat) => (
                <FormControlLabel
                  key={subcat.id}
                  control={
                    <Radio
                      checked={selectedSubcategory === subcat.id}
                      onChange={() => handleSubcategorySelection(subcat.id)}
                      value={subcat.id.toString()}
                      name="subcategory-selection"
                    />
                  }
                  label={truncateText(subcat.name)}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              ))}
              {filteredSubcategories.length === 0 && (
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                    mt: 1,
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.text.secondary,
                  }}
                >
                  No subcategories found.
                </Typography>
              )}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* VENDOR FILTER */}
      <Accordion
        expanded={expandedPanel === 'vendor'}
        onChange={handleAccordionChange('vendor')}
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="vendor-content"
          id="vendor-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px',
            '& .MuiAccordionSummary-content': { margin: 0 },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            Vendor
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Vendors"
              aria-label="search vendors"
              value={vendorSearch}
              onChange={(e) => setVendorSearch(e.target.value)}
            />
          </Search>

          <RadioGroup
            aria-label="vendor-selection"
            name="vendor-selection-group"
            value={selectedVendor !== null ? selectedVendor.toString() : ''}
            onChange={(e) => handleVendorSelection(e.target.value)}
          >
            {filteredVendors.map((vendor) => {
              // e.g. vendor = { id: 2, Short_Name: "PFG", ... }
              const vendorIdStr = vendor.id != null ? vendor.id.toString() : '';
              const label = vendor.Short_Name || vendor.vendor_short_name || 'Unknown Vendor';
              return (
                <FormControlLabel
                  key={vendorIdStr}
                  control={<Radio />}
                  label={truncateText(label, 25)}
                  value={vendorIdStr}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              );
            })}
            {filteredVendors.length === 0 && (
              <Typography
                variant="body2"
                sx={{
                  textAlign: 'center',
                  mt: 1,
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                }}
              >
                No vendors found.
              </Typography>
            )}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

ListsFilterPanel.propTypes = {
  lists: PropTypes.array.isRequired,
  selectedListId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedListId: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.number.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  subcategories: PropTypes.array.isRequired,
  selectedSubcategory: PropTypes.number.isRequired,
  setSelectedSubcategory: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  selectedVendor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSelectedVendor: PropTypes.func.isRequired,
  selectedBrands: PropTypes.array.isRequired,
  setSelectedBrands: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,

  isMobile: PropTypes.bool,
  openCreateModal: PropTypes.func,
  viewMode: PropTypes.string,
  setViewMode: PropTypes.func,
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
};

ListsFilterPanel.defaultProps = {
  isMobile: false,
  openCreateModal: () => {},
  viewMode: 'grid',
  setViewMode: () => {},
  itemsPerPage: 24,
  setItemsPerPage: () => {},
};

export default ListsFilterPanel;
