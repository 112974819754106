import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ListAlt as ListAltIcon,
  Inventory as InventoryIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  // Remove these two:
  // AccessTime as AccessTimeIcon,
  // Description as DescriptionIcon,
} from '@mui/icons-material';

// Added these two:
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import fallbackLogo from './assets/small-logo.avif';
import AddToCartButton from './AddToCartButton';

/** Helper: Retrieve auth headers and user from localStorage */
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');

  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }

  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };

  return { user, headers };
}

/**
 * ListListItem
 *
 * A row-based list item for use within Lists, but now using the EXACT same
 * desktop/mobile layout as your ProductListItem for the price section.
 *
 * - Favorites
 * - Add to List
 * - Add to Inventory
 * - Delete from list (trash icon)
 * - Compare (if hasalternates)
 * - Displays brand | pack_size | ff_catalog_number
 */
function ListListItem({
  product,
  listItemId = null, // the specific list_item.id
  multiplier = 1,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  compareOpen = false,
  onCompareClick,
  showDelete = false,
  onDelete = () => {},
  restaurantId = null, // needed for add-to-inventory if required
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // Destructure fields (renaming them to match ProductListItem’s logic)
  const {
    product_id,
    short_description = '',

    rpp_purchase_price = 0,
    rpp_case_priced_by = '',
    rpp_each_price = 0,
    rpp_each_priced_by = '',
    product_per_unit_price = 0,

    // brand, pack_size, ff_catalog_number => used to build brandLine
    brand = '',
    pack_size = '',
    ff_catalog_number = '',

    image_url,
    vendor_logo_url,
    lead_time,
    special_notes,
    bestdeal = null,
    hasalternates = false,

    timespurchased,
    lastdatepurchased,
  } = product;

  // ---- Build "brand line" in the same style as ProductListItem ----
  const rawBrand = brand === '0' ? '' : brand;
  const rawPackSize = pack_size === '0' ? '' : pack_size;
  const rawCatalog = ff_catalog_number === '0' ? '' : ff_catalog_number;
  const brandLineArr = [rawBrand, rawPackSize, rawCatalog].filter(Boolean);
  const brandLine = brandLineArr.join(' | ');

  // ---- Compare button styling (same as ProductListItem) ----
  const compareBorder = compareOpen ? `2px solid ${theme.palette.grey[400]}` : 'none';
  const compareArrow = compareOpen ? '▲' : '▼';
  // If bestdeal === false => orange button with white text; otherwise normal
  const compareButtonStyles =
    bestdeal === false
      ? { color: 'white', backgroundColor: '#F87906' }
      : { color: compareOpen ? '#006D6D' : '#949E98' };
  const compareButtonText =
    bestdeal === false ? 'Better Deals Available' : 'Compare';

  // ---- Favorites ----
  const isFavorited = !!(favoritesMap[product_id]?.length > 0);
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (isFavorited) onRemoveFavorite?.(product_id);
    else onAddFavorite?.(product_id);
  };

  // ---- Add to List ----
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // NEW: We'll also show a Snackbar (toast) after adding an item to a list
  const [listAddToast, setListAddToast] = useState('');

  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };
  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      const resp = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      if (Array.isArray(resp.data)) {
        setLists(resp.data);
      } else {
        setLists([]);
      }
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      // Also set our toast message
      setListAddToast('Item successfully added to the list!');

      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding item to list:', error);
      setAddListError('Failed to add item. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // ---- Add to Inventory ----
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryInProgress, setAddInventoryInProgress] = useState(false);

  const handleAddToInventoryClick = (e) => {
    e.stopPropagation();
    addToInventory();
  };

  async function addToInventory() {
    if (!restaurantId) {
      console.warn('No restaurantId to add to inventory.');
      setAddInventoryError('No restaurant selected.');
      return;
    }
    try {
      setAddInventoryInProgress(true);
      setAddInventorySuccess('');
      setAddInventoryError('');

      const { headers } = getAuthHeadersAndUser();
      const data = {
        restaurant_id: restaurantId,
        item_description: short_description,
        On_Hand: 1,
        PAR: 1,
        product_id,
      };
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );
      setAddInventorySuccess('Item added to inventory!');
    } catch (err) {
      console.error('Error adding to inventory:', err);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    } finally {
      setAddInventoryInProgress(false);
    }
  }

  // ---- Delete from the list ----
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDeleteIconClick = (e) => {
    e.stopPropagation();
    setConfirmDelete(true);
  };
  const handleConfirmDelete = (e) => {
    e.stopPropagation();
    if (listItemId) {
      onDelete(listItemId);
    }
    setConfirmDelete(false);
  };
  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setConfirmDelete(false);
  };

  // ---- Navigation to product page ----
  const handleRowClick = () => {
    navigate(`/product/${product_id}`);
  };

  // ---- Image fallback ----
  const [imageError, setImageError] = useState(false);
  const finalImageURL = imageError || !image_url ? fallbackLogo : image_url;
  const handleImageError2 = () => setImageError(true);

  // ---- "Simplified" price logic (same as ProductListItem) ----
  const numericPurchase = Number(rpp_purchase_price);
  const numericEach = Number(rpp_each_price);
  const numericPerUnit = Number(product_per_unit_price);

  // If purchase_price <= 0 => "MARKET"
  // else => $xx.xx + case_priced_by
  let casePrice = 'MARKET';
  let casePerUnit = null;

  if (numericPurchase > 0) {
    const numeric = (numericPurchase * multiplier).toFixed(2);
    const suffix = rpp_case_priced_by || 'CS';
    casePrice = `$${numeric} ${suffix}`;

    // If we have a non-zero per-unit price
    if (numericPerUnit > 0) {
      casePerUnit = `$${(numericPerUnit * multiplier).toFixed(2)}/unit`;
    }
  }

  // For “each”
  let eachLine = null;
  if (numericEach > 0) {
    const numericE = (numericEach * multiplier).toFixed(2);
    const suffixE = rpp_each_priced_by || 'EA';
    eachLine = `$${numericE} ${suffixE}`;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          padding: 1,
          position: 'relative',
          overflow: 'hidden',
          border: compareBorder,
          borderBottom: '1px solid #e0e0e0',
          cursor: 'pointer',
        }}
        onClick={handleRowClick}
      >
        {/* DESKTOP LAYOUT (unchanged) */}
        {!isMobile && (
          <>
            {/* Left Column => Product image */}
            <Box
              sx={{
                width: '15%',
                minWidth: 90,
                textAlign: 'center',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                src={finalImageURL}
                alt={short_description}
                onError={handleImageError2}
                sx={{
                  width: 80,
                  height: 80,
                  borderRadius: 1,
                  objectFit: 'contain',
                  backgroundColor: imageError ? 'white' : 'transparent',
                }}
              />

              {/* Vendor logo pinned top-right */}
              {vendor_logo_url && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '10%',
                    right: '10%',
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    overflow: 'hidden',
                    boxShadow: theme.shadows[2],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={vendor_logo_url}
                    alt="Vendor Logo"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = fallbackLogo;
                    }}
                  />
                </Box>
              )}

              {/* "Best Price" banner if bestdeal === true */}
              {bestdeal && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#00BC48',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    zIndex: 3,
                    margin: '4px',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ffffff',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '0.75rem',
                    }}
                  >
                    Best Price
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Middle Column => Short desc, brand line, icons */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: theme.spacing(1),
              }}
            >
              {/* Icons row */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0,
                  marginBottom: theme.spacing(0.5),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {/* Favorites */}
                <IconButton
                  onClick={handleFavoriteClick}
                  sx={{
                    color: '#E92409',
                    p: 0.5,
                    '&:hover': { color: alpha('#E92409', 0.7) },
                  }}
                >
                  {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>

                {/* Add to Another List */}
                <IconButton
                  onClick={handleOpenListDialog}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': { color: alpha('#006D6D', 0.7) },
                  }}
                >
                  <ListAltIcon />
                </IconButton>

                {/* Add to Inventory */}
                <IconButton
                  onClick={handleAddToInventoryClick}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': {
                      color: alpha(theme.palette.primary.main, 0.7),
                    },
                  }}
                >
                  <InventoryIcon />
                </IconButton>

                {/* Delete from this list */}
                {showDelete && (
                  <>
                    {confirmDelete ? (
                      <Box sx={{ display: 'flex', gap: 0.5 }}>
                        <IconButton
                          sx={{
                            color: theme.palette.success.main,
                            p: 0.5,
                            backgroundColor: '#fff',
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.success.main, 0.1),
                            },
                          }}
                          onClick={handleConfirmDelete}
                        >
                          <CheckIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          sx={{
                            color: theme.palette.error.main,
                            p: 0.5,
                            backgroundColor: '#fff',
                            '&:hover': {
                              backgroundColor: alpha(theme.palette.error.main, 0.1),
                            },
                          }}
                          onClick={handleCancelDelete}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ) : (
                      <IconButton
                        sx={{
                          color: theme.palette.grey[700],
                          p: 0.5,
                          backgroundColor: '#fff',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.grey[700], 0.1),
                          },
                        }}
                        onClick={handleDeleteIconClick}
                      >
                        <DeleteIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </>
                )}
              </Box>

              {/* Short description */}
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '9pt',
                  color: '#131616',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  paddingRight: theme.spacing(4),
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                {short_description || ''}
              </Typography>

              {/* brand line + lead_time + special_notes */}
              {brandLine && (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    color: '#3A3F3F',
                    mt: 0.25,
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: theme.spacing(4),
                  }}
                >
                  {brandLine}

                  {/* Where AccessTimeIcon was => AvTimerIcon */}
                  {lead_time > 0 && (
                    <Tooltip title={`Lead Time: ${lead_time}`}>
                      <AvTimerIcon
                        sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                      />
                    </Tooltip>
                  )}

                  {/* Where DescriptionIcon was => NoteAltIcon */}
                  {special_notes && special_notes !== 'No Special Flags' && (
                    <Tooltip title={special_notes}>
                      <NoteAltIcon
                        sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                      />
                    </Tooltip>
                  )}
                </Typography>
              )}

              {/* Times purchased / Last date purchased */}
              {(timespurchased || lastdatepurchased) && (
                <Typography
                  variant="caption"
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '7pt',
                    color: '#949E98',
                    mt: 0.3,
                  }}
                >
                  {timespurchased && `Purchased: ${timespurchased}x`}
                  {lastdatepurchased &&
                    ` | Last: ${new Date(lastdatepurchased).toLocaleDateString()}`}
                </Typography>
              )}

              {/* Compare button */}
              <Box sx={{ py: 0.5, textAlign: 'left' }}>
                {hasalternates ? (
                  <Button
                    variant="text"
                    sx={{
                      ...compareButtonStyles,
                      fontFamily: 'Poppins',
                      fontWeight: 600,
                      fontSize: '0.6rem',
                      textTransform: 'none',
                      px: 1,
                      py: 0.25,
                      minHeight: 'auto',
                      borderRadius: 2,
                      mt: 0.25,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onCompareClick?.(product_id);
                    }}
                  >
                    {compareButtonText} {compareArrow}
                  </Button>
                ) : (
                  <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
                    Compare ▼
                  </Box>
                )}
              </Box>
            </Box>

            {/* Right Column => Price lines + AddToCart */}
            <Box
              sx={{
                width: '45%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                paddingLeft: theme.spacing(1),
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {renderPriceSection()}
            </Box>
          </>
        )}

        {/* MOBILE layout (UPDATED to match ProductListItem exactly) */}
        {isMobile && (
          <Box sx={{ width: '100%', mt: 1 }}>
            {/* Product image section (same structure as ProductListItem mobile) */}
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                py: 1,
              }}
            >
              <Box
                component="img"
                src={finalImageURL}
                alt={short_description}
                onError={handleImageError2}
                sx={{
                  width: 90,
                  height: 90,
                  objectFit: 'cover',
                  borderRadius: 1,
                }}
              />

              {/* Vendor logo pinned on top-right */}
              {vendor_logo_url && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '15%',
                    right: 0,
                    width: 40,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: '#fff',
                    overflow: 'hidden',
                    boxShadow: theme.shadows[2],
                    zIndex: 2,
                    transform: 'translate(-25%, -50%)',
                  }}
                >
                  <Box
                    component="img"
                    src={vendor_logo_url}
                    alt="Vendor Logo"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = fallbackLogo;
                    }}
                  />
                </Box>
              )}

              {/* "Best Price" banner if bestdeal === true */}
              {bestdeal && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    backgroundColor: '#00BC48',
                    display: 'inline-flex',
                    alignItems: 'center',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    zIndex: 3,
                    margin: '4px',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ffffff',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '0.75rem',
                    }}
                  >
                    Best Price
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Icons row */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                marginBottom: theme.spacing(0.5),
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* Favorites */}
              <IconButton
                onClick={handleFavoriteClick}
                sx={{
                  color: '#E92409',
                  p: 0.5,
                  '&:hover': { color: alpha('#E92409', 0.7) },
                }}
              >
                {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>

              {/* Add to List */}
              <IconButton
                onClick={handleOpenListDialog}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': { color: alpha('#006D6D', 0.7) },
                }}
              >
                <ListAltIcon />
              </IconButton>

              {/* Add to Inventory */}
              <IconButton
                onClick={handleAddToInventoryClick}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': { color: alpha(theme.palette.primary.main, 0.7) },
                }}
              >
                <InventoryIcon />
              </IconButton>

              {/* Delete from this list */}
              {showDelete && (
                <>
                  {confirmDelete ? (
                    <Box sx={{ display: 'flex', gap: 0.5, ml: 1 }}>
                      <IconButton
                        sx={{
                          color: theme.palette.success.main,
                          p: 0.5,
                          backgroundColor: '#fff',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.success.main, 0.1),
                          },
                        }}
                        onClick={handleConfirmDelete}
                      >
                        <CheckIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        sx={{
                          color: theme.palette.error.main,
                          p: 0.5,
                          backgroundColor: '#fff',
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.error.main, 0.1),
                          },
                        }}
                        onClick={handleCancelDelete}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ) : (
                    <IconButton
                      sx={{
                        color: theme.palette.grey[700],
                        p: 0.5,
                        backgroundColor: '#fff',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.grey[700], 0.1),
                        },
                        ml: 1,
                      }}
                      onClick={handleDeleteIconClick}
                    >
                      <DeleteIcon fontSize="medium" />
                    </IconButton>
                  )}
                </>
              )}
            </Box>

            {/* Short description */}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '9pt',
                color: '#131616',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {short_description || ''}
            </Typography>

            {/* brand line + icons */}
            {brandLine && (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '8pt',
                  color: '#3A3F3F',
                  mt: 0.25,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {brandLine}

                {lead_time > 0 && (
                  <Tooltip title={`Lead Time: ${lead_time}`}>
                    {/* Replaced AccessTimeIcon with AvTimerIcon */}
                    <AvTimerIcon
                      sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                    />
                  </Tooltip>
                )}
                {special_notes && special_notes !== 'No Special Flags' && (
                  <Tooltip title={special_notes}>
                    {/* Replaced DescriptionIcon with NoteAltIcon */}
                    <NoteAltIcon
                      sx={{ fontSize: '1rem', color: '#F87906', ml: 0.5 }}
                    />
                  </Tooltip>
                )}
              </Typography>
            )}

            {/* Times purchased / Last date purchased */}
            {(timespurchased || lastdatepurchased) && (
              <Typography
                variant="caption"
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '7pt',
                  color: '#949E98',
                  mt: 0.3,
                }}
              >
                {timespurchased && `Purchased: ${timespurchased}x`}
                {lastdatepurchased &&
                  ` | Last: ${new Date(lastdatepurchased).toLocaleDateString()}`}
              </Typography>
            )}

            {/* Price lines + AddToCart, vertical stack */}
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              {renderPriceSection()}
            </Box>

            {/* Compare button, centered */}
            <Box
              sx={{ textAlign: 'center', mt: 1 }}
              onClick={(e) => e.stopPropagation()}
            >
              {hasalternates ? (
                <Button
                  variant="text"
                  sx={{
                    ...compareButtonStyles,
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    fontSize: '0.6rem',
                    textTransform: 'none',
                    px: 1,
                    py: 0.25,
                    minHeight: 'auto',
                    borderRadius: 2,
                  }}
                  onClick={() => onCompareClick?.(product_id)}
                >
                  {compareButtonText} {compareArrow}
                </Button>
              ) : (
                <Box sx={{ visibility: 'hidden', fontSize: '7pt' }}>
                  Compare ▼
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {/* Add to List Dialog */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Select a List</DialogTitle>
        <DialogContent>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText primary={list.list_name} />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography variant="body2" align="center">
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseListDialog} disabled={addingToList}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Inventory success/error snackbars */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{ width: '100%' }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Toast/Snackbar for "Add to List" success */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{ width: '100%' }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );

  /**
   * Renders the "Price lines + AddToCart" exactly as your ProductListItem does.
   */
  function renderPriceSection() {
    // If both case + each are effectively 0 => single MARKET line
    if (numericPurchase <= 0 && numericEach <= 0) {
      return (
        <Box>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              fontSize: '9pt',
              color: '#006D6D',
              mb: 0.5,
            }}
          >
            MARKET
          </Typography>
          <AddToCartButton productId={product_id} buttonLabel="Case" />
        </Box>
      );
    }

    return (
      <>
        {/* CASE row */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
          }}
        >
          <Box>
            {numericPurchase <= 0 ? (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 'bold',
                  fontSize: '9pt',
                  color: '#006D6D',
                }}
              >
                MARKET
              </Typography>
            ) : (
              <>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 'bold',
                    fontSize: '9pt',
                    color: '#006D6D',
                  }}
                >
                  {`$${(numericPurchase * multiplier).toFixed(2)} ${
                    rpp_case_priced_by || 'CS'
                  }`}
                </Typography>
                {numericPerUnit > 0 && (
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '7pt',
                      color: '#949E98',
                      ml: 1,
                    }}
                  >
                    {`($${(numericPerUnit * multiplier).toFixed(2)}/unit)`}
                  </Typography>
                )}
              </>
            )}
          </Box>
          <AddToCartButton
            productId={product_id}
            isSplit={false}
            buttonLabel="Case"
            sx={{ width: 'auto', minWidth: 'unset' }}
          />
        </Box>

        {/* If we have eachLine => show an each row */}
        {eachLine && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <Typography
              component="span"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                fontSize: '9pt',
                color: '#006D6D',
              }}
            >
              {eachLine}
            </Typography>
            <AddToCartButton
              productId={product_id}
              isSplit
              buttonLabel="Each"
              sx={{ width: 'auto', minWidth: 'unset' }}
            />
          </Box>
        )}
      </>
    );
  }
}

export default ListListItem;
