// src/components/SpendSummary.js
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  Menu,
  Tooltip,
} from '@mui/material';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Line,
} from 'recharts';
import { alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/** 
 * Helper: format currency 
 */
const formatCurrency = (amount) => {
  if (typeof amount !== 'number') return '-';
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
};

/** 
 * Helper: "YYYY-MM" -> short month label ("Jan", "Feb", etc.)
 */
const formatMonthAbbrev = (yearMonth) => {
  const [year, month] = yearMonth.split('-');
  const date = new Date(Number(year), Number(month) - 1);
  return date.toLocaleString('default', { month: 'short' });
};

/** 
 * Helper: parse "YYYY-MM" => new Date(year, monthIndex, 1)
 */
function parseYearMonth(yearMonth) {
  const [y, m] = yearMonth.split('-');
  return new Date(Number(y), Number(m) - 1, 1);
}

/**
 * Grab the entire user object from localStorage. We'll want
 * both user.id (for get_financial_info_3) and user.restaurant_id
 * (for add/edit restaurant sales).
 */
function getUser() {
  const userJson = localStorage.getItem('user');
  if (!userJson) {
    throw new Error('No "user" in local storage.');
  }
  let user;
  try {
    user = JSON.parse(userJson);
  } catch (error) {
    throw new Error(`Failed to parse "user" JSON: ${error}`);
  }
  if (!user || user.id == null) {
    throw new Error('User object is missing "id".');
  }
  if (user.restaurant_id == null) {
    throw new Error('User object is missing "restaurant_id".');
  }
  return user;
}

// Colors used for each series:
const SALES_COLOR = (theme) => theme.palette.primary.main; // Food Sales
const SPEND_COLOR = '#999';                               // Food Spend
const COST_PCT_COLOR = '#006D6D';                         // Food Cost %

const SpendSummary = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Combined data => each item includes:
  // month_year, total_order_amount, total_revenue, food_cost_percent, restaurant_sales_id, beforeregistration
  const [financialData, setFinancialData] = useState([]);
  const [spendLoading, setSpendLoading] = useState(true);
  const [spendError, setSpendError] = useState(null);

  // Final data for the chart
  const [chartData, setChartData] = useState([]);

  // "Edit Revenue" modal
  const [openRevenueModal, setOpenRevenueModal] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [revenueInput, setRevenueInput] = useState('');
  const [foodCostInput, setFoodCostInput] = useState('');
  // We'll keep track of an existing ID if it exists
  const [existingSalesId, setExistingSalesId] = useState(null);
  // Track whether it's before registration or not, to disable the food cost field
  const [beforeRegistration, setBeforeRegistration] = useState(true);

  // Toggles to show/hide bars/line in the chart
  const [showFoodSales, setShowFoodSales] = useState(true);
  const [showFoodSpend, setShowFoodSpend] = useState(true);
  const [showFoodCostPct, setShowFoodCostPct] = useState(true);

  // "Time" dropdown
  const [timeMenuAnchor, setTimeMenuAnchor] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(12); // default 12 months

  /**
   * Fetch from the new combined endpoint => get_financial_info_3
   * using the user.id from localStorage
   */
  const fetchFinancialData = useCallback(async () => {
    setSpendLoading(true);
    setSpendError(null);
    try {
      const user = getUser();
      const userId = user.id;
      console.log('Fetching combined financial data for user_id:', userId);

      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_financial_info_3',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ user_id: userId }),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`get_financial_info_3 failed: ${errorText}`);
      }

      const data = await response.json();
      if (!Array.isArray(data)) {
        throw new Error('Expected an array from get_financial_info_3');
      }

      // Map to your chart shape
      const mapped = data.map((item) => {
        const spendVal = parseFloat(item.total_order_amount) || 0;
        const revenueVal = parseFloat(item.total_revenue) || 0;
        const fcPct = parseFloat(item.food_cost_percent); // e.g. 0.28 => 28%

        let foodCostPercent = null;
        if (!isNaN(fcPct)) {
          foodCostPercent = fcPct * 100; // 0.28 -> 28
        }

        return {
          month_year: item.month_year,
          name: formatMonthAbbrev(item.month_year),
          revenue: revenueVal,
          spend: spendVal,
          costUsed: spendVal,
          foodCostPercent,
          restaurant_sales_id: item.restaurant_sales_id || null,
          beforeregistration: item.beforeregistration,
        };
      });

      setFinancialData(mapped);
    } catch (err) {
      console.error('Error fetching financial data:', err);
      setSpendError(err.message || 'Error loading financial data.');
    } finally {
      setSpendLoading(false);
    }
  }, []);

  // On mount, fetch
  useEffect(() => {
    fetchFinancialData();
  }, [fetchFinancialData]);

  /**
   *  Build X-month chart data from "now".
   */
  useEffect(() => {
    if (!spendLoading && !spendError && financialData.length > 0) {
      const now = new Date();
      const baseYear = now.getFullYear();
      const baseMonth = now.getMonth(); // 0-based

      const monthsArr = [];
      for (let i = selectedTimeFrame - 1; i >= 0; i--) {
        const d = new Date(baseYear, baseMonth - i, 1);
        const y = d.getFullYear();
        const m = String(d.getMonth() + 1).padStart(2, '0');
        monthsArr.push(`${y}-${m}`);
      }

      const merged = monthsArr.map((ym) => {
        const match = financialData.find((f) => f.month_year === ym);
        if (match) {
          return { ...match };
        }
        // fallback
        return {
          month_year: ym,
          name: formatMonthAbbrev(ym),
          revenue: 0,
          spend: 0,
          costUsed: 0,
          foodCostPercent: null,
          restaurant_sales_id: null,
          beforeregistration: true, // default if not found
        };
      });

      setChartData(merged);
    }
  }, [financialData, spendLoading, spendError, selectedTimeFrame]);

  /**
   * Populate the "Revenue/FoodCost" fields & track existing ID if user picks a month.
   */
  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const paddedMonth = String(selectedMonth).padStart(2, '0');
      const yearMonth = `${selectedYear}-${paddedMonth}`;

      const match = financialData.find((f) => f.month_year === yearMonth);
      if (match) {
        setRevenueInput(String(match.revenue || ''));
        setFoodCostInput(String(match.spend || ''));
        setExistingSalesId(match.restaurant_sales_id);
        setBeforeRegistration(match.beforeregistration);
      } else {
        setRevenueInput('');
        setFoodCostInput('');
        setExistingSalesId(null);
        setBeforeRegistration(true);
      }
    }
  }, [selectedYear, selectedMonth, financialData]);

  /**
   * Open/close the "Edit" modal
   */
  const handleOpenRevenueModal = () => {
    // Clear prior state
    setSelectedYear('');
    setSelectedMonth('');
    setRevenueInput('');
    setFoodCostInput('');
    setExistingSalesId(null);
    setBeforeRegistration(true);
    setOpenRevenueModal(true);
  };
  const handleCloseRevenueModal = () => {
    setOpenRevenueModal(false);
  };

  /**
   * Submit => PATCH if we have an existing ID, POST otherwise
   */
  const handleSubmitRevenue = async () => {
    try {
      const rev = parseFloat(revenueInput);
      if (isNaN(rev) || rev < 0) throw new Error('Invalid revenue input');

      // If it's after registration, we do NOT allow changes to the food cost,
      // but we still have to parse it for the final payload so Xano doesn’t break
      const fc = parseFloat(foodCostInput);
      if (isNaN(fc) || fc < 0) throw new Error('Invalid food cost input');

      if (!selectedYear || !selectedMonth) {
        throw new Error('Please pick a valid month/year');
      }

      // Convert year+month => timestamp for "applicable_date"
      const yNum = +selectedYear;
      const mNum = +selectedMonth - 1;
      const dateObj = new Date(yNum, mNum, 1, 0, 0, 0);
      const dateValue = dateObj.getTime();

      // Get user from local storage to retrieve restaurant_id
      const user = getUser();

      let response;
      if (existingSalesId) {
        // We have a prior record => PATCH
        response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/edit_historical_data',
          {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              restaurant_sales_id: existingSalesId,
              revenue: rev,
              food_costs: beforeRegistration ? fc : fc,
              // We pass the same fc to the backend; the user just can’t edit it in the UI if false
            }),
          }
        );
      } else {
        // No ID => create new
        response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_sales',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              restaurant_id: user.restaurant_id,
              revenue: rev,
              food_costs: fc,
              applicable_date: dateValue,
            }),
          }
        );
      }

      if (!response.ok) {
        const errTxt = await response.text();
        throw new Error(`Failed to save data: ${errTxt}`);
      }

      console.log('Revenue & Food Cost saved!');
      handleCloseRevenueModal();
      await fetchFinancialData();
    } catch (error) {
      alert(`Failed to submit data: ${error.message}`);
    }
  };

  /**
   * Build year/month dropdown logic for the last 12 months
   */
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const earliest = new Date(now.getFullYear(), now.getMonth() - 11, 1);
  const earliestYear = earliest.getFullYear();
  const earliestMonth = earliest.getMonth() + 1;

  const yearOptions = [];
  for (let y = earliestYear; y <= currentYear; y++) {
    yearOptions.push(y);
  }

  const allMonthOptions = [
    { value: '01', label: 'Jan' },
    { value: '02', label: 'Feb' },
    { value: '03', label: 'Mar' },
    { value: '04', label: 'Apr' },
    { value: '05', label: 'May' },
    { value: '06', label: 'Jun' },
    { value: '07', label: 'Jul' },
    { value: '08', label: 'Aug' },
    { value: '09', label: 'Sep' },
    { value: '10', label: 'Oct' },
    { value: '11', label: 'Nov' },
    { value: '12', label: 'Dec' },
  ];

  let monthOptions = [];
  if (selectedYear) {
    const numericYear = +selectedYear;
    if (numericYear < earliestYear || numericYear > currentYear) {
      monthOptions = [];
    } else if (numericYear > earliestYear && numericYear < currentYear) {
      monthOptions = allMonthOptions;
    } else if (numericYear === earliestYear && numericYear < currentYear) {
      monthOptions = allMonthOptions.filter((m) => +m.value >= earliestMonth);
    } else if (numericYear === currentYear && numericYear > earliestYear) {
      monthOptions = allMonthOptions.filter((m) => +m.value <= currentMonth);
    } else if (numericYear === currentYear && numericYear === earliestYear) {
      monthOptions = allMonthOptions.filter(
        (m) => +m.value >= earliestMonth && +m.value <= currentMonth
      );
    }
  }

  // Style function for the “Time” and “Edit” buttons at the top
  const topButtonStyle = {
    ...theme.typography.button,
    fontSize: '0.625rem',
    px: 1,
    py: 0.3,
    size: 'small',
    border: '1px solid #e0e0e0',
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  };

  // Style function for the legend toggles below the chart
  const legendButtonStyle = (isActive, color) => ({
    textTransform: 'none',
    backgroundColor: isActive ? color : '#fff',
    color: isActive ? '#fff' : color,
    border: `1px solid ${color}`,
    borderRadius: '9999px',
    padding: '4px 12px',
    fontSize: '0.75rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: isActive ? color : alpha(color, 0.1),
    },
  });

  // Check if everything is zero => "Financials Coming Soon"
  const isAllZero =
    chartData.length > 0 &&
    chartData.every((c) => c.revenue === 0 && c.costUsed === 0);

  // Time menu handlers
  const handleTimeButtonClick = (event) => {
    setTimeMenuAnchor(event.currentTarget);
  };
  const handleTimeMenuClose = () => {
    setTimeMenuAnchor(null);
  };
  const handleTimeFrameSelect = (months) => {
    setSelectedTimeFrame(months);
    setTimeMenuAnchor(null);
  };

  return (
    <>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          backgroundColor: theme.palette.background.paper,
          boxShadow: 0,
          borderRadius: theme.shape.borderRadius,
          border: '1px solid #ccc',
        }}
      >
        {/* 1) Loading */}
        {spendLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '250px',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : /* 2) Error */ spendError ? (
          <Alert severity="error" sx={{ m: 2 }}>
            {`There was an error: ${spendError}. Please try again later.`}
          </Alert>
        ) : /* 3) Empty / All Zero */ chartData.length === 0 || isAllZero ? (
          <Box
            sx={{
              textAlign: 'center',
              padding: theme.spacing(4),
              backgroundColor: alpha(theme.palette.info.light, 0.1),
              borderRadius: theme.shape.borderRadius,
              m: 2, // margin around the box to match style
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: 'Poppins, sans-serif',
                color: theme.palette.text.primary,
              }}
            >
              Financials Coming Soon
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ maxWidth: 320, margin: '0 auto' }}
            >
              No data for the last {selectedTimeFrame} months. Start by adding
              a few months of revenue and food cost history to see how your
              financials are trending.
            </Typography>
            <Button
              variant="contained"
              sx={{
                mt: 2,
                backgroundColor: '#0E9C9B',
                '&:hover': {
                  backgroundColor: '#005E5E',
                },
              }}
              onClick={handleOpenRevenueModal}
            >
              Add Your History
            </Button>
          </Box>
        ) : (
          /* 4) Chart & normal view */
          <CardContent sx={{ p: theme.spacing(2) }}>
            {/* Top row with title + "Time" and "Edit" buttons */}
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 2,
              }}
            >
              <Typography
                sx={{
                  color: '#131616',
                  fontFamily: 'Poppins, Arial, sans-serif',
                  fontWeight: 500,    // "Medium"
                  fontSize: '10pt',   // Directly using 10pt
                  mb: { xs: 1, md: 0 },
                }}
              >
                Financials
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  alignItems: 'center',
                }}
              >
                <Button size="small" onClick={handleTimeButtonClick} sx={topButtonStyle}>
                  Time
                </Button>
                <Menu
                  anchorEl={timeMenuAnchor}
                  open={Boolean(timeMenuAnchor)}
                  onClose={handleTimeMenuClose}
                >
                  {[3, 6, 9, 12].map((num) => (
                    <MenuItem key={num} onClick={() => handleTimeFrameSelect(num)}>
                      {num} Months
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={handleOpenRevenueModal}
                  sx={{
                    ...theme.typography.button,
                    fontSize: '0.625rem',
                    px: 1,
                    py: 0.3,
                    size: 'small',
                  }}
                >
                  Edit
                </Button>
              </Box>
            </Box>

            {/* Chart Container */}
            <Box sx={{ width: '100%', height: 250 }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={chartData}
                  margin={{ top: 20, right: 0, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    tick={{
                      fill: theme.palette.text.primary,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '6pt',
                      fontWeight: 600,
                    }}
                  />
                  <YAxis
                    yAxisId="left"
                    tick={{
                      fill: theme.palette.text.primary,
                      fontFamily: theme.typography.fontFamily,
                      fontWeight: 600,
                      fontSize: '7pt',
                    }}
                    tickFormatter={(val) => `$${(val / 1000).toFixed(0)}k`}
                  />
                  {/**
                   * Conditionally render the right-hand Y-axis ONLY when
                   * showFoodCostPct is true.
                   */}
                  {showFoodCostPct && (
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      tick={{
                        fill: theme.palette.text.primary,
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 600,
                        fontSize: '7pt',
                      }}
                      domain={[
                        0,
                        () => {
                          // Dynamically compute a safe max for the % line
                          const values = chartData.map((d) => d.foodCostPercent || 0);
                          const maxVal = values.length ? Math.max(...values) : 0;
                          const domainCeil = Math.ceil(maxVal / 5) * 5;
                          return Math.max(domainCeil, 5);
                        },
                      ]}
                      tickFormatter={(val) => `${val}%`}
                    />
                  )}
                  <RechartsTooltip
                    formatter={(value, name) => {
                      if (name === 'Food Sales') {
                        return formatCurrency(value);
                      }
                      if (name === 'Food Spend') {
                        return formatCurrency(value);
                      }
                      if (name === 'Food Cost %') {
                        return `${value.toFixed(1)}%`;
                      }
                      return value;
                    }}
                  />
                  {/* Sales Bar */}
                  {showFoodSales && (
                    <Bar
                      yAxisId="left"
                      dataKey="revenue"
                      fill={SALES_COLOR(theme)}
                      name="Food Sales"
                      barSize={20}
                    />
                  )}
                  {/* Spend Bar */}
                  {showFoodSpend && (
                    <Bar
                      yAxisId="left"
                      dataKey="costUsed"
                      fill={SPEND_COLOR}
                      name="Food Spend"
                      barSize={20}
                    />
                  )}
                  {/* Cost % Line */}
                  {showFoodCostPct && (
                    <Line
                      yAxisId="right"
                      dataKey="foodCostPercent"
                      name="Food Cost %"
                      stroke={COST_PCT_COLOR}
                      strokeWidth={3}
                      dot={{ r: 4 }}
                      activeDot={{ r: 6 }}
                      connectNulls
                      type="monotone"
                    />
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            </Box>

            {/* "Legend" toggles below the chart */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                mt: 2,
                flexWrap: 'wrap',
              }}
            >
              <Button
                onClick={() => setShowFoodSales((prev) => !prev)}
                sx={legendButtonStyle(showFoodSales, SALES_COLOR(theme))}
              >
                Food Sales
              </Button>
              <Button
                onClick={() => setShowFoodSpend((prev) => !prev)}
                sx={legendButtonStyle(showFoodSpend, SPEND_COLOR)}
              >
                Food Spend
              </Button>
              <Button
                onClick={() => setShowFoodCostPct((prev) => !prev)}
                sx={legendButtonStyle(showFoodCostPct, COST_PCT_COLOR)}
              >
                Cost %
              </Button>
            </Box>
          </CardContent>
        )}
      </Card>

      {/* "Edit" Modal */}
      <Dialog open={openRevenueModal} onClose={handleCloseRevenueModal} fullWidth>
        <DialogTitle>Log Your Sales</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Select a month/year and enter (or edit) your food sales &amp; food cost.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
            <FormControl sx={{ minWidth: 100, flex: 1 }}>
              <InputLabel id="year-select">Year</InputLabel>
              <Select
                labelId="year-select"
                label="Year"
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(e.target.value);
                  setSelectedMonth('');
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {yearOptions.map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 100, flex: 1 }}>
              <InputLabel id="month-select">Month</InputLabel>
              <Select
                labelId="month-select"
                label="Month"
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                disabled={!selectedYear}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {monthOptions.map((m) => (
                  <MenuItem key={m.value} value={m.value}>
                    {m.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {selectedYear && selectedMonth ? (
            <Box>
              <TextField
                fullWidth
                label="Food Sales (USD)"
                type="number"
                margin="normal"
                value={revenueInput}
                onChange={(e) => setRevenueInput(e.target.value)}
                sx={{ ...theme.typography.body1 }}
              />

              {/* If beforeRegistration is false => disable editing & show tooltip. */}
              <Tooltip
                title={
                  beforeRegistration
                    ? ''
                    : 'This is the cost of what you’ve purchased through Farefood. You can’t edit it.'
                }
                arrow
              >
                <TextField
                  fullWidth
                  label="Food Cost (USD)"
                  type="number"
                  margin="normal"
                  value={foodCostInput}
                  onChange={(e) => setFoodCostInput(e.target.value)}
                  sx={{
                    ...theme.typography.body1,
                    backgroundColor: beforeRegistration ? 'transparent' : '#f0f0f0',
                  }}
                  disabled={!beforeRegistration}
                />
              </Tooltip>
            </Box>
          ) : (
            <Typography variant="body2" color="text.secondary">
              Choose a valid month/year first.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRevenueModal} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitRevenue}
            variant="contained"
            color="primary"
            disabled={!selectedYear || !selectedMonth}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SpendSummary;
