import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  Alert,
  CircularProgress,
  Snackbar,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ListAlt as ListAltIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import AddToCartButton from './AddToCartButton';
import fallbackLogo from './assets/small-logo.avif';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

// Helper: get auth
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');

  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }

  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };

  return { user, headers };
}

// Helper to parse "MARKET" vs. "Price + Unit" (like '$15.00 CS')
function parsePriceWithUnit(priceString) {
  if (!priceString || priceString === 'MARKET') {
    return { price: 'MARKET', unit: '' };
  }
  const parts = priceString.split(' ');
  if (parts.length > 1) {
    return { price: parts[0], unit: parts.slice(1).join(' ') };
  }
  return { price: priceString, unit: '' };
}

// A small subcomponent to display the "PriceLine"
function PriceLine({ mainPrice, perUnitPrice }) {
  const theme = useTheme();
  const { price, unit } = parsePriceWithUnit(mainPrice);

  if (price === 'MARKET') {
    return (
      <Typography
        component="span"
        sx={{
          color: '#006D6D',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '9pt',
        }}
      >
        MARKET
      </Typography>
    );
  }

  return (
    <Box component="span">
      <Typography
        component="span"
        sx={{
          color: '#006D6D',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '9pt',
        }}
      >
        {price}
      </Typography>
      {unit && (
        <Typography
          component="span"
          sx={{
            color: '#949E98',
            fontFamily: 'Poppins',
            fontSize: '8pt',
            marginLeft: '4px',
          }}
        >
          {unit}
        </Typography>
      )}
      {perUnitPrice && (
        <Typography
          component="span"
          sx={{
            color: '#949E98',
            fontFamily: 'Poppins',
            fontSize: '7pt',
            marginLeft: '6px',
          }}
        >
          ({perUnitPrice})
        </Typography>
      )}
    </Box>
  );
}

/**
 * PHListItem
 */
function PHListItem({
  product,
  multiplier,
  compareOpen = false,
  onCompareClick,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ---------------------------
  // Destructure product fields
  // ---------------------------
  const {
    product_id,
    short_description = '',
    brand = '',
    pack_size = '',
    ff_catalog_number = '',
    image_url,
    vendor_logo_url,
    rpp_purchase_price,    // case price
    rpp_case_priced_by,
    rpp_each_price,        // each price
    rpp_each_priced_by,
    product_per_unit_price,
    lead_time,
    special_notes,
    timespurchased,
    lastdatepurchased,
    hasalternates = false,
    bestdeal = null,
  } = product;

  // ---------------------------
  // Compare highlight logic
  // ---------------------------
  const compareBorderStyle = compareOpen
    ? `2px solid ${theme.palette.grey[400]}`
    : 'none';
  const compareArrow = compareOpen ? '▲' : '▼';
  const compareButtonColor = compareOpen ? '#006D6D' : '#949E98';

  const compareButtonStyles =
    bestdeal === false
      ? { color: '#fff', backgroundColor: '#F87906' }
      : { color: compareButtonColor };

  const compareLabel = bestdeal === false ? 'Better Deals Available' : 'Compare';

  // ---------------------------
  // Favorites
  // ---------------------------
  const isFavorited = !!favoritesMap[product_id];
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (isFavorited) {
      onRemoveFavorite(product_id);
    } else {
      onAddFavorite(product_id);
    }
  };

  // ---------------------------
  // Navigation
  // ---------------------------
  const handleRowClick = () => {
    navigate(`/product/${product_id}`);
  };

  // ---------------------------
  // Add to List
  // ---------------------------
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  // **NEW**: We'll show a Snackbar toast after adding to a list
  const [listAddToast, setListAddToast] = useState('');

  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };

  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');

    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');

    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');

      // **NEW**: also set toast
      setListAddToast('Item successfully added to the list!');

      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // ---------------------------
  // Add to Inventory
  // ---------------------------
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryInProgress, setAddInventoryInProgress] = useState(false);

  const handleAddToInventoryClick = async (e) => {
    e.stopPropagation();

    try {
      setAddInventoryInProgress(true);
      setAddInventorySuccess('');
      setAddInventoryError('');

      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }

      const data = {
        restaurant_id: user.restaurant_id,
        item_description: short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id,
      };

      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );

      setAddInventorySuccess('Item successfully added to inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    } finally {
      setAddInventoryInProgress(false);
    }
  };

  // ---------------------------
  // Price formatting
  // ---------------------------
  function formatCasePrice() {
    if (rpp_purchase_price > 0) {
      return `$${(rpp_purchase_price * multiplier).toFixed(2)} ${
        rpp_case_priced_by || 'CS'
      }`;
    }
    if (rpp_purchase_price === 0 && rpp_case_priced_by) {
      return 'MARKET';
    }
    return null;
  }

  function formatCasePricePerUnit() {
    if (!product_per_unit_price || product_per_unit_price === 0) return null;
    const ppu = (product_per_unit_price * multiplier).toFixed(2);
    return `$${ppu}/unit`;
  }

  function formatEachPrice() {
    if (rpp_each_price > 0) {
      return `$${(rpp_each_price * multiplier).toFixed(2)} ${
        rpp_each_priced_by || 'EA'
      }`;
    }
    if (rpp_each_price === 0 && rpp_each_priced_by) {
      return 'MARKET';
    }
    return null;
  }

  // If both case & each are zero => single "MARKET"
  const hasCasePrice = !!formatCasePrice();
  const hasEachPrice = !!formatEachPrice();
  const bothZeroNoUnits = !hasCasePrice && !hasEachPrice;

  // ---------------------------
  // brand | pack_size | ff_catalog_number
  // ---------------------------
  const brandLineItems = [];
  if (brand && brand !== '0') {
    brandLineItems.push(brand);
  }
  if (pack_size && pack_size !== '0') {
    brandLineItems.push(pack_size);
  }
  if (ff_catalog_number && ff_catalog_number !== '0') {
    brandLineItems.push(ff_catalog_number);
  }
  const brandLine = brandLineItems.join(' | ');

  // final container border
  const finalBorder = compareOpen ? compareBorderStyle : 'none';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          padding: 1,
          position: 'relative',
          overflow: 'hidden',
          border: finalBorder, // highlight border if compareOpen
          cursor: 'pointer',
          borderBottom: '1px solid #e0e0e0', // optional bottom border
        }}
        onClick={handleRowClick}
      >
        {/* Product Image & Vendor Logo */}
        <Box
          sx={{
            width: { xs: '100%', sm: '15%' },
            textAlign: 'center',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: isMobile ? 1 : 0,
          }}
        >
          {/* Product Image */}
          <Box
            component="img"
            src={image_url || fallbackLogo}
            alt={short_description || 'Product'}
            sx={{
              width: isMobile ? 90 : 60,
              height: isMobile ? 90 : 60,
              objectFit: 'cover',
              borderRadius: 1,
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = fallbackLogo;
            }}
          />

          {/* Vendor Logo pinned top-right */}
          {vendor_logo_url && (
            <Box
              sx={{
                position: 'absolute',
                top: '15%',
                right: 0,
                width: 40,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#fff',
                overflow: 'hidden',
                boxShadow: theme.shadows[2],
                zIndex: 2,
                transform: 'translate(-25%, -50%)',
              }}
            >
              <Box
                component="img"
                src={vendor_logo_url}
                alt="Vendor Logo"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = fallbackLogo;
                }}
              />
            </Box>
          )}

          {/* Best Deal Banner */}
          {bestdeal === true && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                backgroundColor: '#00BC48',
                display: 'inline-flex',
                alignItems: 'center',
                padding: '2px 8px',
                borderRadius: '4px',
                zIndex: 3,
                margin: '4px',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#ffffff',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '0.75rem',
                  fontFamily: 'Poppins',
                }}
              >
                Best Price
              </Typography>
            </Box>
          )}
        </Box>

        {/* DESKTOP LAYOUT */}
        {!isMobile && (
          <>
            {/* Middle Column: icons row + short desc + brand line + times purchased, etc. */}
            <Box
              sx={{
                flex: 1,
                paddingLeft: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginTop: 0,
              }}
            >
              {/* Icons row */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0,
                  marginBottom: theme.spacing(0.5),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <IconButton
                  onClick={handleFavoriteClick}
                  sx={{
                    color: '#E92409',
                    p: 0.5,
                    '&:hover': {
                      color: alpha('#E92409', 0.7),
                    },
                  }}
                >
                  {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>

                <IconButton
                  onClick={handleOpenListDialog}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': {
                      color: alpha('#006D6D', 0.7),
                    },
                  }}
                >
                  <ListAltIcon />
                </IconButton>

                <IconButton
                  onClick={handleAddToInventoryClick}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': {
                      color: alpha('#006D6D', 0.7),
                    },
                  }}
                >
                  <InventoryIcon />
                </IconButton>
              </Box>

              {/* Short Description */}
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '9pt',
                  color: '#131616',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  paddingRight: theme.spacing(4),
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {short_description}
              </Typography>

              {/* brand | pack_size | ff_catalog_number + lead_time + special_notes */}
              {brandLine && (
                <Box
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    color: '#3A3F3F',
                    mt: 0.25,
                    paddingRight: theme.spacing(4),
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '8pt',
                      color: '#3A3F3F',
                    }}
                  >
                    {brandLine}
                  </Typography>

                  {lead_time > 0 && (
                    <Tooltip title={`Lead Time: ${lead_time}`}>
                      <AvTimerIcon
                        sx={{
                          fontSize: '1rem',
                          color: '#F87906',
                          ml: 0.5,
                        }}
                      />
                    </Tooltip>
                  )}

                  {special_notes && !/no special/i.test(special_notes) && (
                    <Tooltip title={special_notes}>
                      <NoteAltIcon
                        sx={{
                          fontSize: '1rem',
                          color: '#F87906',
                          ml: 0.5,
                        }}
                      />
                    </Tooltip>
                  )}
                </Box>
              )}

              {/* Purchased X times | Last purchased date */}
              <Typography
                variant="caption"
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '7pt',
                  color: '#949E98',
                  mt: 0.25,
                }}
              >
                Purchased {timespurchased || 0} times
                {lastdatepurchased && (
                  <>
                    {' '}
                    | Last purchased on{' '}
                    {new Date(lastdatepurchased).toLocaleDateString()}
                  </>
                )}
              </Typography>

              {/* Compare Button => below the purchased text (only if hasalternates) */}
              {hasalternates && (
                <Box
                  sx={{
                    mt: 0.5,
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    variant="text"
                    sx={{
                      ...compareButtonStyles,
                      fontFamily: 'Poppins',
                      fontWeight: 600,
                      fontSize: '0.6rem',
                      textTransform: 'none',
                      paddingX: 1,
                      paddingY: 0.25,
                      minHeight: 'auto',
                      borderRadius: 2,
                    }}
                    onClick={() => onCompareClick?.(product_id)}
                  >
                    {compareLabel} {compareArrow}
                  </Button>
                </Box>
              )}
            </Box>

            {/* Price lines on the far right (desktop) */}
            <Box
              sx={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {bothZeroNoUnits && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <Box>
                    <PriceLine mainPrice="MARKET" perUnitPrice={null} />
                  </Box>
                  <AddToCartButton
                    productId={product_id}
                    isSplit={false}
                    buttonLabel="Case"
                    sx={{ width: 'auto', minWidth: 'unset' }}
                  />
                </Box>
              )}

              {!bothZeroNoUnits && (
                <>
                  {formatCasePrice() && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <PriceLine
                          mainPrice={formatCasePrice()}
                          perUnitPrice={formatCasePricePerUnit()}
                        />
                      </Box>
                      <AddToCartButton
                        productId={product_id}
                        isSplit={false}
                        buttonLabel="Case"
                        sx={{ width: 'auto', minWidth: 'unset' }}
                      />
                    </Box>
                  )}

                  {formatEachPrice() && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                      }}
                    >
                      <Box>
                        <PriceLine
                          mainPrice={formatEachPrice()}
                          perUnitPrice={null}
                        />
                      </Box>
                      <AddToCartButton
                        productId={product_id}
                        isSplit
                        buttonLabel="Each"
                        sx={{ width: 'auto', minWidth: 'unset' }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </>
        )}

        {/* MOBILE LAYOUT */}
        {isMobile && (
          <Box sx={{ width: '100%' }}>
            {/* Row of icons */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                mb: 0.5,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <IconButton
                onClick={handleFavoriteClick}
                sx={{
                  color: '#E92409',
                  p: 0.5,
                  '&:hover': {
                    color: alpha('#E92409', 0.7),
                  },
                }}
              >
                {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>

              <IconButton
                onClick={handleOpenListDialog}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': {
                    color: alpha('#006D6D', 0.7),
                  },
                }}
              >
                <ListAltIcon />
              </IconButton>

              <IconButton
                onClick={handleAddToInventoryClick}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': {
                    color: alpha('#006D6D', 0.7),
                  },
                }}
              >
                <InventoryIcon />
              </IconButton>
            </Box>

            {/* Short Description */}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '9pt',
                color: '#131616',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
                pr: 4,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {short_description}
            </Typography>

            {/* brand line, lead_time, special_notes */}
            {brandLine && (
              <Box
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '8pt',
                  color: '#3A3F3F',
                  mt: 0.25,
                  pr: 4,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    fontSize: '8pt',
                    color: '#3A3F3F',
                  }}
                >
                  {brandLine}
                </Typography>

                {lead_time > 0 && (
                  <Tooltip title={`Lead Time: ${lead_time}`}>
                    <AvTimerIcon
                      sx={{
                        fontSize: '1rem',
                        color: '#F87906',
                        ml: 0.5,
                      }}
                    />
                  </Tooltip>
                )}

                {special_notes && !/no special/i.test(special_notes) && (
                  <Tooltip title={special_notes}>
                    <NoteAltIcon
                      sx={{
                        fontSize: '1rem',
                        color: '#F87906',
                        ml: 0.5,
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}

            {/* Purchased X times | Last purchased date */}
            <Typography
              variant="caption"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '7pt',
                color: '#949E98',
                mt: 0.25,
              }}
            >
              Purchased {timespurchased || 0} times
              {lastdatepurchased && (
                <>
                  {' '}
                  | Last purchased on{' '}
                  {new Date(lastdatepurchased).toLocaleDateString()}
                </>
              )}
            </Typography>

            {/* Price lines (stacked) */}
            <Box sx={{ mt: 1 }} onClick={(e) => e.stopPropagation()}>
              {bothZeroNoUnits && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                    mb: 1,
                  }}
                >
                  <PriceLine mainPrice="MARKET" perUnitPrice={null} />
                  <AddToCartButton
                    productId={product_id}
                    isSplit={false}
                    buttonLabel="Case"
                    sx={{ width: 'auto', minWidth: 'unset' }}
                  />
                </Box>
              )}

              {!bothZeroNoUnits && (
                <>
                  {formatCasePrice() && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                        mb: 1,
                      }}
                    >
                      <PriceLine
                        mainPrice={formatCasePrice()}
                        perUnitPrice={formatCasePricePerUnit()}
                      />
                      <AddToCartButton
                        productId={product_id}
                        isSplit={false}
                        buttonLabel="Case"
                        sx={{ width: 'auto', minWidth: 'unset' }}
                      />
                    </Box>
                  )}

                  {formatEachPrice() && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        alignItems: 'center',
                        mb: 1,
                      }}
                    >
                      <PriceLine
                        mainPrice={formatEachPrice()}
                        perUnitPrice={null}
                      />
                      <AddToCartButton
                        productId={product_id}
                        isSplit
                        buttonLabel="Each"
                        sx={{ width: 'auto', minWidth: 'unset' }}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>

            {/* Compare button at the bottom-center (only if hasalternates) */}
            {hasalternates && (
              <Box
                sx={{ textAlign: 'center', mt: 1 }}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  variant="text"
                  sx={{
                    ...compareButtonStyles,
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    fontSize: '0.6rem',
                    textTransform: 'none',
                    px: 1,
                    py: 0.25,
                    minHeight: 'auto',
                    borderRadius: 2,
                  }}
                  onClick={() => onCompareClick?.(product_id)}
                >
                  {compareLabel} {compareArrow}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {/* ADD TO LIST DIALOG */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Select a List
        </DialogTitle>
        <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText
                      primary={list.list_name}
                      primaryTypographyProps={{
                        fontFamily: theme.typography.fontFamily,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert
              severity="success"
              sx={{
                mt: theme.spacing(2),
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseListDialog}
            disabled={addingToList}
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* INVENTORY SUCCESS/ERROR SNACKBARS */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}

      {/* NEW: Toast/Snackbar for "Add to List" success */}
      {listAddToast && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setListAddToast('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setListAddToast('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {listAddToast}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default PHListItem;
