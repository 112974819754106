import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
  CircularProgress,
  Snackbar,
  useMediaQuery,
} from '@mui/material';
import {
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  ListAlt as ListAltIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { useTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import AddToCartButton from './AddToCartButton';
import fallbackLogo from './assets/small-logo.avif';

// -------------------------------------------
// Auth Helper
// -------------------------------------------
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }
  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

// -------------------------------------------
// Corner Banner Logic
// -------------------------------------------
function getFlagStatus(product) {
  if (product.bestdeal) {
    return {
      text: 'Best',
      color: '#ffffff',
      backgroundColor: '#00BC48',
    };
  }
  if (product.currentproduct) {
    return {
      text: 'Current',
      color: '#ffffff',
      backgroundColor: '#0E9C9B',
    };
  }
  if (product.betterprice) {
    return {
      text: 'Better',
      color: '#ffffff',
      backgroundColor: '#E76F01',
    };
  }
  return {
    text: 'Alt',
    color: '#ffffff',
    backgroundColor: '#626C6A',
  };
}

// -------------------------------------------
// PriceLine
// -------------------------------------------
function parsePriceWithUnit(priceString) {
  if (!priceString || priceString === 'MARKET') {
    return { price: 'MARKET', unit: '' };
  }
  const parts = priceString.split(' ');
  if (parts.length > 1) {
    return { price: parts[0], unit: parts.slice(1).join(' ') };
  }
  return { price: priceString, unit: '' };
}

function PriceLine({ mainPrice, perUnitPrice }) {
  const theme = useTheme();
  const { price, unit } = parsePriceWithUnit(mainPrice);

  if (price === 'MARKET') {
    return (
      <Typography
        component="span"
        sx={{
          color: '#006D6D',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '9pt',
        }}
      >
        MARKET
      </Typography>
    );
  }

  return (
    <Box component="span">
      <Typography
        component="span"
        sx={{
          color: '#006D6D',
          fontFamily: 'Poppins',
          fontWeight: 'bold',
          fontSize: '9pt',
        }}
      >
        {price}
      </Typography>
      {unit && (
        <Typography
          component="span"
          sx={{
            color: '#949E98',
            fontFamily: 'Poppins',
            fontSize: '8pt',
            marginLeft: '4px',
          }}
        >
          {unit}
        </Typography>
      )}
      {perUnitPrice && (
        <Typography
          component="span"
          sx={{
            color: '#949E98',
            fontFamily: 'Poppins',
            fontSize: '7pt',
            marginLeft: '6px',
          }}
        >
          ({perUnitPrice})
        </Typography>
      )}
    </Box>
  );
}

// -------------------------------------------
// CompareImageSection
//  (Shows the product image, vendor logo, and corner banner.)
// -------------------------------------------
function CompareImageSection({ product }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { Image_URL, vendor_logo_url, vendor_short_name, short_description } =
    product;
  const { text: flagText, color: flagColor, backgroundColor: flagBgColor } =
    getFlagStatus(product);

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '15%' },
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: isMobile ? 1 : 0,
      }}
    >
      {/* Main Product Image */}
      <Box
        component="img"
        src={Image_URL || fallbackLogo}
        alt={short_description || 'Compare Product'}
        sx={{
          width: isMobile ? 90 : 60,
          height: isMobile ? 90 : 60,
          objectFit: 'cover',
          borderRadius: 1,
        }}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = fallbackLogo;
        }}
      />

      {/*
        Vendor Logo pinned to the top-right corner,
        *centered* on that corner via transform.
      */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: 40,
          height: 40,
          borderRadius: '50%',
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          boxShadow: theme.shadows[2],
          zIndex: 2,
          transform: 'translate(0%, -25%)', // center the circle on the corner
        }}
      >
        <Box
          component="img"
          src={vendor_logo_url || fallbackLogo}
          alt={vendor_short_name || 'Vendor Logo'}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackLogo;
          }}
        />
      </Box>

      {/* Bottom-left corner banner text (Best, Current, Better, Alt) */}
      <Box
        sx={{
          backgroundColor: flagBgColor,
          display: 'inline-flex',
          alignItems: 'center',
          padding: '2px 8px',
          borderRadius: '4px',
          position: 'absolute',
          bottom: 0,
          left: 0,
          margin: theme.spacing(1),
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: flagColor,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '0.75rem',
          }}
        >
          {flagText}
        </Typography>
      </Box>
    </Box>
  );
}

// -------------------------------------------
// CompareListItem
// -------------------------------------------
function CompareListItem({
  product,
  multiplier = 1,
  restaurantId,
  favoritesMap = {},
  onAddFavorite,
  onRemoveFavorite,
  onQuantityChange,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const {
    product_id,
    short_description,
    brand,
    pack_size,
    FF_Catalog_Number,
    purchase_price,
    case_priced_by,
    each_price,
    each_priced_by,
    purchase_price_per_ozctcs,
    priced_by,
  } = product;

  // Decide the border color from the corner banner "flag"
  const { backgroundColor: flagBorderColor } = getFlagStatus(product);

  // Favorites
  const isFavorited = !!favoritesMap[product_id];
  const handleFavoriteClick = (e) => {
    e.stopPropagation();
    if (!restaurantId) return;
    if (isFavorited) {
      onRemoveFavorite(product_id);
    } else {
      onAddFavorite(product_id);
    }
  };

  const handleRowClick = () => {
    navigate(`/product/${product_id}`);
  };

  // Price formatting
  function formatCasePrice() {
    if (!purchase_price || purchase_price === 0) {
      return 'MARKET';
    }
    const numericPrice = `$${(purchase_price * multiplier).toFixed(2)}`;
    const suffix = case_priced_by || 'CS';
    return `${numericPrice} ${suffix}`;
  }

  function formatCasePricePerUnit() {
    if (!purchase_price_per_ozctcs) return null;
    const ppu = purchase_price_per_ozctcs * multiplier;
    return `$${ppu.toFixed(2)}/${priced_by || ''}`;
  }

  function formatEachPrice() {
    if (!each_price || each_price === 0) {
      return null;
    }
    const numericPrice = `$${(each_price * multiplier).toFixed(2)}`;
    const suffix = each_priced_by || 'EA';
    return `${numericPrice} ${suffix}`;
  }

  const canShowCase = purchase_price !== null && purchase_price !== undefined;
  const canShowEach = each_price > 0;
  const brandLine = [brand, pack_size, FF_Catalog_Number]
    .filter(Boolean)
    .join(' | ');

  // -------------------------------------------
  // Add to List logic
  // -------------------------------------------
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addingToList, setAddingToList] = useState(false);

  const handleOpenListDialog = (e) => {
    e.stopPropagation();
    setOpenAddListDialog(true);
    fetchLists();
  };

  const handleCloseListDialog = () => {
    setOpenAddListDialog(false);
    setAddListSuccess('');
    setAddListError('');
  };

  async function fetchLists() {
    setListsLoading(true);
    setAddListError('');
    try {
      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id in user data.');
      }
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: { restaurantidfilter: user.restaurant_id },
        }
      );
      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  }

  async function handleAddToList(listId) {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        {
          list_item_name: '',
          product_id: product_id,
          lists_id: listId,
        },
        { headers }
      );
      setAddListSuccess('Item successfully added to the list!');
      setTimeout(() => {
        handleCloseListDialog();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  }

  // -------------------------------------------
  // Add to Inventory logic
  // -------------------------------------------
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryInProgress, setAddInventoryInProgress] = useState(false);

  const handleAddToInventoryClick = async (e) => {
    e.stopPropagation();
    try {
      setAddInventoryInProgress(true);
      setAddInventorySuccess('');
      setAddInventoryError('');

      const { user, headers } = getAuthHeadersAndUser();
      if (!user.restaurant_id) {
        throw new Error('No restaurant_id found in user data.');
      }
      const data = {
        restaurant_id: user.restaurant_id,
        item_description: short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id: product_id,
      };
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );
      setAddInventorySuccess('Item successfully added to inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
    } finally {
      setAddInventoryInProgress(false);
    }
  };

  // -------------------------------------------
  // Render
  // -------------------------------------------
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'center',
          padding: 1,
          position: 'relative',
          overflow: 'hidden',
          cursor: 'pointer',
          backgroundColor: '#fff',
          border: `2px solid ${flagBorderColor}`,
          marginTop: '2px',
          marginBottom: '2px',
        }}
        onClick={handleRowClick}
      >
        {/* CompareImageSection on left (desktop) or top (mobile) */}
        <CompareImageSection product={product} />

        {/* DESKTOP LAYOUT */}
        {!isMobile && (
          <>
            {/* Middle Column: icons row, short desc, brand line */}
            <Box
              sx={{
                flex: 1,
                paddingLeft: theme.spacing(1),
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {/* Icons row */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0,
                  marginBottom: theme.spacing(0.5),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <IconButton
                  onClick={handleFavoriteClick}
                  sx={{
                    color: '#E92409',
                    p: 0.5,
                    '&:hover': {
                      color: alpha('#E92409', 0.7),
                    },
                  }}
                >
                  {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>

                <IconButton
                  onClick={handleOpenListDialog}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': {
                      color: alpha('#006D6D', 0.7),
                    },
                  }}
                >
                  <ListAltIcon />
                </IconButton>

                <IconButton
                  onClick={handleAddToInventoryClick}
                  sx={{
                    color: '#006D6D',
                    p: 0.5,
                    '&:hover': {
                      color: alpha(theme.palette.primary.main, 0.7),
                    },
                  }}
                >
                  <InventoryIcon />
                </IconButton>
              </Box>

              {/* Short Description */}
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '9pt',
                  color: '#131616',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  paddingRight: theme.spacing(4),
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {short_description || ''}
              </Typography>

              {/* Brand line */}
              {brandLine && (
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '8pt',
                    color: '#3A3F3F',
                    mt: 0.25,
                    paddingRight: theme.spacing(4),
                  }}
                >
                  {brandLine}
                </Typography>
              )}
            </Box>

            {/* Right Column: Price lines + AddToCart */}
            <Box
              sx={{
                width: '40%',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {/* CASE + EACH */}
              {canShowCase && canShowEach && (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                      alignItems: 'center',
                    }}
                  >
                    <PriceLine
                      mainPrice={formatCasePrice()}
                      perUnitPrice={formatCasePricePerUnit()}
                    />
                    <AddToCartButton
                      productId={product_id}
                      isSplit={false}
                      buttonLabel="Case"
                      sx={{ width: 'auto', minWidth: 'unset' }}
                      onQuantityChange={onQuantityChange}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                      alignItems: 'center',
                    }}
                  >
                    <PriceLine mainPrice={formatEachPrice()} perUnitPrice={null} />
                    <AddToCartButton
                      productId={product_id}
                      isSplit
                      buttonLabel="Each"
                      sx={{ width: 'auto', minWidth: 'unset' }}
                      onQuantityChange={onQuantityChange}
                    />
                  </Box>
                </>
              )}

              {/* CASE only */}
              {canShowCase && !canShowEach && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <PriceLine
                    mainPrice={formatCasePrice()}
                    perUnitPrice={formatCasePricePerUnit()}
                  />
                  <AddToCartButton
                    productId={product_id}
                    isSplit={false}
                    buttonLabel="Case"
                    sx={{ width: 'auto', minWidth: 'unset' }}
                    onQuantityChange={onQuantityChange}
                  />
                </Box>
              )}

              {/* EACH only */}
              {!canShowCase && canShowEach && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <PriceLine mainPrice={formatEachPrice()} perUnitPrice={null} />
                  <AddToCartButton
                    productId={product_id}
                    isSplit
                    buttonLabel="Each"
                    sx={{ width: 'auto', minWidth: 'unset' }}
                    onQuantityChange={onQuantityChange}
                  />
                </Box>
              )}

              {/* Neither => MARKET */}
              {!canShowCase && !canShowEach && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PriceLine mainPrice="MARKET" perUnitPrice={null} />
                </Box>
              )}
            </Box>
          </>
        )}

        {/* MOBILE LAYOUT */}
        {isMobile && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              mt: 1,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Icons row */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0,
                marginBottom: theme.spacing(0.5),
              }}
            >
              <IconButton
                onClick={handleFavoriteClick}
                sx={{
                  color: '#E92409',
                  p: 0.5,
                  '&:hover': {
                    color: alpha('#E92409', 0.7),
                  },
                }}
              >
                {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>

              <IconButton
                onClick={handleOpenListDialog}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': {
                    color: alpha('#006D6D', 0.7),
                  },
                }}
              >
                <ListAltIcon />
              </IconButton>

              <IconButton
                onClick={handleAddToInventoryClick}
                sx={{
                  color: '#006D6D',
                  p: 0.5,
                  '&:hover': {
                    color: alpha(theme.palette.primary.main, 0.7),
                  },
                }}
              >
                <InventoryIcon />
              </IconButton>
            </Box>

            {/* Short Description */}
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: '9pt',
                color: '#131616',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {short_description || ''}
            </Typography>

            {/* Brand line */}
            {brandLine && (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '8pt',
                  color: '#3A3F3F',
                  mt: 0.25,
                }}
              >
                {brandLine}
              </Typography>
            )}

            {/* Price lines + AddToCart in a vertical list */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                mt: 1,
              }}
            >
              {/* CASE + EACH */}
              {canShowCase && canShowEach && (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                      alignItems: 'center',
                    }}
                  >
                    <PriceLine
                      mainPrice={formatCasePrice()}
                      perUnitPrice={formatCasePricePerUnit()}
                    />
                    <AddToCartButton
                      productId={product_id}
                      isSplit={false}
                      buttonLabel="Case"
                      sx={{ width: 'auto', minWidth: 'unset' }}
                      onQuantityChange={onQuantityChange}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr auto',
                      alignItems: 'center',
                    }}
                  >
                    <PriceLine mainPrice={formatEachPrice()} perUnitPrice={null} />
                    <AddToCartButton
                      productId={product_id}
                      isSplit
                      buttonLabel="Each"
                      sx={{ width: 'auto', minWidth: 'unset' }}
                      onQuantityChange={onQuantityChange}
                    />
                  </Box>
                </>
              )}

              {/* CASE only */}
              {canShowCase && !canShowEach && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <PriceLine
                    mainPrice={formatCasePrice()}
                    perUnitPrice={formatCasePricePerUnit()}
                  />
                  <AddToCartButton
                    productId={product_id}
                    isSplit={false}
                    buttonLabel="Case"
                    sx={{ width: 'auto', minWidth: 'unset' }}
                    onQuantityChange={onQuantityChange}
                  />
                </Box>
              )}

              {/* EACH only */}
              {!canShowCase && canShowEach && (
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr auto',
                    alignItems: 'center',
                  }}
                >
                  <PriceLine mainPrice={formatEachPrice()} perUnitPrice={null} />
                  <AddToCartButton
                    productId={product_id}
                    isSplit
                    buttonLabel="Each"
                    sx={{ width: 'auto', minWidth: 'unset' }}
                    onQuantityChange={onQuantityChange}
                  />
                </Box>
              )}

              {/* Neither => MARKET */}
              {!canShowCase && !canShowEach && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PriceLine mainPrice="MARKET" perUnitPrice={null} />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {/* ADD TO LIST DIALOG */}
      <Dialog
        open={openAddListDialog}
        onClose={handleCloseListDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
          Select a List
        </DialogTitle>
        <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
          {listsLoading ? (
            <Box sx={{ textAlign: 'center', mt: theme.spacing(2) }}>
              <CircularProgress size={24} />
            </Box>
          ) : addListError ? (
            <Alert severity="error">{addListError}</Alert>
          ) : (
            <List>
              {lists.map((list) => (
                <ListItem key={list.id} disablePadding>
                  <ListItemButton onClick={() => handleAddToList(list.id)}>
                    <ListItemText
                      primary={list.list_name}
                      primaryTypographyProps={{
                        fontFamily: theme.typography.fontFamily,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
              {lists.length === 0 && (
                <Typography
                  variant="body2"
                  align="center"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  No lists available.
                </Typography>
              )}
            </List>
          )}
          {addListSuccess && (
            <Alert
              severity="success"
              sx={{
                mt: theme.spacing(2),
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {addListSuccess}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseListDialog}
            disabled={addingToList}
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* INVENTORY SUCCESS/ERROR SNACKBARS */}
      {addInventorySuccess && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventorySuccess('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventorySuccess('')}
            severity="success"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {addInventorySuccess}
          </Alert>
        </Snackbar>
      )}
      {addInventoryError && (
        <Snackbar
          open
          autoHideDuration={3000}
          onClose={() => setAddInventoryError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setAddInventoryError('')}
            severity="error"
            sx={{ width: '100%', fontFamily: theme.typography.fontFamily }}
          >
            {addInventoryError}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default CompareListItem;
