// src/services/orderNotificationService.js
import axios from 'axios';

/**
 * Helper to pad strings for emailed table formatting
 */
function padString(str, length) {
  if (str.length > length) {
    return str.substring(0, length - 3) + '...';
  } else {
    return str.padEnd(length, ' ');
  }
}

/**
 * Helper to format a table (ASCII style) with given rows and column widths
 */
function formatTable(rows, columnWidths, headers) {
  let table = '';
  const separatorLine =
    columnWidths.map((width) => '-'.repeat(width)).join('-+-') + '\n';

  // Header row
  const headerRow = headers
    .map((header, index) => padString(header, columnWidths[index]))
    .join(' | ');
  table += separatorLine;
  table += headerRow + '\n';
  table += separatorLine;

  // Data rows
  rows.forEach((row) => {
    const dataRow = row
      .map((cell, index) => padString(cell, columnWidths[index]))
      .join(' | ');
    table += dataRow + '\n';
  });

  table += separatorLine;
  return table;
}

/**
 * Parses "Postgres array" strings like "{3559}" => ["3559"] or "{}" => []
 */
function parsePgArray(pgArrayString) {
  if (!pgArrayString || pgArrayString === '{}' || pgArrayString.length < 2) {
    return [];
  }
  // Remove outer braces
  const trimmed = pgArrayString.replace(/^\{/, '').replace(/\}$/, '');
  // Split by comma (if there's more than one)
  return trimmed.split(',');
}

/**
 * Safely parse a boolean array from Postgres array notation
 */
function parseBooleanPgArray(pgArrayString) {
  // e.g. "{t,f}" => [true, false]
  const raw = parsePgArray(pgArrayString);
  return raw.map((val) => val.trim() === 't');
}

/**
 * Safely parse a number array from Postgres array notation
 */
function parseNumberPgArray(pgArrayString) {
  const raw = parsePgArray(pgArrayString);
  return raw.map((val) => Number(val.trim() || 0));
}

/**
 * Safely parse a string array from Postgres array notation,
 * preserving text (such as short_descriptions).
 */
function parseStringPgArray(pgArrayString) {
  // Because Postgres can store string arrays with optional quotes,
  // you may need a more robust parser. But if your data is simple,
  // you can do a basic split.
  // If your data includes curly braces, etc., adjust as needed.
  const raw = parsePgArray(pgArrayString);
  // Also strip any leading/trailing double quotes:
  return raw.map((val) => val.replace(/^"(.*)"$/, '$1').trim());
}

/**
 * Given a single cart item (which might have duplicates),
 * expand it into an array of “rows” for table-building.
 *
 * Each row will have:
 *   - Catalog Number
 *   - Description
 *   - Quantity + (Case(s) or Each(es))
 */
function expandItemWithDuplicates(item) {
  const rows = [];

  // 1) Main item
  const mainSplit = item.split_item === true || item.split_item === 't'; 
  const mainQty = item.quantity || 0;
  const mainPricedBy = mainSplit ? 'Each(es)' : 'Case(s)';
  rows.push({
    catalogNumber: item.ff_catalog_number || 'N/A',
    shortDescription: item.short_description || 'Product',
    quantity: `${mainQty} ${mainPricedBy}`,
  });

  // 2) Duplicates, if any
  const dupIds = parsePgArray(item.duplicate_order_item_ids);
  if (dupIds.length > 0) {
    const dupSplitBools = parseBooleanPgArray(item.duplicate_split_items);
    const dupShortDescs = parseStringPgArray(item.duplicate_short_descriptions);
    const dupCatalogNums = parseStringPgArray(item.duplicate_ff_catalog_numbers);
    const dupQtys = parseNumberPgArray(item.duplicate_quantities);

    // We assume each of these arrays has the same length => number of duplicates
    for (let i = 0; i < dupIds.length; i++) {
      const thisSplit = dupSplitBools[i];
      const pricedBy = thisSplit ? 'Each(es)' : 'Case(s)';
      const qty = dupQtys[i] || 0;
      rows.push({
        catalogNumber: dupCatalogNums[i] || 'N/A',
        shortDescription: dupShortDescs[i] || 'Product',
        quantity: `${qty} ${pricedBy}`,
      });
    }
  }

  return rows;
}

/**
 * Returns the total “count” of items across main + duplicates
 * For example, if main is 1 case, plus a duplicate of 2 each,
 * we might want to sum them if your system lumps them all as “quantity”.
 *
 * If your business logic wants them separate, you can separate them.
 * Below, we just sum everything for a “total item count.”
 */
function getTotalItemCount(item) {
  let total = item.quantity || 0;
  const dupQtys = parseNumberPgArray(item.duplicate_quantities);
  dupQtys.forEach((q) => {
    total += q;
  });
  return total;
}

/**
 * Main function to handle all order notifications: vendor emails, internal email, and Slack.
 */
export async function sendOrderNotifications({
  restaurantId,
  restaurantName,
  restaurantLocation,
  cartItems,
  totalCases,       // This is the “total cases” from the cart’s perspective
  orderFinalTotal,
  userEmail,
  vendors,
  slackEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
  notificationEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:E1YzybDk/order_notification',
}) {
  if (!restaurantId) {
    throw new Error('No restaurant ID provided');
  }
  if (!Array.isArray(vendors) || vendors.length === 0) {
    throw new Error('Vendors not loaded or invalid');
  }

  // Group cart items by vendor
  const itemsByVendor = {};
  cartItems.forEach((item) => {
    const vendorId = item.vendor_id;
    if (!itemsByVendor[vendorId]) {
      itemsByVendor[vendorId] = [];
    }
    itemsByVendor[vendorId].push(item);
  });

  // Always CC these recipients
  const ccEmails = 'vincent@fare.food,michael@fare.food';

  // ----------------------------------------------------------------
  // 1) Send a separate email to each vendor with only their items
  // ----------------------------------------------------------------
  for (const vendorId in itemsByVendor) {
    const vendorItems = itemsByVendor[vendorId];

    // Sum up the total quantity for this vendor, counting duplicates
    let totalVendorQuantity = 0;
    vendorItems.forEach((i) => {
      totalVendorQuantity += getTotalItemCount(i);
    });

    const vendor = vendors.find((v) => v.vendor_id === parseInt(vendorId));
    if (!vendor) continue;

    const vendorEmail = vendor.vendor_email;
    const vendorName =
      vendor._vendor?.Short_Name || vendor.vendor_name || 'Vendor';

    // Construct the subject line
    const subjectHeader = `${restaurantName} has placed an order with ${vendorName}!`;

    // Build a text-based table to send to this vendor
    // Expand each item + duplicates into multiple “rows”
    const tableRows = [];
    vendorItems.forEach((itm) => {
      const itemRows = expandItemWithDuplicates(itm);
      tableRows.push(...itemRows);
    });

    // Format the rows into ASCII table
    // We'll assume columns: [ Catalog #, Description, Quantity ]
    const vendorColumnWidths = [17, 30, 15];
    const vendorHeaders = ['Catalog Number', 'Description', 'Qty'];
    const vendorTable = formatTable(
      tableRows.map((r) => [r.catalogNumber, r.shortDescription, r.quantity]),
      vendorColumnWidths,
      vendorHeaders
    );

    // Construct the vendor email body
    let emailBody = `Dear ${vendorName},\n\n`;
    emailBody += `${restaurantName} has placed a new order totaling ${totalVendorQuantity} item(s) through FareFood.\n\n`;
    emailBody += `Restaurant Details:\n`;
    emailBody += `Name: ${restaurantName}\n`;
    emailBody += `Location: ${restaurantLocation}\n\n`;
    emailBody += `Order Details:\n`;
    emailBody += vendorTable;
    emailBody += `\nIf there are any issues with fulfilling this order or if any items are out of stock, please contact ${userEmail}, michael@fare.food, vincent@fare.food, or suppliers@fare.food.\n\n`;
    emailBody += 'Thank you for partnering with FareFood!\n\n';
    emailBody += 'Best regards,\nThe FareFood Team';

    // Send the vendor email
    try {
      await axios.post(
        notificationEndpoint,
        {
          admin_email: vendorEmail,
          subject_header: subjectHeader,
          email_body: emailBody,
          cc_email: ccEmails,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
    } catch (err) {
      console.error(`Error sending email to ${vendorEmail}:`, err);
      throw new Error(`Failed to send email to ${vendorEmail}.`);
    }
  }

  // ----------------------------------------------------------------
  // 2) Send an internal notification (to restaurants@fare.food)
  // ----------------------------------------------------------------
  // Expand every cart item and duplicates into a single list for the “internal” table
  const allItemsTableRows = [];
  cartItems.forEach((itm) => {
    const vendorId = itm.vendor_id;
    const vendor = vendors.find((v) => v.vendor_id === parseInt(vendorId));
    const vendorName =
      vendor?._vendor?.Short_Name || vendor?.vendor_name || 'UnknownVendor';

    // Expand main + duplicates
    const itemRows = expandItemWithDuplicates(itm);
    // For internal email, also show the vendor name in each row
    itemRows.forEach((r) => {
      allItemsTableRows.push({
        vendorName,
        catalogNumber: r.catalogNumber,
        shortDescription: r.shortDescription,
        quantity: r.quantity,
      });
    });
  });

  // Format the internal table
  const internalColumnWidths = [20, 17, 30, 15];
  const internalHeaders = ['Vendor', 'Catalog Number', 'Description', 'Qty'];
  const internalTable = formatTable(
    allItemsTableRows.map((r) => [
      r.vendorName,
      r.catalogNumber,
      r.shortDescription,
      r.quantity,
    ]),
    internalColumnWidths,
    internalHeaders
  );

  // Construct the internal email
  const internalSubject = `New order placed by ${restaurantName}`;
  let internalBody =
    `A new order has been placed by ${restaurantName}.\n\n` +
    `User email: ${userEmail}\n` +
    `Total Line Items: ${cartItems.length}\n` +
    `Total Quantity (Cases/Eaches Combined): ${totalCases}\n` +  // Original sum from Cart
    `Total Amount: $${orderFinalTotal.toFixed(2)}\n\n` +
    `Order Details:\n` +
    internalTable +
    `\nPlease review and take necessary actions.\n\n` +
    `Best regards,\nFareFood System`;

  try {
    await axios.post(
      notificationEndpoint,
      {
        admin_email: 'restaurants@fare.food',
        subject_header: internalSubject,
        email_body: internalBody,
        cc_email: '',
      },
      { headers: { 'Content-Type': 'application/json' } }
    );
  } catch (err) {
    console.error('Error sending internal notification email:', err);
    throw new Error('Failed to send internal notification email.');
  }

  // ----------------------------------------------------------------
  // 3) Send Slack notification
  // ----------------------------------------------------------------
  const slackPayload = {
    text: `📦 *New Order*\n\n*Restaurant:* ${restaurantName} (ID: ${restaurantId})\n*User Email:* ${userEmail}\n*Total Line Items:* ${cartItems.length}\n*Total Quantity (Cases/Eaches):* ${totalCases}\n*Total Amount:* $${orderFinalTotal.toFixed(
      2
    )}\n`,
  };

  try {
    const slackResponse = await axios.post(slackEndpoint, slackPayload, {
      headers: { 'Content-Type': 'application/json' },
    });
    if (slackResponse.status !== 200) {
      console.warn('Failed to send Slack notification.');
    }
  } catch (slackErr) {
    console.error('Error sending Slack notification:', slackErr);
    throw new Error('Error sending Slack notification.');
  }
}
