// Inventory.js

import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Drawer,
  Container,
  useMediaQuery,
  CircularProgress,
  Button,
  Pagination,
  Fab,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import {
  ViewModule as ViewModuleIcon,
  ViewList as ViewListIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import axios from 'axios';
import InventoryFilterPanel from './InventoryFilterPanel';
import InventoryProductCard from './InventoryProductCard';
import InventoryListItem from './InventoryListItem';
import AddInventoryItemModal from './AddInventoryItemModal';
import ProductDetailsModal from './ProductDetailsModal';
import CompareListItem from './CompareListItem';
import { useNavigate } from 'react-router-dom';
import usePersistedState from './hooks/usePersistedState';
import { Helmet } from 'react-helmet';

// ---------------------
// Styled Search components
// ---------------------
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 300, // adjust as needed
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled('input')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  padding: theme.spacing(1, 1, 1, 0),
  paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
}));

function Inventory() {
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [loading, setLoading] = useState(false);

  // Persisted states for filters
  const [searchTerm, setSearchTerm] = usePersistedState('inventory_searchTerm', '');
  const [selectedCategory, setSelectedCategory] = usePersistedState('inventory_selectedCategory', 0);
  const [selectedSubcategory, setSelectedSubcategory] = usePersistedState('inventory_selectedSubcategory', 0);
  const [selectedVendor, setSelectedVendor] = usePersistedState('inventory_selectedVendor', []);
  const [sortOption, setSortOption] = usePersistedState('inventory_sortOption', 'default');
  
  // itemsPerPage
  const [itemsPerPage, setItemsPerPage] = usePersistedState('inventory_itemsPerPage', 24);

  // View & Drawer
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Modals
  const [openModal, setOpenModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Multiplier (still used internally, e.g. for cost calculations)
  const [multiplier, setMultiplier] = useState(1);

  // Filter data: categories, subcategories
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);

  // Compare
  const [compareMap, setCompareMap] = useState({});
  const [compareExpanded, setCompareExpanded] = useState({});

  // Restaurant & favorites
  const [restaurantId, setRestaurantId] = useState(null);
  const [favoritesMap, setFavoritesMap] = useState({});

  // ---------------------
  // Fetch user info on mount
  // ---------------------
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);

        if (parsedUser.restaurant_id) {
          setRestaurantId(parsedUser.restaurant_id);
        }
      } catch (e) {
        console.error('Error parsing user data from local storage:', e);
        setMultiplier(1);
      }
    } else {
      console.error('User data not found in localStorage.');
      setMultiplier(1);
    }
  }, []);

  // ---------------------
  // Favorites
  // ---------------------
  useEffect(() => {
    if (!restaurantId) return;
    fetchFavorites(restaurantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  const fetchFavorites = async (rId) => {
    if (!rId) return;
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: rId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        const map = {};
        response.data.forEach((favItem) => {
          const { product_id, id: list_item_id } = favItem;
          if (!map[product_id]) {
            map[product_id] = [];
          }
          map[product_id].push(list_item_id);
        });
        setFavoritesMap(map);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setFavoritesMap({});
    }
  };

  const handleAddFavorite = async (productId) => {
    if (!restaurantId) {
      console.warn('No restaurantId for adding to favorites.');
      return;
    }
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        {
          product_id: productId,
          restaurant_id: restaurantId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    if (!favoritesMap[productId]) return;
    try {
      const listItemIds = favoritesMap[productId];
      const deleteRequests = listItemIds.map((id) =>
        axios.delete(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item',
          {
            data: { list_item_id: id },
            headers: { 'Content-Type': 'application/json' },
          }
        )
      );
      await Promise.all(deleteRequests);
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  // ---------------------
  // Compare
  // ---------------------
  const handleCompareClick = async (productId) => {
    if (compareExpanded[productId]) {
      // If already expanded, collapse it
      setCompareExpanded((prev) => ({ ...prev, [productId]: false }));
      return;
    }
    try {
      const mainItem = filteredInventory.find(
        (p) => p.main_product_id === productId
      );
      if (!mainItem) return;

      const userJson = localStorage.getItem('user');
      const user = userJson ? JSON.parse(userJson) : null;
      const userId = user ? user.id : 1; // fallback

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_2',
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            product_id: productId,
            user_id: userId,
          },
        }
      );

      if (Array.isArray(response.data)) {
        setCompareMap((prev) => ({
          ...prev,
          [productId]: response.data,
        }));
        setCompareExpanded((prev) => ({ ...prev, [productId]: true }));
      }
    } catch (error) {
      console.error('Error comparing products:', error);
    }
  };

  // ---------------------
  // Fetch categories, subcategories
  // ---------------------
  const fetchFilterData = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = { Authorization: `Bearer ${authToken}` };

      const categoriesResponse = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
        { headers }
      );
      setCategories(categoriesResponse.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  const fetchSubcategories = async (categoryId) => {
    if (categoryId === 0) {
      setSubcategories([]);
      setSelectedSubcategory(0);
      return;
    }
    try {
      const authToken = localStorage.getItem('authToken');
      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
        {
          headers,
          params: { filter: categoryId },
        }
      );
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setSubcategories([]);
    }
  };

  // ---------------------
  // Fetch Inventory
  // ---------------------
  const fetchInventory = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      // Example request body; adjust as needed
      const body = {
        restaurantidfilter: user.restaurant_id,
        limit: 1000, // fetch a large chunk, then we'll filter/paginate client-side
        offset: 0,
        productnamefilter: '', // we’ll do client-side search
        category_id: 0,
        subcategory_id: 0,
        vendor_id: 0,
      };

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      };

      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventoryreact_sql_5',
        body,
        { headers }
      );

      const fetchedInventory = response.data || [];
      setInventory(fetchedInventory);
      setFilteredInventory(fetchedInventory);
      setTotalResults(fetchedInventory.length);
    } catch (error) {
      console.error('Error fetching inventory:', error);
      setInventory([]);
      setFilteredInventory([]);
      setTotalResults(0);
    } finally {
      setLoading(false);
    }
  };

  // ---------------------
  // Lifecycle
  // ---------------------
  useEffect(() => {
    fetchFilterData();
    fetchInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If category changes, fetch subcategories
  useEffect(() => {
    fetchSubcategories(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // ---------------------
  // Filtering + Sorting
  // ---------------------
  useEffect(() => {
    let newData = [...inventory];

    // 1) Search
    if (searchTerm) {
      const lower = searchTerm.toLowerCase();
      newData = newData.filter((item) =>
        item.short_description?.toLowerCase().includes(lower)
      );
    }

    // 2) Category
    if (selectedCategory !== 0) {
      newData = newData.filter((item) => item.category_id === selectedCategory);
    }

    // 3) Subcategory
    if (selectedSubcategory !== 0) {
      newData = newData.filter(
        (item) => item.subcategory_id === selectedSubcategory
      );
    }

    // 4) Vendor
    if (selectedVendor.length > 0) {
      // If your vendor data is an ID instead of name, adjust accordingly
      newData = newData.filter((item) =>
        selectedVendor.includes(item.vendor_name)
      );
    }

    // 5) Sorting
    if (sortOption === 'stock_low_high') {
      newData.sort((a, b) => (a.stock || 0) - (b.stock || 0));
    } else if (sortOption === 'stock_high_low') {
      newData.sort((a, b) => (b.stock || 0) - (a.stock || 0));
    }
    // 'default' => do nothing (leave as returned by the API)

    setFilteredInventory(newData);
    setTotalResults(newData.length);
    setCurrentPage(1);
  }, [
    inventory,
    searchTerm,
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    sortOption,
  ]);

  // ---------------------
  // Clear filters
  // ---------------------
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor([]);
    setSearchTerm('');
    setSortOption('default');
    setItemsPerPage(24); // reset items per page
    setFilteredInventory(inventory);
    setTotalResults(inventory.length);
    setCurrentPage(1);

    if (isMobile) setIsDrawerOpen(false);
  };

  // ---------------------
  // Drawer Toggle
  // ---------------------
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // ---------------------
  // Pagination
  // ---------------------
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Current page slice
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentInventory = filteredInventory.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
      }}
    >
      <Helmet>
        <title>FareFood | Inventory</title>
      </Helmet>

      {/* Sticky Nav */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar />
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Container maxWidth="lg" sx={{ paddingTop: theme.spacing(8) }}>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginTop: isMobile ? theme.spacing(1) : theme.spacing(4),
              marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            Inventory
          </Typography>

          <Grid container spacing={2} alignItems="flex-start">
            {/* Filter Sidebar (desktop) */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                display: { xs: 'none', sm: 'block' },
                height: 'calc(100vh - 64px)',
                position: 'sticky',
                top: '64px',
                overflowY: 'auto',
                backgroundColor: theme.palette.background.paper,
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
              }}
            >
              <InventoryFilterPanel
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                subcategories={subcategories}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
                clearAllFilters={clearAllFilters}
                sortOption={sortOption}
                setSortOption={setSortOption}
                viewMode={viewMode}
                setViewMode={setViewMode}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
              />
            </Grid>

            {/* Main content area */}
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              sx={{
                paddingTop: isMobile ? theme.spacing(1) : theme.spacing(0),
                paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
              }}
            >
              {/* Controls row */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: 'space-between',
                  alignItems: isMobile ? 'stretch' : 'center',
                  gap: isMobile ? 2 : 0,
                  marginBottom: theme.spacing(2),
                }}
              >
                {/* Left: Search (always visible) */}
                <Box sx={{ flexGrow: isMobile ? 0 : 1 }}>
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Inventory…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Search>
                </Box>

                {/* Right side (desktop only) => New button, Sort By, Items, View toggle */}
                {!isMobile && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {/* New Button */}
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => setOpenModal(true)}
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        textTransform: 'capitalize',
                        borderRadius: '50px',
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover': {
                          backgroundColor: theme.palette.secondary.dark,
                        },
                      }}
                    >
                      New
                    </Button>

                    {/* Sort By */}
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel id="sort-select-label">Sort By</InputLabel>
                      <Select
                        labelId="sort-select-label"
                        id="sort-select"
                        value={sortOption}
                        label="Sort By"
                        onChange={(e) => setSortOption(e.target.value)}
                      >
                        <MenuItem value="default">Default</MenuItem>
                        <MenuItem value="stock_low_high">Stock: Low to High</MenuItem>
                        <MenuItem value="stock_high_low">Stock: High to Low</MenuItem>
                      </Select>
                    </FormControl>

                    {/* Items Per Page */}
                    <FormControl sx={{ minWidth: 80 }}>
                      <InputLabel>Items</InputLabel>
                      <Select
                        value={itemsPerPage}
                        label="Items"
                        onChange={(e) => {
                          setItemsPerPage(e.target.value);
                          setCurrentPage(1);
                        }}
                      >
                        <MenuItem value={24}>24</MenuItem>
                        <MenuItem value={48}>48</MenuItem>
                        <MenuItem value={96}>96</MenuItem>
                      </Select>
                    </FormControl>

                    {/* View Toggle */}
                    <IconButton
                      onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                    >
                      {viewMode === 'grid' ? <ViewListIcon /> : <ViewModuleIcon />}
                    </IconButton>
                  </Box>
                )}
              </Box>

              {/* Inventory Items */}
              {loading ? (
                <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
                  <CircularProgress />
                </Box>
              ) : filteredInventory.length === 0 ? (
                <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
                  <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    No inventory items found. Add an item from the shop or press new.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/shop')}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      textTransform: 'capitalize',
                      borderRadius: '50px',
                    }}
                  >
                    Discover Items
                  </Button>
                </Box>
              ) : viewMode === 'grid' ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ paddingBottom: isMobile ? theme.spacing(8) : 0 }}
                >
                  {currentInventory.map((invItem) => (
                    <React.Fragment key={invItem.inventory_item_id}>
                      <Grid item xs={6} sm={6} md={3}>
                        <InventoryProductCard
                          productInfo={invItem}
                          item={invItem}
                          multiplier={multiplier}
                          restaurantId={restaurantId}
                          favoritesMap={favoritesMap}
                          onAddFavorite={handleAddFavorite}
                          onRemoveFavorite={handleRemoveFavorite}
                          onCompareClick={handleCompareClick}
                          compareOpen={!!compareExpanded[invItem.main_product_id]}
                          onInventoryDeleted={(invId) => {
                            setFilteredInventory((prev) =>
                              prev.filter((p) => p.inventory_item_id !== invId)
                            );
                          }}
                          onClick={() => {
                            setSelectedItem(invItem);
                            setOpenProductModal(true);
                          }}
                        />
                      </Grid>

                      {/* Compare row if expanded */}
                      {compareExpanded[invItem.main_product_id] &&
                        invItem.hasalternates &&
                        compareMap[invItem.main_product_id] && (
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                backgroundColor: '#f9f9f9',
                                p: theme.spacing(1),
                              }}
                            >
                              {compareMap[invItem.main_product_id].map((compareItem) => (
                                <CompareListItem
                                  key={compareItem.product_id}
                                  product={compareItem}
                                  multiplier={multiplier}
                                  restaurantId={restaurantId}
                                  favoritesMap={favoritesMap}
                                  onAddFavorite={handleAddFavorite}
                                  onRemoveFavorite={handleRemoveFavorite}
                                />
                              ))}
                            </Box>
                          </Grid>
                        )}
                    </React.Fragment>
                  ))}
                </Grid>
              ) : (
                // LIST VIEW
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: isMobile ? theme.spacing(8) : 0,
                    rowGap: '2px',
                  }}
                >
                  {currentInventory.map((invItem) => (
                    <React.Fragment key={invItem.inventory_item_id}>
                      <InventoryListItem
                        item={invItem}
                        multiplier={multiplier}
                        restaurantId={restaurantId}
                        favoritesMap={favoritesMap}
                        onAddFavorite={handleAddFavorite}
                        onRemoveFavorite={handleRemoveFavorite}
                        onCompareClick={handleCompareClick}
                        compareOpen={!!compareExpanded[invItem.main_product_id]}
                        onInventoryDeleted={(invId) => {
                          setFilteredInventory((prev) =>
                            prev.filter((p) => p.inventory_item_id !== invId)
                          );
                        }}
                        isMobile={isMobile}
                        onClick={(selected) => {
                          setSelectedItem(selected);
                          setOpenProductModal(true);
                        }}
                      />

                      {/* Compare row if expanded */}
                      {compareExpanded[invItem.main_product_id] &&
                        invItem.hasalternates &&
                        compareMap[invItem.main_product_id] && (
                          <Box sx={{ backgroundColor: '#f9f9f9', p: theme.spacing(1) }}>
                            {compareMap[invItem.main_product_id].map((compareItem) => (
                              <CompareListItem
                                key={compareItem.product_id}
                                product={compareItem}
                                multiplier={multiplier}
                                restaurantId={restaurantId}
                                favoritesMap={favoritesMap}
                                onAddFavorite={handleAddFavorite}
                                onRemoveFavorite={handleRemoveFavorite}
                              />
                            ))}
                          </Box>
                        )}
                    </React.Fragment>
                  ))}
                </Box>
              )}

              {/* Pagination */}
              {totalResults > itemsPerPage && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: theme.spacing(4),
                    marginBottom: isMobile ? theme.spacing(8) : 0,
                  }}
                >
                  <Pagination
                    count={Math.ceil(totalResults / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    showFirstButton
                    showLastButton
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Drawer (mobile filters) */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            backgroundColor: theme.palette.background.paper,
          }}
          role="presentation"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleDrawer(false)} aria-label="close filters">
              <CloseIcon />
            </IconButton>
          </Box>
          <InventoryFilterPanel
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            subcategories={subcategories}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
            selectedVendor={selectedVendor}
            setSelectedVendor={setSelectedVendor}
            clearAllFilters={clearAllFilters}
            sortOption={sortOption}
            setSortOption={setSortOption}
            viewMode={viewMode}
            setViewMode={setViewMode}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
          />
        </Box>
      </Drawer>

      {/* Floating Action Buttons (mobile) */}
      {isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: theme.spacing(8),
            right: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            zIndex: 9999,
          }}
        >
          {/* Filter FAB */}
          <Fab
            color="secondary"
            aria-label="filter"
            onClick={toggleDrawer(true)}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
            }}
          >
            <FilterListIcon />
          </Fab>

          {/* Add New FAB */}
          <Fab
            color="secondary"
            aria-label="add-new"
            onClick={() => setOpenModal(true)}
          >
            <AddIcon />
          </Fab>
        </Box>
      )}

      {/* Add new inventory modal */}
      {openModal && (
        <AddInventoryItemModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          fetchInventory={fetchInventory}
        />
      )}

      {/* Product details modal */}
      {openProductModal && selectedItem && (
        <ProductDetailsModal
          open={openProductModal}
          handleClose={() => setOpenProductModal(false)}
          inventoryItem={selectedItem}
          fetchInventory={fetchInventory}
          multiplier={multiplier}
        />
      )}
    </Box>
  );
}

export default Inventory;
