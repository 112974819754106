// src/LowStockComponent.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  useMediaQuery,
  IconButton,
  Grid,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import InventoryProductCard from './InventoryProductCard';
import CompareListItem from './CompareListItem';

/**
 * Helper to retrieve headers & user data from localStorage.
 */
function getAuthHeadersAndUser() {
  const authToken = localStorage.getItem('authToken');
  const userJson = localStorage.getItem('user');
  if (!authToken || !userJson) {
    throw new Error('Authentication token or user data not found.');
  }
  const user = JSON.parse(userJson);
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  return { user, headers };
}

export default function LowStockComponent({ handleGoToInventory }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Basic states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [lowStockItems, setLowStockItems] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [multiplier, setMultiplier] = useState(1);

  // Track total inventory count from the get_inventory_count API
  const [inventoryCount, setInventoryCount] = useState(null);

  // Favorites
  const [favoritesMap, setFavoritesMap] = useState({});

  // Compare
  const [compareMap, setCompareMap] = useState({});
  const [compareExpanded, setCompareExpanded] = useState({});

  // Horizontal scroll
  const scrollContainerRef = useRef(null);
  const SCROLL_AMOUNT = 300;

  // ---------------------
  // On mount: fetch user data & inventory count
  // ---------------------
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);

        if (parsedUser.restaurant_id) {
          setRestaurantId(parsedUser.restaurant_id);
        }
      } catch (err) {
        console.error('Error parsing user data:', err);
        setMultiplier(1);
      }

      // Now fetch the total inventory count for this user
      fetchInventoryCount();
    }
  }, []);

  // ---------------------
  // Fetch the inventory count
  // ---------------------
  async function fetchInventoryCount() {
    try {
      const { user, headers } = getAuthHeadersAndUser();
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_inventory_count',
        { user_id: user.id },
        { headers }
      );
      // The endpoint returns a single number (e.g., 0, 10, etc.)
      setInventoryCount(response.data);
    } catch (error) {
      console.error('Error fetching inventory count:', error);
      // If desired, set to some default or handle error gracefully
      setInventoryCount(0);
    }
  }

  // ---------------------
  // When restaurantId is known, fetch favorites & low-stock
  // ---------------------
  useEffect(() => {
    if (restaurantId) {
      fetchFavorites(restaurantId);
      fetchLowStock(restaurantId);
    }
  }, [restaurantId]);

  // ---------------------
  // Fetch favorites
  // ---------------------
  async function fetchFavorites(rId) {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/get_favorites',
        {
          params: { restaurant_id: rId },
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (Array.isArray(response.data)) {
        const map = {};
        response.data.forEach((favItem) => {
          const { product_id, id: list_item_id } = favItem;
          if (!map[product_id]) {
            map[product_id] = [];
          }
          map[product_id].push(list_item_id);
        });
        setFavoritesMap(map);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      setFavoritesMap({});
    }
  }

  const handleAddFavorite = async (productId) => {
    if (!restaurantId) return;
    try {
      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_favorites',
        {
          product_id: productId,
          restaurant_id: restaurantId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error adding favorite:', error);
    }
  };

  const handleRemoveFavorite = async (productId) => {
    if (!favoritesMap[productId]) return;
    try {
      const listItemIds = favoritesMap[productId];
      const deleteRequests = listItemIds.map((id) =>
        axios.delete(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_list_item',
          {
            data: { list_item_id: id },
            headers: { 'Content-Type': 'application/json' },
          }
        )
      );
      await Promise.all(deleteRequests);
      await fetchFavorites(restaurantId);
    } catch (error) {
      console.error('Error removing favorite:', error);
    }
  };

  // ---------------------
  // Fetch low-stock
  // ---------------------
  async function fetchLowStock(rId) {
    setLoading(true);
    setError('');
    try {
      const { headers } = getAuthHeadersAndUser();
      const body = {
        restaurantidfilter: rId,
        limit: 1000,
        offset: 0,
        productnamefilter: '',
        category_id: 0,
        subcategory_id: 0,
        vendor_id: 0,
      };
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventoryreact_sql_5',
        body,
        { headers }
      );
      const data = Array.isArray(response.data) ? response.data : [];
      const lowFiltered = data.filter(
        (item) => (item.stock ?? 0) <= (item.par ?? 0)
      );
      setLowStockItems(lowFiltered);
    } catch (err) {
      console.error('Error fetching low stock items:', err);
      setError('Unable to retrieve low-stock inventory.');
    } finally {
      setLoading(false);
    }
  }

  // ---------------------
  // Compare
  // ---------------------
  const handleCompareClick = async (productId) => {
    if (compareExpanded[productId]) {
      // If open, close
      setCompareExpanded((prev) => ({ ...prev, [productId]: false }));
      return;
    }
    try {
      const { user, headers } = getAuthHeadersAndUser();
      const userId = user ? user.id : 1;
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/compare_products_2',
        {
          headers: { 'Content-Type': 'application/json' },
          params: {
            product_id: productId,
            user_id: userId,
          },
        }
      );
      if (Array.isArray(response.data)) {
        setCompareMap((prev) => ({ ...prev, [productId]: response.data }));
        setCompareExpanded((prev) => ({ ...prev, [productId]: true }));
      }
    } catch (error) {
      console.error('Error comparing products:', error);
    }
  };

  const handleInventoryDeleted = (inventoryItemId) => {
    setLowStockItems((prev) =>
      prev.filter((p) => p.inventory_item_id !== inventoryItemId)
    );
  };

  // ---------------------
  // Horizontal scroll
  // ---------------------
  const handlePrev = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scrollBy({
      left: -SCROLL_AMOUNT,
      behavior: 'smooth',
    });
  };
  const handleNext = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scrollBy({
      left: SCROLL_AMOUNT,
      behavior: 'smooth',
    });
  };

  // ---------------------
  // Render conditions
  // ---------------------
  if (loading) {
    return (
      <Card sx={{ border: '1px solid #ccc', borderRadius: 4, minHeight: 300 }}>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Card>
    );
  }
  if (error) {
    return (
      <Card sx={{ border: '1px solid #ccc', borderRadius: 4 }}>
        <CardContent>
          <Alert severity="error">{error}</Alert>
        </CardContent>
      </Card>
    );
  }

  // 1) If total inventory count is zero, show a "go add inventory" message with marketing copy.
  if (inventoryCount !== null && inventoryCount === 0) {
    return (
      <Card sx={{ border: '1px solid #ccc', borderRadius: 4 }}>
        <CardContent
          sx={{ textAlign: 'center', backgroundColor: alpha('#00BC48', 0.06) }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Poppins, sans-serif',
              color: theme.palette.text.primary,
            }}
          >
            No Inventory Items Yet
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
          Stay on top of your stock and never run out.
          Add your items to get low-stock alerts and compare prices instantly—helping you save money and keep your kitchen running smoothly.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'none',
              fontSize: '12px',
              fontFamily: 'Poppins, sans-serif',
              mt: 2,
            }}
            onClick={handleGoToInventory}
          >
            Add Your Inventory
          </Button>
        </CardContent>
      </Card>
    );
  }

  // 2) If inventoryCount > 0 but none are low-stock, "fully stocked" logic:
  if (lowStockItems.length === 0) {
    return (
      <Card sx={{ border: '1px solid #ccc', borderRadius: 4 }}>
        <CardContent
          sx={{ textAlign: 'center', backgroundColor: alpha('#00BC48', 0.06) }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Your Inventory is Fully Stocked
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Awesome! You have no items needing restocking at the moment. Keep 
            adding and tracking your inventory to stay ahead of your needs and 
            keep comparing prices for the best deals.
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 2, textTransform: 'none' }}
            onClick={handleGoToInventory}
          >
            Manage Inventory
          </Button>
        </CardContent>
      </Card>
    );
  }

  // 3) Otherwise, show the low-stock carousel/list as before
  return (
    <Card sx={{ border: '1px solid #ccc', borderRadius: 4, position: 'relative' }}>
      <CardContent>
        <Typography
          variant="h6"
          sx={{
            color: '#131616',
            fontFamily: 'Poppins, Arial, sans-serif',
            fontWeight: 500, // Medium
            fontSize: '10pt', // Directly in points
            mb: 2,
          }}
        >
          Low Stock Inventory
        </Typography>

        {isMobile ? (
          // ============= MOBILE =============
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {lowStockItems.map((product) => {
              const productId = product.main_product_id;
              const compareIsOpen = compareExpanded[productId] || false;

              return (
                <Box key={product.inventory_item_id}>
                  <InventoryProductCard
                    productInfo={product}
                    item={product}
                    multiplier={multiplier}
                    restaurantId={restaurantId}
                    favoritesMap={favoritesMap}
                    onAddFavorite={handleAddFavorite}
                    onRemoveFavorite={handleRemoveFavorite}
                    onCompareClick={handleCompareClick}
                    compareOpen={compareIsOpen}
                    onInventoryDeleted={handleInventoryDeleted}
                    // In a vertical list, each card just "auto-grows"
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  />

                  {/* Compare items if expanded */}
                  {compareIsOpen && product.hasalternates && compareMap[productId] && (
                    <Box
                      sx={{
                        backgroundColor: '#f9f9f9',
                        p: 2,
                        mt: 1,
                      }}
                    >
                      {compareMap[productId].map((compareItem) => (
                        <CompareListItem
                          key={compareItem.product_id}
                          product={compareItem}
                          multiplier={multiplier}
                          restaurantId={restaurantId}
                          favoritesMap={favoritesMap}
                          onAddFavorite={handleAddFavorite}
                          onRemoveFavorite={handleRemoveFavorite}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        ) : (
          // ============= DESKTOP =============
          <>
            {/* Horizontal scroller for the cards */}
            <Box sx={{ position: 'relative' }}>
              <IconButton
                onClick={handlePrev}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  zIndex: 10,
                  transform: 'translateY(-50%)',
                  backgroundColor: alpha('#fff', 0.8),
                  '&:hover': { backgroundColor: '#fff' },
                }}
              >
                <KeyboardArrowLeft />
              </IconButton>

              <Box
                ref={scrollContainerRef}
                sx={{
                  display: 'flex',
                  alignItems: 'stretch', // Make all cards the same height
                  overflowX: 'auto',
                  scrollBehavior: 'smooth',
                  gap: 2,
                  py: 1,
                  px: 4, // some left/right padding to avoid arrows overlapping
                }}
              >
                {lowStockItems.map((product) => {
                  const productId = product.main_product_id;
                  const compareIsOpen = compareExpanded[productId] || false;
                  return (
                    <Box
                      key={product.inventory_item_id}
                      sx={{
                        width: 250,
                        flex: '0 0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <InventoryProductCard
                        productInfo={product}
                        item={product}
                        multiplier={multiplier}
                        restaurantId={restaurantId}
                        favoritesMap={favoritesMap}
                        onAddFavorite={handleAddFavorite}
                        onRemoveFavorite={handleRemoveFavorite}
                        onCompareClick={handleCompareClick}
                        compareOpen={compareIsOpen}
                        onInventoryDeleted={handleInventoryDeleted}
                        // ensures the card can grow to match the tallest
                        sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
                      />
                    </Box>
                  );
                })}
              </Box>

              <IconButton
                onClick={handleNext}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  zIndex: 10,
                  transform: 'translateY(-50%)',
                  backgroundColor: alpha('#fff', 0.8),
                  '&:hover': { backgroundColor: '#fff' },
                }}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Box>

            {/* Desktop: compare rows full width */}
            <Grid container spacing={0} sx={{ mt: 2 }}>
              {lowStockItems.map((product) => {
                const productId = product.main_product_id;
                const compareIsOpen = compareExpanded[productId] || false;
                const compareItems = compareMap[productId] || [];
                if (!compareIsOpen || !product.hasalternates || compareItems.length === 0) {
                  return null;
                }
                return (
                  <Grid item xs={12} key={productId} sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 1,
                        p: 2,
                        backgroundColor: '#f9f9f9',
                      }}
                    >
                      {compareItems.map((compareItem) => (
                        <CompareListItem
                          key={compareItem.product_id}
                          product={compareItem}
                          multiplier={multiplier}
                          restaurantId={restaurantId}
                          favoritesMap={favoritesMap}
                          onAddFavorite={handleAddFavorite}
                          onRemoveFavorite={handleRemoveFavorite}
                        />
                      ))}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
}
