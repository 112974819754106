import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  forwardRef,
  cloneElement,
} from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  useMediaQuery,
  useTheme,
  InputBase,
  Badge,
  CircularProgress,
  Typography,
  Slide,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
  InputAdornment,
  LinearProgress,
  List as MUIList,
  ListItem as MUIListItem,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import ShopIcon from '@mui/icons-material/Shop';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { useNavigate, useLocation } from 'react-router-dom';

import LogoImage from './assets/FF-Logo-Icon-NT-White-NB.webp';
import SmallLogoImage from './assets/FF-Cart-Icon-White-NB.webp';

import { CartContext } from './CartContext';

/** Transition for Drawer (Mobile search) */
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

/** Styled search bar container */
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${alpha('#FFFFFF', 0.5)}`,
  backgroundColor: alpha('#FFFFFF', 0.15),
  '&:hover': {
    backgroundColor: alpha('#FFFFFF', 0.25),
  },
  width: '100%',
  maxWidth: 500,
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  flexGrow: 1,
  boxSizing: 'border-box',
  zIndex: 1,
}));

/** The search icon on the left inside the bar */
const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: '#FFFFFF',
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
  zIndex: 2,
}));

/** The input base */
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: theme.typography.fontFamily,
  width: '100%',
  position: 'relative',
  zIndex: 1,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`, // space for search icon
    paddingRight: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  '& .MuiInputAdornment-root': {
    position: 'absolute',
    right: theme.spacing(1),
    height: '100%',
    maxHeight: 'none',
    zIndex: 2,
  },
  '& .MuiInputBase-root': {
    width: '100%',
    height: '100%',
  },
}));

function TopNavBar({ onSearch, initialSearchTerm = '' }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Cart context
  const { cartItems, loading: cartLoading, error: cartError } =
    useContext(CartContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchTerm);

  const [suggestions, setSuggestions] = useState([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);

  const [listsMenuAnchorEl, setListsMenuAnchorEl] = useState(null);
  const isListsMenuOpen = Boolean(listsMenuAnchorEl);

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  // For Debounce
  const debounceTimerRef = useRef(null);

  // For suggestions
  const suggestionsRef = useRef(null);
  const searchBarRef = useRef(null);

  // Bottom Nav
  const [bottomNavValue, setBottomNavValue] = useState('home');

  // Grab user from localStorage
  const savedUser = localStorage.getItem('user');
  let userObj = null;
  let restaurantId = 1; // fallback
  let userId = null;
  let initialShowOnboardBanner = false;
  if (savedUser) {
    try {
      userObj = JSON.parse(savedUser);
      if (userObj && userObj.restaurant_id) {
        restaurantId = userObj.restaurant_id;
      }
      if (userObj && userObj.id) {
        userId = userObj.id;
      }
      if (userObj && typeof userObj.showonboardbanner === 'boolean') {
        initialShowOnboardBanner = userObj.showonboardbanner;
      }
    } catch (e) {
      console.error('Error parsing user from localStorage:', e);
    }
  }

  // We'll track if we should show the onboarding progress bar / banner
  const [showOnboardBanner, setShowOnboardBanner] = useState(
    initialShowOnboardBanner
  );

  // Onboarding fetch
  const [onboardingData, setOnboardingData] = useState(null);
  const [onboardingLoading, setOnboardingLoading] = useState(true);

  // Our 5 steps + API keys
  const steps = [
    {
      key: 'has_restaurant_vendors',
      title: 'Connect Your Vendors to Unlock Real-Time Pricing',
      description:
        'Quickly pull current supplier costs for fast, accurate ordering each time you shop.',
    },
    {
      key: 'has_inventory',
      title: 'Add Your Inventory & Compare Better-Priced Alternatives',
      description:
        'Upload your restaurant’s items so we can instantly match them to lower-cost equivalents—saving you money from day one.',
    },
    {
      key: 'has_resale_tax_cert',
      title: 'Upload Your Resale Tax Cert & Verify Restaurant Status',
      description:
        'Stay compliant and enjoy eligible tax exemptions by confirming your restaurant credentials.',
    },
    {
      key: 'has_restaurant_sales',
      title: 'Add Sales & Cost Data for Instant Insights',
      description:
        'Monitor your daily margins and see how your new savings improve profitability—boosting visibility at a glance.',
    },
    {
      key: 'has_orders_with_order_items',
      title: 'Place Your First Order & Centralize Your Purchasing',
      description:
        'Experience how easy it is to buy everything on one platform—fill your cart and streamline your entire ordering process.',
    },
  ];

  // Onboarding modal
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const handleOnboardingModalOpen = () => {
    setOnboardingModalOpen(true);
  };
  const handleOnboardingModalClose = () => {
    setOnboardingModalOpen(false);
  };

  // Decide how many steps are done
  const totalSteps = steps.length;
  let completedSteps = 0;
  if (onboardingData) {
    steps.forEach((step) => {
      if (onboardingData[step.key]) {
        completedSteps++;
      }
    });
  }
  const progressValue = (completedSteps / totalSteps) * 100;

  // fetchOnboardingChecklist
  useEffect(() => {
    if (!showOnboardBanner) {
      // If user turned this off, no need to fetch the data or show anything
      return;
    }
    async function fetchOnboardingChecklist() {
      try {
        setOnboardingLoading(true);
        const response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/onboardingchecklist',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ restaurant_id: restaurantId }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setOnboardingData(data[0]);
        }
      } catch (error) {
        console.error('Onboarding checklist fetch error:', error);
      } finally {
        setOnboardingLoading(false);
      }
    }
    fetchOnboardingChecklist();
  }, [restaurantId, showOnboardBanner]);

  // Step click handler - route user if step is incomplete
  const handleStepClick = (stepKey, isDone) => {
    // If the user already completed the step, do nothing
    if (isDone) return;

    switch (stepKey) {
      case 'has_restaurant_vendors':
        navigate('/settings');
        break;
      case 'has_resale_tax_cert':
        navigate('/settings');
        break;
      case 'has_inventory':
        navigate('/inventory');
        break;
      case 'has_orders_with_order_items':
        navigate('/shop');
        break;
      case 'has_restaurant_sales':
        navigate('/home');
        break;
      default:
        break;
    }
    setOnboardingModalOpen(false);
  };

  // We add new state to open/close the "Don't Show Again" confirmation
  const [dismissConfirmOpen, setDismissConfirmOpen] = useState(false);

  // Show the "Don't Show Again" confirm
  const handleDismissConfirmOpen = () => {
    setDismissConfirmOpen(true);
  };

  // Cancel the "Don't Show Again" confirm
  const handleDismissConfirmClose = () => {
    setDismissConfirmOpen(false);
  };

  // Dismiss Onboarding Banner (actual operation after confirming)
  const handleDismissOnboarding = async () => {
    if (!userId) {
      setShowOnboardBanner(false);
      setOnboardingModalOpen(false);
      setDismissConfirmOpen(false);
      return;
    }
    try {
      // PATCH call
      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/dismissonboard',
        {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userid: userId }),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to dismiss onboarding');
      }

      // If success, update localStorage
      setShowOnboardBanner(false);
      if (userObj) {
        userObj.showonboardbanner = false;
        localStorage.setItem('user', JSON.stringify(userObj));
      }
      setOnboardingModalOpen(false);
      setDismissConfirmOpen(false);
    } catch (error) {
      console.error('Dismiss onboarding error:', error);
    }
  };

  // Cart calculations
  const cartAmount = cartItems.reduce(
    (acc, item) => acc + (item.total_price || 0) + (item.sales_tax_added || 0),
    0
  );
  const itemCount = cartItems.reduce(
    (acc, item) => acc + (item.quantity || 0),
    0
  );

  // Hide suggestions if user clicks outside (desktop)
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        suggestions.length > 0 &&
        suggestionsRef.current &&
        searchBarRef.current &&
        !suggestionsRef.current.contains(event.target) &&
        !searchBarRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [suggestions]);

  // Keep searchQuery in sync
  useEffect(() => {
    setSearchQuery(initialSearchTerm);
  }, [initialSearchTerm]);

  // Sync Bottom Navigation
  useEffect(() => {
    const path = location.pathname.split('/')[1] || 'home';
    const validPaths = [
      'home',
      'shop',
      'inventory',
      'orders',
      'lists',
      'purchase-history',
      'guides',
    ];
    if (validPaths.includes(path)) {
      setBottomNavValue(path);
    } else {
      setBottomNavValue('home');
    }
  }, [location.pathname]);

  // Are we on home page?
  const isHomePagePath = location.pathname === '/home' || location.pathname === '/';

  // Toggling side drawer
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Searching on mobile
  const handleSearchDrawerOpen = () => {
    setSearchDrawerOpen(true);
  };
  const handleSearchDrawerClose = () => {
    setSearchDrawerOpen(false);
  };

  // Logout
  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  // Lists menu open/close
  const handleListsMenuOpen = (event) => {
    setListsMenuAnchorEl(event.currentTarget);
  };
  const handleListsMenuClose = () => {
    setListsMenuAnchorEl(null);
  };

  // Settings
  const handleSettingsClick = () => {
    navigate('/settings');
  };

  // Searching / suggestions from Xano
  const fetchSuggestions = async (query) => {
    if (!query || query.trim().length === 0) {
      setSuggestions([]);
      return;
    }
    setIsFetchingSuggestions(true);

    try {
      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/suggested_search_2',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            search_phrase: query,
            limit: 10,
            offset: 0,
            restaurant_id: restaurantId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data && data.x1) {
        setSuggestions(data.x1);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    } finally {
      setIsFetchingSuggestions(false);
    }
  };

  // Debounce user input
  const handleSearchChange = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      fetchSuggestions(newQuery);
    }, 300);
  };

  // Suggestion click
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setSuggestions([]);
    if (onSearch) {
      onSearch(suggestion);
    } else {
      navigate(`/shop?query=${encodeURIComponent(suggestion)}`);
    }
    if (searchDrawerOpen) {
      setSearchDrawerOpen(false);
    }
  };

  // Submit search
  const handleSearchSubmit = (event) => {
    if (event.type === 'click' || event.key === 'Enter') {
      event.preventDefault();
      if (searchQuery.trim()) {
        if (onSearch) {
          onSearch(searchQuery);
        } else {
          navigate(`/shop?query=${encodeURIComponent(searchQuery)}`);
        }
        setSuggestions([]);
        setSearchQuery('');
        setSearchDrawerOpen(false);
      }
    }
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    navigate('/shop');
  };

  // Nav items
  const navItems = [
    { label: 'Home', path: 'home', icon: <HomeIcon /> },
    { label: 'Shop', path: 'shop', icon: <ShopIcon /> },
    { label: 'Inventory', path: 'inventory', icon: <InventoryIcon /> },
    { label: 'Orders', path: 'orders', icon: <ListAltIcon /> },
  ];

  // Bottom nav change
  const handleBottomNavChange = (event, newValue) => {
    if (newValue !== 'lists') {
      setBottomNavValue(newValue);
      navigate(`/${newValue}`);
    }
  };

  // Vendor login incorrect alert
  const [vendorLoginIncorrect, setVendorLoginIncorrect] = useState(false);

  // Fetch login_incorrect_alert data
  useEffect(() => {
    async function checkLoginIncorrectAlert() {
      try {
        const response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/login_is_incorrect_alert',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ restaurant_id: restaurantId }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          if (data[0].showalert) {
            setVendorLoginIncorrect(true);
          } else {
            setVendorLoginIncorrect(false);
          }
        }
      } catch (error) {
        console.error('Login incorrect alert fetch error:', error);
      }
    }
    checkLoginIncorrectAlert();
  }, [restaurantId]);

  return (
    <>
      {/* Top AppBar */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: '#0E9C9B',
          boxShadow: 'none !important',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          {isMobile && !isHomePagePath && (
            <IconButton
              color="inherit"
              aria-label="go back"
              onClick={() => navigate(-1)}
              sx={{ color: '#FFFFFF', marginRight: theme.spacing(1) }}
            >
              <ArrowBackIcon />
            </IconButton>
          )}

          {/* Logo */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              zIndex: 2,
            }}
            onClick={() => navigate('/home')}
          >
            <img
              src={isMobile ? SmallLogoImage : LogoImage}
              alt="FareFood Logo"
              style={{ height: '30px' }}
            />
          </Box>

          {/* Mobile search icon */}
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open search"
              onClick={handleSearchDrawerOpen}
              sx={{ marginLeft: 1, color: '#FFFFFF' }}
            >
              <SearchIcon />
            </IconButton>
          )}

          {/* Desktop Search */}
          {!isMobile && (
            <Box sx={{ position: 'relative' }} ref={searchBarRef}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search Shop..."
                  inputProps={{ 'aria-label': 'search' }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  onKeyPress={handleSearchSubmit}
                  endAdornment={
                    searchQuery && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClearSearch}
                          aria-label="clear search"
                          edge="end"
                          size="small"
                          sx={{ color: '#FFFFFF' }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </Search>

              {/* Desktop suggestions */}
              {suggestions.length > 0 && searchQuery.trim() !== '' && (
                <Box
                  ref={suggestionsRef}
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    color: '#000000',
                    boxShadow: theme.shadows[3],
                    zIndex: 9999,
                    maxHeight: '220px',
                    overflowY: 'auto',
                    borderRadius: '4px',
                    marginTop: '4px',
                  }}
                >
                  {isFetchingSuggestions && (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        py: 1,
                      }}
                    >
                      <CircularProgress size={20} />
                    </Box>
                  )}
                  {!isFetchingSuggestions &&
                    suggestions.map((item, index) => (
                      <Box
                        key={index}
                        onClick={() =>
                          handleSuggestionClick(item.short_description)
                        }
                        sx={{
                          padding: theme.spacing(1),
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: alpha('#068986', 0.1),
                          },
                        }}
                      >
                        {item.short_description}
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
          )}

          {/* Spacer */}
          <Box sx={{ flexGrow: 1 }} />

          {/* Vendor login incorrect icon, Cart & Settings */}
          <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 2 }}>
            {/* Vendor login incorrect alert icon (persistent, orange) */}
            {vendorLoginIncorrect && (
              <Tooltip
                title={
                  <>
                    <Typography sx={{ fontWeight: 'bold', color: 'white', }}>
                      One or more vendor logins is incorrect.
                    </Typography>
                    <Typography>Click to fix</Typography>
                  </>
                }
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#F87906',
                      color: '#FFFFFF',
                      fontSize: theme.typography.body2.fontSize,
                      borderRadius: 1,
                      p: 1,
                    },
                  },
                  arrow: {
                    sx: {
                      color: '#F87906',
                    },
                  },
                }}
              >
                <IconButton
                  aria-label="vendor login incorrect"
                  onClick={() => navigate('/settings')}
                  sx={{
                    // Make the icon itself orange:
                    color: '#FFFFFF',
                    marginRight: 1,
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.1),
                    },
                  }}
                >
                  <WarningAmberIcon />
                </IconButton>
              </Tooltip>
            )}

            {/* Cart */}
            <Box
              onClick={() => navigate('/cart')}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',
                padding: theme.spacing(1.5, 2),
                borderRadius: '100px',
                '&:hover': {
                  backgroundColor: alpha('#FFFFFF', 0.1),
                },
              }}
            >
              <Box
                sx={{ position: 'relative', marginBottom: theme.spacing(0.2) }}
              >
                <IconButton
                  aria-label="cart"
                  sx={{
                    color: '#FFFFFF',
                    borderRadius: '50%',
                    padding: theme.spacing(0.5),
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Badge
                    badgeContent={
                      cartLoading ? (
                        <CircularProgress size={10} color="inherit" />
                      ) : (
                        itemCount
                      )
                    }
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#F87906',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        minWidth: '20px',
                        height: '20px',
                        padding: '0 6px',
                      },
                    }}
                  >
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
                {cartError && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  >
                    !
                  </Typography>
                )}
              </Box>

              <Typography
                variant="body2"
                sx={{
                  color: '#FFFFFF',
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.body2.fontWeight,
                }}
              >
                ${cartAmount.toFixed(2)}
              </Typography>
            </Box>

            {/* Settings */}
            <IconButton
              color="inherit"
              aria-label="settings"
              onClick={handleSettingsClick}
              sx={{ marginLeft: 1, color: '#FFFFFF' }}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </Toolbar>

        {/* Secondary nav (Desktop) */}
        {!isMobile && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#068986',
              paddingY: theme.spacing(0.5),
              borderTop: `1px solid ${alpha('#FFFFFF', 0.3)}`,
              borderBottom: `1px solid ${alpha('#FFFFFF', 0.3)}`,
            }}
          >
            {/* Left side nav items */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {navItems.map((item) => (
                <Button
                  key={item.label}
                  startIcon={item.icon}
                  onClick={() => navigate(`/${item.path}`)}
                  sx={{
                    color:
                      location.pathname === `/${item.path}`
                        ? '#FFFFFF'
                        : alpha('#FFFFFF', 0.7),
                    fontSize: theme.typography.body2.fontSize,
                    fontWeight: theme.typography.button.fontWeight,
                    textTransform: 'none',
                    marginLeft: theme.spacing(1),
                    paddingY: theme.spacing(0.5),
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.2),
                      color: '#FFFFFF',
                    },
                  }}
                >
                  {item.label}
                </Button>
              ))}

              {/* Lists menu */}
              <Button
                startIcon={<ListIcon />}
                onClick={handleListsMenuOpen}
                sx={{
                  color:
                    ['/purchase-history', '/guides', '/lists'].includes(
                      location.pathname
                    )
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  textTransform: 'none',
                  marginLeft: theme.spacing(1),
                  paddingY: theme.spacing(0.5),
                  '&:hover': {
                    backgroundColor: alpha('#FFFFFF', 0.2),
                    color: '#FFFFFF',
                  },
                }}
              >
                Lists
              </Button>
              <Menu
                anchorEl={listsMenuAnchorEl}
                open={isListsMenuOpen}
                onClose={handleListsMenuClose}
                PaperProps={{
                  style: { backgroundColor: '#068986', color: '#FFFFFF' },
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate('/purchase-history');
                    setBottomNavValue('purchase-history');
                    handleListsMenuClose();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.1),
                    },
                  }}
                >
                  Purchase History
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/lists');
                    setBottomNavValue('lists');
                    handleListsMenuClose();
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: alpha('#FFFFFF', 0.1),
                    },
                  }}
                >
                  Lists
                </MenuItem>
              </Menu>
            </Box>

            {/* Onboarding progress bar (desktop) */}
            {!onboardingLoading && onboardingData && showOnboardBanner && (
              <Box
                sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}
                onClick={handleOnboardingModalOpen}
              >
                <Typography
                  variant="body2"
                  sx={{ color: '#FFFFFF', cursor: 'pointer', marginRight: 1 }}
                >
                  Onboarding:
                </Typography>
                <Box sx={{ width: 120, mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: alpha('#FFFFFF', 0.3),
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: '#F87906',
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{ color: '#FFFFFF', cursor: 'pointer' }}
                >
                  {completedSteps}/{totalSteps} Done (click to view)
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </AppBar>

      {/* Spacing below AppBar */}
      <Toolbar />

      {/* Desktop side drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            backgroundColor: '#E9C9B9',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(2),
          },
        }}
      >
        <Box>
          {/* Drawer logo */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(2),
              justifyContent: 'center',
            }}
          >
            <img
              src={LogoImage}
              alt="FareFood Logo"
              style={{ height: '30px', cursor: 'pointer' }}
              onClick={() => {
                navigate('/home');
                handleDrawerToggle();
              }}
            />
          </Box>
          <Divider sx={{ backgroundColor: alpha('#FFFFFF', 0.3) }} />
          <List>
            {navItems.map((item) => (
              <ListItem
                button
                key={item.label}
                onClick={() => {
                  navigate(`/${item.path}`);
                  handleDrawerToggle();
                }}
                selected={location.pathname === `/${item.path}`}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: alpha('#FFFFFF', 0.2),
                    color: '#FFFFFF',
                    '& .MuiListItemIcon-root': {
                      color: '#FFFFFF',
                    },
                  },
                  '& .MuiListItemText-primary': {
                    fontSize: theme.typography.body2.fontSize,
                    fontWeight: theme.typography.button.fontWeight,
                    color: '#FFFFFF',
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === `/${item.path}`
                        ? '#FFFFFF'
                        : alpha('#FFFFFF', 0.7),
                    minWidth: '40px',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}

            {/* Lists + Purchase History */}
            <ListItem
              button
              onClick={() => {
                navigate('/purchase-history');
                handleDrawerToggle();
              }}
              selected={location.pathname === '/purchase-history'}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: alpha('#FFFFFF', 0.2),
                  color: '#FFFFFF',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                },
                '& .MuiListItemText-primary': {
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === '/purchase-history'
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  minWidth: '40px',
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase History" />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                navigate('/guides');
                handleDrawerToggle();
              }}
              selected={location.pathname === '/guides'}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: alpha('#FFFFFF', 0.2),
                  color: '#FFFFFF',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                },
                '& .MuiListItemText-primary': {
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === '/guides'
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  minWidth: '40px',
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Guides" />
            </ListItem>

            <ListItem
              button
              onClick={() => {
                navigate('/lists');
                handleDrawerToggle();
              }}
              selected={location.pathname === '/lists'}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: alpha('#FFFFFF', 0.2),
                  color: '#FFFFFF',
                  '& .MuiListItemIcon-root': {
                    color: '#FFFFFF',
                  },
                },
                '& .MuiListItemText-primary': {
                  fontSize: theme.typography.body2.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  color: '#FFFFFF',
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === '/lists'
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  minWidth: '40px',
                }}
              >
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Lists" />
            </ListItem>
          </List>
        </Box>

        {/* Logout */}
        <Box sx={{ padding: theme.spacing(2) }}>
          <Divider sx={{ backgroundColor: alpha('#FFFFFF', 0.3) }} />
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              marginTop: theme.spacing(1),
              '& .MuiListItemText-primary': {
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.button.fontWeight,
                color: '#FFFFFF',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: '40px', color: '#FFFFFF' }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Box>
      </Drawer>

      {/* Mobile Bottom Nav */}
      {isMobile && (
        <>
          {/* Onboarding Banner if we have data and showOnboardBanner is true */}
          {!onboardingLoading && onboardingData && showOnboardBanner && (
            <Box
              onClick={handleOnboardingModalOpen}
              sx={{
                backgroundColor: '#068986',
                color: '#FFFFFF',
                textAlign: 'center',
                py: 1,
                cursor: 'pointer',
                mt: 2, // push below the fixed AppBar
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'white' }}>
                Onboarding: {completedSteps}/{totalSteps}
              </Typography>
              <Box sx={{ width: '80%', margin: '0 auto', mt: 0.5 }}>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  sx={{
                    height: 6,
                    borderRadius: 3,
                    backgroundColor: alpha('#FFFFFF', 0.3),
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#F87906',
                    },
                  }}
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: theme.zIndex.appBar + 1,
              borderTop: `1px solid ${alpha('#FFFFFF', 0.3)}`,
              backgroundColor: '#068986',
            }}
          >
            <BottomNavigation
              value={bottomNavValue}
              onChange={handleBottomNavChange}
              showLabels
              sx={{ backgroundColor: 'transparent' }}
            >
              {navItems.map((item) => (
                <BottomNavigationAction
                  key={item.label}
                  label={item.label}
                  value={item.path}
                  icon={cloneElement(item.icon, {
                    style: { color: '#FFFFFF' },
                  })}
                  sx={{
                    '& .MuiBottomNavigationAction-label': {
                      color:
                        bottomNavValue === item.path
                          ? '#FFFFFF'
                          : alpha('#FFFFFF', 0.7),
                    },
                  }}
                />
              ))}

              {/* Lists */}
              <BottomNavigationAction
                label="Lists"
                value="lists"
                icon={<ListIcon style={{ color: '#FFFFFF' }} />}
                onClick={handleListsMenuOpen}
                sx={{
                  '& .MuiBottomNavigationAction-label': {
                    color: ['lists', 'purchase-history', 'guides'].includes(
                      bottomNavValue
                    )
                      ? '#FFFFFF'
                      : alpha('#FFFFFF', 0.7),
                  },
                }}
              />
            </BottomNavigation>

            {/* Mobile Lists Menu */}
            <Menu
              anchorEl={listsMenuAnchorEl}
              open={isListsMenuOpen}
              onClose={handleListsMenuClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              keepMounted
              PaperProps={{
                style: {
                  backgroundColor: '#068986',
                  color: '#FFFFFF',
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate('/lists');
                  setBottomNavValue('lists');
                  handleListsMenuClose();
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: alpha('#FFFFFF', 0.1),
                  },
                }}
              >
                Lists
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate('/purchase-history');
                  setBottomNavValue('purchase-history');
                  handleListsMenuClose();
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: alpha('#FFFFFF', 0.1),
                  },
                }}
              >
                Purchase History
              </MenuItem>
            </Menu>
          </Box>
        </>
      )}

      {/* Mobile Search Drawer */}
      <Drawer
        anchor="top"
        open={searchDrawerOpen}
        onClose={handleSearchDrawerClose}
        TransitionComponent={Transition}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          '& .MuiDrawer-paper': {
            height: 'auto',
            maxHeight: '80vh',
            padding: theme.spacing(2),
            boxSizing: 'border-box',
            backgroundColor: '#068986',
            overflow: 'visible',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              fontFamily: theme.typography.fontFamily,
              color: '#FFFFFF',
            }}
          >
            Search
          </Typography>
          <IconButton
            aria-label="close search"
            onClick={handleSearchDrawerClose}
            sx={{ color: '#FFFFFF' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ position: 'relative' }}>
          <Search sx={{ width: '100%', maxWidth: '100%', margin: 0 }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Product Catalog..."
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyPress={handleSearchSubmit}
              endAdornment={
                searchQuery && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClearSearch}
                      aria-label="clear search"
                      edge="end"
                      size="small"
                      sx={{ color: '#FFFFFF' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Search>

          {suggestions.length > 0 && searchQuery.trim() !== '' && (
            <Box
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: '100%',
                backgroundColor: '#FFFFFF',
                color: '#000000',
                boxShadow: theme.shadows[3],
                zIndex: 9999,
                maxHeight: '300px',
                overflowY: 'auto',
                borderRadius: '4px',
                marginTop: '4px',
              }}
            >
              {isFetchingSuggestions && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 1,
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              )}
              {!isFetchingSuggestions &&
                suggestions.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => {
                      handleSuggestionClick(item.short_description);
                    }}
                    sx={{
                      padding: theme.spacing(1),
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: alpha('#068986', 0.1),
                      },
                    }}
                  >
                    {item.short_description}
                  </Box>
                ))}
            </Box>
          )}
        </Box>

        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: theme.spacing(2) }}
        >
          <Button onClick={handleSearchDrawerClose} color="inherit" sx={{ color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button
            onClick={handleSearchSubmit}
            color="inherit"
            disabled={!searchQuery.trim()}
            sx={{ color: '#FFFFFF' }}
          >
            Search
          </Button>
        </Box>
      </Drawer>

      {/* Logout Dialog */}
      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLogoutDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>

      {/* Onboarding Checklist Modal */}
      <Dialog
        open={onboardingModalOpen}
        onClose={handleOnboardingModalClose}
        aria-labelledby="onboarding-modal-title"
      >
        <DialogTitle id="onboarding-modal-title">Get Set Up in Minutes!</DialogTitle>
        <DialogContent dividers>
          {onboardingLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {!onboardingLoading && onboardingData && (
            <>
              <DialogContentText sx={{ mb: 2 }}>
                Complete these steps to supercharge your restaurant operations:
              </DialogContentText>
              <MUIList dense>
                {steps.map((step, index) => {
                  const done = onboardingData[step.key];
                  return (
                    <MUIListItem key={index} sx={{ mb: 1 }}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 'bold',
                              color: done ? '#068986' : 'inherit',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleStepClick(step.key, done)}
                          >
                            {done ? `✓ ${step.title}` : step.title}
                          </Typography>
                        }
                        secondary={step.description}
                      />
                    </MUIListItem>
                  );
                })}
              </MUIList>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {/* "Don't show again" now opens a confirmation first */}
          <Button onClick={handleDismissConfirmOpen} color="primary">
            Don't Show Again
          </Button>
          <Button onClick={handleOnboardingModalClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Secondary confirmation for "Don't Show Again" */}
      <Dialog
        open={dismissConfirmOpen}
        onClose={handleDismissConfirmClose}
        aria-labelledby="dismiss-confirm-dialog-title"
      >
        <DialogTitle id="dismiss-confirm-dialog-title">
          Confirm Hiding Onboarding
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to hide the onboarding steps and not see them again?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismissConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDismissOnboarding} color="primary" autoFocus>
            Yes, Hide
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TopNavBar;
